import * as yup from "yup";
import { Countries } from "./tenderForm.constant";
import { StringSchema } from "yup";
import { isAfter } from "date-fns";
import { UploadedFile, UploadingFile } from "../../common/files/files.module";

export interface Account {
	id: number;
	name: string;
	external_id: string;
}

export interface TenderCreationModel {
	account?: Account;
	tenderName?: string;
	deadline?: Date;
	validityDate?: Date;
	direction: string;
	transactionType: string;
	historicalData: Array<UploadingFile | UploadedFile>;
	bypassWarning: boolean;
	realized_data_will_be_added: boolean;
	pricing_stage: string;
	has_negative_price_clause: boolean;
}

function validateValidityDateAfterDeadline(values: any) {
	const isValid = isAfter(values.validityDate, values.deadline);
	if (!isValid) {
		// @ts-ignore
		return this.createError({
			path: "deadline",
			message: "Validity date must be greater then deadline",
		});
	}
}

export const tenderSchema = yup
	.object({
		account: yup.object({
			id: yup.number().required("Account is a required field"),
			name: yup.string().required("Account is a required field"),
		}),
		realized_data_will_be_added: yup.boolean().required(),
		tenderName: yup.string().required("Tender name is a required field"),
		deadline: yup
			.date()
			.typeError("Deadline is a required field")
			.required("Deadline is a required field"),
		validityDate: yup
			.date()
			.typeError("Validity date is a required field")
			.required("Validity date is a required field"),
		direction: yup
			.string()
			.oneOf(["PPA", "CPPA"])
			.required("Direction is a required field"),
		transactionType: yup
			.string()
			.oneOf(["Physical", "Financial"])
			.required("Transaction type is a required field"),
	})
	.test("validityDateAfterDeadline", validateValidityDateAfterDeadline);

const yupValidateGpsCoordinates = (
	gps_type: string[] | string,
	schema: StringSchema,
	{ key }: any
) => {
	if (gps_type == "degMinSec")
		return schema.matches(
			/^[NSEW]?(\d{0,}(\.\d+)?)*?((?:°|d)(\d{0,})'(\d+(?:\.\d+)?)\"?([NSEW]?))$/g,
			"Please consider adding the right format , ex: N44°1'14.574\" "
		);
	if (gps_type == "lambert93") {
		if (key == "latitude")
			return yup
				.number()
				.required("Latitude is a required field")
				.min(6000000, "Please enter a minimum value of Y > 6000000")
				.max(7200000, "Please enter a Maximum value of Y > 7200000");
		if (key == "longitude")
			return yup
				.number()
				.required("Longitude is a required field")
				.min(100000, "Please enter a minimum value of  X > 100000")
				.max(1300000, "Please enter a Maximum value of  X > 1300000");
		return yup.number();
	}
	if (gps_type == "gaubKruger") {
		if (key == "latitude")
			return yup
				.number()
				.required("Latitude is a required field")
				.min(5200000, "Please enter a minimum value of  Y > 5200000")
				.max(6200000, "Please enter a Maximum value of  Y > 6200000");
		if (key == "longitude")
			return yup
				.number()
				.required("Longitude is a required field")
				.min(2500000, "Please enter a minimum value of  X > 2500000")
				.max(5500000, "Please enter a Maximum value of  X > 5500000");
		return yup.number();
	}
	if (gps_type == "decimalDegree")
		return schema.matches(
			/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/g,
			"Please consider adding the right format , ex: 50.8471913"
		);
	return schema;
};
export const siteSchema = yup.object({
	name: yup.string().required("Site name is a required field"),
	country: yup
		.string()
		.oneOf(Countries.map((element) => element.value))
		.required("Country is a required field"),
	capacity: yup.number().required("Capacity is a required field"),
	latitude: yup
		.string()
		.required("Latitude is a required field")
		.when("gps_type", yupValidateGpsCoordinates),
	longitude: yup
		.string()
		.required("Longitude is a required field")
		.when("gps_type", yupValidateGpsCoordinates),
	technology: yup.string().required("Technology is a required field"),
	hub_height: yup
		.number()
		.when("technology", (technology, schema) => {
			if (!technology) {
				return schema;
			}

			if (technology[0] === "solar") {
				return schema;
			}

			return schema.required("Hub Height (m) is required field");
		})
		.min(1, "Hub Height (m) Must be between 1 and 200")
		.max(200, "Hub Height (m) Must be between 1 and 200"),
	azimuth: yup
		.number()
		.when("technology", (technology, schema) => {
			if (!technology || technology[0] !== "solar") {
				return schema;
			}

			return schema.required("Azimuth (°) is required field");
		})
		.min(0, "Azimuth (°) Must be between 0 and 360")
		.max(360, "Azimuth (°) Must be between 0 and 360"),
	tilt_angle: yup
		.number()
		.when("technology", (technology, schema) => {
			if (!technology || technology[0] !== "solar") {
				return schema;
			}

			return schema.required("Tilt angle (°) is required field");
		})
		.min(0, "Azimuth (°) Must be between 0 and 360")
		.max(360, "Azimuth (°) Must be between 0 and 360"),
});

export const siteImportSchema = yup.object({
	importFileType: yup.string().required("Please select a template type"),
	siteImportFile: yup.array().min(1, "please select a file to continue"),
});
