export const style = {
	container: {
		display: "flex",
		gap: "24px",
		width: "100%",
		overflowHorizontal: "scroll",
	},
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
};
