import { Box } from "@mui/material";
import Modal from "../../../../common/components/Modal";
import { ReactComponent as HistoryIcon } from "../../../../common/assets/icons/history.svg";
import { style } from "./CurtailmentObject.style";
import { CurtailmentObjectGroupType } from "./CurtailmentObject.types";
import CurtailmentObjectHistorySlider from "./CurtailmentObjectHistorySlider";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { CurtailmentObjectType } from "../../../../requests_geco/sitesApi/sitesApi.types";

interface CurtailmentObjectAuditModalProps {
	curtailmentObjectGroup: CurtailmentObjectGroupType;
	curtailmentObjects: CurtailmentObjectType[];
	setOpenModal: (open: string | null) => void;
	openModal: boolean;
}

const CurtailmentObjectAuditModal = ({
	curtailmentObjectGroup,
	curtailmentObjects,
	setOpenModal,
	openModal,
}: CurtailmentObjectAuditModalProps) => {
	const curtailmentObjectsIds = curtailmentObjects.map(
		(curtailmentObject) => curtailmentObject.id
	);

	return (
		<Modal
			onClose={() => setOpenModal(null)}
			open={openModal}
			title={
				<Box sx={style.modalTitle}>
					<HistoryIcon /> Audit of {curtailmentObjectGroup.site}
				</Box>
			}
			maxWidth={false}
			actions={
				<PrimaryButton
					onClick={() => setOpenModal(null)}
					text="Close"
					type="button"
					color="primary"
				/>
			}
		>
			<Box sx={{ display: "flex" }}>
				{curtailmentObjectsIds.map((curtailmentId) => (
					<CurtailmentObjectHistorySlider
						key={curtailmentId}
						curtailmentId={curtailmentId}
						width={`${100 / curtailmentObjectsIds.length}%`}
					/>
				))}
			</Box>
		</Modal>
	);
};

export default CurtailmentObjectAuditModal;
