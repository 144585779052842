import {
	Box,
	IconButton,
	Stack,
	SvgIcon,
	SxProps,
	Theme,
	Link,
	keyframes,
	Tooltip,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReactComponent as EngieLogo } from "../../common/assets/icons/logo.svg";
import { grey, primaryColor, white } from "../../core/theme";
import {
	selectAuthenticatedUser,
	selectIsAuthenticated,
	selectUserGroupNames,
	selectUserGroups,
	selectWsConnected,
} from "../../features/authentication/auth.selector";
import { useDispatch, useSelector } from "react-redux";
import { selectisNewNotificationReceivedFlag } from "../../features/notifications/notifications.selector";
import { setNotificationsDrawerOpened } from "../../features/notifications/notificationsSlice";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import { PATH } from "../../router-path";
import { useNavigate } from "react-router-dom";
import { VpnStatus } from "./VpnStatus";
import { useVpnConnectionCheck } from "../hooks/useVpnConnectionCheck";

const belring = keyframes`
  33% {
    transform: rotate(-30deg);
  }
  66% {
    transform: rotate(30deg);
  }
  99% {
    transform: rotate(0deg);
  }
`;

const style: { [key: string]: SxProps<Theme> } = {
	headerContainer: {
		display: "flex",
		padding: "35px 40px 0",
		borderBottom: `1px solid ${grey}`,
		marginBottom: "40px",
	},
	prod: {
		bgcolor: white,
	},
	dev: {
		background:
			"linear-gradient(180deg, rgba(165,207,182,1) 0%, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 100%);",
	},
	qa: {
		background:
			"linear-gradient(180deg, rgba(98,223,231,1) 0%, rgba(255,255,255,1) 100%, rgba(255,255,255,1) 100%);",
	},
	headerRight: {
		flexGrow: 2,
		display: "flex",
		alignItems: "center",
		justifyContent: "end",
		"& > *": {
			marginLeft: "64px !important",
			height: 40,
		},
		"& > div": {
			marginLeft: "10px !important",
		},
		"& .selected": {
			borderBottom: `2px ${primaryColor} solid`,
		},
	},
	login: {
		fontSize: 14,
		weight: 700,
		color: "#007ACD",
	},
	role: {
		fontSize: 12,
		weight: 400,
		color: "#778C9B",
	},
	navTitle: {
		textDecoration: "none",
		color: "inherit",
		"&:hover": {
			cursor: "pointer",
			opacity: 0.7,
			textDecoration: "none",
		},
		"&.selected": {
			textDecoration: "none",
		},
	},
	newNotification: {
		color: "red",
		animation: `${belring} 5s infinite ease-in-out`,
	},
	logoContainer: {
		display: "flex",
		minHeight: "50px",
		size: "20px",
	},
};

function Header() {
	const navigate = useNavigate();

	const user = useSelector(selectAuthenticatedUser);
	const groups = useSelector(selectUserGroupNames);
	const connected = useSelector(selectWsConnected);
	const { isVpnConnected } = useVpnConnectionCheck();
	const dispatch = useDispatch();

	const isAuthenticated = useSelector(selectIsAuthenticated);
	const currentUserGroup = useSelector(selectUserGroups);
	const isNewNotificationReceived = useSelector(
		selectisNewNotificationReceivedFlag
	);

	const env = process.env.REACT_APP_ENV ?? "prod";
	const spreadSxProp = (s: SxProps<Theme>) => {
		return [...(Array.isArray(s) ? s : [s])];
	};

	return (
		<Box
			sx={[
				...spreadSxProp(style.headerContainer),
				...spreadSxProp(style[env]),
			]}
		>
			<Box sx={style.logoContainer}>
				<Link
					href={PATH.LANDING}
					onClick={(e) => {
						e.preventDefault();
						navigate(PATH.LANDING);
						return false;
					}}
				>
					<SvgIcon
						sx={{ width: 88, height: 32, marginRight: 1 }}
						component={EngieLogo}
						inheritViewBox
					/>
				</Link>
				{connected && (
					<Tooltip title={"Connected"} placement="bottom-start">
						<PowerIcon color={"success"} sx={style.logoContainer} />
					</Tooltip>
				)}
				{!connected && (
					<Tooltip title={"Offline"} placement="bottom-start">
						<PowerOffIcon
							color={"error"}
							sx={style.logoContainer}
						/>
					</Tooltip>
				)}

				<Box sx={{ display: "flex", alignItems: "center" }}>
					<VpnStatus isVpnActif={isVpnConnected} />
				</Box>
			</Box>
			<Box sx={style.headerRight}>
				<Link
					variant="h3"
					href={PATH.TENDERS}
					sx={style.navTitle}
					className={
						window.location.pathname == PATH.TENDERS
							? "selected"
							: ""
					}
					onClick={(e) => {
						e.preventDefault();
						navigate(PATH.TENDERS);
						return false;
					}}
				>
					My&nbsp;Tenders
				</Link>
				{currentUserGroup.isTrader && (
					<Link
						variant="h3"
						href={PATH.PARAMETERS}
						sx={style.navTitle}
						className={
							window.location.pathname == PATH.PARAMETERS
								? "selected"
								: ""
						}
						onClick={(e) => {
							e.preventDefault();
							navigate(PATH.PARAMETERS);
							return false;
						}}
					>
						Pricing Param
					</Link>
				)}
				{currentUserGroup.isTrader && (
					<Link
						variant="h3"
						href={PATH.PRICING_TRADERS}
						sx={style.navTitle}
						className={
							window.location.pathname == PATH.PRICING_TRADERS
								? "selected"
								: ""
						}
						onClick={(e) => {
							e.preventDefault();
							navigate(PATH.PRICING_TRADERS);
							return false;
						}}
					>
						Pricing traders
					</Link>
				)}
				<Link
					variant="h3"
					href={PATH.CONTRACTS}
					sx={style.navTitle}
					className={
						window.location.pathname == PATH.CONTRACTS
							? "selected"
							: ""
					}
					onClick={(e) => {
						e.preventDefault();
						navigate(PATH.CONTRACTS);
						return false;
					}}
				>
					My&nbsp;Contracts
				</Link>
				<Link
					variant="h3"
					href={PATH.SITES}
					sx={style.navTitle}
					className={
						window.location.pathname == PATH.SITES ? "selected" : ""
					}
					onClick={(e) => {
						e.preventDefault();
						navigate(PATH.SITES);
						return false;
					}}
				>
					My&nbsp;Sites
				</Link>
				<Link
					variant="h3"
					href={PATH.REPORTS}
					sx={style.navTitle}
					className={
						window.location.pathname == PATH.REPORTS
							? "selected"
							: ""
					}
					onClick={(e) => {
						e.preventDefault();
						navigate(PATH.REPORTS);
						return false;
					}}
				>
					GEODE&nbsp;tool
				</Link>

				<IconButton
					onClick={() => {
						dispatch(
							setNotificationsDrawerOpened({ opened: true })
						);
					}}
				>
					<NotificationsIcon
						color="primary"
						sx={
							isNewNotificationReceived
								? style.newNotification
								: null
						}
					/>
				</IconButton>
				{isAuthenticated && (
					<>
						<Stack spacing={0}>
							<Box component="span" sx={style.login}>
								{user?.name}
							</Box>
							<Box component="span" sx={style.role}>
								{groups.join(", ")}
							</Box>
						</Stack>
					</>
				)}
			</Box>
		</Box>
	);
}

export default Header;
