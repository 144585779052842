import { borderNeutralSecondary, primaryColor } from "../../../core/theme";

export const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		border: `1px solid ${borderNeutralSecondary}`,
		padding: "24px 16px",
		minWidth: "612px",
		position: "relative",

		email_link: {
			fontFamily: "Lato",
			color: `${primaryColor}`,
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 2,
			textDecorationLine: "underline",
		},
	},
};
