import { Typography } from "@mui/material";
import { textNeutralTertiary } from "../../../core/theme";

export const BreadCrumbsSeparator = () => {
	return (
		<Typography variant="subtitle2" noWrap color={textNeutralTertiary}>
			/
		</Typography>
	);
};
