import { createContext, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PATH } from "../../router-path";
import Header from "../components/Header";

export interface BreadCrumbNode {
	path: string;
	label: string;
}

export interface BreadCrumbContextValue {
	breadCrumbs: BreadCrumbNode[];
	renameCrumbs: (crumbNames: { path: string; newName: string }[]) => void;
}

const defaultBreadCrumbsContextValue: BreadCrumbContextValue = {
	breadCrumbs: [],
	renameCrumbs: (_crumbNames: { path: string; newName: string }[]) => {},
};

export const BreadCrumbsContext = createContext<BreadCrumbContextValue>(
	defaultBreadCrumbsContextValue
);

export const BreadCrumbsProvider = () => {
	const location = useLocation();
	const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbNode[]>([]);

	const removeFirstSlash = (value: string): string => {
		if (value.length > 0 && value[0] == "/") {
			return value.slice(1);
		}
		return value;
	};

	const replaceAllOccurrenceBy = (
		initialString: string,
		searchValue: string,
		replaceBy: string
	) => {
		return initialString
			.split("")
			.map((char) => (char === searchValue ? replaceBy : char))
			.join("");
	};

	const generatePathTree = useCallback((singlePath: string): string[] => {
		// from a path, this function can generate the path tree to that path
		const paths = [];
		let currentPath = "";
		const splitPath = singlePath.split("/");

		for (const subPath of splitPath) {
			currentPath += "/" + subPath;
			paths.push(currentPath);
		}
		return paths;
	}, []);

	const generateCrumbs = useCallback((newPath: string): BreadCrumbNode[] => {
		const pathTree = generatePathTree(newPath);
		const nameTree = pathTree.map((str) =>
			replaceAllOccurrenceBy(removeFirstSlash(str), "/", "_")
		);
		return nameTree.map(
			(name: string, index: number): BreadCrumbNode => ({
				path: pathTree[index],
				label: name,
			})
		);
	}, []);

	const renameCrumbs = useCallback(
		(crumbNames: { path: string; newName: string }[]) => {
			setBreadCrumbs((crumbs: BreadCrumbNode[]) => {
				return crumbs.map((crumb: BreadCrumbNode) =>
					crumbNames.find(
						(crumbName: { path: string; newName: string }) =>
							crumbName.path === crumb.path
					)
						? {
								...crumb,
								label: crumbNames.find(
									(crumbName) => crumbName.path === crumb.path
								)?.newName as string,
						  }
						: crumb
				);
			});
		},
		[]
	);

	useEffect(() => {
		const path = location.pathname;
		if (path.includes(PATH.LANDING)) {
			setBreadCrumbs([]);
		} else {
			setBreadCrumbs(() => generateCrumbs(removeFirstSlash(path)));
		}
	}, [location]);

	return (
		<BreadCrumbsContext.Provider value={{ breadCrumbs, renameCrumbs }}>
			<Header />
			<Outlet />
		</BreadCrumbsContext.Provider>
	);
};
