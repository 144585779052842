import { Box } from "@mui/material";

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		background: "white",
		padding: "24px",
		flex: 1,
	},
};

export interface DefaultTabContentProps extends React.PropsWithChildren<{}> {}

export const DefaultTabContent = (props: DefaultTabContentProps) => {
	return <Box sx={style.container}>{props.children}</Box>;
};
