import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../common/components/CustomButton";
import { getPathWithParameters, PATH } from "../../router-path";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { Box } from "@mui/material";
import { style } from "../contracts_page/ContractsPage.style";
import CircularProgress from "@mui/material/CircularProgress";
import PageContent from "../../common/components/PageContent";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetOneSiteQuery } from "../../requests_geco/sitesApi";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { BreadCrumbs } from "../../common/components/BreadCrumbs";
import { Spacer } from "../../common/components/Spacer";
import { useMemo, useState } from "react";
import TechnologyIcon from "../../common/components/TechnologyIcon";
import _ from "lodash";
import SiteDetailTabs from "./SiteDetailTabs";
import { TechnologyType } from "../../requests_geco/sitesApi/sitesApi.types";
import { MakeClickable } from "../../common/components/MakeClickable";
import { NJLink } from "@engie-group/fluid-design-system-react";
import { ContactModal } from "../contract_period_list/ContactModal";
import Country from "../../common/components/Country";

const SiteDetailPage = () => {
	const [isContactModalOpen, setContactModalOpen] = useState(false);
	const navigate = useNavigate();
	const { siteId } = useParams();

	const { data: dataSite, isLoading: isLoadingSite } =
		useRtkQueryDynamicEndpoint(useGetOneSiteQuery)({
			siteId: Number(siteId!),
		});

	const isLoadingPage = isLoadingSite;

	const crumbsLabels = useMemo(() => {
		if (dataSite) {
			const sitesPath = getPathWithParameters(PATH.SITES, {});
			const sitePath = getPathWithParameters(PATH.SITE_DETAIL, {
				siteId: siteId,
			});
			return [
				{ path: sitesPath, name: "Site list" },
				{ path: sitePath, name: dataSite.name },
			];
		}
		return [];
	}, [dataSite]);

	const numberOfContracts = () => {
		const uniqueContractsIds = _.chain(dataSite)
			.get("service_points", [])
			.flatMap("contractperiods")
			.map("contract.id")
			.uniq()
			.value();

		return uniqueContractsIds.length;
	};

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label={dataSite?.name ?? ""}
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={() => navigate(PATH.SITES)}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
			/>
			{isLoadingPage && (
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			)}

			<PageContent>
				{!isLoadingPage && dataSite && (
					<>
						<BreadCrumbs crumbsLabels={crumbsLabels} />
						<Spacer gap={24} />
						<InfoBlock
							head={[{ label: "Site", value: dataSite.name }]}
							info={[
								{
									label: "TPR ID",
									value: dataSite.tpr_system_id,
								},
								{
									label: "Installed Capacity",
									value: dataSite.installed_capacity,
								},
								{
									label: "Max grid injection",
									value:
										dataSite.max_grid_injection ??
										dataSite.installed_capacity,
								},
								{
									label: "Country",
									value: (
										<Country country={dataSite.country} />
									),
								},
								{ label: "TSO", value: dataSite.tso },
								{ label: "Latitude", value: dataSite.latitude },
								{
									label: "Longitude",
									value: dataSite.longitude,
								},
								{
									label: "#Service Point",
									value: dataSite.service_points?.length ?? 0,
								},
								{
									label: "#Asset",
									value:
										dataSite.service_points?.reduce(
											(total, servicePoint) =>
												total +
												servicePoint.assets?.length,
											0
										) ?? 0,
								},
								{
									label: "#Contract",
									value: numberOfContracts(),
								},
								{
									label: "Techno",
									value: <Box>{dataSite.technology}</Box>,
									icon: (
										<TechnologyIcon
											technology={
												dataSite.technology as TechnologyType
											}
										/>
									),
								},
								{
									label: "Contacts",
									value: (
										<MakeClickable
											onClick={() =>
												setContactModalOpen(true)
											}
										>
											<NJLink href={"#"}>{`${
												(dataSite?.contacts || [])
													?.length
											} contacts`}</NJLink>
										</MakeClickable>
									),
								},
							]}
							withBackground
						/>
						<Spacer gap={24} />
						<SiteDetailTabs site={dataSite} />
					</>
				)}
			</PageContent>
			<ContactModal
				contacts={dataSite?.contacts || []}
				isOpen={isContactModalOpen}
				onClose={() => setContactModalOpen(false)}
			/>
		</>
	);
};

export default SiteDetailPage;
