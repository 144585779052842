import { Box } from "@mui/material";
import CustomAutocomplete from "../../common/components/CustomAutocomplete";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { CounterpartyFormik } from "./formik/counterpartyFormik";
import { useEffect, useMemo } from "react";
import {
	TprPartyDetailType,
	TprPartyListType,
} from "../../requests_geco/gepoTprProxyApi/gepoTprProxyApi.types";
import { ErrorText } from "../../common/components/ErrorText";
import { If } from "../../common/components/If";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";

export interface CounterpartyTabProps {
	values: CounterpartyFormik;
	parties: TprPartyListType[];
	detailParty: TprPartyDetailType | undefined;
	errorMessage: ErrorType;
	isLoading: boolean;
	searchParties: ({ search }: { search: string }) => void;
	getPartyDetail: ({ id }: { id: string }) => void;
	setFieldValue: (field: string, value: any) => void;
	handleChange: (e: React.ChangeEvent<any> | boolean) => void;
}

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: "24px",
	},
};

const CounterpartyTab = ({
	values,
	parties,
	detailParty,
	errorMessage,
	isLoading,
	setFieldValue,
	searchParties,
	getPartyDetail,
}: CounterpartyTabProps) => {
	const partiesResult = useMemo(() => {
		return (parties || []).map((party: TprPartyListType) => ({
			...party,
			id: party.partyId,
		}));
	}, [parties]);

	useEffect(() => {
		if (detailParty) {
			setFieldValue("party", detailParty);
		}
	}, [detailParty]);

	const error = useMemo(() => {
		return errorMessage
			? formatApiErrorMessage(errorMessage as ErrorType)
			: null;
	}, [errorMessage]);

	return (
		<Box sx={style.container}>
			<InfoBlock
				withBackground
				info={[
					{
						label: "Mnemonic",
						value: values?.party?.mnemonic,
					},
					{
						label: "Short Name",
						value: values?.party?.short_name,
					},
					{
						label: "Is Internal",
						value: values?.party?.is_internal ? "Yes" : "No",
					},
				]}
			/>
			<CustomAutocomplete
				fetchData={(search: string) => searchParties({ search })}
				data={partiesResult}
				// @ts-ignore
				setFieldValue={(_fieldName: string, value: TprPartyListType) =>
					getPartyDetail({ id: value?.id as string })
				}
				label="Counter Party"
				name="party"
				loading={isLoading}
				value={values.party}
			/>
			<If condition={error}>
				<ErrorText>{error}</ErrorText>
			</If>
		</Box>
	);
};

export default CounterpartyTab;
