import { useMemo } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Table,
	Box,
	CircularProgress,
	Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { PricingHeader } from "./TableHeader";
import { Pricing } from "../../pricingListSlice";
import { MarginTableRow } from "./TableRow";
import { grey } from "@mui/material/colors";
import { If } from "../../../../common/components/If";
import {
	ValidationError,
	usePydanticErrorFormat,
} from "../../../../common/hooks/usePydanticErrorsFormat";
import { ErrorText } from "../../../../common/components/ErrorText";

const style = {
	contentText: {
		marginTop: 20,
		marginBottom: 10,
	},
	tableWrapper: {
		marginTop: "30px",
		maxWidth: "100%",
		maxHeight: "100%",
		border: `1px solid ${grey}`,
		overflow: "scroll",
	},
};

interface PricingModalProps extends React.PropsWithChildren<{}> {
	open: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	forPricings: Pricing[];
	isLoading: boolean;
	title: string;
	subTitle: string;
	errorMessage: string | ValidationError | undefined | null;
	isSubmitDisabled: boolean;
	isEmptyHidden?: boolean;
}

export default function PricingModal(props: PricingModalProps) {
	const {
		open,
		forPricings,
		title,
		subTitle,
		isSubmitDisabled,
		children,
		isLoading,
		isEmptyHidden = false,
		errorMessage = "",
		onClose,
		onSubmit,
	} = props;

	const { getRootErrorMessage } = usePydanticErrorFormat(errorMessage);

	const isPowerbridgeIssue = useMemo(
		() => JSON.stringify(subTitle).includes("powerbridge"),
		[subTitle]
	);

	return (
		<Dialog
			PaperProps={{ style: { minHeight: "28%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "50%" } }}
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent sx={{ marginTop: "24px" }}>
				{children}
				<If condition={isPowerbridgeIssue}>
					<DialogContentText>
						Unknown error returned by powerbridge. Please send email
						with the stack trace here:{" "}
						<a href="mailto:oussama.salhi@external.engie.com">
							Send Email
						</a>
					</DialogContentText>
				</If>
				<If condition={getRootErrorMessage()}>
					<ErrorText>{getRootErrorMessage()}</ErrorText>
				</If>
				<DialogContentText style={style.contentText}>
					{JSON.stringify(subTitle)
						.split("\\n")
						.map((errorLine) => (
							<p key={errorLine}>{errorLine}</p>
						))}
				</DialogContentText>
				<Box sx={style.tableWrapper}>
					<If
						condition={(forPricings?.length || 0) > 0 && !isLoading}
					>
						<Table
							stickyHeader
							size="small"
							aria-label="simple table"
							style={{ tableLayout: "fixed" }}
						>
							<PricingHeader />
							<TableBody>
								{forPricings.map((pricing, index) => (
									<MarginTableRow
										key={index}
										pricingRow={pricing}
									/>
								))}
							</TableBody>
						</Table>
					</If>
					<If condition={isLoading}>
						<CircularProgress color="inherit" size={20} />
					</If>
					<If
						condition={
							(forPricings?.length || 0) === 0 &&
							!isLoading &&
							!isEmptyHidden
						}
					>
						<Typography component="h5">
							No pricings available
						</Typography>
					</If>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()}>Close</Button>
				<If condition={!!onSubmit}>
					<Button
						disabled={
							isSubmitDisabled || (forPricings?.length || 0) === 0
						}
						onClick={onSubmit}
					>
						Submit
					</Button>
				</If>
			</DialogActions>
		</Dialog>
	);
}
