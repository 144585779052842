import FiltersDrawer, {
	FieldValue,
	FiltersField,
	Operations,
	retrieveOperationFromFieldName,
	retrieveValueFromFieldName,
} from "../../common/components/FiltersDrawer";
import { GenericReferential } from "../../requests_geco/referentialApi/referentialApi.types";

import FluidSelectMultiple from "../../common/components/FluidSelectMulti";
import { noop } from "../../common/utils/operations";
import { useMemo } from "react";
import ArrayInput from "../../common/components/ArrayInput";
import { FluidTextField } from "../../common/components/FluidTextField";

interface SiteListPageFiltersProps {
	countries: GenericReferential[];
	tsos: GenericReferential[];
	isOpen: boolean;
	onClose: () => void;
	onApplyFilters: (data: Record<string, FieldValue>) => void;
	defaultFilters?: Record<string, FieldValue>;
}

const SiteListPageFilters = ({
	countries,
	tsos,
	isOpen,
	onClose,
	onApplyFilters,
	defaultFilters = {},
}: SiteListPageFiltersProps) => {
	const countryOptions = useMemo(() => {
		return countries.map((country) => ({
			label: country.name,
			value: country.id,
		}));
	}, [countries]);

	const tsoOptions = useMemo(() => {
		return tsos.map((tso) => ({
			label: tso.name,
			value: tso.id,
		}));
	}, [tsos]);

	return (
		<FiltersDrawer
			title={"Sites Filters"}
			isOpen={isOpen}
			onClose={onClose}
			onApplyFilters={onApplyFilters}
			defaultFilters={defaultFilters}
		>
			<FiltersField
				name={"id"}
				label="Site ID"
				defaultOperation={
					retrieveOperationFromFieldName("id", defaultFilters) ||
					Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName("id", defaultFilters) || []
				}
				allowedOperations={[Operations.IN, Operations.EQ]}
				selector={(data: string[]) =>
					data.map((item) => item.replace(/[^0-9,]/g, ""))
				}
				Input={
					<ArrayInput
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"country_id"}
				label="Country"
				defaultOperation={
					retrieveOperationFromFieldName(
						"country_id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName("country_id", defaultFilters) ||
					[]
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={countryOptions}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"site_servicepoints__servicepoint__tso__id"}
				label="Servicepoint TSO"
				defaultOperation={
					retrieveOperationFromFieldName(
						"site_servicepoints__servicepoint__tso__id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"site_servicepoints__servicepoint__tso__id",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={tsoOptions}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>

			<FiltersField
				name={"site_servicepoints__servicepoint__code"}
				label="Servicepoint Code"
				defaultOperation={Operations.EQ}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"site_servicepoints__servicepoint__code",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.EQ]}
				//selector={(event: any) => event.target.value}
				Input={
					<FluidTextField
						label={""}
						name={""}
						onChange={noop}
						value={""}
					/>
				}
			/>
			<FiltersField
				name={
					"site_contractperiods__contractperiod__contract__book__name"
				}
				label="Contract book name"
				defaultOperation={Operations.ILIKE}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"site_contractperiods__contractperiod__contract__book__name",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.ILIKE]}
				Input={
					<FluidTextField
						label={""}
						name={""}
						onChange={noop}
						value={""}
					/>
				}
			/>
			<FiltersField
				name={"site_contractperiods__contractperiod__contract__name"}
				label="Contract name"
				defaultOperation={Operations.ILIKE}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"site_contractperiods__contractperiod__contract__name",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.ILIKE]}
				Input={
					<FluidTextField
						label={""}
						name={""}
						onChange={noop}
						value={""}
					/>
				}
			/>
		</FiltersDrawer>
	);
};

export default SiteListPageFilters;
