import { createSlice } from "@reduxjs/toolkit";
import { ParamItem } from "./ParametersPage.schema";

export interface ParametersState {
	loader: {
		getPricingParams?: boolean;
		sendPricingParams?: boolean;
	};
	errors: {
		pricingParams?: boolean;
		featureFlags?: string;
	};
	pricingParams: string | undefined;
	parameters: ParamItem[];
	featureFlags: { [key: string]: boolean };
}

const initialState: ParametersState = {
	loader: {
		getPricingParams: true,
		sendPricingParams: false,
	},
	errors: {},
	pricingParams: undefined,
	parameters: [],
	featureFlags: {},
};

export const ParametersRequestSlice = createSlice({
	name: "parameters",
	initialState,
	reducers: {
		setGetPricingPramsRequestLoader: (state) => {
			state.loader.getPricingParams = true;
		},
		getPricingParamsRequestSuccess: (state, action) => {
			state.parameters = action.payload;
			state.pricingParams = action.payload[0].value;
			state.loader.getPricingParams = false;
		},
		getPricingParamsRequestError: (state, action) => {
			state.errors.pricingParams = action.payload;
			state.loader.getPricingParams = false;
		},
		setPricingParamsRequestSuccess: (state, action) => {
			state.pricingParams = action.payload;
			state.loader.sendPricingParams = false;
			state.errors.pricingParams = false;
		},
		setPricingParamsRequestError: (state, action) => {
			state.errors.pricingParams = action.payload;
			state.loader.sendPricingParams = false;
		},
		setSendingPricingPramsRequestLoader: (state) => {
			state.loader.sendPricingParams = true;
		},
		getFeatureFlagsRequestSuccess: (state, action) => {
			state.featureFlags = action.payload;
		},
		getFeatureFlagsRequestError: (state, action) => {
			state.errors.featureFlags = action.payload;
		},
	},
});

export const {
	setPricingParamsRequestSuccess,
	setPricingParamsRequestError,
	getPricingParamsRequestError,
	getPricingParamsRequestSuccess,
	setSendingPricingPramsRequestLoader,
	setGetPricingPramsRequestLoader,
	getFeatureFlagsRequestSuccess,
	getFeatureFlagsRequestError,
} = ParametersRequestSlice.actions;

export default ParametersRequestSlice.reducer;
