export const replacePlaceholderWithData = function (
	text: string,
	variables: any[string]
) {
	const placeholders: string[] = text.match(/{(.*?)\}/g) ?? [];
	placeholders.map((placeholder: string) => {
		const key = placeholder
			.replaceAll(" ", "")
			.replaceAll("{", "")
			.replaceAll("}", "");
		text = text.replaceAll(placeholder, variables[key]);
	});
	return text;
};
