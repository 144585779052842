import { useEffect, useState } from "react";
import { differenceInSeconds, isBefore } from "date-fns";
import { getColor, SVGCircle } from "./CountdownTimer.helpers";
import style from "./CountdownTimer.style";
import { Box } from "@mui/material";

const CountdownTimer = ({
	targetDate,
	size = 20,
	sx,
}: {
	targetDate: Date;
	size: number;
	sx?: any;
}) => {
	const [countdown, setCountdown] = useState<number | null>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			const then = targetDate;
			const now = new Date();

			if (isBefore(now, then)) {
				setCountdown(differenceInSeconds(then, now));
			} else {
				setCountdown(0);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	if (countdown === null) {
		return null;
	}

	const minutes = Math.floor(countdown / 60);
	const seconds = countdown % 60;

	const color = getColor(minutes);

	return (
		<Box sx={{ ...style.countdownTimer({ color, size }), ...sx }}>
			<SVGCircle seconds={seconds} />
			<Box sx={style.countdownText}>
				{minutes === 0 && seconds === 0 ? 0 : minutes + 1}
			</Box>
		</Box>
	);
};

export default CountdownTimer;
