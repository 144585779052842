import { Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import BookingLogsContainer from "./BookingLogsContainer";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "./BookingLogs.style";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";

const BookingLogsDrawer = ({
	contractPeriodId,
}: {
	contractPeriodId: number;
}) => {
	const [open, setOpen] = useState(false);

	const onClose = () => {
		setOpen(false);
	};

	return (
		<>
			<IconButton
				edge="end"
				aria-label="logs"
				onClick={() => setOpen(true)}
				sx={style.handler}
			>
				<LibraryBooksOutlinedIcon />
			</IconButton>
			<Drawer anchor={"right"} open={open} onClose={onClose}>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={style.closeButton}
				>
					<CloseIcon />
				</IconButton>
				<BookingLogsContainer contractPeriodId={contractPeriodId} />
			</Drawer>
		</>
	);
};

export default BookingLogsDrawer;
