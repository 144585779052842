import { borderNeutralSecondary } from "../../../../../core/theme";

export const style = {
	container: {
		padding: "16px 24px",
		display: "flex",
		flexDirection: "column",
		border: `1px solid ${borderNeutralSecondary}`,
		width: "max-content",
		gap: "24px",
	},
};
