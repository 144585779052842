import { Tender } from "../../features/tender_page/tender.module";
import { TenderStatus } from "../../features/pricing_list/pricingListSlice";
import { UserGroups } from "../../features/authentication/authSlice";
import { User } from "../../features/authentication/auth.model";
import { isFalsy } from "../utils/isFalsy";

export const canUserCloneTender = (groups: UserGroups) => {
	return !!groups.isOriginator;
};

export const isTenderInNonCancellableStatus = (tender: Tender) => {
	return [
		TenderStatus.LOST,
		TenderStatus.WON,
		TenderStatus.CANCELLED,
		TenderStatus.BOOKED,
	].includes(tender?.status as TenderStatus);
};

export const isTenderInNonPickableStatus = (tender: Tender) => {
	return [TenderStatus.LOST, TenderStatus.CANCELLED].includes(
		tender?.status as TenderStatus
	);
};

export const isUserCanPickUpTender = (
	tender: Tender,
	currentUser: User | undefined,
	groups: UserGroups
) => {
	if (isTenderInNonPickableStatus(tender)) {
		return false;
	}
	const tenderOriginatorGaia = tender?.originator?.gaia_id;
	const couldBecomeTenderOriginator =
		groups?.isOriginator &&
		(!currentUser?.preferred_username
			?.toUpperCase()
			?.includes(tenderOriginatorGaia?.toUpperCase() || "") ||
			!tenderOriginatorGaia);
	const tenderTraderGaia = tender?.trader?.gaia_id;
	const couldBecomeTenderTrader =
		groups?.isTrader &&
		(!currentUser?.preferred_username
			?.toUpperCase()
			?.includes(tenderTraderGaia?.toUpperCase() || "") ||
			!tenderTraderGaia);
	return !!(couldBecomeTenderOriginator || couldBecomeTenderTrader);
};

export const isUserOriginatorAssignedToTender = (
	tender: Tender,
	currentUser: User | undefined
) => {
	const tenderOriginatorGaia = tender?.originator?.gaia_id;
	if (!tenderOriginatorGaia) {
		return false;
	}
	if (!isFalsy(currentUser)) {
		return (
			!!currentUser?.preferred_username?.includes(
				tenderOriginatorGaia?.toUpperCase() || ""
			) || currentUser?.preferred_username == tenderOriginatorGaia
		); // test purposes
	}
	return false;
};

export const isUserTraderAssignedToTender = (
	tender: Tender,
	currentUser: User | undefined
) => {
	const tenderTraderGaia = tender?.trader?.gaia_id;

	if (!tenderTraderGaia) {
		return false;
	}

	if (!isFalsy(currentUser)) {
		return (
			!!currentUser?.preferred_username?.includes(
				tenderTraderGaia?.toUpperCase() || ""
			) || currentUser?.preferred_username == tenderTraderGaia
		);
	}
	return false;
};

export const isUserAuthorizedToCreatePricings = (
	tender: Tender,
	currentUser: User | undefined
) => {
	return (
		![TenderStatus.CANCELLED].includes(tender?.status as TenderStatus) &&
		(isUserOriginatorAssignedToTender(tender, currentUser) ||
			isUserTraderAssignedToTender(tender, currentUser))
	);
};

export const isUserAbleToCancelTender = (
	tender: Tender,
	currentUser: User | undefined
) => {
	return !!(
		isUserOriginatorAssignedToTender(tender, currentUser) &&
		!isTenderInNonCancellableStatus(tender)
	);
};

export const isUserAbleToCreateOffer = (
	tender: Tender,
	currentUser: User | undefined
) => {
	return isUserOriginatorAssignedToTender(tender, currentUser);
};

export const isUserAbleUpdateOffer = (
	tender: Tender,
	currentUser: User | undefined
) => {
	return (
		![TenderStatus.CANCELLED, TenderStatus.BOOKED].includes(
			tender?.status as TenderStatus
		) && isUserOriginatorAssignedToTender(tender, currentUser)
	);
};

export const isUserAbleUpdateFilesOnTender = (
	tender: Tender,
	currentUser: User | undefined
) => {
	return (
		![TenderStatus.CANCELLED, TenderStatus.BOOKED].includes(
			tender?.status as TenderStatus
		) && isUserTraderAssignedToTender(tender, currentUser)
	);
};
