import { Portfolio, Site } from "../../features/sites/sites.module";

export const formatCoordinates = (
	decimals: number,
	site: Site
): { latitude: string; longitude: string } => {
	const latitude = site?.coordinates?.DECIMAL?.latitude
		? parseFloat(site?.coordinates?.DECIMAL?.latitude).toFixed(decimals)
		: (site.latitude || 0).toFixed(decimals);
	const longitude = site?.coordinates?.DECIMAL?.longitude
		? parseFloat(site?.coordinates?.DECIMAL?.longitude).toFixed(decimals)
		: (site.longitude || 0).toFixed(decimals);
	return { latitude, longitude };
};

export const makeLatLongColumn = (
	latitudeAccessor: (site: Site | Portfolio) => string,
	longitudeAccessor: (site: Site | Portfolio) => string
) => {
	return [
		{
			id: "11",
			header: () => "Latitude",
			xs: 1,
			accessor: latitudeAccessor,
		},
		{
			id: "12",
			header: () => "Longitude",
			xs: 1,
			accessor: longitudeAccessor,
		},
	];
};
