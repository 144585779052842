import { lightGrey, pagePadding } from "../../../core/theme";

export const style = {
	pageWrapper: {
		padding: `${pagePadding}`,
		backgroundColor: "white",
	},
	h2: {
		fontSize: "1.5rem",
		marginBottom: "1rem",
	},
	h3: {
		fontWeight: "bold",
	},
	contractInfos: {
		paddingLeft: "40px",
		paddingBottom: "40px",
		display: "flex",
		gap: "32px",
		fontSize: "14px",
	},
	infoBlock: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	},
	section: {
		border: "1px solid gray",
		backgroundColor: lightGrey,
		padding: "16px",
		marginBottom: "32px",
	},
	rows: {
		display: "flex",
		flexDirection: "column",
	},
	cols: {
		display: "flex",
		alignItems: "center",
		padding: "32px 0",
		gap: "40px",
	},
	label: {
		width: "240px",
	},
	dateInput: {
		"& p": {
			backgroundColor: lightGrey,
		},
	},

	buttons: {
		display: "flex",
		justifyContent: "flex-end",
		gap: "16px",
		paddingTop: "32px",
	},
};
