import { NJFormItem } from "@engie-group/fluid-design-system-react";
import { Box } from "@mui/material";
import FluidSelect from "../../../../common/components/FluidSelect";
import { NegativePriceType } from "../../../../requests_geco/contractsApi/contractsApi.types";
import { getSyntheticEvent } from "../../../../common/utils/getSyntheticEvent";
import { Spacer } from "../../../../common/components/Spacer";
import { useMemo } from "react";
import { NegativePriceIndex } from "../../../../requests_geco/referentialApi/referentialApi.types";

const style = {
	container: {
		width: 162,
	},
};

export interface NegativePriceInputsProps {
	onChange: (event: any) => void;
	currentNegativePriceIndex?: number;
	negativePriceIndexes: NegativePriceIndex[];
	negativePriceValue?: number;
	negativePriceType?: NegativePriceType;
	isReadOnly?: boolean;
}

export const NegativePriceInputs = (props: NegativePriceInputsProps) => {
	const availableOptions = useMemo(() => {
		return (props.negativePriceIndexes || []).map(
			(index: NegativePriceIndex) => ({
				value: `${index.orchestrade_id}`,
				label: index.name,
			})
		);
	}, [props.negativePriceIndexes]);

	return (
		<>
			<Box sx={style.container}>
				<FluidSelect
					isLabelStatic
					items={availableOptions}
					label={"Negative Price Index"}
					name={"negative_price_index"}
					// @ts-ignore does not provides an HTMLElement as it should but the value itself
					onChange={(index: number) =>
						props.onChange(
							getSyntheticEvent("negative_price_index", index)
						)
					}
					value={`${props.currentNegativePriceIndex}`}
					isReadOnly={props.isReadOnly}
				/>
			</Box>
			<Box sx={style.container}>
				<FluidSelect
					isReadOnly={props.isReadOnly}
					isLabelStatic
					items={[
						{
							label: NegativePriceType.PRICE,
							value: NegativePriceType.PRICE,
						},
						{
							label: NegativePriceType.CENTS,
							value: NegativePriceType.CENTS,
						},
					]}
					label={"Negative Price Type"}
					name={"negative_price_type"}
					// @ts-ignore does not provides an HTMLElement as it should but the value itself
					onChange={(type: NegativePriceType) =>
						props.onChange(
							getSyntheticEvent("negative_price_type", type)
						)
					}
					value={props.negativePriceType}
				/>
			</Box>
			<Spacer gap={24} />
			<Box sx={style.container}>
				<NJFormItem
					id="negative_price_value"
					label="Negative Price Value"
					labelKind="static"
					name="negative_price_value"
					type="number"
					// @ts-ignore Typing issue coming from package, expecting string...
					value={props.negativePriceValue}
					onChange={props.onChange}
					title="Negative Price Value"
					wrap="soft"
					readOnly={props.isReadOnly}
				/>
			</Box>
		</>
	);
};
