import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography, SxProps, Theme, IconButton } from "@mui/material";
import { primaryColor, white } from "../../core/theme";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { useMemo } from "react";

const style: { [key: string]: SxProps<Theme> } = {
	autocompletInput: {
		bgcolor: white,
	},

	autocompleteContainer: {
		maxHeight: 40,
		marginBottom: "16px",
		"& label": {
			top: -8,
		},
		"& div": {
			borderRadius: 0,
			height: 40,
			"& .MuiOutlinedInput-root": {
				padding: "0 8px",
			},
		},
	},
	tagContainer: {
		display: "flex",
		flexFlow: "row wrap",
	},
	tag: {
		backgroundColor: primaryColor,
		color: "white",
		padding: "0 10px",
		borderRadius: "50px",
		display: "flex",
		alignItems: "center",
		marginRight: 1,
		marginBottom: 1,
		"& p": {
			marginRight: "5px",
			marginBottom: 0,
		},
		"& button": {
			color: "white",
		},
	},
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Data {
	name: string;
	id?: string | number;
}

interface CheckboxesTagsProps<T extends Data> extends StandardTextFieldProps {
	name: string;
	value: T[];
	options: T[];
	setFieldValue: (fieldName: string, value: T[]) => void;
	trackBy?: keyof T;
	disabled?: boolean;
	label: string;
	["data-testid"]?: string;
}

export const SPECIAL_TAG_ALL_ID = "SPECIAL_TAG_ALL_ID";

export default function CheckboxesTags<T extends Data>(
	propsTag: CheckboxesTagsProps<T>
) {
	const {
		name,
		value,
		setFieldValue,
		error,
		helperText,
		options,
		disabled,
		label,
	} = propsTag;
	const adornment = useMemo(() => `${value.length} items selected`, [value]);
	const trackBy = propsTag.trackBy || "id";

	const removeElement = (element: any) => {
		setFieldValue(
			name,
			value.filter((option: any) => option[trackBy] != element[trackBy])
		);
	};

	return (
		<>
			<Autocomplete
				data-testid={propsTag["data-testid"]}
				autoComplete={true}
				multiple
				disabled={disabled ?? false}
				options={options}
				disableCloseOnSelect
				getOptionLabel={(option: T) => option.name}
				isOptionEqualToValue={(option: any, item: any) => {
					return option[trackBy] == item[trackBy];
				}}
				onChange={(event: any, newValue: T[]) => {
					setFieldValue(name, newValue);
				}}
				value={value}
				renderOption={(props, option, { selected }) => {
					return (
						<li {...props}>
							<Checkbox
								data-testid={
									propsTag["data-testid"] + "-" + option.name
								}
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={
									option[trackBy] === SPECIAL_TAG_ALL_ID
										? value.length >= options.length - 1 // -1 because "select all" options count in it
										: selected
								}
							/>
							{option.name}
						</li>
					);
				}}
				style={{ width: "100%" }}
				sx={style.autocompleteContainer}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							name={name}
							error={!!error}
							helperText={helperText}
							sx={style.autocompletInput}
							InputProps={{
								...params.InputProps,
								startAdornment: value.length ? adornment : "",
							}}
							label={label}
						/>
					);
				}}
			/>
			<Box sx={style.tagContainer as any}>
				{value.map((option: any) => (
					<Box sx={style.tag as any} key={option.id || option.name}>
						<Typography paragraph>{option.name}</Typography>
						<IconButton
							color={"primary"}
							onClick={() => removeElement(option)}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>
				))}
			</Box>
		</>
	);
}
