import { useNavigate, useLocation } from "react-router-dom";
import { useCallback } from "react";

type GoBackOptions = {
	fallback?: string;
};

export const useGoBack = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return useCallback(
		(options: GoBackOptions = {}) => {
			const { fallback = "/" } = options;
			const thereIsAPrevPage = location.key !== "default";

			if (thereIsAPrevPage) {
				navigate(-1);
			} else {
				navigate(fallback);
			}
		},
		[location.key, navigate]
	);
};
