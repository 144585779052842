import { RootState } from "../../core/rootReducers";
import * as _ from "lodash";

export const selectCommodityFixingsState = (state: RootState) =>
	_.sortBy(state.pricingRequestForm.commodityFixings, "id");
export const selectPricingTemplateState = (state: RootState) =>
	state.pricingRequestForm.templates;

export const selectPricingTemplateAddLoader = (state: RootState) =>
	state.pricingRequestForm.loader.addNewTemplate;

export const selectIsPricingTemplateAdded = (state: RootState) =>
	state.pricingRequestForm.success.addTemplate;

export const selectPricingTemplateErrorMessage = (state: RootState) =>
	state.pricingRequestForm.errors.addNewTemplate;
