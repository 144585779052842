import { PaginationType } from "./gecoTypes";

type QueryArgsType = { [key: string]: any };
interface ResultType {
	id: number;
}
interface CacheType<T extends ResultType> {
	pagination: PaginationType;
	result: T[];
}

export const isLastPage = <T extends ResultType>(list: CacheType<T>) =>
	list.pagination.pages === 0 ||
	list.pagination.page === list.pagination.pages;

export const serializeQueryArgs = ({
	queryArgs,
}: {
	queryArgs: QueryArgsType;
}) => {
	const newQueryArgs = {
		...queryArgs,
	} as any;
	delete newQueryArgs.endpoint;
	delete newQueryArgs.page;

	return newQueryArgs;
};

export const merge = <T extends ResultType>(
	currentCache: CacheType<T>,
	newItems: CacheType<T>
) => {
	const uniqueResult = newItems.result.reduce(
		(acc, item) => {
			if (!acc.uniqueIds.has(item.id)) {
				acc.uniqueIds.add(item.id);
				acc.result.push(item);
			}
			return acc;
		},
		{
			uniqueIds: new Set<number>(
				currentCache.result.map((item) => item.id)
			),
			result: [...currentCache.result],
		}
	).result;

	return {
		...newItems,
		result: uniqueResult,
	};
};
