import { Box, CircularProgress } from "@mui/material";
import { ControlObjectCard } from "./ControlObjectCard";
import { style } from "./ControlObjectsTab.style";
import { useEffect, useMemo } from "react";
import { useLazyGetSiteControlObjectsQuery } from "../../../../requests_geco/sitesApi";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../../../common/components/If";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../../parameters/ParametersPage.selector";
import { FeatureName } from "../../../../common/hooks/useFeatureFlags";
import NoDataMessage from "../../../../common/components/NoDataMessage";

export interface ControlObjectsTabProps {
	siteId: number;
}

export const ControlObjectsTab = ({ siteId }: ControlObjectsTabProps) => {
	// TODO: waiting for the fix on geco side to provide host for file path
	const features = useSelector(selectFeatureFlags);
	const gecoEndpoint = useMemo(
		() => features[FeatureName.geco_endpoint],
		[features]
	) as unknown as string;

	const [
		getSiteControlObjectBase,
		{ isLoading, error, data: controlObjects },
	] = useLazyGetSiteControlObjectsQuery();
	const getSiteControlObject = useRtkQueryDynamicEndpoint(
		getSiteControlObjectBase
	);
	useEffect(() => {
		if (siteId) {
			getSiteControlObject({ siteId });
		}
	}, []);

	return (
		<>
			<If condition={isLoading}>
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			</If>
			<If condition={!isLoading}>
				<Box sx={style.container}>
					{!controlObjects?.length && <NoDataMessage />}
					{!!controlObjects?.length &&
						(controlObjects || []).map((controlObject, index) => (
							<ControlObjectCard
								key={index}
								active={index === 0}
								controlObject={controlObject}
								gecoEndpoint={gecoEndpoint}
							/>
						))}
				</Box>
			</If>
			<ErrorMessageModal
				title={`Cannot retrieve control objects on site ${siteId}`}
				content={formatApiErrorMessage(error as ErrorType)}
			/>
		</>
	);
};
