import { RootState } from "../../core/rootReducers";

const selectSitesState = (state: RootState) => state.sites;

export function selectSites(state: RootState) {
	const siteState = selectSitesState(state);
	return siteState.sites;
}

export function selectPage(state: RootState) {
	const siteState = selectSitesState(state);
	return siteState.page;
}

export function selectLoading(state: RootState) {
	const siteState = selectSitesState(state);
	return siteState.loading;
}
