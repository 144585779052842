import { Formik } from "formik";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../formik/counterpartyFormik";
import { FormikAutoSubmit } from "../../../common/components/FormikAutoSubmit";
import CounterpartyTab from "../CounterpartyTab";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_geco/gepoTprProxyApi";

export interface CounterpartyTabProps {
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
}

const CounterpartyTabForm = ({ contract, onSubmit }: CounterpartyTabProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmit}
		>
			{({
				handleChange,
				setFieldValue,
				handleSubmit,
				values,
				submitForm,
			}) => (
				<form onSubmit={handleSubmit}>
					<FormikAutoSubmit onSubmitForm={submitForm} values={values}>
						<CounterpartyTab
							values={values}
							parties={parties?.partyResults || []}
							detailParty={detailParty}
							errorMessage={detailPartyError as ErrorType}
							isLoading={isDetailLoading || isSearchLoading}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							searchParties={searchParties}
							getPartyDetail={getPartyDetail}
						/>
					</FormikAutoSubmit>
				</form>
			)}
		</Formik>
	);
};

export default CounterpartyTabForm;
