import { apiPatch, apiPost } from "../utils/request.utils";
import axios from "axios";
import { isUploadedFile, UploadedFile } from "./files.module";
import { AppDispatch } from "../../store";
import { getTender } from "../../features/tender_form/tenderForm.thunk";

export interface PreSignedUploadResponse {
	parts: string[];
	file_id: number;
	upload_id: string;
	on_failure: string;
}

const CHUNK_SIZE = 5242880; // 5Mib

function sliceFile(file: File) {
	let start = 0;
	const chunks = [];
	while (start < file.size) {
		chunks.push(file.slice(start, start + CHUNK_SIZE));
		start += CHUNK_SIZE;
	}
	return chunks;
}

export function uploadFile(file: File | UploadedFile, prefix: string = "") {
	return async function () {
		if (isUploadedFile(file)) {
			return file;
		}
		const response = await apiPost("files", {
			file_name: file.name,
			file_extension: file.name.split(".").reverse()[0],
			file_size: file.size,
			prefix,
		});
		const presignedUpload: PreSignedUploadResponse = response.data;
		// using a custom axios instance to avoid the interceptor adding headers
		// and to be able to use another url
		const axiosInstance = axios.create();
		const chunks = sliceFile(file);
		const proms = chunks.map(async (chunk, index) => {
			const config = {
				headers: {
					"content-type": false,
					accept: "application/json",
				},
			};
			const partresponse = await axiosInstance.put(
				presignedUpload.parts[index],
				chunk,
				config
			);
			return {
				ETag: partresponse.headers.etag?.slice(1, -1),
				PartNumber: index + 1,
			};
		});
		try {
			const partsResponse = await Promise.all(proms);
			await apiPost("files/complete", {
				upload_id: presignedUpload.upload_id,
				file_id: presignedUpload.file_id,
				parts: partsResponse,
			});
		} catch (err: any) {
			await axiosInstance.delete(presignedUpload.on_failure);
			throw err;
		}

		return response.data as UploadedFile;
	};
}

export function linkFileToTender(fileId: number, tenderId: number) {
	return async function (dispatch: AppDispatch) {
		await apiPatch("/files/" + fileId, { tender_id: tenderId });
		dispatch(getTender(tenderId, false));
	};
}

export function validateBaseCapacityFile(fild_id: number) {
	return async function () {
		try {
			await apiPost("/pricing/validate-base-profile-volume", {
				file_id: fild_id,
			});
			return [];
		} catch (err: any) {
			if (!err?.response?.data?.errors) {
				return ["unknown error"];
			}
			return err.response.data.errors;
		}
	};
}
