import React, { useEffect } from "react";
import { Formik } from "formik";
import { PrimaryButton } from "./CustomButton";
import CustomInput from "./CustomInput";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { borderGrey } from "../../core/theme";

const style: { [key: string]: SxProps<Theme> } = {
	searchInput: {
		minWidth: "325px",
		"& svg": {
			color: borderGrey,
		},
	},
};

export interface SearchFieldProps {
	onSearch: (search: string) => void;
	defaultValue?: string;
}

export function SearchField(props: SearchFieldProps) {
	useEffect(() => {
		if (props.defaultValue) {
			props.onSearch(props.defaultValue);
		}
	}, [props.defaultValue]);

	return (
		<Formik
			initialValues={{ search: props.defaultValue || "" }}
			onSubmit={(values) => {
				props.onSearch(values.search.trim());
			}}
			enableReinitialize={true}
		>
			{({ handleChange, handleSubmit, values }) => (
				<form onSubmit={handleSubmit}>
					<CustomInput
						id="search"
						aria-label="search-input"
						value={values.search}
						onChange={handleChange}
						sx={style.searchInput}
						placeholder="Search"
						InputProps={{
							startAdornment: <SearchIcon />,
						}}
					></CustomInput>

					<PrimaryButton
						text="Search"
						type="submit"
						color="secondary"
						disabled={false}
						sx={{ marginRight: "16px", width: 106 }}
					></PrimaryButton>
				</form>
			)}
		</Formik>
	);
}
