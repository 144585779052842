import {
	PersistedFilters,
	setPersistedFilters,
} from "../../features/filters/filtersSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const usePersistedFilters = (): PersistedFilters | undefined => {
	const dispatch = useDispatch();
	const [filters, setFilters] = useState<PersistedFilters>();
	useEffect(() => {
		// This hook will be usefull if we decide to use local storage again.
		// we might need to version this information (in case we need to overwrite it following breaking change)
		// const values = {
		// 	tenders_query: localStorage.getItem("Tenders_query") ?? "",
		// 	tenders_filters: localStorage.getItem("Tenders_filters") ?? "",
		// 	tenders_filters_count: Number(localStorage.getItem("Tenders_filters_count") ?? 0),
		// 	tenders_filter_template: localStorage.getItem("Tenders_filter_template") ?? "",
		// 	pricings_query: localStorage.getItem("Pricings_query") ?? "",
		// 	pricings_filters: localStorage.getItem("Pricings_filters") ?? "",
		// 	pricings_filters_count: Number(localStorage.getItem("Pricings_filters_count") ?? 0),
		// 	pricings_filter_template: localStorage.getItem("Pricings_filter_template") ?? ""
		// };
		const values = {
			tenders_query: "",
			tenders_filters: "",
			tenders_filters_count: 0,
			tenders_filter_template: "",
			pricings_query: "",
			pricings_filters: "",
			pricings_filters_count: 0,
			pricings_filter_template: "",
		};

		setFilters(values);
		dispatch(setPersistedFilters(values));
	}, []);

	return filters;
};
