import { useEffect, useState } from "react";
import * as _ from "lodash";
import usePrevious from "./usePrevious";

export function useDeepMemo<T>(factory: () => T, dependencies: any[]) {
	const [value, setValue] = useState(factory());
	const previousDependencies = usePrevious(dependencies);

	useEffect(() => {
		if (!_.isEqual(dependencies, previousDependencies)) {
			setValue(factory());
		}
	});
	return value;
}
