import { NJTabs } from "@engie-group/fluid-design-system-react";
import { ReactElement } from "react";

export interface TabsProps extends React.PropsWithChildren<{}> {
	activeTab?: string;
	label?: string;
	density?: "normal" | "spacious" | "stretched" | "compact";
	onClickTabItem?: (event: any) => void;
}

const Tabs = ({
	activeTab,
	label = "",
	density = "normal",
	children,
	onClickTabItem,
}: TabsProps) => {
	return (
		<NJTabs
			density={density}
			activeTab={activeTab}
			label={label}
			onClickTabItem={onClickTabItem}
		>
			{children as ReactElement}
		</NJTabs>
	);
};

export default Tabs;
