import { createSlice } from "@reduxjs/toolkit";
import { GroupingField } from "../metadata/metadata.module";
import { GroupedResponseCount } from "./grouping.module";

export interface GroupingState {
	pricings: {
		selectedGroups: GroupingField[];
		count: {
			isLoading: boolean;
			error: any;
			data: GroupedResponseCount | null;
		};
	};
}

export const groupingInitialState: GroupingState = {
	pricings: {
		selectedGroups: [],
		count: {
			isLoading: false,
			error: null,
			data: null,
		},
	},
};

export const groupingSlice = createSlice({
	name: "grouping",
	initialState: groupingInitialState,
	reducers: {
		addGroup: (state, { payload }) => {
			state.pricings.selectedGroups.push(payload);
		},
		removeGroup: (state, { payload }) => {
			state.pricings.selectedGroups =
				state.pricings.selectedGroups.filter(
					(group) => group.field_key !== payload.field_key
				);
		},
		setGroups: (state, { payload }) => {
			state.pricings.selectedGroups = payload.groups;
		},
		clearGroups: (state) => {
			state.pricings.selectedGroups = [];
		},
		startLoadingGroupCount: (state) => {
			state.pricings.count.error = null;
			state.pricings.count.isLoading = true;
		},
		groupCountSuccess: (state, { payload }) => {
			state.pricings.count.isLoading = false;
			state.pricings.count.data = payload;
		},
		groupCountFailure: (state, { payload }) => {
			state.pricings.count.isLoading = false;
			state.pricings.count.error = payload;
		},
	},
});

export const {
	addGroup,
	removeGroup,
	setGroups,
	clearGroups,
	startLoadingGroupCount,
	groupCountFailure,
	groupCountSuccess,
} = groupingSlice.actions;

export default groupingSlice.reducer;
