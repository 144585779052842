export function capitalize(it?: string) {
	if (!it) {
		return "";
	}
	return it
		?.split("")
		.map((char, index) =>
			index > 0 ? char.toLowerCase() : char.toUpperCase()
		)
		.join("");
}
