import { Pricing } from "../../features/pricing_list/pricingListSlice";

export const siteNameOrPortfolio = (pricing?: Pricing) => {
	if (pricing?.portfolio_id && pricing?.portfolio) {
		return pricing.portfolio.name;
	}
	return (
		pricing?.site_pricings
			?.map((site_pricing) =>
				site_pricing.site.specific_name
					? site_pricing.site.specific_name
					: site_pricing.site.name
			)
			.join(",") || ""
	);
};
