import { apiGet } from "../../common/utils/request.utils";
import { RootState } from "../../core/rootReducers";
import { AppDispatch } from "../../store";
import { GroupedResponseCount } from "./grouping.module";
import { selectPricingSelectedGroupingFields } from "./grouping.selector";
import * as groupingActions from "./grouping.slice";
import * as _ from "lodash";

function makeGroupCountAsNewUpdate(groupCount: GroupedResponseCount) {
	return groupCount.map((group) => {
		const newGroup = { ...group, isNewUpdate: true };
		if (group.children?.length) {
			newGroup.children = makeGroupCountAsNewUpdate(group.children);
		}
		return newGroup;
	});
}

export function loadPricingsGroupCount(
	tenderId: number,
	odataQuery?: string,
	isNewUpdate?: boolean
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		if (!isNewUpdate) {
			dispatch(groupingActions.startLoadingGroupCount());
		}
		try {
			const groups = selectPricingSelectedGroupingFields(getState());
			let endpoint = `/pricing/grouped?tender_id=${tenderId}&groups=${_.map(
				groups,
				"field_key"
			)}`;
			if (odataQuery) {
				endpoint += `&filters=${odataQuery}`;
			}
			const response = await apiGet(endpoint);
			const { data } = response.data;
			if (isNewUpdate) {
				dispatch(
					groupingActions.groupCountSuccess(
						makeGroupCountAsNewUpdate(data)
					)
				);
			} else {
				dispatch(groupingActions.groupCountSuccess(data));
			}
		} catch (err: any) {
			if (err.response) {
				if (!isNewUpdate) {
					dispatch(groupingActions.groupCountFailure(err.response));
				}
			} else if (err.request) {
				if (!isNewUpdate) {
					dispatch(
						groupingActions.groupCountFailure("Unable to reach API")
					);
				}
			} else {
				if (!isNewUpdate) {
					dispatch(
						groupingActions.groupCountFailure(
							"Internal Error: " + err
						)
					);
				}
			}
		}
	};
}
