import { useCallback, useMemo, useState } from "react";
import { Tender } from "../tender.module";
import * as _ from "lodash";

export function useTenderSelection(tenders: Tender[]) {
	const [selectedTenders, setSelectedTenders] = useState<Tender[]>([]);

	const toggleTenderSelection = useCallback(
		(tender: Tender) => {
			setSelectedTenders((prev) => _.xorBy(prev, [tender], "id"));
		},
		[setSelectedTenders]
	);

	const clearSelectedTenders = useCallback(() => {
		setSelectedTenders([]);
	}, [setSelectedTenders]);

	const selectAllTenders = useCallback(() => {
		setSelectedTenders(tenders);
	}, [tenders]);

	const selectedIds = useMemo(
		() => selectedTenders.map((tender) => tender.id),
		[selectedTenders]
	);

	const availableActions = useMemo(
		() =>
			_.intersectionBy(
				...selectedTenders.map((tender) => tender.actions_blotter),
				"action"
			).filter(
				(action) =>
					action.is_batchable ||
					(action.redirection_url && selectedTenders.length === 1)
			),
		[selectedTenders]
	);

	return {
		selectedTenders,
		toggleTenderSelection,
		clearSelectedTenders,
		selectAllTenders,
		selectedIds,
		availableActions,
	};
}
