import { apiGet, apiPost } from "../../common/utils/request.utils";
import { RootState } from "../../core/rootReducers";
import { AppDispatch } from "../../store";
import { setPickLists } from "../filters/filtersSlice";
import { Filter, ModelMetadata, PickLists } from "./metadata.module";
import * as actions from "./metadata.slice";
import * as _ from "lodash";

async function getFilterPickList(
	state: RootState,
	filter: Filter,
	queryString: string,
	incomingItem?: { id: string; name: string; filterKey: string }
) {
	if (filter.http_source) {
		const pickListId =
			filter.field_key === "pricing_type" ||
			filter.field_key === "site" ||
			filter.field_key === "portfolio" ||
			filter.field_key === "delivery-periods" ||
			filter.field_key === "parties"
				? `${filter.field_key}_${queryString}`
				: filter.field_key;
		const cachedList = state.filters.lists.find(
			(f: PickLists) => f.id === pickListId
		);

		if (cachedList && incomingItem) {
			if (filter.field_key === incomingItem.filterKey) {
				const items = [...cachedList.items];
				items.push({ id: incomingItem.id, name: incomingItem.name });
				return { ...filter, pickList: items, pickListId };
			}
		}

		if (cachedList) {
			return { ...filter, pickList: cachedList.items, pickListId };
		}

		const response = await apiPost(
			filter.http_source.replace("/{id}", queryString),
			filter.filter_data
		);
		const dataList = response?.data?.data ?? response?.data;
		if (incomingItem) {
			dataList.push(incomingItem);
		}
		return { ...filter, pickList: dataList, pickListId };
	}
	return filter;
}

export function getMetadata(
	page: string,
	tenderId?: number,
	incomingItem?: { id: string; name: string; filterKey: string }
) {
	return async function (dispatch: AppDispatch, getState: () => RootState) {
		dispatch(actions.startLoading());
		let path = "";
		let queryString = "";
		switch (page.toLowerCase()) {
			case "tenders":
				path = "tenders";
				break;
			case "pricings":
				path = "pricing";
				if (tenderId) {
					queryString = `/${tenderId}`;
				}
				break;
			default:
				break;
		}
		try {
			const state = getState();
			const cachedMetadata = state.metadata.cachedMetadatas.find(
				(c: actions.CachedMetadata) =>
					c.id === `${path}/metadata${queryString}`
			);
			let metadata: ModelMetadata;
			if (cachedMetadata) {
				metadata = _.cloneDeep(cachedMetadata.metadata);
			} else {
				const response = await apiGet(`${path}/metadata${queryString}`);
				metadata = _.cloneDeep(response.data);
			}
			metadata.filters.fields = await Promise.all(
				metadata.filters.fields.map((filter) => {
					if (
						[
							"pricing_type",
							"delivery-periods",
							"parties",
						].includes(filter.field_key) &&
						incomingItem
					) {
						return getFilterPickList(
							getState(),
							filter,
							queryString,
							incomingItem
						);
					}

					return getFilterPickList(getState(), filter, queryString);
				})
			);

			const lists = [...state.filters.lists];
			const cachedLists: PickLists[] = [];
			metadata.filters.fields.forEach((field: any) => {
				if (field.pickListId) {
					const cachedListIndex = lists.findIndex(
						(f: PickLists) => f.id === field.pickListId
					);
					if (cachedListIndex === -1) {
						cachedLists.push({
							id: field.pickListId,
							items: field.pickList,
						});
					} else {
						cachedLists[cachedListIndex] = {
							id: field.pickListId,
							items: field.pickList,
						};
					}
				}
			});

			const aggregatedList = [...lists];
			cachedLists.forEach((item) => {
				const index = aggregatedList.findIndex((l) => l.id === item.id);
				if (index === -1) {
					aggregatedList.push(item);
				} else {
					aggregatedList[index] = item;
				}
			});

			dispatch(setPickLists({ lists: aggregatedList }));
			dispatch(
				actions.success({
					page,
					metadata,
					id: `${path}/metadata${queryString}`,
				})
			);
		} catch (err) {
			dispatch(actions.failure(err));
		}
	};
}
