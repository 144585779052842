import { TableCell } from "@mui/material";
import React from "react";

export interface PricingTableCellProps extends React.PropsWithChildren<{}> {
	size: number;
}

export function PricingTableCell(props: PricingTableCellProps) {
	return (
		<TableCell
			sx={{
				border: 0,
				width: props.size,
			}}
			align="left"
		>
			{props.children}
		</TableCell>
	);
}
