import { pagePadding } from "../../../core/theme";

export const style = {
	container: {
		marginBottom: "24px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: `0 ${pagePadding}`,
	},
	leftSideContainer: {
		display: "flex",
		gap: "16px",
		alignItems: "center",
	},
	label: {
		fontSize: "2.25rem",
	},
};
