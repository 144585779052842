import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";
import { SxProps, Theme } from "@mui/material";
import { white } from "../../core/theme";
import { SystemProps } from "@mui/system";

const style: { [key: string]: SxProps<Theme> } = {
	datePicker: {
		bgcolor: white,
		"& div": {
			borderRadius: 0,
			height: 40,
		},
	},
};

type Props<TDate> = {
	name: string;
	lastDayOfTheMonth?: boolean;
	onChange?: (val: TDate | null) => void;
} & Omit<DatePickerProps<TDate>, "value">;
const CustomDatePicker = <TInputDate, TDate = TInputDate>(
	props: Props<TDate>
) => {
	const { name, lastDayOfTheMonth, ...restProps } = props;
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();
	const onChange = (val: TDate | null) => {
		let newVal: Date | null = val as Date | null;
		if (lastDayOfTheMonth && newVal) {
			newVal = new Date(newVal.getFullYear(), newVal.getMonth() + 1, 0);
		}
		setFieldValue(name, newVal);
		if (props.onChange) {
			props.onChange(val);
		}
	};
	return (
		<DatePicker
			format="dd/MM/yyyy"
			{...restProps}
			sx={{ ...(style.datePicker as SystemProps<Theme>), ...props.sx }}
			value={field.value ?? null}
			onChange={onChange}
		/>
	);
};

export default CustomDatePicker;
