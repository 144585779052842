import { createSlice } from "@reduxjs/toolkit";

export interface Trader {
	id: number;
	gaia_id: string;
	name: string;
	email: string;
	last_assigned_to_a_tender?: Date;
	on_duty: boolean;
	last_assigned: boolean;
	assigned_tenders: number;
	on_duty_order?: number;
}

export interface PricingTradersState {
	loader: {
		traders?: boolean;
	};
	errors: {
		traders?: string;
	};
	traders: Trader[];
}

const initialState: PricingTradersState = {
	loader: {
		traders: true,
	},
	errors: {},
	traders: [],
};

export const PricingTradersSlice = createSlice({
	name: "pricing-traders",
	initialState,
	reducers: {
		getTradersSuccess: (state, action) => {
			const traders = action.payload.map((m: Trader) => ({
				...m,
				last_assigned: false,
			}));

			const sortedTraders = traders
				.filter((m: Trader) => m.last_assigned_to_a_tender && m.on_duty)
				.sort((a: Trader, b: Trader) =>
					a.last_assigned_to_a_tender! < b.last_assigned_to_a_tender!
						? 1
						: a.last_assigned_to_a_tender! >
						  b.last_assigned_to_a_tender!
						? -1
						: 0
				);

			if (sortedTraders.length) {
				const lastAssignedTrader = traders.find(
					(m: Trader) => m.id === sortedTraders[0].id
				);
				if (lastAssignedTrader) {
					lastAssignedTrader.last_assigned = true;
				}
			}

			state.traders = traders;
			state.loader.traders = false;
		},
		getTradersError: (state, action) => {
			state.errors.traders = action.payload;
			state.loader.traders = false;
		},
	},
});

export const { getTradersSuccess, getTradersError } =
	PricingTradersSlice.actions;

export default PricingTradersSlice.reducer;
