import { ReactComponent as VpnActif } from "../assets/icons/vpnActif.svg";
import { ReactComponent as VpnInActif } from "../assets/icons/vpnInActif.svg";
import { If } from "./If";

export interface VpnStatusProps {
	isVpnActif: boolean;
}

export const VpnStatus = ({ isVpnActif = false }: VpnStatusProps) => {
	return (
		<>
			<If condition={isVpnActif}>
				<VpnActif />
			</If>
			<If condition={!isVpnActif}>
				<VpnInActif />
			</If>
		</>
	);
};
