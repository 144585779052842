import { Box } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
	colorPaletteBlue600,
	textNeutralTertiary,
} from "../../../../../core/theme";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import { Spacer } from "../../../../../common/components/Spacer";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { useState } from "react";
const style = {
	container: {
		width: "580px",
		paddingLeft: "24px",
		paddingRight: "24px",
		paddingBottom: "24px",
	},
	titleIcon: {
		color: colorPaletteBlue600,
	},
	description: {
		color: textNeutralTertiary,
		fontSize: 20,
		fontWeight: 400,
		fontFamily: "Lato",
	},
	inputsContainer: {
		gap: "40px",
		display: "flex",
	},
};

export interface GenerateCurtailmentObjectsProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: ({
		startDate,
		endDate,
	}: {
		startDate: string;
		endDate: string;
	}) => void;
}

export const GenerateCurtailmentObjects = ({
	isOpen,
	onClose,
	onSubmit,
}: GenerateCurtailmentObjectsProps) => {
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			title={"Generate Curtailment Objects"}
			sx={style.container}
			icon={
				<span style={style.titleIcon}>
					<AddCircleOutlineOutlinedIcon />
				</span>
			}
			actions={
				<>
					<PrimaryButton
						onClick={onClose}
						text="Cancel"
						type="button"
						color="secondary"
					/>
					<PrimaryButton
						onClick={() => {
							onSubmit({ startDate, endDate });
						}}
						text="Generate"
						type="button"
						color="primary"
						startIcon={<AddCircleOutlineOutlinedIcon />}
					/>
				</>
			}
		>
			<Box sx={style.description}>
				Please specify the start and end dates for the period you want
				to generate curtailment objects.
			</Box>
			<Spacer gap={32} />
			<Box sx={style.inputsContainer}>
				<FluidDatePicker
					onChange={setStartDate}
					name={"start_date"}
					label={"Start Date (optional)"}
					value={startDate}
				/>
				<FluidDatePicker
					onChange={setEndDate}
					name={"end_date"}
					label={"End Date (optional)"}
					value={undefined}
				/>
			</Box>
			<Spacer gap={32} />
		</Modal>
	);
};
