import {
	NJListItem,
	NJMultiSelect,
} from "@engie-group/fluid-design-system-react";
import { kebabCase } from "../utils/kebabCase";
import { Typography } from "@mui/material";
import { ErrorText } from "./ErrorText";

interface FluidSelectMultipleProps {
	items: { value: string; label?: string }[];
	label: string;
	name: string;
	onChange?: ((e: React.ChangeEvent<HTMLElement>[]) => any) &
		((newValue: string) => void);
	value?: string[];
	hasError?: boolean;
	errorMessage?: string;
	isRequired?: boolean;
	isLabelStatic?: boolean;
	isDisabled?: boolean;
	description?: string;
}

const FluidSelectMultiple = ({
	items,
	label,
	name,
	onChange,
	value,
	errorMessage = "",
	hasError = false,
	isRequired = false,
	isLabelStatic = false,
	isDisabled = false,
	description = "",
}: FluidSelectMultipleProps) => {
	return (
		<>
			<NJMultiSelect
				isDisabled={isDisabled}
				buttonDefaultValueLabel=""
				id={kebabCase(label)}
				labelKind={isLabelStatic ? "static" : "floating"}
				label={label}
				listNavigationLabel=""
				name={name}
				// @ts-ignore
				onChange={onChange}
				value={value}
				hasError={hasError}
				isRequired={isRequired}
			>
				{items.map((item) => (
					<NJListItem key={item.value} value={item.value}>
						{item.label}
					</NJListItem>
				))}
			</NJMultiSelect>
			<Typography variant="subtitle2">{description}</Typography>
			<ErrorText>{errorMessage}</ErrorText>
		</>
	);
};

export default FluidSelectMultiple;
