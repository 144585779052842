import { RootState } from "../../core/rootReducers";
import { getPathWithParameters, PATH } from "../../router-path";
import {
	NotificationEventType,
	NotificationItemType,
} from "./notificationsSlice";

const selectNotificationsState = (state: RootState) => state.notifications;

export function addUrlOnNotification(notification: NotificationItemType) {
	switch (notification.event_type) {
		case NotificationEventType.PICKUP:
			return notification;
		case NotificationEventType.NEW_TENDER:
			return {
				...notification,
				url: getPathWithParameters(PATH.EDIT_TENDER, {
					tenderId: notification?.entity_id,
				}),
			};
		case NotificationEventType.PRICING_FIXED:
		case NotificationEventType.PRICING_FIXED_APPROVED:
		case NotificationEventType.PRICING_FIXING_REQUEST:
			return {
				...notification,
				url: getPathWithParameters(PATH.TENDER_PRICING_RUN, {
					tenderId: notification?.entity_id,
				}),
			};
		default:
			return notification;
	}
}

export function selectIsNotificationsDrawerOpened(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.opened;
}

export function selectAllNotifications(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.notificationsList
		.filter((notification) => !!notification)
		.map(addUrlOnNotification);
}

export function selectisNewNotificationReceivedFlag(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.isNewNotificationReceived;
}

export function selectIsLoading(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.isLoading;
}

export function selectHasNextPage(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.hasNextPage;
}

export function selectOldestReadNotificationId(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.oldestReadNotificationId;
}

export function selectOldestUnReadNotificationId(state: RootState) {
	const notificationsState = selectNotificationsState(state);
	return notificationsState.oldestUnReadNotificationId;
}
