import {
	AccessToken,
	OktaAuth,
	TokenManagerInterface,
} from "@okta/okta-auth-js";

export const getOktaToken = async () => {
	const authClient: OktaAuth = new OktaAuth({
		issuer: process.env.REACT_APP_ISSUER,
		clientId: process.env.REACT_APP_CLIENT_ID,
	});
	const tokenManager: TokenManagerInterface = authClient.tokenManager;

	const accessToken: AccessToken = (await tokenManager.get(
		"accessToken"
	)) as AccessToken;
	return accessToken?.accessToken;
};
