import {
	Box,
	SxProps,
	Theme,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import { lightGrey } from "../../../core/theme";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import {
	updatePricingParameter,
	getPricingParameter,
} from "../ParametersPage.thunk";
import { setSendingPricingPramsRequestLoader } from "../ParametersPage.slice";
import {
	selectSendPricingParamLoader,
	selectGetPricingParamLoader,
	selectPricingParamState,
	selectAllPricingParams,
} from "../ParametersPage.selector";
import { useDispatch, useSelector } from "react-redux";
import { OverlayLoader } from "../../../common/components/OverlayLoader";
import PageTitle from "../../../common/components/PageTitle/PageTitle";
import PageContent from "../../../common/components/PageContent";
import StyledTextarea from "../../../common/components/StyledTextarea";
import { selectUserGroups } from "../../authentication/auth.selector";
import { formatStringDate } from "../../../common/utils/dateUtils";
import { If } from "../../../common/components/If";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		padding: "56px 40px",
		bgcolor: lightGrey,
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
	},
	titleContainer: {
		display: "flex",
		height: 80,
		width: "100%",
		alignItems: "center",
	},
	contentContainer: {
		textAlign: "center",
		display: "flex",
		flexDirection: "row",
		width: "100%",
		gap: "32px",
	},
	section: {
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		minWidth: "400px",
	},
	buttonSave: {
		marginBottom: "2em",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "space-around",
	},
	versionButtonContainer: {
		display: "flex",
		marginTop: "16px",
		justifyContent: "flex-start",
	},
	comment: {
		backgroundColor: "white",
		padding: "16px",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "column",
		borderRadius: "5px",
		marginTop: "16px",
	},
	commentDialog: {
		minWidth: "800px",
		minHeight: "50%",
	},
	commentDialogBody: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		flex: 1,
	},
	versionSelector: {
		backgroundColor: "white",
	},
};

function ParametersPage() {
	const [pricingConstant, setPricingConstant] = useState<string | undefined>(
		undefined
	);
	const [error, setError] = useState<any>(undefined);
	const dispatch = useDispatch();
	const isLoading = useSelector(selectGetPricingParamLoader);
	const isSending = useSelector(selectSendPricingParamLoader);
	const pricingParams = useSelector(selectPricingParamState);
	const allParams = useSelector(selectAllPricingParams);
	const isReadOnly = !useSelector(selectUserGroups)?.isAdmin;
	const [previousVersion, setPreviousVersion] = useState("");

	useEffect(() => {
		dispatch(getPricingParameter());
	}, []);

	useEffect(() => {
		if (pricingParams) {
			var JSONInPrettyFormat = JSON.stringify(
				pricingParams,
				undefined,
				4
			);
			setPricingConstant(JSONInPrettyFormat);
			setPreviousVersion("" + allParams[0].id);
		}
	}, [pricingParams, allParams]);

	const formatJsonParam = () => {
		if (pricingConstant) {
			try {
				var JSONInPrettyFormat = JSON.stringify(
					JSON.parse(pricingConstant),
					undefined,
					4
				);
				setPricingConstant(JSONInPrettyFormat);
				setError(undefined);
			} catch (e: any) {
				setError(e);
				enqueueSnackbar(e.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
			}
		}
	};

	const onPreviousVersionSelectedChanges = useCallback(
		(event: SelectChangeEvent) => {
			setPreviousVersion(event.target.value as string);
		},
		[setPreviousVersion]
	);

	const selectedPreviousVersion = useMemo(
		() =>
			allParams.filter(
				(param) => "" + param.id === "" + previousVersion
			)[0],
		[previousVersion, allParams]
	);

	const onUsePreviousVersion = useCallback(() => {
		const selectedPreviousVersionValue = selectedPreviousVersion.value;
		setPreviousVersion("");
		setPricingConstant(
			JSON.stringify(selectedPreviousVersionValue, undefined, 4)
		);
	}, [selectedPreviousVersion, allParams]);

	const [comment, setComment] = useState("");
	const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

	const savePricingParameters = async () => {
		try {
			if (!isReadOnly && pricingConstant) {
				setIsCommentModalOpen(false);
				dispatch(setSendingPricingPramsRequestLoader());
				await dispatch(
					updatePricingParameter(JSON.parse(pricingConstant), comment)
				);
				setError(undefined);
				setComment("");
			}
		} catch (e: any) {
			setError(e);
			enqueueSnackbar(e.message, {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	return (
		<>
			<PageTitle
				label={
					"Pricing Param" +
					(allParams.length ? ` (v${allParams[0].id})` : "")
				}
			/>
			<PageContent>
				<Box sx={style.container}>
					{isLoading && <OverlayLoader />}

					{!isLoading && (
						<Box sx={style.contentContainer}>
							<Box sx={style.section}>
								<Box sx={style.buttonContainer}>
									<PrimaryButton
										text="Format JSON"
										type="submit"
										color="secondary"
										onClick={formatJsonParam}
										sx={style.buttonSave}
										disabled={isSending}
										loader={isSending}
									></PrimaryButton>
									<PrimaryButton
										text="Save"
										type="submit"
										color="primary"
										onClick={() =>
											setIsCommentModalOpen(true)
										}
										sx={style.buttonSave}
										disabled={isSending || isReadOnly}
										loader={isSending}
									></PrimaryButton>
								</Box>
								{error && (
									<FormHelperText
										sx={{ fontSize: 15 }}
										error={!!error}
									>
										Invalid Json Format. <br />{" "}
										{error.message}
									</FormHelperText>
								)}
								<FormControl>
									<StyledTextarea
										readOnly={isReadOnly}
										onChange={(e) => {
											if (!isReadOnly)
												setPricingConstant(
													e.target.value
												);
										}}
										value={pricingConstant}
										minRows={30}
									/>
								</FormControl>
							</Box>
							<Box sx={style.section}>
								<FormControl>
									<InputLabel id="params-previous-versions">
										Previous versions
									</InputLabel>
									<Select
										labelId="params-previous-versions"
										id="params-previous-versions-select"
										label="Previous versions"
										onChange={
											onPreviousVersionSelectedChanges
										}
										value={previousVersion}
										sx={style.versionSelector}
									>
										{allParams.map((param) => (
											<MenuItem
												key={param.id}
												value={param.id}
											>
												{`${param.id} - ${
													param.created_by?.gaia_id ||
													"System"
												} - ${formatStringDate(
													param?.created_at
												)}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<If
									condition={selectedPreviousVersion?.comment}
								>
									<Box sx={style.comment}>
										{selectedPreviousVersion?.comment
											.split("\n")
											.map((content, index) => (
												<Typography key={index}>
													{content || <br />}
												</Typography>
											))}
									</Box>
								</If>
								<Box sx={style.versionButtonContainer}>
									<PrimaryButton
										text="Use this version"
										type="button"
										color="primary"
										onClick={onUsePreviousVersion}
										disabled={!previousVersion}
									></PrimaryButton>
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			</PageContent>
			<Dialog
				sx={style.commentDialog}
				open={isCommentModalOpen}
				onClose={() => setIsCommentModalOpen(false)}
				fullWidth={true}
				maxWidth={"md"}
			>
				<DialogTitle>Please describe your changes</DialogTitle>
				<DialogContent sx={style.commentDialogBody}>
					<TextField
						multiline
						rows={8}
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					></TextField>
				</DialogContent>
				<DialogActions>
					<PrimaryButton
						text="Save"
						type="button"
						color="primary"
						onClick={savePricingParameters}
						disabled={!comment}
					></PrimaryButton>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ParametersPage;
