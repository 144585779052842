import _ from "lodash";
import { Operations, FieldValue } from "./FiltersDrawer.type";

function retrieveFilterKeyFromFieldName(
	fieldName: string,
	filters: Record<string, FieldValue>
): string | undefined {
	// filterKey contain the field name plus an operator
	// this function helps to retrieve the filter key and value
	const keys = Object.keys(filters);
	const filterKeyIndex = _.findIndex(keys, (key: string) =>
		key.includes(fieldName)
	);
	return keys[filterKeyIndex];
}

export function retrieveOperationFromFieldName(
	fieldName: string,
	filters: Record<string, FieldValue>
): Operations | undefined {
	// filter key are defined as follow [field]__[operator] ex: name__eq
	const filterKey = retrieveFilterKeyFromFieldName(fieldName, filters) || "";
	const operatorFieldSplit = filterKey.split("__");
	if (operatorFieldSplit.length > 1) {
		return operatorFieldSplit[operatorFieldSplit.length - 1] as Operations;
	}
}

export function retrieveValueFromFieldName(
	fieldName: string,
	filters: Record<string, FieldValue>
): FieldValue | undefined {
	const filterKey = retrieveFilterKeyFromFieldName(fieldName, filters) || "";
	return filters[filterKey];
}
