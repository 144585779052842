import {
	backgroundNeutralSecondary,
	borderBrandSubtle,
	borderNeutralModerate,
	borderNeutralSecondary,
	colorPaletteBlue400,
	primaryColor,
	textNeutralContrast,
	textNeutralTertiary,
} from "../../../../core/theme";
import { CurtailmentObjectSubtypeType } from "../../../../requests_geco/contractsApi/contractsApi.types";

export const style: { [key: string]: any } = {
	cartridge: {
		color: textNeutralContrast,
		height: "20px",
		background: "rgba(5, 43, 80, 8%)",
		display: "inline-block",
		borderRadius: "10px",
		padding: "0 8px",
	},
	brandCartridge: {
		color: "white",
		backgroundColor: primaryColor,
	},
	wrapper: { background: "white", padding: "8px" },
	siteBlockHeader: {
		padding: "0 24px",
		minHeight: "64px",
		display: "flex",
		alignItems: "center",
		gap: "16px",
	},
	siteName: {
		whiteSpace: "nowrap",
	},
	siteBlockHandler: {
		display: "flex",
		alignItems: "center",
		gap: "16px",
		cursor: "pointer",
	},
	tagsWrapper: {
		display: "flex",
		flexWrap: "wrap",
		gap: "8px",
	},
	collapseWrapper: {
		display: "flex",
		flexWrap: "wrap",
		padding: "24px",
		paddingTop: "0",
		gap: "8px",
	},
	curtailmentKindWrapper: {
		border: `1px solid ${borderNeutralSecondary}`,
		padding: "16px",
	},
	curtailmentObjectList: { display: "flex", gap: "8px" },
	curtailmentObject: {
		background: backgroundNeutralSecondary,
		border: `1px solid ${borderNeutralSecondary}`,
		padding: "16px",
		display: "flex",
		flexDirection: "column",
		gap: "8px",
	},
	dataTitle: {
		color: textNeutralTertiary,
		display: "flex",
	},
	changedCartridge: {
		border: `1px solid ${borderBrandSubtle}`,
		borderRadius: "12px",
		height: "24px",
		display: "flex",
		alignItems: "center",
		textTransform: "uppercase",
		color: primaryColor,
		fontSize: "12px",
		padding: "0 8px",
		margin: "-1px 0 0 8px",
	},
	dataValueChanged: {
		fontWeight: "bold",
	},
	iconsWrapper: {
		display: "flex",
		paddingTop: "16px",
	},
	icon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "40px",
		flexGrow: "1",
	},
	modalTitle: {
		display: "flex",
		alignItems: "center",
		gap: "16px",
	},
	curtailmentObjectModalDetailsWrapper: { display: "flex", gap: "48px" },
	curtailmentObjectModalDetails: (subtype: CurtailmentObjectSubtypeType) => ({
		borderLeft: `1px solid ${
			subtype === CurtailmentObjectSubtypeType.GRID
				? colorPaletteBlue400
				: borderNeutralModerate
		}`,
		paddingLeft: "16px",
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		margin: "24px 0",
		alignSelf: "start",
	}),
};
