import { Box, InputLabel, Slider } from "@mui/material";
import CustomInput from "../../../../../common/components/CustomInput";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Formik, FormikValues } from "formik";
import CustomSelect from "../../../../../common/components/CustomSelect";
import {
	AssetType,
	ServicePointType,
	TechnologyType,
} from "../../../../../requests_geco/sitesApi/sitesApi.types";
import { getAssetValidationSchema } from "./AddAssetsForm.schema";
import { GenericReferential } from "../../../../../requests_geco/referentialApi/referentialApi.types";
import { ErrorText } from "../../../../../common/components/ErrorText";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";

const AssetFormSolar = ({
	onClose,
	servicePoints,
	upsertSingleAsset,
	machines,
	asset,
	technology,
}: {
	onClose: () => void;
	servicePoints: ServicePointType[];
	upsertSingleAsset: (newAssets: FormikValues) => void;
	machines: GenericReferential[];
	technology: TechnologyType;
	asset?: AssetType;
}) => {
	return (
		<Formik
			initialValues={{
				commissioning_date: asset?.commissioning_date ?? "",
				installed_capacity: asset?.installed_capacity ?? 0,
				key: asset?.key ?? "",
				latitude: asset?.latitude ?? 0,
				longitude: asset?.longitude ?? 0,
				name: asset?.name ?? "",
				azimuth: asset?.azimuth ?? 130,
				tilt_angle: asset?.tilt_angle ?? 40,
				manufacturer_serial_number:
					asset?.manufacturer_serial_number ?? "",
				subsidy: asset?.subsidy ?? "",
				sr_code: asset?.sr_code ?? "",
				tr_code: asset?.tr_code ?? "",
				tr_mastr_no: asset?.tr_mastr_no ?? "",
				machine: String(asset?.machine?.id ?? ""),
				service_point:
					servicePoints.length === 1 ? servicePoints[0].id : "",
			}}
			validationSchema={getAssetValidationSchema(technology)}
			onSubmit={(values) => {
				upsertSingleAsset(values);
				onClose();
			}}
		>
			{({
				errors,
				handleChange,
				handleSubmit,
				submitForm,
				touched,
				values,
			}) => (
				<form onSubmit={handleSubmit}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "16px",
							padding: "16px 0",
						}}
					>
						<Box>
							<InputLabel shrink={false} htmlFor="service_point">
								Service Point *
							</InputLabel>
							<CustomSelect
								items={servicePoints.map(({ id, name }) => ({
									key: id,
									value: id,
									label: name,
								}))}
								label=""
								name="service_point"
								value={values.service_point}
								onChange={handleChange}
							/>
							{errors.service_point && touched.service_point && (
								<ErrorText>{errors.service_point}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="name">
								Asset Name *
							</InputLabel>
							<CustomInput
								id="name"
								name="name"
								aria-label="name"
								value={values.name}
								onChange={handleChange}
								fullWidth
							/>
							{errors.name && touched.name && (
								<ErrorText>{errors.name}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel
								shrink={false}
								htmlFor="installed_capacity"
							>
								Installed Capacity *
							</InputLabel>
							<CustomInput
								type="number"
								id="installed_capacity"
								name="installed_capacity"
								aria-label="installed_capacity"
								value={values.installed_capacity}
								onChange={handleChange}
							/>
							{errors.installed_capacity &&
								touched.installed_capacity && (
									<ErrorText>
										{errors.installed_capacity}
									</ErrorText>
								)}
						</Box>
						<Box sx={{ display: "flex", gap: "16px" }}>
							<Box>
								<InputLabel shrink={false} htmlFor="latitude">
									Latitude
								</InputLabel>
								<CustomInput
									type="number"
									id="latitude"
									aria-label="latitude"
									value={values.latitude}
									onChange={handleChange}
								/>
							</Box>
							<Box>
								<InputLabel shrink={false} htmlFor="longitude">
									Longitude
								</InputLabel>
								<CustomInput
									type="number"
									id="longitude"
									aria-label="longitude"
									value={values.longitude}
									onChange={handleChange}
								/>
							</Box>
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="machine">
								Machine Name *
							</InputLabel>
							<CustomSelect
								items={machines.map(({ id, name }) => ({
									key: id,
									value: id,
									label: name,
								}))}
								label=""
								name="machine"
								value={values.machine}
								id="machine"
								aria-label="machine"
								onChange={handleChange}
							/>
							{errors.machine && touched.machine && (
								<ErrorText>{errors.machine}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="azimuth">
								Azimuth
							</InputLabel>
							<Box sx={{ width: "532px", padding: "16px" }}>
								<Slider
									id="azimuth"
									name="azimuth"
									aria-label="azimuth"
									value={values.azimuth}
									step={1}
									max={360}
									valueLabelDisplay="on"
									valueLabelFormat={(value) => `${value}°`}
									marks={[
										{ value: 0, label: "0°" },
										{ value: 90, label: "90°" },
										{ value: 180, label: "180°" },
										{ value: 270, label: "270°" },
										{ value: 360, label: "360°" },
									]}
									onChange={handleChange}
								/>
							</Box>
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="tilt_angle">
								Tilt Angle
							</InputLabel>
							<Box sx={{ width: "532px", padding: "16px" }}>
								<Slider
									id="titleAngle"
									name="tilt_angle"
									aria-label="tilt_angle"
									value={values.tilt_angle}
									step={1}
									max={90}
									valueLabelDisplay="on"
									valueLabelFormat={(value) => `${value}°`}
									marks={[
										{ value: 0, label: "0°" },
										{ value: 100, label: "100°" },
									]}
									onChange={handleChange}
								/>
							</Box>
						</Box>
						<Box>
							<InputLabel
								shrink={false}
								htmlFor="manufacturer_serial_number"
							>
								Serial Number *
							</InputLabel>
							<CustomInput
								id="manufacturer_serial_number"
								aria-label="manufacturer_serial_number"
								value={values.manufacturer_serial_number}
								onChange={handleChange}
								fullWidth
							/>
							{errors.manufacturer_serial_number &&
								touched.manufacturer_serial_number && (
									<ErrorText>
										{errors.manufacturer_serial_number}
									</ErrorText>
								)}
						</Box>
						<Box>
							<FluidDatePicker
								name="commissioning_date"
								onChange={handleChange}
								label={"Commissioning Date *"}
								value={values.commissioning_date}
								errorMessage={errors.commissioning_date}
							/>
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="key">
								Asset Key *
							</InputLabel>
							<CustomInput
								id="key"
								aria-label="key"
								value={values.key}
								onChange={handleChange}
								fullWidth
							/>
							{errors.key && touched.key && (
								<ErrorText>{errors.key}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="subsidy">
								Subsidy *
							</InputLabel>
							<CustomInput
								type="number"
								id="subsidy"
								aria-label="subsidy"
								value={values.subsidy}
								onChange={handleChange}
								fullWidth
							/>
							{errors.subsidy && touched.subsidy && (
								<ErrorText>{errors.subsidy}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="sr_code">
								SR Code *
							</InputLabel>
							<CustomInput
								id="sr_code"
								aria-label="sr_code"
								value={values.sr_code}
								onChange={handleChange}
								fullWidth
							/>
							{errors.sr_code && touched.sr_code && (
								<ErrorText>{errors.sr_code}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="tr_code">
								TR Code *
							</InputLabel>
							<CustomInput
								id="tr_code"
								aria-label="tr_code"
								value={values.tr_code}
								onChange={handleChange}
								fullWidth
							/>
							{errors.tr_code && touched.tr_code && (
								<ErrorText>{errors.tr_code}</ErrorText>
							)}
						</Box>
						<Box>
							<InputLabel shrink={false} htmlFor="tr_mastr_no">
								TR Master Number *
							</InputLabel>
							<CustomInput
								id="tr_mastr_no"
								aria-label="tr_mastr_no"
								value={values.tr_mastr_no}
								onChange={handleChange}
								fullWidth
							/>
							{errors.tr_mastr_no && touched.tr_mastr_no && (
								<ErrorText>{errors.tr_mastr_no}</ErrorText>
							)}
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							gap: "16px",
						}}
					>
						<PrimaryButton
							onClick={onClose}
							text="Cancel"
							type="button"
							color="secondary"
						/>
						<PrimaryButton
							onClick={submitForm}
							text={`${asset ? "Save" : "Add"}`}
							type="button"
							color="primary"
						/>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default AssetFormSolar;
