import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as EditIcon } from "../../../common/assets/icons/edit.svg";
import { style } from "./EditSiteModal.style";
import { Box, CircularProgress, DialogActions } from "@mui/material";
import { FluidTextField } from "../../../common/components/FluidTextField";
import InfoBlock from "../../../common/components/InfoBlock/InfoBlock";
import { Toggle } from "../../../common/components/Toggle";
import { FluidNumber } from "../../../common/components/FluidNumberField";
import { If } from "../../../common/components/If";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { TitleWithIcon } from "../../../common/components/TitleWithIcon";
import { GetOneSiteResponseType } from "../../../requests_geco/sitesApi/sitesApi.types";
import { editSiteModalSchema } from "./editSiteModal.schema";
import { Formik } from "formik";
import {
	useAddContactMutation,
	useLazyGetOneSiteQuery,
	useRemoveContactMutation,
	useUpdateOneSiteMutation,
} from "../../../requests_geco/sitesApi/sitesApi";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	formatApiErrorMessage,
	ErrorType,
} from "../../../common/utils/formatApiErrorMessage";
import { ErrorText } from "../../../common/components/ErrorText";
import { FluidButton } from "../../../common/components/FluidButton";
import { noop } from "../../../common/utils/operations";
import { ContactCard } from "../../../common/components/ContactCard";
import { Spacer } from "../../../common/components/Spacer";
import Collapse from "../../../common/components/Collapse";
import AddContactModal from "./AddContactModal";
import { ContactType } from "../../../requests_geco/referentialApi/referentialApi.types";

export interface EditSiteModalProps {
	handleClose: () => void;
	handleSucessSubmit: () => void;
	siteId: number | undefined;
}
export const EditSiteModal = ({
	handleClose,
	handleSucessSubmit,
	siteId,
}: EditSiteModalProps) => {
	const [contactsModalOpen, setContactsModalOpen] = useState(false);

	const [addContactBase] = useAddContactMutation();
	const addContact = useRtkQueryDynamicEndpoint(addContactBase);

	const [removeContactBase] = useRemoveContactMutation();
	const removeContact = useRtkQueryDynamicEndpoint(removeContactBase);

	const [
		getOneSiteBase,
		{
			data: singleSite,
			isLoading: isSingleSiteLoading,
			error: getOneSiteError,
		},
	] = useLazyGetOneSiteQuery();

	const [
		updateOneSiteBase,
		{ isLoading: isUpdating, error: updateOneSiteError, isSuccess },
	] = useUpdateOneSiteMutation();

	const updateOneSite = useRtkQueryDynamicEndpoint(updateOneSiteBase);
	const getOnSite = useRtkQueryDynamicEndpoint(getOneSiteBase);

	useEffect(() => {
		if (siteId) {
			getOnSite({ siteId });
		}
	}, [siteId]);

	useEffect(() => {
		if (isSuccess) {
			handleSucessSubmit();
		}
	}, [isSuccess]);

	const onSubmit = useCallback(
		(site: GetOneSiteResponseType) => {
			updateOneSite(site);
		},
		[updateOneSite]
	);

	const isLoading = useMemo(() => {
		return isUpdating || isSingleSiteLoading;
	}, [isSingleSiteLoading, isUpdating]);

	const errorMessage = useMemo(() => {
		return formatApiErrorMessage(
			(updateOneSiteError as ErrorType) || (getOneSiteError as ErrorType)
		);
	}, [updateOneSiteError, getOneSiteError]);

	const handleAddContact = (contact: Pick<ContactType, "id" | "name">) => {
		addContact({ site_id: siteId!, contact });
	};

	const handleRemoveContact = (contactId: number) => {
		removeContact({ site_id: siteId!, contact_id: contactId });
	};

	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={true}>
			<Box sx={style.title}>
				<TitleWithIcon icon={<EditIcon />}>
					<DialogTitle>{"Edit site"}</DialogTitle>
				</TitleWithIcon>
			</Box>
			<Box sx={style.container}>
				<If condition={isLoading}>
					<Box sx={style.progressWrapper}>
						<CircularProgress data-testid="loader" />
					</Box>
				</If>
				<If condition={!isLoading && singleSite}>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						enableReinitialize
						initialValues={singleSite as GetOneSiteResponseType}
						validationSchema={editSiteModalSchema}
						onSubmit={onSubmit}
					>
						{({ handleChange, handleSubmit, values, errors }) => (
							<form onSubmit={handleSubmit}>
								<DialogContent>
									<Box sx={style.container.content}>
										<Box
											sx={style.container.tprIdContainer}
										>
											<FluidTextField
												isRequired
												isDisabled
												onChange={handleChange}
												value={values.tpr_system_id}
												name={"tpr_system_id"}
												label={"TPR ID"}
												errorMessage={
													errors.tpr_system_id
												}
											/>
										</Box>

										<FluidTextField
											isRequired
											onChange={handleChange}
											value={values.name}
											name={"name"}
											label={"Site name"}
											errorMessage={errors.name}
										/>
										<InfoBlock
											withBackground
											fullWidth
											info={[]}
										>
											<Toggle
												onChange={handleChange}
												isTrue={
													!!values.is_self_consumer
												}
												name={"is_self_consumer"}
												label={"Is Self Consumer"}
												description="Indicates if the site will consume some of its own energy"
											/>
											<If
												condition={
													!!values.is_self_consumer
												}
											>
												<FluidNumber
													sx={{ width: "100%" }}
													onChange={handleChange}
													//@ts-ignore
													value={
														values.self_consumption_ratio
													}
													name={
														"self_consumption_ratio"
													}
													title={
														"Self Consumption Ratio"
													}
													description="The amount of produced energy that is consumed on-site"
													errorMessage={
														errors.self_consumption_ratio
													}
												/>
												<FluidNumber
													sx={{ width: "100%" }}
													onChange={handleChange}
													//@ts-ignore
													value={
														values?.self_sufficiency_ratio
													}
													name={
														"self_sufficiency_ratio"
													}
													title={
														"Self Sufficiency Ratio"
													}
													description="A measure that indicates how well this site can provide it's own energy"
													errorMessage={
														errors.self_sufficiency_ratio
													}
												/>
											</If>
										</InfoBlock>
										<FluidNumber
											isDisabled
											onChange={handleChange}
											value={3405}
											name={""}
											title={"Orchestrade ID"}
											errorMessage=""
										/>
										<FluidNumber
											onChange={handleChange}
											value={values.max_grid_injection}
											name={"max_grid_injection"}
											title={"Max Grid Injection"}
											description="Unit: Kw"
											errorMessage={
												errors.max_grid_injection
											}
										/>
										<ErrorText>{errorMessage}</ErrorText>
									</Box>
									<Box>
										<Collapse
											title="Contacts"
											action={
												<FluidButton
													label="+ Add"
													onClick={() =>
														setContactsModalOpen(
															true
														)
													}
													type="button"
													variant="inverse"
												/>
											}
										>
											{singleSite?.contacts.map(
												(contact) => (
													<>
														<ContactCard
															key={contact.id}
															id={contact.id}
															email={
																contact.email
															}
															recipient_types={
																contact?.recipient_types ||
																[]
															}
															removeContact={
																handleRemoveContact
															}
														/>
														<Spacer gap={16} />
													</>
												)
											)}
										</Collapse>
									</Box>
								</DialogContent>
								<Box sx={style.actionContainer}>
									<DialogActions>
										<If condition={!isLoading}>
											<PrimaryButton
												onClick={handleClose}
												text="Cancel"
												type="button"
												color="secondary"
											/>
											<FluidButton
												type="submit"
												icon={"edit"}
												label={"Edit"}
												onClick={noop}
											/>
										</If>
										<If condition={!!isLoading}>
											<CircularProgress />
										</If>
									</DialogActions>
								</Box>
							</form>
						)}
					</Formik>
				</If>
			</Box>
			<AddContactModal
				isOpen={contactsModalOpen}
				onClose={() => setContactsModalOpen(false)}
				addContact={handleAddContact}
			/>
		</Dialog>
	);
};
