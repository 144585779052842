import React, { useRef } from "react";
import { GroupingField } from "../../../features/metadata/metadata.module";
import { Chip } from "@mui/material";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import { GROUPS_SELECTOR_DRAG_TYPE } from "./groupsSelector.constants";

export interface GroupPillProps {
	group: GroupingField;
	onDelete?: (group: GroupingField) => void;
	swapGroups: (from: number, to: number) => void;
	index: number;
	onDrop: () => void;
}

export function GroupPill(props: GroupPillProps) {
	const { group, onDelete, index, swapGroups: insertGroup } = props;
	const ref = useRef<HTMLDivElement>(null);

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: GROUPS_SELECTOR_DRAG_TYPE,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			item: () => ({ group, index }),
			end: (_, monitor) => {
				// handles cases where the user started dragging the pill over a slot
				// then moved away while still dragging, therefore dropping outside a
				// slot, we still want to commit the change in groups ordering
				if (!monitor.didDrop()) {
					props.onDrop();
				}
			},
		}),
		[group, index, props.onDrop]
	);

	const [{}, drop] = useDrop<
		{ group: GroupingField; index: number },
		any,
		any
	>(
		() => ({
			accept: GROUPS_SELECTOR_DRAG_TYPE,
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
				item: monitor.getItem(),
			}),
			hover: (item, monitor) => {
				if (!ref.current) {
					return;
				}
				const dragIndex = item.index;
				const hoverBoundingRect = ref.current?.getBoundingClientRect();
				const hoverMiddleX =
					(hoverBoundingRect.right - hoverBoundingRect.left) / 2;
				const clientOffset = monitor.getClientOffset();
				const hoverClientX =
					(clientOffset as XYCoord).x -
					(hoverBoundingRect?.left || 0);
				if (dragIndex < index && hoverClientX < hoverMiddleX) {
					return;
				}
				if (dragIndex > index && hoverClientX > hoverMiddleX) {
					return;
				}
				insertGroup(dragIndex, index);
				item.index = index;
			},
			drop: () => {
				props.onDrop();
			},
			canDrop: () => true,
		}),
		[index, group, props.onDrop]
	);

	drag(drop(ref));

	const opacity = isDragging ? 0 : 1;

	return (
		<div ref={ref} style={{ opacity }}>
			{
				<Chip
					sx={{ cursor: "move" }}
					label={group.display_name}
					onDelete={onDelete ? () => onDelete(group) : undefined}
					key={group.field_key}
				/>
			}
		</div>
	);
}
