import { Box, BoxProps } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as _ from "lodash";

const ANIM_DURATION = 200;

export interface FlashUpdateProps extends BoxProps {
	field: any;
	isUpdate?: boolean;
}

export function FlashUpdate(props: FlashUpdateProps) {
	const { field, isUpdate, ...boxProps } = props;
	const ref = useRef<any>(field);
	const rendered = useRef(false);
	const [flash, setFlash] = useState(false);

	useEffect(() => {
		if (!isUpdate) {
			setFlash(false);

			return () => {};
		}

		if (!rendered.current || !_.isEqual(field, ref.current)) {
			setFlash(true);
			const timeoutInterval = setTimeout(() => {
				setFlash(false);
			}, ANIM_DURATION);

			return () => {
				clearTimeout(timeoutInterval);
			};
		}

		ref.current = field;
		rendered.current = true;
	}, [field]);

	const sx = useMemo(
		() => ({
			...(boxProps.sx || {}),
			transition: `background-color ${ANIM_DURATION}ms ease-in-out`,
			backgroundColor: flash ? "rgba(0, 255, 0, 1)" : "unset",
		}),
		[boxProps, flash]
	);

	return (
		<Box {...boxProps} sx={sx} style={{ willChange: "background-color" }}>
			{props.children}
		</Box>
	);
}
