export const style = {
	container: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
	},
	wrapper: {
		display: "flex",
		flexGrow: "1",
		overflow: "hidden",
		flexWrap: "nowrap",
		position: "relative",
	},
	gradientLeft: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "10px",
		height: "100%",
		background:
			"linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)",
		zIndex: "1",
	},
	gradientRight: {
		position: "absolute",
		top: 0,
		right: 0,
		width: "10px",
		height: "100%",
		background:
			"linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)",
		zIndex: "1",
	},
	slider: (sliderPosition = 0) => ({
		padding: "1px",
		display: "flex",
		transition: "transform 0.3s ease-out",
		transform: `translateX(${sliderPosition}px)`,
	}),
};
