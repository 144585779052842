import { createTheme } from "@mui/material/styles";

export const backgroundBrandSecondary = "#F6F8FD";
export const backgroundBrandTertiary = "#E7EEFC";
export const backgroundNeutralSecondary = "#F6F8F9";
export const borderBrandSubtle = "#C0D4F9";
export const borderGrey = "#757575";
export const borderNeutralModerate = "#9EADB8";
export const borderNeutralSecondary = "#CCD4DA";
export const colorPaletteBlue400 = "#7FADEF";
export const colorPaletteBlue600 = "#3D85CA";
export const dangerPrimary = "#DB3735";
export const darkGrey = "#768C9B";
export const errorColor = "#db3131";
export const grey = "#C5CED5";
export const labelColor = "#616161";
export const lightGrey = "#F3F5F6";
export const linkColor = "#009DE9";
export const neutralPrimary = "#171D21";
export const primaryColor = "#007ACD";
export const secondaryColor = "#E6EAED";
export const successColor = "#71cb71";
export const textNeutralContrast = "#435C6E";
export const textNeutralTertiary = "#60798B";
export const warningColor = "#ff9800";
export const white = "#fff";
export const pagePadding = "40px";
export const successPrimary = "#008836";
export const successSecondary = "#F3FAF4";
export const successSubtle = "#AEDFB3";
export const warningPrimary = "#FF8C47";
export const warningTertiary = "#FFEADA";
export const dangerSecondary = "#FFF7F5";
export const dangerSubtle = "#FFC5B9";

const theme = createTheme({
	palette: {
		primary: {
			main: primaryColor,
		},
		secondary: {
			main: secondaryColor,
		},
		info: {
			main: white,
		},
	},
	typography: {
		fontFamily: "Lato",
		h3: {
			color: "#192229",
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 2,
		},
		subtitle1: {
			fontSize: 14,
			fontWeight: 700,
		},
		subtitle2: {
			fontSize: "14px",
			fontWeight: 400,
		},
	},
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					margin: 0,
					paddingLeft: "15px",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "#db3131",
					"&$error": {
						color: "#db3131",
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {
					display: "flex",
					flexDirection: "column",
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingTop: "10px !important",
				},
			},
		},
	},
});

export default theme;
