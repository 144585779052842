import { NJFormItem } from "@engie-group/fluid-design-system-react";
import React from "react";

export interface CommodityFixingProps {
	onChange: (event: any) => void;
	commodity: string | undefined;
}

export const CommodityFixing = (props: CommodityFixingProps) => {
	return (
		<NJFormItem
			readOnly
			id={"commodity"}
			isRequired
			value={props.commodity || ""}
			label="Commodity Fixing"
			labelKind="static"
			name="commodity"
			onChange={props.onChange}
			title="Commodity Fixing"
			type="text"
		/>
	);
};
