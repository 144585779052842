import {
	Box,
	Button,
	CircularProgress,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	SxProps,
	Theme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	selectAllNotifications,
	selectHasNextPage,
	selectIsLoading,
	selectIsNotificationsDrawerOpened,
	selectOldestReadNotificationId,
	selectOldestUnReadNotificationId,
} from "./notifications.selector";
import { setNotificationsDrawerOpened } from "./notificationsSlice";
import CloseIcon from "@mui/icons-material/Close";
import NotificationItem from "./NotificationItem";
import { useCallback, useEffect, useState } from "react";
import {
	SetAllNotificationsAsRead,
	getAllNotifications,
} from "./notificationsThunk";
import { Waypoint } from "react-waypoint";
import { selectAuthenticatedUser } from "../authentication/auth.selector";

const style: { [key: string]: SxProps<Theme> } = {
	drawer: {
		width: 389,
	},
	list: {
		width: "100%",
		maxWidth: 389,
		bgcolor: "background.paper",
	},
	header: {
		color: "#171D21",
		fontSize: 28,
		weight: 400,
	},
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
};

export default function NotificationDrawer() {
	const dispatch = useDispatch();
	const isOpened = useSelector(selectIsNotificationsDrawerOpened);
	const notificationsList = useSelector(selectAllNotifications);
	const [readFilter, setReadFilter] = useState(false);
	const authenticatedUser = useSelector(selectAuthenticatedUser);

	const close = useCallback(() => {
		dispatch(setNotificationsDrawerOpened({ opened: false }));
	}, [dispatch]);

	useEffect(() => {
		const onBlur = () => close();
		window.addEventListener("blur", onBlur);
		return () => window.removeEventListener("blur", onBlur);
	}, [close]);

	const oldestReadNotificationId = useSelector(
		selectOldestReadNotificationId
	);
	const oldestUnReadNotificationId = useSelector(
		selectOldestUnReadNotificationId
	);
	const versionNumber = process.env.REACT_APP_VERSION_NUMBER
		? `Version: ${process.env.REACT_APP_VERSION_NUMBER}`
		: "Version: N/A";

	const handleNextPage = () => {
		dispatch(
			getAllNotifications(
				readFilter,
				readFilter
					? oldestReadNotificationId
					: oldestUnReadNotificationId
			)
		);
	};

	const isFetching = useSelector(selectIsLoading);
	const hasNextPage = useSelector(selectHasNextPage);

	useEffect(() => {
		if (!authenticatedUser) {
			return;
		}

		dispatch(getAllNotifications(readFilter, undefined));
	}, [readFilter, authenticatedUser]);

	return (
		<Drawer
			anchor={"right"}
			open={isOpened}
			onClose={close}
			PaperProps={{
				sx: style.drawer,
			}}
		>
			<List
				sx={style.list}
				component="nav"
				aria-labelledby="notifications"
				subheader={
					<ListSubheader
						sx={style.header}
						component="div"
						id="notifications-header"
					>
						Main notifications
						<ListItemSecondaryAction
							onClick={() =>
								dispatch(
									setNotificationsDrawerOpened({
										opened: false,
									})
								)
							}
						>
							<IconButton edge="end" aria-label="delete">
								<CloseIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListSubheader>
				}
			>
				<ListItem>
					<ListItemText
						primary="Green Power Portal (GEPO)"
						secondary={versionNumber}
					/>
				</ListItem>

				<ListItem
					sx={{ display: "flex", justifyContent: "space-between" }}
				>
					<Box>
						<Button
							variant={"outlined"}
							onClick={() => setReadFilter(false)}
							size="small"
							sx={
								readFilter
									? {
											borderColor: "#ccc",
											color: "#ccc",
											marginRight: "5px",
									  }
									: { marginRight: "5px" }
							}
						>
							Unread
						</Button>

						<Button
							variant={"outlined"}
							sx={
								!readFilter
									? { borderColor: "#ccc", color: "#ccc" }
									: {}
							}
							onClick={() => setReadFilter(true)}
							size="small"
						>
							Read
						</Button>
					</Box>
					<Button
						onClick={() => dispatch(SetAllNotificationsAsRead())}
						variant="text"
						size="small"
						disabled={!notificationsList.length}
					>
						Mark all as Read
					</Button>
				</ListItem>

				{notificationsList
					.filter((notif) => (readFilter ? notif.read : !notif.read))
					.map((notificationItem, key) => (
						<ListItem key={key}>
							<NotificationItem data={notificationItem} />
						</ListItem>
					))}
				{!isFetching && hasNextPage && (
					<Waypoint onEnter={handleNextPage}></Waypoint>
				)}

				{isFetching && (
					<Box sx={style.progressWrapper}>
						<CircularProgress data-testid="loader" />
					</Box>
				)}
			</List>
		</Drawer>
	);
}
