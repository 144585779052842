import { secondaryColor } from "../../core/theme";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, SxProps, Theme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { ColModel } from "./GenericTableRow";

const style: {
	[key: string]: SxProps<Theme>;
} = {
	headerCellContainer: {
		backgroundColor: secondaryColor,
		color: "#000",
		fontWeight: 700,
		fontSize: 12,
		paddingLeft: 0,
		display: "flex",
		alignItems: "center",
		"& div": {
			display: "flex",
			alignItems: "center",
		},
	},
	orderByButton: {
		color: "#000",
		padding: 0,
		backgroundColor: secondaryColor,
		marginRight: 0,
	},
};

function GenericHeaderCell<TModel>(props: ColModel<TModel>) {
	return (
		<>
			{!props.hidden && (
				<Grid
					item
					xs={props.xs ? props.xs : 1}
					sx={{ ...style.headerCellContainer, ...props.sx }}
				>
					<Box
						sx={{
							justifyContent:
								props.align === "right" ? "end" : "start",
						}}
					>
						<>
							{props.sortable && (
								<IconButton
									aria-label="orderBy"
									sx={style.orderByButton}
								>
									<UnfoldMoreIcon sx={{ fontSize: 20 }} />
								</IconButton>
							)}
							{props.header()}
						</>
						{!!props.filterBy && (
							<ArrowDropDownIcon sx={{ fontSize: 20 }} />
						)}
					</Box>
				</Grid>
			)}
		</>
	);
}

export default GenericHeaderCell;
