import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "./CustomButton";
import StyledTextarea from "./StyledTextarea";
import { useState } from "react";

interface AlertDialogType {
	title: string;
	textBoxTitle: string;
	isOpen: boolean;
	isTextRequired?: boolean;
	handleClose: () => void;
	handleApprove: (text: string) => void;
}
export default function AlertDialogWithTextArea(props: AlertDialogType) {
	const {
		isOpen,
		isTextRequired,
		textBoxTitle,
		title,
		handleClose,
		handleApprove,
	} = props;
	const [text, setText] = useState("");
	return (
		<Dialog maxWidth={"lg"} onClose={handleClose} open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<StyledTextarea
					title={textBoxTitle}
					required={isTextRequired}
					onChange={(e) => {
						setText(e.target.value);
					}}
					value={text}
					minRows={3}
				/>
			</DialogContent>
			<DialogActions>
				<PrimaryButton
					onClick={handleClose}
					text="Cancel"
					type="button"
					color="secondary"
				></PrimaryButton>
				<PrimaryButton
					isDisabled={isTextRequired && !text}
					onClick={() => handleApprove(text)}
					text="Send"
					type="button"
					color="primary"
				></PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}
