import { Box, CircularProgress } from "@mui/material";
import { RedispatchConstraintCard } from "./RedispatchConstraintCard";
import { style } from "./RedispatchConstraint.style";
import { useEffect, useMemo, useState } from "react";
import {
	useAddRedispatchConstraintMutation,
	useEditRedispatchConstraintMutation,
	useLazyGetSiteRedispatchConstraintsQuery,
} from "../../../../requests_geco/sitesApi";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../../../common/components/If";
import ErrorMessageModal from "../../../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { FluidButton } from "../../../../common/components/FluidButton";
import EditRedispatchConstraintModal from "./EditRedispatchConstraintModal";
import { Spacer } from "../../../../common/components/Spacer";

export interface RedispatchConstraintTabProps {
	siteId: number;
}

function objectDiff(
	obj1: Record<string, any>,
	obj2: Record<string, any> | undefined
) {
	const result: Record<string, boolean> = {};
	if (obj2) {
		const keySet = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
		Array.from(keySet).forEach((key) => {
			result[key] = obj1[key] !== obj2[key];
		});
	} else {
		Object.keys(obj1).forEach((key) => {
			result[key] = false;
		});
	}
	return result;
}

export const RedispatchConstraintTab = ({
	siteId,
}: RedispatchConstraintTabProps) => {
	const [addRedispatchConstraintBase] = useAddRedispatchConstraintMutation();
	const addRedispatchConstraint = useRtkQueryDynamicEndpoint(
		addRedispatchConstraintBase
	);

	const [editRedispatchConstraintBase] =
		useEditRedispatchConstraintMutation();
	const editRedispatchConstraint = useRtkQueryDynamicEndpoint(
		editRedispatchConstraintBase
	);

	const [
		getRedispatchConstraintsBase,
		{ isLoading, error, data: redispatchConstraints },
	] = useLazyGetSiteRedispatchConstraintsQuery();
	const getRedispatchConstraints = useRtkQueryDynamicEndpoint(
		getRedispatchConstraintsBase
	);

	const [
		pendingEditRedispatchConstraintId,
		setPendingEditRedispatchConstraintId,
	] = useState<number | null>(null);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

	useEffect(() => {
		if (siteId) {
			getRedispatchConstraints({ siteId });
		}
	}, []);

	const sortedConstraints = useMemo(
		() =>
			[...(redispatchConstraints || [])].sort(
				(redispatch1, redispatch2) => redispatch2?.id - redispatch1?.id
			),
		[redispatchConstraints]
	);

	const handleSubmitNewVersion = (values: any) => {
		setIsEditModalOpen(false);
		if (pendingEditRedispatchConstraintId) {
			editRedispatchConstraint({
				site_id: siteId,
				constraint_id: pendingEditRedispatchConstraintId,
				data: { ...values, id: pendingEditRedispatchConstraintId },
			});
			setPendingEditRedispatchConstraintId(null);
		} else {
			addRedispatchConstraint({ site_id: siteId, data: values });
		}
	};

	return (
		<>
			<If condition={isLoading}>
				<Box sx={style.progressWrapper}>
					<CircularProgress data-testid="loader" />
				</Box>
			</If>
			<If condition={!isLoading}>
				<FluidButton
					label="Add new version"
					onClick={() => setIsEditModalOpen(true)}
					icon="add_circle_outline"
				/>
				<Spacer gap={16} />
				<Box sx={style.container}>
					{!sortedConstraints.length && <NoDataMessage />}
					{!!sortedConstraints.length &&
						(sortedConstraints || []).map(
							(redispatchConstraint, index) => (
								<RedispatchConstraintCard
									currentRedispatchConstraint={index === 0}
									onEdit={() => {
										setPendingEditRedispatchConstraintId(
											redispatchConstraint.id
										);
										setIsEditModalOpen(true);
									}}
									key={index}
									redispatchConstraint={redispatchConstraint}
									highlightedFields={objectDiff(
										sortedConstraints![index],
										sortedConstraints![index + 1]
									)}
									active={index === 0}
								/>
							)
						)}
				</Box>
			</If>
			<ErrorMessageModal
				title={`Cannot retrieve redispatch constraints on site ${siteId}`}
				content={formatApiErrorMessage(error as ErrorType)}
			/>
			<EditRedispatchConstraintModal
				isOpen={isEditModalOpen}
				onClose={() => {
					setPendingEditRedispatchConstraintId(null);
					setIsEditModalOpen(false);
				}}
				onSubmit={handleSubmitNewVersion}
				constraintModel={sortedConstraints[0] ?? {}}
				constraintEdit={sortedConstraints.find(
					(constraint) =>
						constraint.id === pendingEditRedispatchConstraintId
				)}
			/>
		</>
	);
};
