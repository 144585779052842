import { ContractDetailType } from "../contractsApi/contractsApi.types";

export enum ContractChangeStatus {
	PENDING_VALIDATION = "under_approval",
	APPROVED = "change_approved",
	REJECTED = "change_rejected",
}

export interface ContractChangeResponse {
	creation_datetime: number;
	data: ContractChangePayload;
	contract_id: number;
}
export interface ContractChangePayload {
	payload: ContractDetailType;
	status: ContractChangeStatus;
	contract_id: number;
	creation_datetime: string;
	requestor: string;
	comments: string[];
}
