import { NJRadioGroup, NJRadio } from "@engie-group/fluid-design-system-react";
import { Box, Typography } from "@mui/material";
import { ErrorText } from "../ErrorText";

export interface FluidRadioOption {
	value: string;
	label: string;
}

export interface FluidRadioGroupProps {
	value: string;
	title: string;
	name: string;
	onChange: (event: any) => void;
	orientation?: "row" | "column";
	options: FluidRadioOption[];
	description?: string;
	errorMessage?: string;
}

export const FluidRadioGroup = ({
	value,
	onChange,
	options,
	name,
	title,
	description,
	errorMessage = "",
	orientation = "column",
}: FluidRadioGroupProps) => {
	return (
		<Box>
			<NJRadioGroup
				checkedId={value}
				legend={title}
				onChange={onChange}
				orientation={orientation}
			>
				{options.map((option, index) => (
					<NJRadio
						key={index}
						id={option.value}
						label={option.label}
						name={name}
						value={option.value}
					/>
				))}
			</NJRadioGroup>
			<Typography variant="subtitle2">{description}</Typography>
			<ErrorText>{errorMessage}</ErrorText>
		</Box>
	);
};
