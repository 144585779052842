import { Theme } from "@mui/material";

export const style = {
	handler: { position: "absolute", right: "48px", top: "0" },
	closeButton: {
		position: "absolute",
		right: 8,
		top: 8,
		color: (theme: Theme) => theme.palette.grey[500],
	},
	content: {
		minWidth: "500px",
		padding: "24px",
	},
	progress: {
		display: "flex",
		justifyContent: "center",
		paddingTop: "24px",
	},
	logWrapper: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		marginTop: "32px",
	},
	label: { fontWeight: "bold" },
};
