import { PricingRunStatusTransitions } from "../../pricingList.constant";
import SendIcon from "@mui/icons-material/Send";
import BookIcon from "@mui/icons-material/CollectionsBookmarkSharp";

export interface TenderActionIconProps {
	transition: PricingRunStatusTransitions;
}

export const TenderActionIcon = (props: TenderActionIconProps) => {
	const ICON_MAPPER = {
		[PricingRunStatusTransitions.BOOK]: <BookIcon />,
		[PricingRunStatusTransitions.CREATE_OFFER]: <SendIcon />,
	};

	const ICON = ICON_MAPPER[props.transition];
	if (ICON) {
		return ICON;
	}
	return <SendIcon />;
};
