import FiltersDrawer, {
	FieldValue,
	FiltersField,
	Operations,
	retrieveValueFromFieldName,
} from "../../../../common/components/FiltersDrawer";
import FluidDatePicker from "../../../../common/components/FluidDatePicker";
import { noop } from "../../../../common/utils/operations";
import FluidSelectMultiple from "../../../../common/components/FluidSelectMulti";

const AssetsTabFilters = ({
	isOpen,
	onClose,
	defaultFilters = {},
	onApplyFilters,
	servicePoints,
}: {
	isOpen: boolean;
	onClose: () => void;
	onApplyFilters: (data: Record<string, FieldValue>) => void;
	defaultFilters?: Record<string, FieldValue>;
	servicePoints: string[];
}) => {
	return (
		<FiltersDrawer
			title={"Assets Filters"}
			isOpen={isOpen}
			onClose={onClose}
			onApplyFilters={onApplyFilters}
			defaultFilters={defaultFilters}
		>
			<FiltersField
				name={"servicepoints__method"}
				label="Service points"
				defaultOperation={Operations.IN}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"servicepoints__method",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={servicePoints.map((servicePoint) => ({
							value: servicePoint,
							label: servicePoint,
						}))}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={"start_date"}
				label="Start date"
				defaultOperation={Operations.GE}
				defaultFieldValue={
					retrieveValueFromFieldName("start_date", defaultFilters) ||
					""
				}
				allowedOperations={[Operations.GE]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
			<FiltersField
				name={"end_date"}
				label="End date"
				defaultOperation={Operations.LE}
				defaultFieldValue={
					retrieveValueFromFieldName("end_date", defaultFilters) || ""
				}
				allowedOperations={[Operations.LE]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
		</FiltersDrawer>
	);
};

export default AssetsTabFilters;
