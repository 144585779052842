import { Box, DialogTitle, Typography } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Spacer } from "../../../../../common/components/Spacer";
import {
	dangerPrimary,
	warningPrimary,
	warningTertiary,
} from "../../../../../core/theme";
import ErrorIcon from "@mui/icons-material/Error";
import { GeneratedCurtailmentObjectItem } from "./GeneratedCurtailmentObjectItem";

export interface GeneratingCurtailmentObjectFailedModalProps {
	isOpen: boolean;
	onClose: () => void;
	errors: string[];
	success: { name: string; start_date: string; end_date: string }[];
}

export const GeneratingCurtailmentObjectFailedModal = ({
	isOpen,
	onClose,
	errors,
}: GeneratingCurtailmentObjectFailedModalProps) => {
	const style = {
		container: {
			width: "580px",
			padding: "24px",
		},
		bodyContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		ErrorTitleIcon: {
			fontSize: "64px",
			color: dangerPrimary,
			lineHeight: 0,
		},
		errorIcon: {
			color: dangerPrimary,
			lineHeight: 0,
		},
		errorMessageContainer: {
			border: `1px solid ${warningPrimary}`,
			background: warningTertiary,
			padding: "16px",
			width: "400px",
		},
	};
	return (
		<Modal sx={style.container} open={isOpen} onClose={onClose}>
			<Box sx={style.bodyContainer}>
				<span style={style.ErrorTitleIcon}>
					<ErrorIcon fontSize="inherit" />
				</span>
				<DialogTitle>Generation failed</DialogTitle>
				<Typography>
					Curtailment objects could not be generated.
				</Typography>
				<Typography>Please correct the data and try again.</Typography>
				<Spacer gap={32} />
				{(errors || []).map((message, index) => (
					<>
						<GeneratedCurtailmentObjectItem
							key={index}
							message={message}
						/>
						<Spacer gap={8} />
					</>
				))}
				<Spacer gap={32} />
				<PrimaryButton
					onClick={onClose}
					text="Close"
					type="button"
					color="primary"
				/>
			</Box>
		</Modal>
	);
};
