import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { SnackbarProvider } from "notistack";
import { Box } from "@mui/material";
import { lightGrey } from "./core/theme";
import { initSentry } from "./common/utils/initSentry";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { usePersistedFilters } from "./common/hooks/usePersistedFilters";
import { useSelector } from "react-redux";
import { selectUserIdentity } from "./features/authentication/auth.selector";
import * as Sentry from "@sentry/react";

const style = {
	app: {
		backgroundColor: lightGrey,
		height: "100vh",
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
		height: "100vh",
	},
};

initSentry();
function App() {
	usePersistedFilters();
	const identity = useSelector(selectUserIdentity);
	Sentry.setUser({ id: identity?.gaia_id });
	return (
		<Box sx={style.app}>
			<SnackbarProvider>
				<DndProvider backend={HTML5Backend}>
					<Box sx={style.wrapper}>
						<RouterProvider router={router} />
					</Box>
				</DndProvider>
			</SnackbarProvider>
		</Box>
	);
}

export default App;
