import { useCallback, useState } from "react";

export interface UseSearchPaginateInterface {
	nextPage: (page: number) => void;
	limitedNextPage: (
		page: number,
		resourcesPerPage: number,
		currentLen: number
	) => void;
	onSearch: (search: string) => void;
	resetPagination: () => void;
	currentPage: number;
	currentSearch: string;
	resetCurrentPage: () => void;
}

export const useSearchPaginate = (
	getResources: (page: number, search: string) => void
): UseSearchPaginateInterface => {
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSearch, setCurrentSearch] = useState("");

	const nextPage = useCallback(
		(page: number) => {
			setCurrentPage(page);
			getResources(page, currentSearch);
		},
		[currentPage, currentSearch]
	);

	const limitedNextPage = useCallback(
		(page: number, resourcesPerPage: number, currentLen: number) => {
			if (currentPage * resourcesPerPage <= currentLen) {
				nextPage(page);
			}
		},
		[]
	);

	const onSearch = useCallback(
		(search: string) => {
			setCurrentSearch(search);
			setCurrentPage(1);
			getResources(1, search);
		},
		[currentSearch]
	);

	const resetPagination = useCallback(() => {
		setCurrentPage(1);
		setCurrentSearch("");
		getResources(1, "");
	}, []);

	const resetCurrentPage = useCallback(() => {
		setCurrentPage(1);
	}, []);

	return {
		nextPage,
		limitedNextPage,
		onSearch,
		resetPagination,
		currentPage,
		currentSearch,
		resetCurrentPage,
	};
};
