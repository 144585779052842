import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse as CollapseMUI } from "@mui/material";
import React, { useState } from "react";
import { style } from "./Collapse.style";
import { Spacer } from "../Spacer";

const Collapse = ({
	title,
	action,
	children,
}: {
	title: React.ReactNode;
	action?: React.ReactNode;
	children: React.ReactNode;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					gap: "16px",
				}}
			>
				<Box
					onClick={() => setIsOpen((currentIsOpen) => !currentIsOpen)}
					sx={style.handle}
				>
					{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} {title}
				</Box>
				{action}
			</Box>
			<Spacer gap={8} />
			<CollapseMUI in={isOpen}>{children}</CollapseMUI>
		</>
	);
};

export default Collapse;
