export const style = {
	root: {
		display: "inline-flex",
		alignItems: "center",
		padding: "2px 4px",
		border: "1px solid #63be74",
		backgroundColor: "#e2f3e3",
	},
	no: {
		border: "1px solid #9eadb8",
		backgroundColor: "#ebeef1",
	},
	icon: { marginRight: "2px" },
	iconYes: { color: "#63be74" },
	iconNo: { color: "#9eadb8" },
};
