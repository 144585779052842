import { Box } from "@mui/material";
import { FluidNumber } from "../../common/components/FluidNumberField";
import { If } from "../../common/components/If";
import {
	Fee,
	SettlementType,
} from "../../requests_geco/contractsApi/contractsApi.types";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import FluidSelect from "../../common/components/FluidSelect";

export interface PricingTabProps {
	errors: any;
	values: any;
	indexes: { label: string; value: string }[];
	setFieldValue: (field: string, value: any) => void;
	handleChange: (e: React.ChangeEvent<any> | boolean) => void;
}

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		gap: "24px",
	},
	submitWrapper: {
		marginLeft: "auto",
	},
	feeContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "24px",
		alignItems: "flex-end",
		feeWrapper: {
			width: 162,
		},
	},
};

const PricingTab = ({
	indexes,
	values,
	setFieldValue,
	handleChange,
}: PricingTabProps) => {
	return (
		<Box sx={style.container}>
			{(values?.settlements || []).map(
				(settlement: SettlementType, settlementIndex: number) => (
					<>
						<InfoBlock
							withBackground
							info={[
								{
									label: "Type",
									value: settlement?.type || "",
								},
								{
									label: "Period",
									value: settlement?.period || "",
								},
							]}
						/>
						<If condition={settlement?.index}>
							<FluidSelect
								isLabelStatic
								items={indexes}
								label={"Index"}
								name={`settlements[${settlementIndex}].index.commodity_fixing_id`}
								// @ts-ignore does not provides an HTMLElement as it should but the value itself
								onChange={(index: number) =>
									setFieldValue(
										`settlements[${settlementIndex}].index.commodity_fixing_id`,
										index
									)
								}
								value={`${settlement.index?.commodity_fixing_id}`}
							/>
						</If>
						<If condition={settlement?.index?.alpha}>
							<FluidNumber
								sx={{ width: "100%" }}
								onChange={handleChange}
								value={settlement?.index?.alpha?.commodity}
								name={`settlements[${settlementIndex}].index.alpha.commodity`}
								title={"Alpha"}
							/>
							<Box sx={style.feeContainer}>
								{(settlement?.index?.alpha?.fees || []).map(
									(fee: Fee, feeIndex: number) => (
										<Box
											key={feeIndex}
											sx={style.feeContainer.feeWrapper}
										>
											<FluidNumber
												onChange={handleChange}
												value={fee?.value}
												name={`settlements[${settlementIndex}].index.alpha.fees[${feeIndex}].value`}
												title={fee?.name}
											/>
										</Box>
									)
								)}
							</Box>
						</If>
						<If condition={settlement?.index?.beta}>
							<FluidNumber
								sx={{ width: "100%" }}
								onChange={handleChange}
								value={settlement?.index?.beta?.commodity}
								name={`settlements[${settlementIndex}].index.beta.commodity`}
								title={"Beta"}
							/>
							<Box sx={style.feeContainer}>
								{(settlement?.index?.beta?.fees || []).map(
									(fee: Fee, feeIndex: number) => (
										<Box
											key={feeIndex}
											sx={style.feeContainer.feeWrapper}
										>
											<FluidNumber
												onChange={handleChange}
												value={fee?.value}
												name={`settlements[${settlementIndex}].index.beta.fees[${feeIndex}].value`}
												title={fee?.name}
											/>
										</Box>
									)
								)}
							</Box>
						</If>
						<If condition={settlement?.price}>
							<FluidNumber
								sx={{ width: "100%" }}
								onChange={handleChange}
								value={settlement?.price?.commodity}
								name={`settlements[${settlementIndex}].price.commodity`}
								title={"Fixed Price"}
							/>
							<Box sx={style.feeContainer}>
								{(settlement?.price?.fees || []).map(
									(fee: Fee, feeIndex: number) => (
										<Box
											key={feeIndex}
											sx={style.feeContainer.feeWrapper}
										>
											<FluidNumber
												onChange={handleChange}
												value={fee?.value}
												name={`settlements[${settlementIndex}].price.fees[${feeIndex}].value`}
												title={fee?.name}
											/>
										</Box>
									)
								)}
							</Box>
						</If>
					</>
				)
			)}
			<FluidNumber
				sx={{ width: "100%" }}
				onChange={handleChange}
				value={values?.goo_price}
				name={"goo_price"}
				title={"Goo Price"}
			/>
		</Box>
	);
};

export default PricingTab;
