import { useCallback, useEffect, useMemo, useState } from "react";
import intlFormatDistance from "date-fns/intlFormatDistance";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const A_MINUTE = 1000 * 60;
const HALF_A_MINUTE = A_MINUTE / 2;

export function TimeAgo(props: { since: string }) {
	const [delta, setDelta] = useState("");
	const since = useMemo(() => new Date(`${props.since}`), [props.since]);

	const updateDelta = useCallback(() => {
		if (Date.now() - since.getTime() < A_MINUTE) {
			return setDelta("Less than a minute ago");
		}
		return setDelta(
			intlFormatDistance(since.getTime(), Date.now(), {
				locale: "en",
			})
		);
	}, [setDelta, since]);

	useEffect(() => {
		updateDelta();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			updateDelta();
		}, HALF_A_MINUTE);
		return () => clearInterval(interval);
	}, [updateDelta]);

	return (
		<>
			<AccessTimeIcon sx={{ fontSize: "14px", marginRight: "5px" }} />
			{delta}
		</>
	);
}
