import SimpleTable from "../../../../common/components/SimpleTable";
import { HeadersType } from "../../../../common/components/SimpleTable/SimpleTable";
import { ServicePointType } from "../../../../requests_geco/sitesApi/sitesApi.types";
import NoDataMessage from "../../../../common/components/NoDataMessage";

const ServicePointsTab = ({
	servicePoints,
}: {
	servicePoints: ServicePointType[];
}) => {
	const headers: HeadersType<ServicePointType>[] = [
		{ accessor: "id", label: "Id" },
		{ accessor: "name", label: "Name" },
		{ accessor: "installed_capacity", label: "Installed Capacity" },
		{ accessor: "type.name", label: "Service Point type" },
		{ accessor: "dso.name", label: "DSO" },
		{ accessor: "tso.name", label: "TSO" },
		{ accessor: "code", label: "Code" },
		{ accessor: "localconso", label: "Local Conso" },
	];

	return (
		<>
			{!servicePoints.length && <NoDataMessage />}
			{!!servicePoints.length && (
				<SimpleTable
					headers={headers}
					items={servicePoints.map((servicePoint) => ({
						...servicePoint,
						type: servicePoint.type.name,
					}))}
				/>
			)}
		</>
	);
};

export default ServicePointsTab;
