import InputAdornment from "@mui/material/InputAdornment";
import CustomInput from "../../../../common/components/CustomInput";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	Action,
	Field,
	FieldGroup,
	Party,
	Pricing,
	updatePartiesLoader,
} from "../../pricingListSlice";
import PricingModal from "../PricingModal";
import { Formik, FormikProps, FormikValues } from "formik";
import { useActionFieldValidation } from "../../../../common/hooks/useActionFieldValidation";
import CustomAutocomplete from "../../../../common/components/CustomAutocomplete";
import { getParties } from "../../pricingList.thunk";
import { useAppDispatch } from "../../../../common/hooks/default";
import { useSelector } from "react-redux";
import { selectParties, selectPartiesLoader } from "../../pricingList.selector";
import { ValidationError } from "../../../../common/hooks/usePydanticErrorsFormat";
import { Box, IconButton, SxProps, Theme, FormHelperText } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as _ from "lodash";
import { useDeepMemo } from "../../../../common/hooks/useDeepMemo";
import { selectTender } from "../../../tender_form/tenderForm.selector";
import DeleteIcon from "@mui/icons-material/Delete";
import { If } from "../../../../common/components/If";

interface ActionPricingModalProps {
	open: boolean;
	onClose: () => void;
	forPricings: Pricing[];
	onSubmit: (body: Record<string, string | number | boolean>) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

interface FormValues {
	[key: string]: string;
}

export function mapParty(party: Party | undefined) {
	if (party)
		return {
			id: party.party_id,
			partyId: party.party_id,
			name: party.name,
			shortName: party.short_name,
		};
}

function formatFieldsGroup(values: FormValues, rowsCount: number) {
	let result = [];
	for (let i = 0; i < rowsCount; i++) {
		result.push({
			party: values[`party${i}`],
			mirror_book: values[`mirror_book${i}`],
			percentage: values[`percentage${i}`],
		});
	}
	return { fields: result };
}

const style: { [key: string]: SxProps<Theme> } = {
	form_style: {
		display: "flex",
		flexDirection: "column",
	},
	partyInputGroup: {
		display: "flex",
		marginBottom: "10px",
		"& > div": {
			minWidth: "30%",
			flexGrow: 2,
			marginRight: "12px",
		},
	},
};

export default function AddPartyModal(props: ActionPricingModalProps) {
	const {
		open,
		isLoading,
		forPricings,
		action,
		errorMessage,
		onClose,
		onSubmit,
	} = props;

	const formRef = useRef<FormikProps<FormikValues>>();

	// useDeepMemo instead of use memo because an incoming message would change
	// the reference to the action, but when compared deep it does not changes
	// so we actually don't want it to re-render
	const fields = useDeepMemo(() => {
		if (action) {
			const newFields: Record<string, any> = {};
			_.toPairs(action.fields).forEach(([fieldName, field]) => {
				if ((field as Field).is_array) {
					const arrayFieldName = fieldName + "_array";
					const arrayField = { ...field, label: arrayFieldName };
					newFields[arrayFieldName] = arrayField;
					newFields[fieldName] = {
						...field,
						is_array: false,
						required: false,
					};
				} else {
					newFields[fieldName] = field;
				}
			});
			return newFields;
		}
		return {};
	}, [action]);
	const dispatch = useAppDispatch();
	const partiesLoader = useSelector(selectPartiesLoader);
	const parties = useSelector(selectParties);
	const [activeIndex, setActiveIndex] = useState(0);
	const [rowsCount, setRowsCount] = useState(1);
	const [percentageSum, setPercentageSum] = useState(0);
	let partiesList = forPricings[0].parties || [];
	// useDeepMemo instead of use memo because an incoming message would change
	// the reference to the action, but when compared deep it does not changes
	// so we actually don't want it to re-render
	const initialValues = useDeepMemo(() => {
		if (action) {
			if (action?.fields?.party) {
				let result = {};
				if (partiesList.length > 0) {
					for (let i = 0; i < partiesList.length; i++) {
						result = {
							...result,
							[`party${i}`]: mapParty({
								id: partiesList[i].party?.party_id,
								name: partiesList[i].party?.name || "",
								party_id: partiesList[i].party?.party_id || 0,
								short_name:
									partiesList[i].party?.short_name || "",
							}),
							[`mirror_book${i}`]: partiesList[i].mirror_book,
							[`percentage${i}`]: partiesList[i].percentage,
						};
					}
				}

				return result;
			}
		}
		return {};
	}, [action]);

	let validationSchema = useActionFieldValidation(fields || {}, rowsCount);

	const fetchParties = (search: string) => {
		dispatch(updatePartiesLoader(true));
		dispatch(getParties(search));
	};
	const tender = useSelector(selectTender);
	const loaderDataAutoComplete = useMemo<boolean>(() => {
		return partiesLoader as boolean;
	}, [partiesLoader]);

	const fetchDataAutoComplete = useCallback(
		(fieldName: string) => {
			if (fieldName == "party") return parties;
			return [];
		},
		[parties, forPricings, tender]
	);

	const triggerAutocompleteDataFetch = (
		fieldName: string,
		search: string = "",
		index: number = 0
	): void => {
		setActiveIndex(index);
		if (fieldName == "party") {
			fetchParties(search);
		}
	};

	const [actionGroup, setActionGroup] = useState<FieldGroup>({ fields: [] });

	useEffect(() => {
		setRowsCount(partiesList.length > 0 ? partiesList.length : 1);
		if (Object.entries(action?.fields || {})) {
			let partyFields = JSON.parse(
				JSON.stringify(Object.entries(action?.fields || {})[0][1])
			);
			if (forPricings[0]?.parties?.length) {
				partyFields.fields = Array(
					forPricings[0]?.parties?.length
				).fill(partyFields.fields[0]);
			}
			setActionGroup(partyFields);
			let sum = 0;
			if (partiesList.length > 0) {
				for (let i = 0; i < partiesList.length; i++) {
					sum = sum + partiesList[i].percentage;
				}
			}
			setPercentageSum(sum);
		}
	}, [action?.fields]);

	const addParty = () => {
		setActionGroup((previousValues: any) => {
			let newValues = JSON.parse(JSON.stringify(previousValues));
			newValues.fields.push(newValues.fields[0]);
			return newValues;
		});
		setRowsCount((previousValues: number) => {
			return previousValues + 1;
		});
	};

	const activateAddParty = (values: FormValues) => {
		let partiesValues = formatFieldsGroup(values, rowsCount);
		let sum = 0;
		for (let i = 0; i < partiesValues.fields.length; i++) {
			let percentage = parseFloat(partiesValues.fields[i].percentage);
			sum = sum + (isNaN(percentage) ? 0 : percentage);
		}
		setPercentageSum(sum);
		if (sum > 100) {
			return true;
		}
		return false;
	};
	const removeParty = (index: number, values: FormValues, errors: any) => {
		values = _.omit(values, [
			`party${index}`,
			`mirror_book${index}`,
			`percentage${index}`,
		]);
		errors = _.omit(errors, [
			`party${index}`,
			`mirror_book${index}`,
			`percentage${index}`,
		]);
		activateAddParty(values);
		setActionGroup((previousValues: FieldGroup) => {
			let newValues = JSON.parse(JSON.stringify(previousValues));
			newValues.fields.splice(index, 1);
			return newValues;
		});
		setRowsCount((previousValues: number) => {
			return previousValues - 1;
		});
		return [values, errors];
	};
	return (
		<PricingModal
			open={open}
			onClose={onClose}
			onSubmit={() => {
				if (formRef.current) {
					// @ts-ignore
					formRef.current.submitForm();
				}
			}}
			forPricings={forPricings}
			title={action?.display_name || ""}
			subTitle={"Applicable for the following pricings:"}
			isSubmitDisabled={isLoading}
			isLoading={isLoading}
			errorMessage={errorMessage}
		>
			<Formik
				key={"formik-action-pricing-modal"}
				//@ts-ignore
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				validate={(values) => {
					if (activateAddParty(values)) {
						return {
							general: "The percentage should not exceed 100",
						};
					}
					return {};
				}}
				onSubmit={async (values) => {
					values = formatFieldsGroup(values, rowsCount);
					onSubmit(values);
				}}
			>
				{({
					handleChange,
					handleSubmit,
					values,
					errors,
					setFieldValue,
					touched,
					resetForm,
					setErrors,
				}) => {
					return (
						<form
							key={"form-action-pricing-modal"}
							onSubmit={handleSubmit}
						>
							<Box sx={style.form_style}>
								{(actionGroup.fields as Field[][]).map(
									(fieldGroup, indexGroup) => (
										<Box
											key={indexGroup}
											sx={style.partyInputGroup}
										>
											{(fieldGroup as Field[]).map(
												(field, ind) => (
													<Box
														key={`${field.name}_container`}
													>
														<>
															<Box
																sx={{
																	display:
																		"flex",
																}}
															>
																{field.component ==
																	"input" && (
																	<CustomInput
																		key={
																			field.label +
																			"input"
																		}
																		InputProps={{
																			endAdornment:
																				(
																					<InputAdornment position="start">
																						{
																							field.label
																						}
																					</InputAdornment>
																				),
																			type: field.type,
																		}}
																		rows={3}
																		name={`${field.name}${indexGroup}`}
																		autoFocus={
																			ind ===
																			0
																		}
																		placeholder={
																			field.title
																		}
																		fullWidth
																		onChange={
																			handleChange
																		}
																		variant="standard"
																		// @ts-ignore
																		value={
																			values[
																				`${field.name}${indexGroup}`
																			]
																				? values[
																						`${field.name}${indexGroup}`
																				  ]
																				: ""
																		}
																		// @ts-ignore
																		errorText={
																			errors[
																				`${field.name}${indexGroup}`
																			]
																		}
																		// @ts-ignore
																		touched={
																			touched[
																				`${field.name}${indexGroup}`
																			] ||
																			(!!errors[
																				`${field.name}${indexGroup}`
																			] &&
																				!touched[
																					`${field.name}${indexGroup}`
																				] &&
																				Object.entries(
																					touched
																				)
																					.length >
																					0)
																		}
																	/>
																)}

																{field.component ==
																	"autocomplete" && (
																	<CustomAutocomplete
																		key={
																			field.label +
																			"autocomplete"
																		}
																		sx={{
																			"& .MuiInputLabel-shrink":
																				{
																					display:
																						"none",
																				},
																			width: "100%",
																			marginBottom: 0,
																		}}
																		fetchData={(
																			search
																		) =>
																			triggerAutocompleteDataFetch(
																				field.name as string,
																				search,
																				indexGroup
																			)
																		}
																		data={fetchDataAutoComplete(
																			field.name as string
																		)}
																		label={
																			field.label
																		}
																		name={`${field.name}${indexGroup}`}
																		loading={
																			loaderDataAutoComplete &&
																			activeIndex ===
																				indexGroup
																		}
																		setFieldValue={
																			setFieldValue
																		}
																		defaultValue={
																			""
																		}
																		value={
																			values[
																				`${field.name}${indexGroup}`
																			]
																				? values[
																						`${field.name}${indexGroup}`
																				  ]
																				: undefined
																		}
																		onChange={
																			handleChange
																		}
																		error={
																			!!errors[
																				`${field.name}${indexGroup}`
																			] &&
																			Object.entries(
																				touched
																			)
																				.length >
																				0 &&
																			!touched[
																				`${field.name}${indexGroup}`
																			]
																		}
																		helperText={
																			!!errors[
																				`${field.name}${indexGroup}`
																			] &&
																			Object.entries(
																				touched
																			)
																				.length >
																				0 &&
																			!touched[
																				`${field.name}${indexGroup}`
																			]
																				? (
																						errors[
																							`${field.name}${indexGroup}`
																						] as any
																				  )
																						.name
																				: ""
																		}
																	/>
																)}
															</Box>
														</>
													</Box>
												)
											)}
											<Box
												component={"span"}
												sx={{
													minWidth: "50px",
												}}
											>
												{indexGroup ==
													actionGroup.fields.length -
														1 &&
													indexGroup > 0 && (
														<IconButton
															onClick={() => {
																const result =
																	removeParty(
																		indexGroup,
																		values,
																		errors
																	);
																resetForm({
																	values: result[0],
																	errors: result[1],
																});
																setErrors(
																	result[1]
																);
															}}
														>
															<DeleteIcon />
														</IconButton>
													)}
											</Box>
										</Box>
									)
								)}
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<IconButton
										disabled={percentageSum > 100}
										onClick={addParty}
									>
										<AddCircleOutlineIcon />
									</IconButton>
								</Box>
								<If condition={(errors as any).general}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<FormHelperText
											error={true}
											sx={{
												fontSize: "16px",
											}}
										>
											{(errors as any).general}
										</FormHelperText>
									</Box>
								</If>
								<If condition={percentageSum < 100}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<FormHelperText
											error={false}
											sx={{
												fontSize: "16px",
											}}
										>
											The percentage is less than 100
										</FormHelperText>
									</Box>
								</If>
							</Box>
						</form>
					);
				}}
			</Formik>
		</PricingModal>
	);
}
