export enum WsEventTypes {
	NOTIFICATION_CREATED = "notifications/notification_created",
	TENDER_UPDATED = "tenders/tender_updated",
	TENDER_CREATED = "tenders/tender_created",
	TENDER_PICKUP = "tenders/tender_pickup",
	PRICING_CREATED = "pricings/pricing_created",
	PRICING_UPDATED = "pricings/pricing_updated",
	PRICINGS_CANCELLED = "pricings/pricings_cancelled",
	WEBSOCKET_CONNECTED = "websocket_connected",
	PROXY_IN_PROGRESS = "sites/proxy_in_progress",
	PROXY_SUCCEEDED = "sites/proxy_succeeded",
	PROXY_FAILED = "sites/proxy_failed",
}
