import { enqueueSnackbar } from "notistack";
import { apiPost } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import { getTradersError, getTradersSuccess } from "./PricingTradersPage.slice";

export function getTraders(): any {
	return function (dispatch: AppDispatch) {
		return apiPost(`external-services/users`, {
			group_filter: "gepo-trader",
		}).then(
			(users) => {
				return dispatch(getTradersSuccess(users.data));
			},
			(error) => {
				return dispatch(getTradersError(error.message));
			}
		);
	};
}

export function setTradersOnDuty(traders: number[]): any {
	return function (dispatch: AppDispatch) {
		return apiPost(`external-services/traders_on_duty`, { traders }).then(
			() => {
				enqueueSnackbar("Pricing traders saved", {
					variant: "success",
					autoHideDuration: 5000,
				});
				dispatch(getTraders());
			},
			(error) => {
				enqueueSnackbar(error.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
				return dispatch(getTradersError(error.message));
			}
		);
	};
}
