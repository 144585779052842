import { Box } from "@mui/material";
import { Toggle } from "../../Toggle";
import Tag from "../../Tag/Tag";
import { style } from "./CurtailmentKindFilter.style";
import { useCallback } from "react";
import { noop } from "../../../utils/operations";

export interface CurtailmentKindFilterProps {
	curtailementKinds: { name: string; isActive: boolean }[];
	onChange: (kinds: { name: string; isActive: boolean }[]) => void;
}

export const CurtailmentKindFilter = ({
	curtailementKinds,
	onChange,
}: CurtailmentKindFilterProps) => {
	const onToggleOneKind = useCallback(
		({ name, isActive }: { name: string; isActive: boolean }) => {
			const currentKinds = [...curtailementKinds];
			const kindToChange = currentKinds.find(
				(kind) => kind.name === name
			);
			kindToChange!.isActive = !isActive;
			return onChange(currentKinds);
		},
		[curtailementKinds]
	);

	return (
		<Box sx={style.container}>
			{curtailementKinds.map(
				(kind: { name: string; isActive: boolean }, index) => (
					<Box sx={style.container.itemContainer} key={index}>
						<Box onClick={() => onToggleOneKind(kind)}>
							<Toggle
								name={""}
								label={""}
								onChange={noop}
								isTrue={kind.isActive}
							/>
						</Box>
						<Tag label={kind.name} />
					</Box>
				)
			)}
		</Box>
	);
};
