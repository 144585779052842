import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

const style = {
	button: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		gap: "4px",
	},
};

const CopyToClipboardButton = ({
	label,
	text,
}: {
	label: string;
	text: string;
}) => {
	const [isActive, setIsActive] = useState(false);

	if (!text) {
		return null;
	}

	useEffect(() => {
		if (isActive) {
			const interval = setInterval(() => {
				setIsActive(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [isActive]);

	const handleClick = () => {
		navigator.clipboard.writeText(text);
		setIsActive(true);
	};

	return (
		<Box role="button" onClick={handleClick} sx={style.button}>
			<ContentCopyIcon />
			{isActive && "Copied!"}
			{!isActive && label}
		</Box>
	);
};

export default CopyToClipboardButton;
