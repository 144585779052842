import { useRef, useState } from "react";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { Box, Checkbox, IconButton } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
	backgroundBrandSecondary,
	dangerPrimary,
	neutralPrimary,
	primaryColor,
	textNeutralTertiary,
} from "../../../core/theme";
import Modal from "../../../common/components/Modal";
import { PrimaryButton } from "../../../common/components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ContractAttachementType } from "../../../requests_geco/contractsApi/contractsApi.types";

const ContractFilesModal = ({
	onClose,
	open,
	files,
	isLoading,
	deleteAttachement,
	uploadAttachement,
	gecoEndpoint,
}: {
	onClose: (contractId: number | null) => void;
	deleteAttachement: (attachementId: number) => void;
	uploadAttachement: (attachement: File) => void;
	open: boolean;
	files?: (ContractAttachementType & { uploading: boolean })[];
	isLoading?: boolean;
	gecoEndpoint: string | undefined;
}) => {
	const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
	const [fileIdSelectedForDeletion, setFileIdSelectedForDeletion] = useState<
		number | null
	>(null);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleUploadButtonClick = () => {
		fileInputRef.current?.click();
	};
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!!event.target.files?.[0])
			uploadAttachement(event.target.files?.[0]);
	};

	const handleClose = () => onClose(null);

	const handleDelete = (attachementId: number) => () => {
		setFileIdSelectedForDeletion(null);
		deleteAttachement(attachementId);
	};

	const handleSelect = (fileId: number) => () =>
		setSelectedFiles((prevSelectedFiles) =>
			prevSelectedFiles.includes(fileId)
				? prevSelectedFiles.filter((id) => id !== fileId)
				: prevSelectedFiles.concat(fileId)
		);

	async function handleDownloadSelectedFiles() {
		const selectedFilesToDownload = files?.filter((file) =>
			selectedFiles.includes(file.id)
		);
		if (!selectedFilesToDownload) return;
		const zip = new JSZip();
		const zipFilename = "documents.zip";
		for (const fileData of selectedFilesToDownload) {
			try {
				const filename = fileData.filename.split("/")[1];
				const file = await JSZipUtils.getBinaryContent(fileData.file);
				zip.file(filename, file, { binary: true });
			} catch (err) {
				console.log(
					`error while getting and zipping file ${fileData.file}`
				);
				continue;
			}
		}
		const content = await zip.generateAsync({ type: "blob" });
		saveAs(content, zipFilename);
	}

	return (
		<>
			<Modal
				title={
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<FilePresentIcon
							sx={{ color: primaryColor, width: "20px" }}
						/>
						Files Attached
					</Box>
				}
				onClose={handleClose}
				open={open}
			>
				{isLoading && (
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<CircularProgress />
					</Box>
				)}
				{!isLoading && !files?.length && (
					<Box sx={{ color: textNeutralTertiary }}>
						<Box>No file attached yet.</Box>
						<Box>
							To add some one, upload one or several files (.pdf)
							with the button below.
						</Box>
					</Box>
				)}
				{!isLoading && !!files?.length && (
					<>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
							}}
						>
							{files.map((file) => (
								<Box
									key={file.id}
									sx={{
										background: backgroundBrandSecondary,
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Box>
										<Checkbox
											size="small"
											checked={selectedFiles.includes(
												file.id
											)}
											onClick={handleSelect(file.id)}
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: 1,
											flexGrow: 1,
										}}
									>
										<InsertDriveFileIcon
											sx={{ width: "18px" }}
										/>
										<Box
											id="name"
											sx={{
												textDecoration: "underline",
												maxWidth: "400px",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{file.name}
										</Box>
									</Box>
									{file.uploading && (
										<CircularProgress
											size={20}
											sx={{ margin: 2 }}
										/>
									)}
									{!file.uploading && (
										<>
											<a
												download={file.name}
												href={new URL(
													file.file,
													gecoEndpoint
												).toString()}
												style={{
													display: "flex",
													alignItems: "center",
													gap: 1,
													color: neutralPrimary,
													textTransform: "initial",
												}}
												target="_blank"
												rel="noreferrer"
											>
												<DownloadOutlinedIcon />{" "}
												Download
											</a>

											<IconButton
												onClick={() =>
													setFileIdSelectedForDeletion(
														file.id
													)
												}
											>
												<DeleteOutlineIcon
													sx={{
														color: dangerPrimary,
													}}
												/>
											</IconButton>
										</>
									)}
								</Box>
							))}
						</Box>

						{!!selectedFiles.length && (
							<PrimaryButton
								color="secondary"
								text={`Download selection (${selectedFiles.length})`}
								sx={{ width: "100%", marginTop: 3 }}
								startIcon={<DownloadIcon />}
								onClick={handleDownloadSelectedFiles}
							/>
						)}
					</>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						marginTop: 4,
					}}
				>
					<input
						type="file"
						style={{ display: "none" }}
						ref={fileInputRef}
						onChange={handleFileChange}
					/>
					<PrimaryButton
						color="secondary"
						text="Upload file"
						sx={{ width: "100%" }}
						startIcon={<CloudUploadIcon />}
						onClick={handleUploadButtonClick}
					/>
					<PrimaryButton
						text="Close"
						sx={{ width: "100%" }}
						onClick={handleClose}
					/>
				</Box>
			</Modal>

			{fileIdSelectedForDeletion !== null && (
				<Modal
					title={
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
							}}
						>
							<DeleteOutlineIcon
								sx={{
									color: dangerPrimary,
									width: "20px",
								}}
							/>
							Deletion
						</Box>
					}
					actions={
						<>
							<PrimaryButton
								color="secondary"
								text="No, keep it"
								onClick={() =>
									setFileIdSelectedForDeletion(null)
								}
							/>
							<PrimaryButton
								sx={{
									backgroundColor: dangerPrimary,
									"&:hover": {
										backgroundColor: "#ff4d4d",
									},
								}}
								text="Yes, delete"
								onClick={handleDelete(
									fileIdSelectedForDeletion
								)}
								startIcon={<CloudUploadIcon />}
							/>
						</>
					}
					open={true}
					onClose={() => setFileIdSelectedForDeletion(null)}
				>
					Are you sure to delete this file?
				</Modal>
			)}
		</>
	);
};

export default ContractFilesModal;
