import { createSlice } from "@reduxjs/toolkit";
import { Groups } from "./authentication.constant";
import { User } from "./auth.model";

export interface UserGroups {
	isReader?: boolean;
	isAdmin?: boolean;
	isTrader?: boolean;
	isOriginator?: boolean;
}

export interface Identity {
	id: number;
	first_name: string;
	last_name: string;
	gaia_id: string;
}

interface AuthenticatedUserState {
	user?: User;
	groupNames: Groups[];
	loading: boolean;
	connected: boolean;
	disconnecting: boolean;
	token?: string;
	groups: UserGroups;
	identity?: Identity;
}

const initialState: AuthenticatedUserState = {
	user: undefined,
	loading: true,
	connected: false,
	token: undefined,
	groups: {},
	groupNames: [],
	disconnecting: false,
	identity: undefined,
};

export const authenticatedUserSlice = createSlice({
	name: "AuthenticatedUser",
	initialState,
	reducers: {
		setAuth(state, { payload }) {
			state.loading = false;
			state.token = payload.token;
			state.user = payload.user;
			state.disconnecting = payload.disconnecting;
		},
		getMeRequestSuccess(state, { payload }) {
			state.identity = payload;
			state.groups = {
				isAdmin: payload.groups.includes(Groups.admin),
				isOriginator: payload.groups.includes(Groups.originator),
				isTrader: payload.groups.includes(Groups.trader),
				isReader: payload.groups.includes(Groups.reader),
			};
			state.groupNames = payload.groups.map((g: string) =>
				g.replace(/^gepo-/, "")
			);
		},
		updateWsConnected(state, { payload }) {
			state.connected = payload;
		},
	},
});

export const { setAuth, getMeRequestSuccess, updateWsConnected } =
	authenticatedUserSlice.actions;

export default authenticatedUserSlice.reducer;
