import { useEffect } from "react";

export interface FormikAutoSubmitProps extends React.PropsWithChildren {
	onSubmitForm: () => void;
	values: any;
}
export const FormikAutoSubmit = ({
	children,
	values,
	onSubmitForm,
}: FormikAutoSubmitProps) => {
	useEffect(() => {
		onSubmitForm();
	}, [values]);
	return <>{children}</>;
};
