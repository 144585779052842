import _ from "lodash";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import {
	useLazyGetOneContractQuery,
	useUpdateOneContractMutation,
} from "../../requests_geco/contractsApi";
import ContractPeriodTabs from "./ContractPeriodTabs";
import { BreadCrumbs } from "../../common/components/BreadCrumbs";
import { Spacer } from "../../common/components/Spacer";
import InfoBlock from "../../common/components/InfoBlock/InfoBlock";
import { PrimaryButton } from "../../common/components/CustomButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { If } from "../../common/components/If";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";
import {
	ContractDetailType,
	ContractPeriodType,
	TradeType,
} from "../../requests_geco/contractsApi/contractsApi.types";
import { ContractPeriodSelect } from "./ContractPeriodSelect";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyGetNegativePriceIndexesQuery } from "../../requests_geco/referentialApi/referentialApi";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { getPathWithParameters, PATH } from "../../router-path";
import BookingLogs from "./BookingLogs";

const ContractPeriod = () => {
	const navigate = useNavigate();
	const { contractId, contractPeriodId } = useParams();
	const [
		getContractBase,
		{
			data: contractData,
			isLoading: isFetchingContract,
			error: isGetContractError,
		},
	] = useLazyGetOneContractQuery();
	const [
		getIndexesBase,
		{ data: negativePriceIndexes, isLoading: isFetchingIndex },
	] = useLazyGetNegativePriceIndexesQuery();
	const [updateContractBase, { isLoading, error: contractUpdateError }] =
		useUpdateOneContractMutation();

	const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);
	const getIndexes = useRtkQueryDynamicEndpoint(getIndexesBase);
	const getContract = useRtkQueryDynamicEndpoint(getContractBase);

	const [updatedContract, setUpdatedContract] = useState<
		ContractDetailType | undefined
	>();

	useEffect(() => {
		if (contractId) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: false,
			});
		}
	}, [contractId]);

	useEffect(() => {
		if (contractId && isGetContractError) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: true,
			});
		}
	}, [contractId, isGetContractError]);

	useEffect(() => {
		if (contractData) {
			setUpdatedContract(contractData);
			getIndexes({ country_code: contractData?.country });
		}
	}, [contractData]);

	const contractContainsChanges = useMemo(() => {
		return !_.isEqual(updatedContract, contractData);
	}, [updatedContract, contractData]);

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return updatedContract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [updatedContract, contractPeriodId]);

	const breadCrumbsLabel = useMemo(() => {
		if (contractPeriod && contractData) {
			const contractPath = getPathWithParameters(PATH.CONTRACT_DETAIL, {
				contractId: contractData.id,
			});
			const contractPeriodPath = getPathWithParameters(
				PATH.CONTRACTPERIOD,
				{
					contractId: contractData.id,
					contractPeriodId: contractPeriod.id,
				}
			);
			return [
				{ path: contractPath, name: contractData.name },
				{ path: contractPeriodPath, name: contractPeriod.name },
			];
		}
		return [];
	}, [contractPeriod, contractData]);

	const navigateToOtherContractPeriod = (cpId: string) => {
		navigate(
			getPathWithParameters(PATH.CONTRACTPERIOD, {
				contractId,
				contractPeriodId: cpId,
			})
		);
	};

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract period"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={() =>
							navigate(
								getPathWithParameters(PATH.CONTRACT_DETAIL, {
									contractId,
								})
							)
						}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				rightSide={
					<>
						<If condition={contractContainsChanges}>
							<PrimaryButton
								onClick={() =>
									updateContract(
										updatedContract as ContractDetailType
									)
								}
								text="Update changes"
								type="button"
								color="primary"
							></PrimaryButton>
						</If>
					</>
				}
			/>
			<PageContent>
				<BreadCrumbs crumbsLabels={breadCrumbsLabel} />
				<Spacer gap={24} />
				{isLoading && <CircularProgress data-testid="loader" />}
				{!!contractPeriod && contractData && !isLoading && (
					<>
						<BookingLogs contractPeriodId={contractPeriod.id} />
						<InfoBlock
							withBackground
							info={[
								{
									label: "Contract Type",
									value: contractData?.agreement_type,
								},
								{
									label: "Start Date",
									value: contractPeriod?.start_date,
								},
								{
									label: "End Date",
									value: contractPeriod?.end_date,
								},
								{
									label: "Signature Date",
									value: contractData?.trade_date,
								},
								{
									label: "Techno Type",
									value: contractData?.techno_type,
								},
								{
									label: "Uid",
									value: contractData?.contract_uid,
								},
								{
									label: "Counterpart",
									value:
										contractData?.mirror_book ??
										contractData?.party?.mnemonic,
								},
								{ label: "Way", value: contractData?.way },
								{
									label: "Country",
									value: contractData?.country,
								},
								{
									label: "Book",
									value: contractData?.trading_book,
								},
							]}
						/>

						<Spacer gap={32} />
						<InfoBlock
							withBackground
							info={[
								{
									label: "Base Config",
									value:
										contractPeriod?.trade_type ===
										TradeType.PPA_BASE_PHYSICAL
											? "Yes"
											: "No",
								},
								{
									label: "Goo Included",
									value: contractPeriod?.goo_price
										? "Yes"
										: "No",
								},
							]}
						>
							<ContractPeriodSelect
								availableContractPeriods={
									contractData?.contract_periods || []
								}
								currentContractPeriodId={contractPeriodId}
								onChange={navigateToOtherContractPeriod}
								showConfirmation={contractContainsChanges}
							/>
						</InfoBlock>
						<Spacer gap={24} />
						{!!updatedContract && !!contractPeriod && (
							<ContractPeriodTabs
								onSaveDraftContract={setUpdatedContract}
								contract={updatedContract}
								contractPeriod={contractPeriod}
								negativePriceIndexes={negativePriceIndexes}
							/>
						)}
					</>
				)}
			</PageContent>
			<If condition={isLoading || isFetchingContract || isFetchingIndex}>
				<OverlayLoader />
			</If>
			<ErrorMessageModal
				title={"Failed to update Contract"}
				content={formatApiErrorMessage(
					contractUpdateError as ErrorType
				)}
			/>
		</>
	);
};
export default ContractPeriod;
