import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GroupedField } from "../grouping/grouping.module";
import { useTemporaryFetch } from "../../common/hooks/useTemporaryFetch";
import { Box, LinearProgress } from "@mui/material";
import { Pricing } from "../pricing_list/pricingListSlice";
import { PricingTable } from "../pricing_list/components/PricingTable/PricingTable";
import { grey, lightGrey } from "../../core/theme";
import { usePricingActions } from "../pricing_list/hooks/actions/pricingActions.hook";
import usePrevious from "../../common/hooks/usePrevious";
import * as _ from "lodash";
import wsPublisher$ from "../../core/wsPublisher";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { ValueOrPercentContext } from "../pricing_list/ValueOrPercentContext";
import { LinqPredicate } from "../filters/utils";
import { WsMessage } from "../../core/socketProvider";
import { mergeIncomingPricings } from "../live_update/LiveUpdate.merge";
import { Column } from "../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../pricing_list/hooks/columns/common";
import { generateOdataQuery } from "./odataQuery";

export interface PricingListProps {
	tenderId?: number;
	perPage?: number;
	group: GroupedField;
	odataQuery?: string;
	rootPredicates?: LinqPredicate[];
	selectedColumns: Column<ColumnIds>[];
}

export function PricingList(props: PricingListProps) {
	const [page, setPage] = useState(1);
	const valueOrPercent = useContext(ValueOrPercentContext);
	const [isInitialLoading, setIsInitialLoading] = useState(true);

	const odataQuery = useMemo(() => {
		return generateOdataQuery(props.group.filters, props.odataQuery);
	}, [props.group, props.odataQuery]);
	const previousOdataQuery = usePrevious(odataQuery);

	useEffect(() => {
		if (odataQuery !== previousOdataQuery) {
			setPage(1);
		}
	}, [odataQuery, previousOdataQuery, setPage]);

	const { fetchTemporaryData, data, isLoading } =
		useTemporaryFetch<Pricing[]>();

	const [pricings, setPricings] = useState<Pricing[]>([]);

	const wasLoading = usePrevious(isLoading);

	useEffect(() => {
		if (wasLoading && !isLoading) {
			if (page > 1) {
				setPricings((prev) =>
					_.uniqBy(
						_.orderBy(
							[...prev, ...data],
							[
								"pricing_group_id",
								"id",
								(pricing) =>
									new Date(pricing.updated_at).getTime(),
							],
							["desc", "desc", "desc"]
						),
						"id"
					)
				);
			} else {
				setPricings(data);
			}
		}
	}, [data, isLoading, setPricings]);

	useEffect(() => {
		if (!isLoading) {
			setIsInitialLoading(false);
		}
	}, [setIsInitialLoading, isLoading]);

	const fetchPricings = useCallback(
		(currentPage: number) => {
			fetchTemporaryData(
				`/pricing?page=${currentPage}&per_page=${
					props.perPage || 100
				}&tender_id=${props.tenderId}&filters="${odataQuery}"`
			);
		},
		[props.perPage, odataQuery, props.tenderId]
	);

	useEffect(() => {
		fetchPricings(page);
	}, [fetchPricings, page]);

	const onStatusStartLoading = useCallback(
		(ids: number[]) => {
			setPricings((prev) =>
				prev.map((pricing) => {
					if (ids.includes(pricing.id)) {
						return { ...pricing, isStatusUpdating: true };
					}
					return pricing;
				})
			);
		},
		[setPricings]
	);

	const onStatusFinishLoading = useCallback(
		(ids: number[]) => {
			setPricings((prev) =>
				prev.map((pricing) => {
					if (ids.includes(pricing.id)) {
						return { ...pricing, isStatusUpdating: false };
					}
					return pricing;
				})
			);
		},
		[setPricings]
	);

	const { actOnPricings, actionModal } = usePricingActions(
		pricings,
		onStatusStartLoading,
		onStatusFinishLoading
	);

	const onTenderPickupMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			const filteredMessages = messages.filter(
				(f) => f.data?.id === props.tenderId
			);
			filteredMessages.forEach(() => {
				setPage(1);
				fetchPricings(1);
			});
		},
		[props.tenderId]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_PICKUP],
			onTenderPickupMessages
		);
	}, [onTenderPickupMessages]);

	useEffect(() => {
		const onPricingChange = (incomingPricings: Pricing[]) => {
			setPricings((prev) =>
				mergeIncomingPricings(
					prev,
					incomingPricings.filter(
						(pricing) => pricing.tender_id === props.tenderId
					),
					props.group.filters,
					props.rootPredicates
				)
			);
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_CREATED, WsEventTypes.PRICING_UPDATED],
			(messages) => {
				const incoming = messages.map((message) => message.data);
				onPricingChange(incoming);
			}
		);
	}, [setPricings, props.group, props.tenderId]);

	if (isInitialLoading) {
		return <LinearProgress />;
	}

	return (
		<Box
			sx={{
				border: `1px solid ${grey}`,
				backgroundColor: lightGrey,
				minWidth: "1888px",
				overflow: "hidden",
				height: "100%",
			}}
		>
			<PricingTable
				selectedColumns={props.selectedColumns}
				height={"100%"}
				pricings={pricings}
				valueOrPercent={valueOrPercent}
				hasNextPage={data?.length === props.perPage}
				nextPage={() => setPage((prev) => prev + 1)}
				currentPage={page}
				loading={isLoading}
				actOnPricings={actOnPricings}
				useInfiniteScroll={false}
			/>
			{actionModal}
		</Box>
	);
}
