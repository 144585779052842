export const style = {
	container: {
		display: "flex",
		gap: "24px",
		paddingLeft: "16px",
		marginBottom: "24px",

		itemContainer: {
			display: "flex",
			alignItems: "center",
			gap: "16px",
		},
	},
};
