import PricingModal from "./PricingModal";

export interface ReadCommentModalProps {
	isOpen: boolean;
	onClose: () => void;
	comment?: string;
}

export const ReadCommentModal = (props: ReadCommentModalProps) => {
	const { isOpen, comment, onClose } = props;
	return (
		<PricingModal
			open={isOpen}
			onClose={onClose}
			forPricings={[]}
			title={"Comment"}
			subTitle={comment || ""}
			isSubmitDisabled={true}
			isEmptyHidden={true}
			isLoading={false}
			errorMessage={""}
		></PricingModal>
	);
};
