import { useMemo } from "react";
import { ColModel } from "../components/GenericTableRow";
import { Trader } from "../../features/pricing_traders/PricingTradersPage.slice";
import { Item } from "../components/GenericMultiSelect";

export const useTraderTableModel = (): {
	left: ColModel<Item<Trader>>[];
	right: ColModel<Item<Trader>>[];
} => {
	const rowModel: ColModel<Item<Trader>>[] = useMemo(() => {
		const rows = [
			{
				id: "0",
				header: () => "Id",
				xs: 1,
				hidden: true,
				accessor: (m: Item<Trader>) => m.id,
			},
			{
				id: "1",
				header: () => "Name",
				xs: 2,
				accessor: (m: Item<Trader>) => (m as Trader).name,
			},
			{
				id: "2",
				header: () => "GAID ID",
				xs: 1,
				accessor: (m: Item<Trader>) => (m as Trader).gaia_id,
			},
			{
				id: "3",
				header: () => "Email",
				xs: 2,
				accessor: (m: Item<Trader>) => (m as Trader).email,
			},
			{
				id: "4",
				header: () => "Assigned tenders",
				xs: 1,
				accessor: (m: Item<Trader>) => (m as Trader).assigned_tenders,
			},
			{
				id: "5",
				header: () => "Last assigned",
				xs: 1,
				accessor: (m: Item<Trader>) =>
					(m as Trader).last_assigned ? (
						<span style={{ color: "orange" }}>(last assigned)</span>
					) : (
						""
					),
			},
		];
		return rows;
	}, []);

	const leftRowModel = [...rowModel].filter((m) => m.id !== "5");
	const rightRowModel = [...rowModel];
	return { left: leftRowModel, right: rightRowModel };
};
