export type FieldValue =
	| string
	| number
	| boolean
	| (string | number | boolean)[];

export enum OperationsDescriptions {
	EQ = "==",
	NOT = "!=",
	GE = ">=",
	LE = "<=",
	GT = ">",
	LT = "<",
	LIKE = "like",
	ILIKE = "ilike",
	IN = "in",
	NOT_IN = "not in",
	NOT_ILIKE = "not ilike",
}

export enum Operations {
	EQ = "eq",
	NOT = "not",
	GE = "ge",
	LE = "le",
	GT = "gt",
	LT = "lt",
	LIKE = "like",
	ILIKE = "ilike",
	IN = "in",
	NOT_IN = "not_in",
	NOT_ILIKE = "not_ilike",
}

export const operationMapper = {
	[Operations.EQ]: OperationsDescriptions.EQ,
	[Operations.NOT]: OperationsDescriptions.NOT,
	[Operations.GE]: OperationsDescriptions.GE,
	[Operations.LE]: OperationsDescriptions.LE,
	[Operations.GT]: OperationsDescriptions.GT,
	[Operations.LT]: OperationsDescriptions.LT,
	[Operations.LIKE]: OperationsDescriptions.LIKE,
	[Operations.ILIKE]: OperationsDescriptions.ILIKE,
	[Operations.IN]: OperationsDescriptions.IN,
	[Operations.NOT_IN]: OperationsDescriptions.NOT_IN,
	[Operations.NOT_ILIKE]: OperationsDescriptions.NOT_ILIKE,
};
