import { LinearProgress, Link, SxProps, Theme, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { grey, lightGrey, primaryColor } from "../../../core/theme";
import { pricingTableHeaders } from "../tender.constant";
import { Pricing } from "../../pricing_list/pricingListSlice";
import { siteNameOrPortfolio } from "../../../common/utils/siteNameOrPortfolio";
import { formatCurrency } from "../../../common/utils/formatCurrency";
import { formatMWH } from "../../../common/utils/formatMWH";
import { If } from "../../../common/components/If";
import { Link as RouterLink } from "react-router-dom";
import { PricingStatusComp } from "../../../common/components/StatusIcon";
import { getPathWithParameters, PATH } from "../../../router-path";
import { formatDecimal } from "../../../common/utils/formatDecimal";
import { formatPercentage } from "../../../common/utils/formatPercentage";

const style: { [key: string]: SxProps<Theme> } = {
	headersRow: {
		backgroundColor: lightGrey,
		border: `1px solid ${grey}`,
	},
	headerCell: {
		padding: 0,
		paddingLeft: 2,
		borderBottom: `1px solid ${grey}`,
		"& div": {
			display: "flex",
			alignItems: "center",
		},
	},
	tableCell: {
		paddding: 0,
		borderBottom: "none",
		"& svg": {
			float: "left",
			padding: "2px 5px 0 0",
		},
	},
	tableProxyCell: {
		paddding: 0,
		borderBottom: "none",
		width: 150,
		maxWidth: 150,
		textOverflow: "ellipsis",
		overflow: "hidden",
		"& svg": {
			float: "left",
			padding: "2px 5px 0 0",
		},
	},
	smallCell: {
		paddding: 0,
		borderBottom: "none",
		"& svg": {
			float: "left",
			padding: "2px 5px 0 0",
		},
		maxWidth: 160,
	},
};

export interface PricingTableProps {
	pricings?: Pricing[];
	tenderId: number;
	isLoading: boolean;
}

function PricingTable(props: PricingTableProps) {
	return (
		<Table size="small" aria-label="purchases">
			<TableHead>
				<TableRow sx={style.headersRow}>
					{pricingTableHeaders.map((header: string | undefined) => (
						<TableCell key={header} sx={style.headerCell}>
							{header}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{props.isLoading ? (
					<TableRow>
						<TableCell colSpan={15}>
							<LinearProgress
								data-testid={`pricing-list-on-tender-${props.tenderId}-loader`}
								sx={{ width: "100%" }}
							/>
						</TableCell>
					</TableRow>
				) : (
					props.pricings?.slice(0, 10)?.map((pricing) => (
						<TableRow key={pricing.id}>
							<TableCell
								sx={style.tableCell}
								component="th"
								scope="row"
							>
								{pricing.pricing_group_id}
							</TableCell>
							<TableCell
								sx={style.tableCell}
								component="th"
								scope="row"
							>
								{pricing.id}
							</TableCell>
							<TableCell
								sx={style.tableProxyCell}
								component="th"
								scope="row"
							>
								<Tooltip
									title={
										pricing?.tourbillon_ref ||
										pricing?.site_pricings[0].site
											.cluster_names
									}
								>
									<p>
										{pricing?.tourbillon_ref
											? pricing?.tourbillon_ref
											: "CLUSTER"}
									</p>
								</Tooltip>
							</TableCell>
							<TableCell
								sx={{ ...style.smallCell, color: primaryColor }}
							>
								<PricingStatusComp
									loader={false}
									pricingRun={_.get(
										pricing,
										"pricing_runs[0]"
									)}
								/>
							</TableCell>
							<TableCell sx={style.tableCell}>
								{siteNameOrPortfolio(pricing)}
							</TableCell>
							<TableCell sx={style.smallCell}>
								{pricing.pricing_type?.name}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{pricing.delivery_period}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatDecimal(pricing?.installed_capacity)}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatMWH(
									_.get(
										pricing,
										"pricing_runs[0].summary.P50.value"
									)
								)}
							</TableCell>
							<TableCell sx={style.tableCell}>
								<>
									<If
										condition={
											pricing?.site_pricings &&
											pricing?.site_pricings.length > 1
										}
									>
										{formatMWH(
											pricing?.site_pricings.reduce(
												(t, s) =>
													t +
													(s.site
														.real_power_production ??
														0),
												0
											)
										)}
									</If>

									<If
										condition={
											pricing?.site_pricings &&
											pricing?.site_pricings.length == 1
										}
									>
										{formatMWH(
											pricing?.site_pricings[0].site
												.real_power_production
										)}
									</If>
								</>
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatMWH(
									_.get(
										pricing,
										"pricing_runs[0].summary.Quantity.value"
									)
								)}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatCurrency(
									_.get(
										pricing,
										"pricing_runs[0].summary.Cross.value"
									)
								)}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatPercentage(
									_.get(
										pricing,
										"pricing_runs[0].summary.Alpha.value"
									)
								)}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{pricing?.beta
									? formatCurrency(
											_.get(
												pricing,
												"pricing_runs[0].summary.BetaContract.value"
											)
									  )
									: "-"}
							</TableCell>
							<TableCell sx={style.tableCell}>
								{formatCurrency(
									_.get(
										pricing,
										"pricing_runs[0].summary.INDIC.value"
									)
								)}
							</TableCell>
						</TableRow>
					))
				)}
				<If condition={!props.isLoading}>
					<TableRow>
						<TableCell colSpan={15}>
							<Link
								component={RouterLink}
								sx={{ cursor: "pointer" }}
								to={getPathWithParameters(
									PATH.TENDER_PRICING_RUN,
									{ tenderId: props.tenderId }
								)}
							>
								More
							</Link>
						</TableCell>
					</TableRow>
				</If>
			</TableBody>
		</Table>
	);
}

export default PricingTable;
