import { GenericReferential } from "../../../requests_geco/referentialApi/referentialApi.types";

export interface CreateServicePointFormik {
	servicepoint: GenericReferential | undefined;
	volume_share: number | undefined;
	start_date: string | undefined;
	end_date: string | undefined;
}

export interface CreateServicePointSubmitValues {
	servicepoint_id: string | undefined;
	volume_share: number | undefined;
	start_date: string | undefined;
	end_date: string | undefined;
}

export const getCreateServicePointDefaultValues = (
	default_start_date: string | undefined,
	default_end_date: string | undefined
) => ({
	servicepoint: undefined,
	volume_share: 100,
	start_date: default_start_date,
	end_date: default_end_date,
});

export const mapFormikToSubmitValue = (
	formikValues: CreateServicePointFormik
): CreateServicePointSubmitValues => {
	const {
		servicepoint,
		volume_share: volumeShare,
		...submitValues
	} = formikValues;
	return {
		servicepoint_id: servicepoint?.id,
		volume_share: (volumeShare as number) / 100,
		...submitValues,
	};
};
