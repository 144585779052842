import { TableCell, Box, TableRow, SxProps, Theme } from "@mui/material";
import { primaryColor, white } from "../../../../core/theme";
import { Pricing } from "../../pricingListSlice";
import { PricingStatusComp } from "../../../../common/components/StatusIcon";
import { SiteLogoOrPortfolioLogo } from "../../../../common/components/SiteOrPortfolioLogo";
import { formatPercentage } from "../../../../common/utils/formatPercentage";
import { PricingTableCell } from "../PricingTable/PricingTableCell";

export const siteNameOrPortfolio = (pricing: Pricing) => {
	if (pricing?.portfolio_id && pricing?.portfolio) {
		return pricing.portfolio.name;
	}
	return pricing.site_pricings
		.map((site_pricing) => site_pricing.site.name)
		.join(",");
};

const style: { [key: string]: SxProps<Theme> } = {
	stickyRow: {
		position: "sticky",
		left: 0,
		paddingLeft: "11px !important",
		paddingTop: "10px !important",
		paddingBottom: "10px !important",
		background: white,
		"& > td": { padding: "0px" },
	},
};

export const MarginTableRow = (props: { pricingRow: Pricing }) => {
	const { pricingRow } = props;

	const pricingTypeDisplayName =
		pricingRow.pricing_type.geco_name ||
		pricingRow.pricing_type.long_name ||
		pricingRow.pricing_type.name;

	return (
		<TableRow
			sx={{
				"&:last-child td, &:last-child th": {
					border: 0,
				},
				"& > td": { padding: "0px" },
			}}
		>
			<TableCell sx={style.stickyRow}>
				<TableCell align="left" sx={{ border: 0, width: 50 }}>
					{pricingRow.id}
				</TableCell>
				<TableCell
					sx={{
						border: 0,
						width: 190,
						minWidth: 190,
						maxWidth: 190,
						color: primaryColor,
					}}
					align="left"
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<PricingStatusComp
							pricingRun={pricingRow.pricing_runs[0]}
						/>
					</Box>
				</TableCell>
				<TableCell
					sx={{
						border: 0,
						width: 200,
						minWidth: 200,
						maxWidth: 200,
						overflow: "hidden",
					}}
					align="left"
				>
					<Box>
						<SiteLogoOrPortfolioLogo pricing={pricingRow} />
						{siteNameOrPortfolio(pricingRow)}
					</Box>
				</TableCell>
				<TableCell
					sx={{
						border: 0,
						width: 200,
						minWidth: 200,
						maxWidth: 200,
					}}
					align="left"
				>
					{pricingTypeDisplayName}
				</TableCell>
				<PricingTableCell size={50}>
					{formatPercentage(
						pricingRow.base_capacity_volume_percentage
					)}
				</PricingTableCell>
				<PricingTableCell size={50}>
					{pricingRow.clickability_params?.click_count > 0
						? "Yes"
						: "No"}
				</PricingTableCell>
				<PricingTableCell size={150}>
					{pricingRow.delivery_period}
				</PricingTableCell>
				<PricingTableCell size={100}>
					{pricingRow.site_pricings
						.reduce(
							(acc, currentValue) =>
								acc + currentValue?.site?.installed_capacity_mw,
							0
						)
						.toFixed(2)}
				</PricingTableCell>
			</TableCell>
		</TableRow>
	);
};
