export interface UploadedFile {
	file_name: string;
	file_extension: string;
	file_size: number;
	id: number;
	errors?: string[] | null;
	sheet_names?: string[] | null;
	is_processing?: boolean;
	process_start_datetime?: string;
	latest_renewex_submission?: RenewexSubmission;
	import_status?: ImporterFileStatus;
	download_url?: string;
	associated_site_id?: number | null;
	associated_site_name?: string | null;
}

export enum UploadingFileStatus {
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
}

export interface UploadingFile {
	id: string;
	file: File;
	progress: number;
	uploaded_file?: UploadedFile;
	status: UploadingFileStatus;
	import_status?: ImporterFileStatus;
}

export function isUploadedFile(it: any): it is UploadedFile {
	return it?.id && it?.file_name;
}

export function isUploadedFileSubmitted(file: any): file is UploadedFile {
	return isUploadedFile(file) && !!file?.latest_renewex_submission;
}

export interface RenewexSubmission {
	submission_id: number;
	tender_id: number;
	status: RenewexSubmissionStatus;
	files: Array<{ id: number }>;
}

export enum RenewexSubmissionStatus {
	IN_PROGRESS = "IN_PROGRESS",
	SUBMITTED = "SUBMITTED",
	ERROR = "ERROR",
	OUT_OF_DATE = "OUT_OF_DATE",
	DONE = "DONE",
}

export enum ImporterFileStatus {
	NEW = "NEW",
	IMPORTED = "IMPORTED",
	PENDING_IMPORT = "PENDING_IMPORT",
	IMPORT_FAILED = "IMPORT_FAILED",
	SITE_NOT_FOUND = "SITE_NOT_FOUND",
}
