import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "./CustomButton";
import { If } from "./If";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

interface ErrorMessageProps {
	title: string;
	content?: any;
	actionAfterClose?: () => void;
}
export default function ErrorMessageModal(props: ErrorMessageProps) {
	const { content, title, actionAfterClose } = props;
	const [isOpen, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (content) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [content]);

	const closeModal = () => {
		setOpen(false);
		if (actionAfterClose) {
			actionAfterClose();
		}
	};

	return (
		<Dialog onClose={closeModal} open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{Array.isArray(content) &&
						content?.map((element: string) => (
							<Typography paragraph key={element}>
								- &nbsp;{element}{" "}
							</Typography>
						))}
					<If condition={typeof content === "string"}>
						<Typography paragraph>{content}</Typography>
					</If>
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<PrimaryButton
					onClick={closeModal}
					text="Close"
					type="button"
					color="primary"
				></PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}
