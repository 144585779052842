export const style = {
	gap: "16px",
	display: "flex",
	item: {
		display: "flex",
		flexDirection: "column",
		fontFamily: "Lato",
		fontSize: "16px",
		lineHeight: "24px",
		gap: "4px",
	},
	head: {
		flexDirection: "inline",
		gap: "8px",
	},
	title: {
		color: "#60798B",
	},
	content: {
		fontWeight: "400",
	},
	contentActive: {
		fontWeight: "800",
	},
};
