import {
	Box,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "./FullScreenModal.style";
import { ReactNode } from "react";

function FullScreenModal({
	open,
	onClose,
	children,
	title,
	actions,
	maxWidth,
}: {
	open: boolean;
	onClose(): void;
	children: ReactNode;
	title?: ReactNode;
	actions?: ReactNode;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}) {
	return (
		<Dialog fullScreen open={open} onClose={onClose} maxWidth={maxWidth}>
			{!!title && (
				<DialogTitle sx={style.title} id="customized-dialog-title">
					{title}
				</DialogTitle>
			)}
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={style.closeButton}
			>
				<CloseIcon />
			</IconButton>

			<Box sx={{ padding: "16px" }}>{children}</Box>

			{!!actions && (
				<DialogActions sx={style.actions}>{actions}</DialogActions>
			)}
		</Dialog>
	);
}

export default FullScreenModal;
