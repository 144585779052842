import { AxiosError } from "axios";
import {
	formatErrorResponse,
	isBadRequestResponseWithErrors,
} from "../../common/errors/formatErrorResponse";
import { apiPut, apiGet } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import {
	getFeatureFlagsRequestSuccess,
	getPricingParamsRequestError,
	getPricingParamsRequestSuccess,
	setPricingParamsRequestError,
	setPricingParamsRequestSuccess,
	getFeatureFlagsRequestError,
} from "./ParametersPage.slice";
import { enqueueSnackbar } from "notistack";

export function getPricingParameter(): any {
	return async function (dispatch: AppDispatch) {
		try {
			const response = await apiGet("parameters?api_version=2");
			const list = response.data.data;
			return dispatch(getPricingParamsRequestSuccess(list));
		} catch (error) {
			const axiosError = error as AxiosError<any>;
			enqueueSnackbar(axiosError?.response?.data?.message, {
				variant: "error",
				autoHideDuration: 3000,
			});
			return dispatch(getPricingParamsRequestError(axiosError.message));
		}
	};
}

export function updatePricingParameter(
	pricingParam: object,
	comment: string
): any {
	return function (dispatch: AppDispatch) {
		return apiPut("parameters", {
			parameters_type: "constant_pricing_parameters",
			value: pricingParam,
			comment,
		})
			.then(() => dispatch(getPricingParameter()))
			.then(
				() => {
					enqueueSnackbar(`Pricing Parameter succesfuly saved`, {
						variant: "success",
						autoHideDuration: 3000,
					});
					return dispatch(
						setPricingParamsRequestSuccess(pricingParam)
					);
				},
				(error) => {
					try {
						if (
							isBadRequestResponseWithErrors(
								error?.response?.data
							)
						) {
							const msg = formatErrorResponse(
								error?.response?.data
							).join(", ");
							enqueueSnackbar(msg, {
								variant: "error",
								autoHideDuration: 3000,
							});
						} else {
							enqueueSnackbar("Something went wrong", {
								variant: "error",
								autoHideDuration: 3000,
							});
						}
					} finally {
						return dispatch(
							setPricingParamsRequestError(error.response)
						);
					}
				}
			);
	};
}

export function getGepoFeatureFlags(): any {
	return function (dispatch: AppDispatch) {
		return apiGet("feature-flags").then(
			(flagData) => {
				const data = flagData.data;
				return dispatch(getFeatureFlagsRequestSuccess(data));
			},
			(error) => {
				enqueueSnackbar(error.response.data.message, {
					variant: "error",
					autoHideDuration: 3000,
				});
				return dispatch(getFeatureFlagsRequestError(error.message));
			}
		);
	};
}
