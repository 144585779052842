import ContractFilesModal from "./ContractFilesModal";
import { useRtkQueryDynamicEndpoint } from "../../../common/hooks/useRtkQueryDynamicEndpoint";
import {
	useGetContractAttachementsQuery,
	useUploadContractAttachementMutation,
	useDeleteContractAttachementMutation,
} from "../../../requests_geco/contractsApi/contractsApi";
import { useMemo, useState } from "react";
import { FeatureName } from "../../../common/hooks/useFeatureFlags";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../parameters/ParametersPage.selector";
import axios from "axios";

const ContractFilesModalContainer = ({
	setContractIdFilesOpen,
	contractIdFilesModalOpen,
}: {
	setContractIdFilesOpen: (contractId: number | null) => void;
	contractIdFilesModalOpen: number;
}) => {
	const { data, isLoading } = useRtkQueryDynamicEndpoint(
		useGetContractAttachementsQuery
	)({ contract_id: contractIdFilesModalOpen });

	const [uploadAttachementBase] = useUploadContractAttachementMutation();
	const uploadAttachement = useRtkQueryDynamicEndpoint(uploadAttachementBase);

	const [deleteAttachementBase] = useDeleteContractAttachementMutation();
	const deleteAttachement = useRtkQueryDynamicEndpoint(deleteAttachementBase);

	const [uploadingFileIds, setUploadingFileIds] = useState<number[]>([]);

	const features = useSelector(selectFeatureFlags);
	const gecoEndpoint = useMemo(
		() => features[FeatureName.geco_endpoint],
		[features]
	) as unknown as string;

	const handleClose = () => setContractIdFilesOpen(null);

	const handleDelete = (attachementId: number) => {
		deleteAttachement({
			contract_id: contractIdFilesModalOpen,
			attachement_id: attachementId,
		});
	};

	const handleUpload = async (file: File) => {
		const response = await uploadAttachement({
			contract_id: contractIdFilesModalOpen,
			filename: file.name,
		});

		setUploadingFileIds((currentUploadingFileIds) => [
			...currentUploadingFileIds,
			// @ts-ignore
			response?.data?.id,
		]);

		const formData = new FormData();
		formData.append(file.name, file);

		const uploader = axios.create();
		// @ts-ignore
		await uploader.put(response?.data?.presigned_upload_url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		setUploadingFileIds((currentUploadingFileIds) =>
			// @ts-ignore
			currentUploadingFileIds.filter((id) => id !== response?.data?.id)
		);
	};

	return (
		<ContractFilesModal
			onClose={handleClose}
			open={contractIdFilesModalOpen !== null}
			files={
				data?.map((file) => ({
					...file,
					uploading: uploadingFileIds.includes(file.id),
				})) ?? []
			}
			isLoading={isLoading}
			deleteAttachement={handleDelete}
			uploadAttachement={handleUpload}
			gecoEndpoint={gecoEndpoint}
		/>
	);
};

export default ContractFilesModalContainer;
