import { TableHead, TableRow } from "@mui/material";
import { style } from "../../pricing_list/PricingListPage";
import { headerCells } from "../tender.constant";
import HeaderCell from "./HeaderCell";
import { memo } from "react";
import { HamburgerMenu } from "../../../common/components/HamburgerMenu";
import { HamburgerOptions } from "../../../common/components/HamburgerOptions";
import { Action } from "../../pricing_list/pricingListSlice";

export interface TenderTableHeaderProps {
	selectAll: () => void;
	clearSelection: () => void;
	selectedIds: number[];
	actions: Action[];
	onTenderBatchActions: (action: Action) => void;
}

function TenderTableHeaderComponent(props: TenderTableHeaderProps) {
	return (
		<TableHead>
			<TableRow sx={style.headersRow}>
				<HeaderCell align="center">
					<HamburgerMenu datatestId="hamburgerMenuList">
						{!props.selectedIds?.length ? (
							<HamburgerOptions
								title={"Select All"}
								action={() => props.selectAll()}
							/>
						) : (
							<HamburgerOptions
								title={"Clear Selection"}
								action={() => props.clearSelection()}
							/>
						)}
						{props.actions.map((action) => (
							<HamburgerOptions
								key={action.action}
								title={action.display_name}
								action={() =>
									props.onTenderBatchActions(action)
								}
							/>
						))}
					</HamburgerMenu>
				</HeaderCell>
				{headerCells.map(
					(headerCell: {
						text: string;
						id: string;
						filterBy?: boolean;
					}) => (
						<HeaderCell
							align="left"
							key={headerCell.id}
							id={headerCell.id}
							text={headerCell.text}
							filterBy={headerCell.filterBy}
						/>
					)
				)}
				<HeaderCell text="" id="" align="right"></HeaderCell>
			</TableRow>
		</TableHead>
	);
}

export const TenderTableHeader = memo(TenderTableHeaderComponent);
