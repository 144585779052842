import { useCallback } from "react";

export interface ValidationError {
	Reason: string;
	errors: {
		loc: string[];
		msg: string;
		type: string;
	}[];
}

export interface PydanticErrorFormat {
	getFieldErrorMessage: (field: string) => string;
	getRootErrorMessage: () => string;
}

export const usePydanticErrorFormat = (
	currentError: string | ValidationError | undefined | null
): PydanticErrorFormat => {
	const formatPydanticRootMessage = (message: string) => {
		const regex = /'([^']*)'/g;
		const match = regex.exec(message);
		if ((match?.length || 0) > 1) {
			// @ts-ignore
			return match[1];
		}
		return "";
	};

	const isValidationError = (
		error: ValidationError | string | undefined | null
	) => {
		if (typeof error === "object") {
			if (error?.Reason) {
				return true;
			}
		}
		return false;
	};

	const getFieldErrorMessage = useCallback(
		(field: string) => {
			if (isValidationError(currentError)) {
				const pydanticError = currentError as ValidationError;
				const rootError = pydanticError.errors.find((error) =>
					error.loc.join("").includes(field)
				);
				return formatPydanticRootMessage(rootError?.msg || "");
			}
			return "";
		},
		[currentError, formatPydanticRootMessage, isValidationError]
	);

	const getRootErrorMessage = useCallback(() => {
		return getFieldErrorMessage("__root__");
	}, [getFieldErrorMessage]);

	return {
		getFieldErrorMessage,
		getRootErrorMessage,
	};
};
