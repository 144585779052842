export const stringToPastelColor = (str: string) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	const multiplier = 3;

	const r = Math.min(Math.floor((hash & 0x7f) * multiplier) + 128, 255);
	const g = Math.min(
		Math.floor(((hash >> 8) & 0x7f) * multiplier) + 128,
		255
	);
	const b = Math.min(
		Math.floor(((hash >> 16) & 0x7f) * multiplier) + 128,
		255
	);

	const color = ((r << 16) | (g << 8) | b).toString(16).toUpperCase();

	return `#${"00000".substring(0, 6 - color.length)}${color}`;
};

export const adjustBrightness = (hexColor: string, delta: number) => {
	// Convert hexadecimal to RGB
	hexColor = hexColor.replace("#", "");
	const bigint = parseInt(hexColor, 16);
	let r = (bigint >> 16) & 255;
	let g = (bigint >> 8) & 255;
	let b = bigint & 255;

	// Adjust brightness by adding delta to each RGB component
	r = Math.min(Math.max(r + delta, 0), 255);
	g = Math.min(Math.max(g + delta, 0), 255);
	b = Math.min(Math.max(b + delta, 0), 255);

	// Convert back to hexadecimal
	const newHexColor = ((r << 16) | (g << 8) | b)
		.toString(16)
		.padStart(6, "0");

	return `#${newHexColor}`;
};

export const isColorLight = (hexColor: string) => {
	// Convert hexadecimal to RGB
	hexColor = hexColor.replace("#", "");
	const bigint = parseInt(hexColor, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	// Calculate brightness using luminance
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

	// Determine if color is light or dark based on luminance
	return luminance > 0.5;
};
