import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";

const style = {
	addIcon: {
		cursor: "pointer",
		marginRight: "8px",
	},
};

export interface AddTimeseriesButtonProps {
	onAddManually: () => void;
	onUploadFile: () => void;
}

export function AddTimeseriesButton(props: AddTimeseriesButtonProps) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleMenuItemClick = () => {
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<React.Fragment>
			<ButtonGroup ref={anchorRef}>
				<Button
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<AddIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom"
									? "center top"
									: "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									<MenuItem
										onClick={() => {
											handleMenuItemClick();
											props.onAddManually();
										}}
									>
										<EditIcon sx={style.addIcon} />
										Add timeserie from tourbillon
									</MenuItem>
									<MenuItem
										onClick={() => {
											handleMenuItemClick();
											props.onUploadFile();
										}}
									>
										<CloudUploadIcon sx={style.addIcon} />
										Upload file linked to site
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}
