import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
	Status,
	getStatusValues,
} from "../../pricing_list/pricingList.constant";

interface PrincingStatusProps {
	status: Status;
}

const style: { [key: string]: (status: Status) => SxProps<Theme> } = {
	pricingStatusBox: (status: Status) => ({
		textAlign: "left",
		minWidth: "112px",
		color: getStatusValues(status).color,
	}),
};

function PricingStatusCell(props: PrincingStatusProps) {
	const { status } = props;
	const StatusIcon = getStatusValues(status).icon;
	return (
		<Box sx={style.pricingStatusBox(props.status)}>
			<StatusIcon sx={{ fontSize: 16 }} />
			<Typography variant="subtitle2" component="p">
				{getStatusValues(status).text}
			</Typography>
		</Box>
	);
}

export default PricingStatusCell;
