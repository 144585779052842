import PlaceIcon from "@mui/icons-material/Place";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { ReactCountryFlag } from "react-country-flag";
import { ColSubModel } from "../components/GenericTableRow";
import { formatCoordinates } from "./helpers";
import {
	Portfolio,
	Site,
	isPortfolio,
} from "../../features/sites/sites.module";
import { formatProxyGenerationStatus } from "../utils/formatProxyGenerationStatus";
import { FlashUpdateTableCell } from "../components/FlashUpdateTableCell";
import { SiteKnownStatusField } from "../components/SiteKnownStatusField";
import { SiteTechnicalDetails } from "../components/SiteTechnicalDetails";

export interface TenderFormSiteTableSubModelParams {
	showActions: boolean;
	showRefreshCluster?: boolean;
	deleteAction: (parent: any, m: Site) => void;
}

export const useTenderFormSiteTableSubModel = (
	params: TenderFormSiteTableSubModelParams
) => {
	const { showActions, deleteAction, showRefreshCluster = true } = params;
	const subRowModel: ColSubModel<Site>[] = useMemo(() => {
		const rows = [
			{
				id: "0",
				header: () => "Actions",
				xs: 1,
				hidden: !showActions,
				accessor: (m: Site, index: number, parent: any) => (
					<>
						<Typography
							component="span"
							sx={{
								justifyContent: "center",
								display: "flex",
								width: "145px",
							}}
						>
							<DeleteIcon
								sx={{ cursor: "pointer" }}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									deleteAction(parent, m);
								}}
							/>
						</Typography>
					</>
				),
			},
			{
				id: "1",
				header: () => "Id",
				xs: 1,
				hidden: true,
				accessor: (m: Site) => m.id,
			},
			{
				id: "2",
				header: () => "Name",
				xs: 2,
				accessor: (m: Site) =>
					m.specific_name ? m.specific_name : m.name,
			},
			{
				id: "3",
				header: () => "Type",
				xs: 1,
				accessor: (m: Site) => (
					<>
						{!!m.children && (
							<>
								<BookmarksIcon />
								{" Portfolio"}
							</>
						)}
						{!m.children && (
							<>
								{" "}
								<PlaceIcon></PlaceIcon>
								{" Site"}
							</>
						)}
					</>
				),
			},
			{
				id: "4",
				header: () => "Country",
				xs: 1,
				accessor: (m: Site) => (
					<>
						<ReactCountryFlag
							countryCode={m.country ?? ""}
							svg
							title={m.country ?? ""}
						/>{" "}
						{m.country}
					</>
				),
			},
			{
				id: "5",
				header: () => "Cluster",
				xs: 1,
				accessor: (m: Site) => {
					let clusterName = "";
					if ("cluster_names" in m) {
						clusterName = m.cluster_names ?? "";
					}
					return (
						<FlashUpdateTableCell
							field={clusterName}
							isUpdate={m.isNewUpdate}
							align="left"
							componentType="box"
							title={clusterName}
							sx={{
								border: "0 !important",
								padding: "0 !important",
								width: "100% !important",
							}}
						>
							<Typography
								variant="subtitle2"
								noWrap
								sx={{
									border: "0 !important",
									padding: "0 !important",
									width: "100% !important",
								}}
							>
								{clusterName}
							</Typography>
						</FlashUpdateTableCell>
					);
				},
			},

			{
				id: "6",
				header: () => "Cl. Status",
				xs: 1,
				accessor: (m: Site) => {
					return (
						<FlashUpdateTableCell
							field={formatProxyGenerationStatus(
								m.proxy_generation_status,
								m.proxy_generation_errors
							)}
							isUpdate={m.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								padding: "0 !important",
							}}
						>
							{formatProxyGenerationStatus(
								m.proxy_generation_status,
								m.proxy_generation_errors
							)}
						</FlashUpdateTableCell>
					);
				},
			},
			{
				id: "7",
				header: () => "Cl. Date",
				xs: 1,
				accessor: (m: Site) => {
					return (
						<FlashUpdateTableCell
							field={
								m.proxy_generation_date
									? new Date(
											Date.parse(
												`${m.proxy_generation_date}Z`
											)
									  ).toLocaleString()
									: ""
							}
							isUpdate={m.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								padding: "0 !important",
							}}
						>
							{m.proxy_generation_date
								? new Date(
										Date.parse(
											`${m.proxy_generation_date}Z`
										)
								  ).toLocaleString()
								: ""}
						</FlashUpdateTableCell>
					);
				},
			},
			{
				id: "8",
				header: () => "Technology",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					"techno" in m && m?.techno?.name,
			},
			{
				id: "9",
				header: () => "Installed capacity",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						{"installed_capacity_mw" in m &&
							(m.installed_capacity_mw ?? "")}
						{!("installed_capacity_mw" in m)
							? (m as Portfolio).children.reduce(
									(t: number, s: Site) =>
										t + (s.installed_capacity_mw ?? 0),
									0
							  )
							: ""}
					</>
				),
			},
			{
				id: "10",
				header: () => "Park age",
				xs: 1,
				accessor: (m: Site | Portfolio) => m?.park_age,
			},
			{
				id: "11",
				header: () => "Technical details",
				xs: 2,
				accessor: (m: Site | Portfolio) => (
					<SiteTechnicalDetails site={m} />
				),
			},
			{
				id: "12",
				header: () => "Latitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).latitude,
			},
			{
				id: "13",
				header: () => "Longitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).longitude,
			},
			{
				id: "14",
				header: () => "Known status",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					return (
						<Typography variant="subtitle2">
							{!isPortfolio(m) && (
								<SiteKnownStatusField site={m} />
							)}
						</Typography>
					);
				},
			},
			{
				id: "15",
				header: () => "Customer P50",
				xs: 1,
				accessor: () => <></>,
			},

			{
				id: "16",
				header: () => "Cluster P50",
				xs: 1,
				accessor: () => <></>,
			},
			{
				id: "17",
				header: () => "Pricings",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? null : <></>,
			},
			{
				id: "18",
				header: () => " ",
				hidden: !showRefreshCluster,
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? null : <></>,
			},
		];
		return rows;
	}, []);

	return subRowModel;
};
