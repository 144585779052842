import { ensureArray } from "../../../common/utils/ensureArray";
import { notNullLike } from "../../../common/utils/notNullLike";
import { Portfolio } from "../../sites/sites.module";
import { Tender } from "../../tender_page/tender.module";
import { TenderReception } from "../tenderForm.module";

export function mapPortfolioFromApi(portfolio: Portfolio): Portfolio {
	return {
		...portfolio,
		children: ensureArray(portfolio.sites),
	};
}

export function mapTenderDataToReducerTender(
	tender: TenderReception
): Partial<Tender> {
	return {
		...tender,
		portfolios: tender.portfolios
			.map(mapPortfolioFromApi)
			.filter(notNullLike),
	};
}
