import { Box } from "@mui/material";
import { style } from "./InfoBlockItem.style";
import { ReactNode } from "react";
import { If } from "../If";

export interface InfoBlockItemProps {
	label?: string;
	value?: string | number | ReactNode;
	fallback?: string | number | ReactNode;
	active?: boolean;
}

export const InfoBlockItem = ({
	label,
	value,
	fallback,
	active,
}: InfoBlockItemProps) => {
	return (
		<Box sx={style.item}>
			<Box sx={style.title}>{label}</Box>
			<Box sx={active ? style.contentActive : style.content}>
				<If condition={value}>{value}</If>
				<If condition={!value}>{fallback}</If>
			</Box>
		</Box>
	);
};
