import * as _ from "lodash";
import {
	Box,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { style } from "./SimpleEditableTable.style";
import { dangerPrimary } from "../../../core/theme";
import CircularProgress from "@mui/material/CircularProgress";
import { useMemo, useState } from "react";
import { FieldTypes, SimpleEditableTableCell } from "./SimpleEditableTableCell";
import { Formik } from "formik";
import { HamburgerMenu } from "../HamburgerMenu";
import { HamburgerOptions } from "../HamburgerOptions";
import { ReactComponent as RemoveIcon } from "../../assets/icons/cross.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ActionConfirmationModal } from "../ActionConfirmationModal";
import { If } from "../If";
import { ColumnFieldModal } from "./ColumnFieldModal";
import {
	ErrorType,
	ValidationErrorType,
} from "../../utils/formatApiErrorMessage";

export interface EditHeadersType {
	accessor: string;
	label: string;
	type: FieldTypes;
	items?: any[];
	onSearch?: (search: string) => any;
}

export interface SimpleEditableTableType {
	clickableRows?: boolean;
	dataTestid?: string;
	headers: EditHeadersType[];
	isLoading?: boolean;
	selectableRows?: boolean;
	selectedRows?: (string | number)[];
	items: any[];
	validationSchema?: Record<string, any>;
	handleRowClick?: (id: number | string) => void;
	clearColumn: (header: EditHeadersType) => void;
	fillInColumn: (header: EditHeadersType, value: any) => void;
	formikRef: React.RefObject<HTMLInputElement>;
	setSelectedRows?: (rows: (string | number)[]) => void;
	onSubmit: (data: any) => void;
	errorUpdateSite?: ErrorType;
}

function SimpleEditableTable({
	clickableRows,
	dataTestid,
	handleRowClick = () => {},
	headers,
	isLoading,
	items,
	selectableRows,
	selectedRows,
	setSelectedRows,
	clearColumn,
	fillInColumn,
	onSubmit,
	formikRef,
	validationSchema,
	errorUpdateSite,
}: SimpleEditableTableType) {
	const colsLength = headers.length + (!!selectedRows ? 1 : 0);
	const [selectedHeaderTypesToClear, setHeaderTypeToClear] =
		useState<EditHeadersType>();
	const [selectedHeaderTypeToColumnFill, setHeaderTypeToColumnFill] =
		useState<EditHeadersType>();

	const itemsIds = useMemo(
		() => items.map((item) => item.id).sort(),
		[items]
	);
	const areAllItemsSelected = useMemo(
		() => JSON.stringify(itemsIds) === JSON.stringify(selectedRows?.sort()),
		[itemsIds, selectedRows]
	);

	const handleSelectAllRows = () => {
		if (setSelectedRows) {
			if (areAllItemsSelected) {
				setSelectedRows([]);
			} else {
				setSelectedRows(itemsIds);
			}
		}
	};

	return (
		<>
			<Table data-testid={dataTestid}>
				<TableHead>
					<TableRow sx={style.headerRow}>
						{selectableRows && (
							<TableCell>
								<Checkbox
									checked={areAllItemsSelected}
									onClick={() => handleSelectAllRows()}
								/>
							</TableCell>
						)}
						{headers.map((header) => (
							<TableCell key={header.accessor}>
								<Box sx={style.thWrapper}>
									{header.label}
									<HamburgerMenu datatestId="test">
										<HamburgerOptions
											title={"Edit all"}
											icon={<EditIcon />}
											action={() =>
												setHeaderTypeToColumnFill(
													header
												)
											}
										/>
										<HamburgerOptions
											title={"Clear all"}
											icon={<RemoveIcon />}
											textStyle={{ color: dangerPrimary }}
											action={() =>
												setHeaderTypeToClear(header)
											}
										/>
									</HamburgerMenu>
								</Box>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody sx={style.tableBody}>
					{isLoading && (
						<TableRow>
							<TableCell colSpan={colsLength}>
								<CircularProgress data-testid="loader" />
							</TableCell>
						</TableRow>
					)}
					<Formik
						initialValues={{
							items: items,
						}}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formikHelpers) => {
							// @ts-ignore
							formikRef.current = formikHelpers;
							const { handleChange, values, errors } =
								formikHelpers;
							return (
								<>
									{(values.items || []).map(
										(_item: any, index) => {
											const itemErrors = (
												(errorUpdateSite?.data as ValidationErrorType[]) ??
												[]
											)?.filter(
												(error) =>
													Number(error.loc[3]) ===
													index
											);

											return (
												<TableRow
													key={index}
													sx={{
														...style.bodyRow,
														...(clickableRows
															? style.bodyRow
																	.clickable
															: {}),
													}}
													onClick={() =>
														clickableRows &&
														handleRowClick(index)
													}
												>
													{selectableRows && (
														<TableCell
															sx={{
																verticalAlign:
																	"top",
															}}
														>
															<Checkbox
																checked={selectedRows?.includes(
																	index
																)}
																onClick={() =>
																	handleRowClick(
																		index
																	)
																}
															/>
														</TableCell>
													)}
													{headers.map(
														(
															header,
															headerIndex
														) => {
															const fieldError =
																itemErrors.find(
																	(error) =>
																		error
																			.loc[4] ===
																		header.accessor
																);

															return (
																<SimpleEditableTableCell
																	id={`${index}-${header.accessor}`}
																	hasError={
																		!formikHelpers.isValid ||
																		!!fieldError
																	}
																	key={
																		headerIndex
																	}
																	type={
																		header.type
																	}
																	items={
																		header?.items
																	}
																	onSearch={
																		header?.onSearch
																	}
																	value={_.get(
																		values,
																		`items[${index}].${header.accessor}`
																	)}
																	name={`items[${index}].${header.accessor}`}
																	errorMessage={
																		_.get(
																			errors,
																			`items[${index}].${header.accessor}`
																		) ||
																		fieldError?.msg
																	}
																	onChange={
																		handleChange
																	}
																/>
															);
														}
													)}
												</TableRow>
											);
										}
									)}
								</>
							);
						}}
					</Formik>
				</TableBody>
			</Table>
			<ActionConfirmationModal
				isOpen={!!selectedHeaderTypesToClear}
				title={"Clear Column"}
				text="Are you sure you want to clear the column ?"
				onClose={() => setHeaderTypeToClear(undefined)}
				onConfirm={() =>
					clearColumn(selectedHeaderTypesToClear as EditHeadersType)
				}
			/>

			<If condition={selectedHeaderTypeToColumnFill}>
				<ColumnFieldModal
					title={`Fill in column ${selectedHeaderTypeToColumnFill?.label}`}
					header={selectedHeaderTypeToColumnFill as EditHeadersType}
					onClose={() => setHeaderTypeToColumnFill(undefined)}
					onConfirm={fillInColumn}
				/>
			</If>
		</>
	);
}

export default SimpleEditableTable;
