import { useEffect, useRef } from "react";

export default function useTimeout(callback: any, delay: number) {
	const timeoutRef = useRef(null);
	const savedCallback = useRef(callback);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => savedCallback.current();
		if (typeof delay === "number") {
			// @ts-ignore
			timeoutRef.current = window.setTimeout(tick, delay);
			// @ts-ignore
			return () => window.clearTimeout(timeoutRef.current);
		}
	}, [delay]);
	return timeoutRef;
}
