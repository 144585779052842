import { useCancelDealMutation } from "../../../../../requests_geco/contractsApi/contractsApi";
import { useRtkQueryDynamicEndpoint } from "../../../../../common/hooks/useRtkQueryDynamicEndpoint";
import DealTabCancelButton from "./DealTabCancelButton";
import React, { useEffect, useState } from "react";
import Modal from "../../../../../common/components/Modal";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { QueryStatus } from "@reduxjs/toolkit/query";

const DealTabCancelButtonContainer = ({
	contractPeriodId,
	dealId,
	isCompensation,
}: {
	contractPeriodId: number;
	dealId: number;
	isCompensation: boolean;
}) => {
	const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
		useState(false);

	const [cancelDealMutationBase, { isLoading, status }] =
		useCancelDealMutation();
	const cancelDealMutation = useRtkQueryDynamicEndpoint(
		cancelDealMutationBase
	);

	useEffect(() => {
		if (
			status === QueryStatus.fulfilled ||
			status === QueryStatus.rejected
		) {
			setIsCancelConfirmationModalOpen(false);
		}
	}, [status]);

	const handleCloseCancelConfirmationModal = () =>
		setIsCancelConfirmationModalOpen(false);

	const handleClickButton = () => setIsCancelConfirmationModalOpen(true);

	const cancelDeal = () =>
		cancelDealMutation({
			contract_period_id: contractPeriodId,
			deal_id: dealId,
			isCompensation,
		});

	return (
		<>
			<DealTabCancelButton
				handleCancelDeal={handleClickButton}
				isLoadingCancelDeal={isLoading}
			/>
			<Modal
				open={isCancelConfirmationModalOpen}
				onClose={handleCloseCancelConfirmationModal}
				title={"Cancel the Deal?"}
				actions={
					<>
						<PrimaryButton
							onClick={handleCloseCancelConfirmationModal}
							text="Cancel"
							type="button"
							color="secondary"
						/>
						<PrimaryButton
							onClick={cancelDeal}
							text={"Yes, cancel the Deal"}
							type="button"
							color="primary"
							loader={isLoading}
						/>
					</>
				}
			>
				By canceling this Deal, you will...
			</Modal>
		</>
	);
};

export default DealTabCancelButtonContainer;
