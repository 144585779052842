import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Switch,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import CheckboxesTags from "../../../common/components/CheckboxesTags";
import CustomDatePicker from "../../../common/components/CustomDatePicker";
import CustomInput from "../../../common/components/CustomInput";
import {
	CLICK_GRANULARITY,
	PRICING_GRANULARITY,
} from "../PricingRequestStepper.constant";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { lightGrey } from "../../../core/theme";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCommodityFixingsState } from "../PricingRequestStepper.selector";
import { CommodityFixings } from "../PricingRequestStepper.slice";
import { Portfolio, Site } from "../../sites/sites.module";
import CustomSelect from "../../../common/components/CustomSelect";
import { endOfYear, subMonths } from "date-fns";
import { PricingRequestData } from "./PricingStep";

const style: { [key: string]: SxProps<Theme> } = {
	radioGroup: {
		flexDirection: "row",
		textTransform: "capitalize",
		marginBottom: "5px",
	},
	titlePricingType: {
		marginBottom: "32px",
		fontWeight: 700,
	},
	titleOptions: {
		fontWeight: 700,
	},
	datePicker: {
		width: "100%",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	adjustmentPower: {
		width: "200px",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	granularityCheckBox: {
		flexDirection: "column",
		textTransform: "capitalize",
	},
	clickCount: {
		width: "160px",
		"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
			{
				WebkitAppearance: "none",
				margin: 0,
			},
		"& input": {
			textAlign: "center",
		},
		"& input::placeholder": {
			textAlign: "center",
		},
		"& svg": {
			color: "grey",
		},
	},
	pricingDate: {
		width: "100%",
	},
};

interface PricingGooStepProps {
	values: PricingRequestData;
	handleChange: any;
	setFieldValue: (fieldName: string, value: any) => void;
	setFieldError: (fieldName: string, value: any) => void;
	errors: any;
	touched: any;
	sitesValues: (Site | Portfolio)[];
	setLoading: (value: boolean) => void;
}

function PricingGooStep(props: PricingGooStepProps) {
	const { values, handleChange, setFieldValue, errors, touched } = props;
	const commodityFixingsList = useSelector(selectCommodityFixingsState);
	const allowedGooIndexes = useMemo(() => {
		if (!commodityFixingsList) {
			return [];
		}

		return commodityFixingsList.filter((f) => f.id === 1);
	}, [commodityFixingsList]);

	return (
		<Grid container columnSpacing={4} rowSpacing={2}>
			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<Typography
					variant="h6"
					component="h6"
					sx={style.titlePricingType}
				>
					Goo pricing type
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{allowedGooIndexes && (
					<CheckboxesTags<CommodityFixings>
						label="Index"
						trackBy="external_id"
						name="gooPricingType"
						value={values.gooPricingType ?? []}
						options={allowedGooIndexes}
						setFieldValue={setFieldValue}
					/>
				)}
			</Grid>
			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						name="gooStartDate"
						sx={style.datePicker}
						views={["month", "year"]}
						onChange={(value) => {
							if (value) {
								const startDate = new Date(value as string);
								const endDate = endOfYear(startDate);
								setFieldValue("gooEndDate", endDate);
								setFieldValue(
									"clickLimitDate",
									subMonths(startDate, 3)
								);
							}
						}}
						slotProps={{
							textField: {
								size: "small",
								label: "Start date",
								error:
									!!errors.gooStartDate &&
									!!touched.gooStartDate,
								helperText:
									!!errors.gooStartDate &&
									touched.gooStartDate
										? errors.gooStartDate
										: "",
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>
			<Grid item xs={6}>
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={en}
				>
					<CustomDatePicker
						name="gooEndDate"
						minDate={values.gooStartDate}
						sx={style.datePicker}
						views={["month", "year"]}
						lastDayOfTheMonth
						slotProps={{
							textField: {
								size: "small",
								label: "End date",
								error:
									!!errors.gooEndDate && !!touched.gooEndDate,
								helperText:
									!!errors.gooEndDate && touched.gooEndDate
										? errors.gooEndDate
										: "",
							},
						}}
					/>
				</LocalizationProvider>
			</Grid>

			<Grid item xs={12} display="flex" justifyContent="flex-start">
				<FormGroup sx={style.granularityCheckBox}>
					<FormLabel sx={{ display: "flex" }}>Granularity</FormLabel>
					<Box>
						<FormControlLabel
							name="gooGranularityMonthly"
							control={
								<Checkbox
									disabled={true}
									onChange={handleChange}
									checked={values.gooGranularityMonthly}
								/>
							}
							label={PRICING_GRANULARITY[0]}
						/>
						<FormControlLabel
							name="gooGranularityQuarter"
							control={
								<Checkbox
									disabled={true}
									onChange={handleChange}
									checked={values.gooGranularityQuarter}
								/>
							}
							label={PRICING_GRANULARITY[1]}
						/>
						<FormControlLabel
							name="gooGranularityCal"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.gooGranularityCal}
								/>
							}
							label={PRICING_GRANULARITY[2]}
						/>
						<FormControlLabel
							name="gooGranularityWholeHorizon"
							control={
								<Checkbox
									onChange={handleChange}
									checked={values.gooGranularityWholeHorizon}
								/>
							}
							label={PRICING_GRANULARITY[3]}
						/>
					</Box>
				</FormGroup>
			</Grid>
			<Grid item xs={10} display="flex" justifyContent="flex-start">
				<Typography variant="h6" component="h6" sx={style.titleOptions}>
					Parameters
				</Typography>
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"gooFixPriceSwitch"}
								checked={values.gooFixPriceSwitch}
								onChange={handleChange}
							/>
						}
						label="Fix Price"
					/>
				</FormGroup>
				<FormControl>
					<FormLabel>GOO adjustment on power</FormLabel>
					<CustomInput
						name="gooPowerAdjustment"
						placeholder="%"
						disabled={!values.gooFixPriceSwitch}
						sx={style.adjustmentPower}
						onChange={handleChange}
						variant="standard"
						type="number"
						value={values.gooPowerAdjustment}
						error={!!errors.gooPowerAdjustment}
						errorText={errors.gooPowerAdjustment}
						touched={true}
					/>
				</FormControl>
			</Grid>
			<Grid
				item
				xs={3}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"gooClickSwitch"}
								checked={values.gooClickSwitch}
								onChange={handleChange}
							/>
						}
						label="Clickability Options"
					/>
				</FormGroup>
				<FormControl required>
					<RadioGroup
						value={values.gooClickGranularity}
						name="gooClickGranularity"
						onChange={handleChange}
						sx={style.radioGroup}
					>
						<FormControlLabel
							value={CLICK_GRANULARITY[0]}
							control={<Radio disabled={true} />}
							label={CLICK_GRANULARITY[0]}
						/>
					</RadioGroup>
				</FormControl>
				<CustomInput
					id="clickCount"
					name="gooClickCount"
					type="number"
					value={values.gooClickCount}
					onChange={handleChange}
					sx={style.clickCount}
					placeholder="1"
					label="Number of clicks"
					disabled={true}
					errorText={errors.gooClickCount}
					touched={touched.gooClickCount}
					InputProps={{
						startAdornment: (
							<IconButton
								disabled={true}
								onClick={() => {
									setFieldValue(
										"gooClickCount",
										Number(values.gooClickCount) > 1
											? Number(values.gooClickCount) - 1
											: 0
									);
									return (
										document.getElementById(
											"gooClickCount"
										) as any
									).stepDown();
								}}
							>
								<RemoveIcon />
							</IconButton>
						),
						endAdornment: (
							<IconButton
								disabled={true}
								onClick={() => {
									setFieldValue(
										"gooClickCount",
										Number(values.gooClickCount) + 1
									);
									return (
										document.getElementById(
											"gooClickCount"
										) as any
									).stepUp();
								}}
							>
								<AddIcon />
							</IconButton>
						),
					}}
				></CustomInput>
			</Grid>
			<Grid
				item
				xs={3}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<Box sx={{ marginTop: "85px" }}>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={en}
					>
						<CustomDatePicker
							name="clickLimitDate"
							disabled={true}
							sx={style.datePicker}
							views={["month", "year"]}
							lastDayOfTheMonth
							slotProps={{
								textField: {
									size: "small",
									label: "Click limit date",
									error:
										!!errors.clickLimitDate &&
										!!touched.clickLimitDate,
									helperText:
										!!errors.clickLimitDate &&
										touched.clickLimitDate
											? errors.clickLimitDate
											: "",
								},
							}}
						/>
					</LocalizationProvider>
				</Box>
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				flexDirection={"column"}
				justifyContent="flex-start"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Switch
								name={"gooFormulaSwitch"}
								disabled={true}
								checked={values.gooFormulaSwitch}
								onChange={handleChange}
							/>
						}
						label="Formula options"
					/>
				</FormGroup>
				<FormControl>
					<CustomSelect
						name="gooFormula"
						label=""
						value=""
						disabled={false}
						onChange={(event) => console.log(event)}
						sxFC={{
							width: "250px",
							background: "#fff",
							borderRadius: "0px",
							marginTop: "1em",
						}}
						items={[
							{ value: "Northwind formula", key: "Northwind" },
							{ value: "Norther formula", key: "Norther" },
						]}
					></CustomSelect>
				</FormControl>
			</Grid>
		</Grid>
	);
}

export default PricingGooStep;
