import { Box, Stack, SxProps, Theme } from "@mui/material";
import { lightGrey } from "../../../core/theme";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PageTitle from "../../../common/components/PageTitle/PageTitle";
import PageContent from "../../../common/components/PageContent";
import GenericMultiSelect, {
	Item,
	SelectableItem,
} from "../../../common/components/GenericMultiSelect";
import { Trader } from "../PricingTradersPage.slice";
import { useTraderTableModel } from "../../../common/TableGenericModel/useTraderTableModel";
import { useSelector } from "react-redux";
import {
	selectTraders,
	selectTradersLoading,
} from "../PricingTradersPage.selector";
import { OverlayLoader } from "../../../common/components/OverlayLoader";
import { useEffect, useRef } from "react";
import { useAppDispatch } from "../../../common/hooks/default";
import { getTraders, setTradersOnDuty } from "../PricingTradersPage.thunk";
import { PrimaryButton } from "../../../common/components/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { enqueueSnackbar } from "notistack";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		padding: "56px 40px",
		bgcolor: lightGrey,
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
	},
	titleContainer: {
		display: "flex",
		height: 80,
		width: "100%",
		alignItems: "center",
	},
	contentContainer: {
		textAlign: "center",
		display: "flex",
		flexDirection: "row",
		width: "100%",
		gap: "32px",
	},
	section: {
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		minWidth: "400px",
	},
	buttonSave: {
		marginBottom: "2em",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "space-around",
	},
};

function PricingTradersPage() {
	const dispatch = useAppDispatch();
	const isLoading = useSelector(selectTradersLoading);

	useEffect(() => {
		dispatch(getTraders());
	}, []);

	const { left: leftModel, right: rightModel } = useTraderTableModel();
	const items = useSelector(selectTraders);
	const onDuty = useRef<Item<Trader>[]>([]);

	useEffect(() => {
		if (items && items.length) {
			onDuty.current = items;
		}
	}, [items]);

	const savePricingTraders = async () => {
		try {
			const traders = onDuty.current.map((m: Item<Trader>) =>
				Number(m.id)
			);
			dispatch(setTradersOnDuty(traders));
		} catch (e: any) {
			enqueueSnackbar(e.message, {
				variant: "error",
				autoHideDuration: 3000,
			});
		}
	};

	return (
		<>
			<PageTitle label="Pricing Traders" />
			<PageContent>
				<Box sx={style.container}>
					{isLoading && <OverlayLoader />}

					<GenericMultiSelect<Trader>
						onSelectionChange={(traders: Item<Trader>[]) =>
							(onDuty.current = traders)
						}
						isLoading={isLoading ?? false}
						initialSelectedItems={
							items
								.filter((m: Trader) => m.on_duty)
								.sort(
									(a, b) =>
										(a.on_duty_order ?? 0) -
										(b.on_duty_order ?? 0)
								) || []
						}
						itemList={items.filter((m: Trader) => !m.on_duty) || []}
						isItemSelectable={(_item: Item<Trader>) => true}
						leftRowModel={leftModel}
						rightRowModel={rightModel}
						keyProperty={(item: SelectableItem<Trader>) =>
							(item as Trader).gaia_id
						}
						itemReadyProperty={(_item: SelectableItem<Trader>) =>
							true
						}
						evaluatePropertyOnSelectionChanged={(
							traderList: SelectableItem<Trader>[]
						) => {
							const traders = traderList.map(
								(t: SelectableItem<Trader>) => ({
									...t,
									last_assigned: false,
								})
							);
							const sortedTraders = traders
								.filter(
									(m: SelectableItem<Trader>) =>
										(m as Trader).last_assigned_to_a_tender
								)
								.sort(
									(
										a: SelectableItem<Trader>,
										b: SelectableItem<Trader>
									) =>
										(a as Trader)
											.last_assigned_to_a_tender! <
										(b as Trader).last_assigned_to_a_tender!
											? 1
											: (a as Trader)
													.last_assigned_to_a_tender! >
											  (b as Trader)
													.last_assigned_to_a_tender!
											? -1
											: 0
								);

							if (sortedTraders.length) {
								const lastAssignedTrader = traders.find(
									(m: SelectableItem<Trader>) =>
										m.id === sortedTraders[0].id
								);
								if (lastAssignedTrader) {
									(
										lastAssignedTrader as Trader
									).last_assigned = true;
								}
							}

							return traders;
						}}
						withSearch={false}
					/>
				</Box>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
					sx={{ paddingRight: "40px" }}
				>
					<PrimaryButton
						text="Cancel"
						type="button"
						disabled={false}
						color="secondary"
						sx={{
							marginRight: "16px",
							width: 140,
						}}
						onClick={() => dispatch(getTraders())}
					>
						<HighlightOffIcon sx={{ color: "#000" }} />
					</PrimaryButton>
					<PrimaryButton
						text="Save"
						type="button"
						form="tender-form"
						color="primary"
						disabled={false}
						sx={{
							marginRight: "16px",
							width: 140,
						}}
						onClick={() => {
							savePricingTraders();
						}}
					>
						<AddCircleIcon sx={{ color: "#fff" }} />
					</PrimaryButton>
				</Stack>
			</PageContent>
		</>
	);
}

export default PricingTradersPage;
