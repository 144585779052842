import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
} from "react-router-dom";
import { AuthLayout } from "./features/authentication/AuthLayout";
import LandingPage from "./features/landing_page/LandingPage";
import { LoginCallback } from "@okta/okta-react";
import ProtectedRoute from "./features/authentication/ProtectedRoute";
import TenderPage from "./features/tender_page/TenderPage";
import TenderForm from "./features/tender_form/TenderForm";
import OktaError from "./features/authentication/OktaError";
import PricingRequestStepper from "./features/pricing_request/PricingRequestStepper";
import ParametersPage from "./features/parameters/components/ParametersPage";
import PricingListPage from "./features/pricing_list/PricingListPage";
import { Groups } from "./features/authentication/authentication.constant";
import TenderWrapper from "./common/components/TenderWrapper";
import ReportingPage from "./features/reporting_page/ReportingPage";
import ValidateContractPeriodPage from "./features/geco/validate_contract_period/ValidateContractPeriodPage";
import ContractsPage from "./features/contracts_page/ContractsPage";
import { BreadCrumbsProvider } from "./common/contexts/BreadCrumbsContext";
import ContractPeriod from "./features/contract_period";
import ContractPeriodList from "./features/contract_period_list";
import { PATH } from "./router-path";
import ContractValidation from "./features/contract_validation/ContractValidation";
import SiteListPage from "./features/site_list_page";
import SiteDetailPage from "./features/site_detail_page";
import PricingTradersPage from "./features/pricing_traders/components/PricingTradersPage";
import { CounterValidation } from "./features/contract_validation/CounterValidation";
import DataQualityReport from "./features/reporting_page/DataQualityReport";

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<BreadCrumbsProvider />}>
			<Route element={<AuthLayout />}>
				<Route
					path={PATH.ROOT}
					element={<Navigate to={PATH.LANDING} />}
				/>
				<Route
					path={PATH.LOGIN}
					element={<LoginCallback errorComponent={OktaError} />}
				/>
				<Route
					path={PATH.LANDING}
					element={<ProtectedRoute element={<LandingPage />} />}
				/>
				<Route
					path={PATH.REPORTS}
					element={<ProtectedRoute element={<ReportingPage />} />}
				/>
				<Route
					path={PATH.DATA_QUALITY_REPORTS}
					element={<ProtectedRoute element={<DataQualityReport />} />}
				/>
				<Route path={PATH.TENDERS}>
					<Route
						index={true}
						element={<ProtectedRoute element={<TenderPage />} />}
					/>
					<Route
						index={false}
						key="addTender"
						path={PATH.NEW_TENDER}
						element={
							<ProtectedRoute
								element={<TenderForm mode="new" />}
							/>
						}
					/>
					<Route
						index={false}
						key="editTender"
						path={PATH.EDIT_TENDER}
						element={
							<ProtectedRoute
								element={
									<TenderWrapper>
										<TenderForm mode="edit" />
									</TenderWrapper>
								}
							/>
						}
					/>
					<Route
						index={false}
						key="cloneTender"
						path={PATH.CLONE_TENDER}
						element={
							<ProtectedRoute
								element={
									<TenderWrapper>
										<TenderForm mode="clone" />
									</TenderWrapper>
								}
							/>
						}
					/>
					<Route
						index={false}
						key="addNewPricing"
						path={PATH.PRICING_REQUEST}
						element={
							<ProtectedRoute
								element={
									<TenderWrapper
										isOwner={true}
										allPortfolios={true}
									>
										<PricingRequestStepper />
									</TenderWrapper>
								}
								allowedGroups={[
									Groups.originator,
									Groups.trader,
								]}
							/>
						}
					/>
					<Route
						index={false}
						key="myPricings"
						path={PATH.TENDER_PRICING_RUN}
						element={
							<TenderWrapper>
								<ProtectedRoute element={<PricingListPage />} />
							</TenderWrapper>
						}
					/>
					<Route
						index={false}
						key="myPricings"
						path={PATH.MAXIMIZED_TENDER_PRICING_RUN}
						element={
							<TenderWrapper>
								<ProtectedRoute
									element={
										<PricingListPage maximized={true} />
									}
								/>
							</TenderWrapper>
						}
					/>
				</Route>
				<Route
					path={PATH.PARAMETERS}
					element={
						<ProtectedRoute
							allowedGroups={[Groups.trader]}
							element={<ParametersPage />}
						/>
					}
				/>
				<Route
					path={PATH.PRICING_TRADERS}
					element={
						<ProtectedRoute
							allowedGroups={[Groups.trader]}
							element={<PricingTradersPage />}
						/>
					}
				/>
				<Route
					path={PATH.CONTRACTS}
					element={<ProtectedRoute element={<ContractsPage />} />}
				/>
				<Route
					path={PATH.CONTRACT_DETAIL}
					element={
						<ProtectedRoute element={<ContractPeriodList />} />
					}
				/>
				<Route
					path={PATH.VALIDATE_CONTRACTPERIOD}
					element={
						<ProtectedRoute
							element={<ValidateContractPeriodPage />}
						/>
					}
				/>
				<Route
					path={PATH.CONTRACTPERIOD}
					element={<ProtectedRoute element={<ContractPeriod />} />}
				/>
				<Route
					path={PATH.CONTRACT_VALIDATION}
					element={
						<ProtectedRoute element={<ContractValidation />} />
					}
				/>
				<Route
					path={PATH.SITES}
					element={<ProtectedRoute element={<SiteListPage />} />}
				/>
				<Route
					path={PATH.SITE_DETAIL}
					element={<ProtectedRoute element={<SiteDetailPage />} />}
				/>
				<Route
					path={PATH.CONTRACT_COUNTER_VALIDATION}
					element={<ProtectedRoute element={<CounterValidation />} />}
				/>
			</Route>
		</Route>
	)
);

export default router;
