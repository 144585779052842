import { RootState } from "../../core/rootReducers";
import { Portfolio, Site, isPortfolio } from "../sites/sites.module";
import {
	DuplicateWarningReason,
	SiteImportFile,
	SiteImportWarning,
} from "./tenderForm.module";

const selectTenderState = (state: RootState) => state.tenderForm;

export function selectTender(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.tender;
}

export function selectCreateTenderWarning(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.warning.createTender;
}

export function selectTenderLoading(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.loader.tender;
}

export function selectTenderSites(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState?.tender?.sites || [];
}

export function selectGlobalSites(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState?.tender?.sites?.filter(
		(site: Site | Portfolio) => !isPortfolio(site)
	);
}

export function selectPortfolio(state: RootState): Portfolio[] {
	const tenderState = selectTenderState(state);
	return tenderState?.tender?.portfolios || [];
}

export function selectAccounts(state: RootState) {
	const tenderState = selectTenderState(state);
	let accounts = tenderState.accounts;
	let newAccounts: any = [];
	if (Array.isArray(accounts)) {
		newAccounts = accounts.map(
			(account: { accountId?: number; accountName?: string }) => {
				return {
					id: account.accountId,
					name: account.accountName,
				};
			}
		);
	}

	return newAccounts;
}

export function selectAccountsLoader(state: RootState): boolean {
	const tenderState = selectTenderState(state);
	return tenderState.loader.accounts;
}

export function selectCreateSiteLoader(state: RootState): boolean {
	const tenderState = selectTenderState(state);
	return tenderState.loader.createSite;
}

export function selectCreateSiteIsWarning(state: RootState): boolean {
	const tenderState = selectTenderState(state);
	return tenderState.siteForm.isWarning;
}

export function selectCreateSiteDuplicates(state: RootState): Site[] {
	const tenderState = selectTenderState(state);
	return tenderState.siteForm.duplicates;
}

export function selectCreateSiteDuplicateReasons(
	state: RootState
): DuplicateWarningReason[] {
	const tenderState = selectTenderState(state);
	return tenderState.siteForm.reasons;
}

export function selectSiteImportErrors(
	state: RootState
): string[] | undefined | null {
	const tenderState = selectTenderState(state);
	return tenderState.siteImport.file?.errors;
}

export function selectSiteImportWarnings(
	state: RootState
): SiteImportWarning[] | undefined | null {
	const tenderState = selectTenderState(state);
	return tenderState.siteImport.file?.warnings;
}

export function selectSiteImportFile(
	state: RootState
): SiteImportFile | undefined | null {
	const tenderState = selectTenderState(state);
	return tenderState.siteImport.file;
}

export function selectSiteImportStep(state: RootState): number {
	const tenderState = selectTenderState(state);
	if (!tenderState.siteImport.file) {
		return 0;
	} else if (tenderState.siteImport.file?.errors?.length) {
		return 1;
	} else if (tenderState.siteImport.file?.warnings?.length) {
		return 2;
	}
	return 3;
}

export function selectSiteKnownStatusLoading(state: RootState) {
	const tenderState = selectTenderState(state);

	return tenderState.tender?.sites?.reduce(
		(prev, curr) => prev || !!curr.known_status_loading,
		false
	);
}

export function selectSiteKnownStatusError(state: RootState) {
	const tenderState = selectTenderState(state);

	return tenderState.tender?.sites?.reduce(
		(prev, curr) => prev || !!curr.known_status_error,
		false
	);
}

export function selectTsBySite(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.tsBySite;
}

export function selectTsBySiteLoader(state: RootState) {
	const tenderState = selectTenderState(state);
	return tenderState.loader.tsBySite;
}

export function selectTsTabError(
	state: RootState,
	type: "createPricingByTs" | "tsBySite"
) {
	const tenderState = selectTenderState(state);
	return tenderState.errors[type];
}

export function selectLoader(
	state: RootState,
	name: "downloadGraph" | "generateGraph" | "tender"
) {
	const tenderState = selectTenderState(state);
	return tenderState.loader[name];
}
