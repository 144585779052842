import { unstable_useBlocker as useBlocker } from "react-router-dom";
import AlertDialogModal from "./AlertDialogModal";

export interface PromptProps extends React.PropsWithChildren<{}> {
	message: string;
	block: boolean;
}
export default function Prompt(props: PromptProps) {
	let blocker = useBlocker(props.block);
	const handleClose = () => {
		if (blocker?.reset) {
			blocker.reset();
		}
	};

	const handleConfirm = () => {
		if (blocker?.proceed) {
			blocker.proceed();
		}
	};

	return (
		<AlertDialogModal
			title={props.message}
			isOpen={blocker.state === "blocked"}
			handleApprove={handleConfirm}
			handleClose={handleClose}
		/>
	);
}
