import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "./CustomButton";
import { If } from "./If";

interface AlertDialogType {
	title: string;
	content?: string;
	isOpen: boolean;
	handleClose: () => void;
	handleApprove: () => void;
}
export default function AlertDialogModal(props: AlertDialogType) {
	const { isOpen, content, title, handleClose, handleApprove } = props;
	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<If condition={content}>
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
			</If>
			<DialogActions>
				<PrimaryButton
					onClick={handleClose}
					text="No"
					type="button"
					color="secondary"
				></PrimaryButton>
				<PrimaryButton
					onClick={handleApprove}
					text="Yes"
					type="button"
					color="primary"
				></PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}
