import { createSlice } from "@reduxjs/toolkit";
import { Site } from "./sites.module";
import uniqBy from "lodash.uniqby";

interface SiteState {
	page: number;
	sites: Site[];
	loading: boolean;
	errors: string[];
}

const initialState: SiteState = {
	page: 0,
	sites: [],
	errors: [],
	loading: false,
};

export const siteSlice = createSlice({
	name: "site",
	initialState,
	reducers: {
		initSites: (state) => {
			state.sites = [];
			state.page = 0;
			state.loading = false;
		},
		setLoadingState: (state) => {
			state.loading = true;
		},
		getSitesSuccess: (state, action) => {
			const { page, sites } = action.payload;
			state.loading = false;
			state.page = page;
			if (page <= 1) {
				state.sites = sites;
			} else {
				state.sites = [...state.sites, ...sites];
			}
			state.sites = uniqBy(state.sites, "id");
		},
		getSitesError: (state, action) => {
			state.errors += action.payload;
			state.loading = false;
		},
		updateSiteLocal: (state, action) => {
			state.sites.forEach((site, index) => {
				if (site.id === action.payload.id) {
					state.sites[index] = action.payload;
				}
			});
		},
	},
});

export const {
	getSitesSuccess,
	getSitesError,
	initSites,
	setLoadingState,
	updateSiteLocal,
} = siteSlice.actions;

export default siteSlice.reducer;
