import * as yup from "yup";

export const addRowSchema = yup.object({
	siteName: yup.string().required("Site is a required field"),
	fileName: yup.string().required("File is a required field"),
});

export const tsInfoSchema = (rows: any) => {
	let schema = {};
	rows.forEach((row: any) => {
		schema = {
			...schema,
			[row.id]: yup.object({
				sheetName: row.fileName.endsWith("csv")
					? yup.string().notRequired()
					: yup.string().required("Required"),
				productionCol: yup.number().required("Required"),
				indexDate: yup.number().required("Required"),
				unit: yup.string().required("Required "),
				timezone: yup.string().required("Required "),
				offset: yup.number().required("Required "),
			}),
		};
	});

	return yup.object(schema);
};
