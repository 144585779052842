import { Formik, getIn } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { noop } from "../../../../../common/utils/operations";
import { Box, CircularProgress } from "@mui/material";
import { FluidButton } from "../../../../../common/components/FluidButton";
import { Spacer } from "../../../../../common/components/Spacer";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import { useMemo } from "react";
import { If } from "../../../../../common/components/If";
import { ErrorText } from "../../../../../common/components/ErrorText";
import {
	CreateServicePointFormik,
	getCreateServicePointDefaultValues,
} from "../../../formik/servicepointsTabFormik";
import CustomAutocomplete from "../../../../../common/components/CustomAutocomplete";
import { contractPeriodServicePointValidator } from "../../../formik/servicepointsTabValidators";

import { style } from "./ServicePointCreateModal.style";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import Group from "../../../../../common/components/Group";
import { ServicePoint } from "../../../../../requests_geco/referentialApi/referentialApi.types";
import { ContractPeriodType } from "../../../../../requests_geco/contractsApi/contractsApi.types";

interface ServicePointCreateModalProps {
	isOpen: boolean;
	errorMessage: any;
	servicePoints: ServicePoint[];
	contractPeriod: ContractPeriodType | undefined;
	isLoading?: boolean;
	onSearchServicePoints: (name: string) => void;
	handleClose: () => void;
	onCreateContractPeriodServicePoint: (
		contractPeriodServicePoint: CreateServicePointFormik
	) => void;
}
export default function ServicePointCreateModal(
	props: ServicePointCreateModalProps
) {
	const errorMessage = useMemo(() => {
		if (props.errorMessage?.data) {
			return (
				`${props.errorMessage?.data?.errors}` ||
				`${props.errorMessage?.data}` ||
				`${props.errorMessage?.data}`
			);
		}
	}, [props.errorMessage]);

	const { isOpen, handleClose } = props;
	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
			<DialogTitle>{"Add service point to contract period"}</DialogTitle>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={contractPeriodServicePointValidator}
				enableReinitialize
				initialValues={getCreateServicePointDefaultValues(
					props.contractPeriod?.start_date,
					props.contractPeriod?.end_date
				)}
				onSubmit={props.onCreateContractPeriodServicePoint}
			>
				{({
					handleChange,
					handleSubmit,
					setFieldValue,
					values,
					errors,
					touched,
				}) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Box sx={style.container}>
								<CustomAutocomplete
									fetchData={props.onSearchServicePoints}
									data={props.servicePoints}
									label="Service Point"
									name="servicepoint"
									loading={props.isLoading}
									setFieldValue={setFieldValue}
									value={values.servicepoint}
									onChange={handleChange}
									error={
										!!errors.servicepoint &&
										touched.servicepoint
									}
									helperText={
										!!errors.servicepoint &&
										touched.servicepoint
											? (errors.servicepoint as any).name
											: ""
									}
								/>
								<Spacer gap={24} />
								<FluidNumber
									onChange={handleChange}
									errorMessage={getIn(errors, "volume_share")}
									name={"volume_share"}
									title={"Volume Share in %"}
									value={
										values.volume_share as unknown as number
									}
								/>
								<Spacer gap={24} />
								<Group>
									<FluidDatePicker
										onChange={handleChange}
										name={"start_date"}
										label={"Start Date"}
										value={values.start_date}
										errorMessage={getIn(
											errors,
											"start_date"
										)}
									/>

									<FluidDatePicker
										onChange={handleChange}
										name={"end_date"}
										label={"End Date"}
										value={values.end_date}
										errorMessage={getIn(errors, "end_date")}
									/>
								</Group>
								<Spacer gap={24} />

								<If condition={!!errorMessage}>
									<ErrorText>{errorMessage}</ErrorText>
									<Spacer gap={24} />
								</If>
							</Box>
						</DialogContent>

						<DialogActions>
							<If condition={!props.isLoading}>
								<PrimaryButton
									onClick={handleClose}
									text="Cancel"
									type="button"
									color="secondary"
								></PrimaryButton>
								<FluidButton
									type="submit"
									icon={"add_circle_outline"}
									label={"Add"}
									onClick={noop}
								/>
							</If>
							<If condition={!!props.isLoading}>
								<CircularProgress />
							</If>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
}
