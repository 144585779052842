import { useCallback, useMemo } from "react";
import { PrimaryButton } from "../CustomButton";
import { Column } from "../ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../../features/pricing_list/hooks/columns/common";
import * as _ from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export interface ShowHideProvisionColumnsButtonProps<T extends React.Key> {
	availableColumns: Column<T>[];
	selectedColumns: Column<T>[];
	onColumnToggled: (column: Column<T>) => void;
}

const provisionColumns = [
	ColumnIds.SETUPFEE,
	ColumnIds.IMBALANCECOSTPROVISION,
	ColumnIds.PROFILECOSTPROVISION,
	ColumnIds.DELTAPROFILECOSTPROVISION,
	ColumnIds.SHAPEPROVISION,
	ColumnIds.SHAPEBIDASKPROVISION,
];

export function ShowHideProvisionColumnsButton(
	props: ShowHideProvisionColumnsButtonProps<ColumnIds>
) {
	const isOpen = useMemo(
		() =>
			props.selectedColumns.some((selectColumn) =>
				provisionColumns.includes(selectColumn.id)
			),
		[props.selectedColumns]
	);
	const onClick = useCallback(() => {
		if (isOpen) {
			const columnsToClose: Column<ColumnIds>[] = provisionColumns
				.map((columnId) =>
					_.find(
						props.availableColumns,
						(available) => available.id === columnId
					)
				)
				.filter((column) => !!column)
				.filter((column) =>
					props.selectedColumns
						.map((selectedColumn) => selectedColumn.id)
						.includes(column?.id as ColumnIds)
				) as Column<ColumnIds>[];
			columnsToClose.forEach(props.onColumnToggled);
		} else {
			const columnsToOpen: Column<ColumnIds>[] = provisionColumns
				.map((columnId) =>
					_.find(
						props.availableColumns,
						(available) => available.id === columnId
					)
				)
				.filter((column) => !!column)
				.filter(
					(column) =>
						!props.selectedColumns
							.map((selectedColumn) => selectedColumn.id)
							.includes(column?.id as ColumnIds)
				) as Column<ColumnIds>[];
			columnsToOpen.forEach(props.onColumnToggled);
		}
	}, [
		isOpen,
		props.selectedColumns,
		props.onColumnToggled,
		props.availableColumns,
	]);

	return (
		<PrimaryButton
			sx={{
				width: 180,
				marginLeft: "10px",
			}}
			startIcon={isOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
			type="button"
			color="secondary"
			id="column-selector-button"
			onClick={onClick}
			text={(isOpen ? "Hide" : "Show") + " provisions"}
		/>
	);
}
