import {
	backgroundBrandTertiary,
	colorPaletteBlue400,
} from "../../../core/theme";

export const style = {
	container: {
		display: "inline-flex",
		flexDirection: "column",
		padding: "24px",
		gap: "16px",

		infoBlock: {
			gap: "16px",
			display: "flex",
			item: {
				display: "flex",
				flexDirection: "column",
				fontFamily: "Lato",
				fontSize: "16px",
				lineHeight: "24px",
			},
			head: {
				flexDirection: "inline",
				gap: "8px",
			},
			title: {
				color: "#60798B",
			},
			content: {
				fontWeight: "800",
			},
		},
	},
	withBackground: {
		backgroundColor: backgroundBrandTertiary,
		border: `1px solid ${colorPaletteBlue400}`,
	},
	fullWidth: {
		width: "100%",
	},
};
