import { useState } from "react";
import Tabs, { SingleTab } from "../../../common/components/Tabs";
import { DefaultTabContent } from "../../../common/components/DefaultTabContent";
import { If } from "../../../common/components/If";
import GeneralTab from "./GeneralTab";
import {
	ContractDetailType,
	ContractPeriodType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import { NegativePriceIndex } from "../../../requests_geco/referentialApi/referentialApi.types";
import SettlementTab from "./SettlementTab";
import ClickabilityTab from "./ClickabilityTab";
import ServicePointsTab from "./ServicePointsTab";
import DealsTab from "./DealsTab";
import CurtailmentObjectTab from "./CurtailmentObjectTab";

export enum TabID {
	GENERAL = "GENERAL",
	CLICKABILITY = "CLICKABILITY",
	SETTLEMENT_OBJECT = "SETTLEMENT_OBJECT",
	SERVICE_POINT = "SERVICE_POINT",
	CURTAILMENT_OBJECT = "CURTAILMENT_OBJECT",
	DEAL = "DEAL",
	FIELD_DATA = "FIELD_DATA",
}

export interface ContractPeriodTabsProps {
	contract: ContractDetailType;
	contractPeriod: ContractPeriodType;
	onSaveDraftContract: (data: ContractDetailType) => void;
	negativePriceIndexes: NegativePriceIndex[] | undefined;
}

const ContractPeriodTabs = ({
	contractPeriod,
	onSaveDraftContract,
	contract,
	negativePriceIndexes,
}: ContractPeriodTabsProps) => {
	const [activeTab, setActiveTab] = useState<TabID>(TabID.GENERAL);

	return (
		<>
			<Tabs
				activeTab={activeTab}
				label={""}
				onClickTabItem={(tab: TabID) => {
					setActiveTab(tab);
				}}
			>
				<SingleTab
					label="General"
					id={TabID.GENERAL}
					isActive={activeTab === TabID.GENERAL}
				/>
				<SingleTab
					label="Clickability"
					id={TabID.CLICKABILITY}
					isDisabled={!contractPeriod?.clickability}
					isActive={activeTab === TabID.CLICKABILITY}
				/>
				<SingleTab
					label="Settlement Object"
					id={TabID.SETTLEMENT_OBJECT}
					isActive={activeTab === TabID.SETTLEMENT_OBJECT}
				/>
				<SingleTab
					label="Service Point"
					id={TabID.SERVICE_POINT}
					isActive={activeTab === TabID.SERVICE_POINT}
				/>
				<SingleTab
					label="Curtailment Object"
					id={TabID.CURTAILMENT_OBJECT}
					isActive={activeTab === TabID.CURTAILMENT_OBJECT}
				/>
				<SingleTab
					label="Deals"
					id={TabID.DEAL}
					isActive={activeTab === TabID.DEAL}
				/>
				<SingleTab
					label="Field Data"
					id={TabID.FIELD_DATA}
					isActive={activeTab === TabID.FIELD_DATA}
				/>
			</Tabs>
			<If condition={activeTab === TabID.GENERAL}>
				<DefaultTabContent>
					<GeneralTab
						onSaveDraftContract={onSaveDraftContract}
						contract={contract}
						contractPeriod={contractPeriod}
						negativePriceIndexes={negativePriceIndexes}
					/>
				</DefaultTabContent>
			</If>
			<If condition={activeTab === TabID.CLICKABILITY}>
				<ClickabilityTab
					contractPeriodId={contractPeriod?.id}
					onSaveDraftContract={onSaveDraftContract}
					contract={contract}
				/>
			</If>
			<If condition={activeTab === TabID.SETTLEMENT_OBJECT}>
				<SettlementTab
					contract={contract}
					settlements={contractPeriod?.ppa_physical?.settlements}
				/>
			</If>
			<If condition={activeTab === TabID.SERVICE_POINT}>
				<ServicePointsTab contractPeriod={contractPeriod} />
			</If>
			<If condition={activeTab === TabID.CURTAILMENT_OBJECT}>
				<CurtailmentObjectTab contractPeriodId={contractPeriod.id} />
			</If>
			<If condition={activeTab === TabID.DEAL}>
				<DealsTab contractPeriodId={contractPeriod?.id} />
			</If>
			<If condition={activeTab === TabID.FIELD_DATA}>
				<DefaultTabContent>{"Field Data"}</DefaultTabContent>
			</If>
		</>
	);
};

export default ContractPeriodTabs;
