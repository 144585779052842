import * as yup from "yup";

export const contractPeriodServicePointValidator = yup.object().shape({
	volumeShare: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Must be greater than to 0 %")
		.max(100, "Must be less than or equal to 100%")
		.required("Required"),
	startDate: yup
		.date()
		.typeError("please enter a valid date")
		.required("start date is a required field"),
	endDate: yup
		.date()
		.typeError("please enter a valid date")
		.required("end date is a required field")
		.when("start_date", (start_date, schema) => {
			if (start_date && start_date[0]) {
				return schema.min(start_date[0], "cannot be before start date");
			}
			return schema;
		}),
});
