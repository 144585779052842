import { Box, TableCell, TableCellProps } from "@mui/material";
import React, {
	createContext,
	memo,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

const ANIM_DURATION = 100;

interface FlashUpdateContextCellState {
	previousValue: any;
	flash: boolean;
}
type FlashUpdateContextStore = Record<string, FlashUpdateContextCellState>;
type FlashUpdateContextType = {
	store: FlashUpdateContextStore;
	setValue?: (id: string, value: any, isUpdate?: boolean) => void;
};

const FlashUpdateContext = createContext<FlashUpdateContextType>({ store: {} });

function useFlashUpdateShouldFlash(id: string) {
	const context = useContext<FlashUpdateContextType>(FlashUpdateContext);

	return context?.store[id]?.flash || false;
}

function useFlashUpdateSetValue(id: string, value: any, isUpdate?: boolean) {
	const context = useContext<FlashUpdateContextType>(FlashUpdateContext);
	if (context.setValue) {
		context.setValue(id, value, isUpdate);
	}
}

export function StatelessFlashUpdateProvider(
	props: React.PropsWithChildren<{}>
) {
	const [flashContext, setFlashContext] = useState<FlashUpdateContextType>({
		store: {},
	});

	const setFlashValue = useCallback(
		(id: string, value: any, isUpdate?: boolean) => {
			if (isUpdate && value !== flashContext.store[id]?.previousValue) {
				setFlashContext((prev) => ({
					...prev,
					store: {
						...prev.store,
						[id]: {
							...(prev?.store?.[id] || {}),
							flash: true,
							previousValue: value,
						},
					},
				}));
				setTimeout(() => {
					setFlashContext((prev) => ({
						...prev,
						store: {
							...prev.store,
							[id]: {
								...(prev?.store?.[id] || {}),
								flash: false,
							},
						},
					}));
				}, ANIM_DURATION);
			}
		},
		[flashContext, setFlashContext]
	);

	return (
		<FlashUpdateContext.Provider
			value={{ ...flashContext, setValue: setFlashValue }}
		>
			{props.children}
		</FlashUpdateContext.Provider>
	);
}

export interface FlashUpdateTableCellProps extends TableCellProps {
	field: any;
	isUpdate?: boolean;
	componentType?: "table-cell" | "box";
	id: string;
}

function StatelessFlashUpdateTableCellComponent(
	props: FlashUpdateTableCellProps
) {
	const { id, field, isUpdate, componentType, ...cellProps } = props;
	const flash = useFlashUpdateShouldFlash(id);
	useFlashUpdateSetValue(id, field, isUpdate);

	const component = componentType ?? "table-cell";

	const sx = useMemo(
		() => ({
			...(cellProps.sx || {}),
			transition: `all ${ANIM_DURATION}ms ease-in-out`,
			backgroundColor: flash ? "rgba(0, 255, 0, 1)" : "unset",
		}),
		[cellProps, flash]
	);

	return component === "table-cell" ? (
		<TableCell
			{...cellProps}
			sx={sx}
			style={{ willChange: "background-color" }}
		>
			{props.children}
		</TableCell>
	) : (
		<Box {...cellProps} sx={sx} style={{ willChange: "background-color" }}>
			{props.children}
		</Box>
	);
}

export const StatelessFlashUpdateTableCell = memo(
	StatelessFlashUpdateTableCellComponent
);
