import { Formik, getIn } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { FluidRadioGroup } from "../../../../../common/components/FluidRadioGroup";
import { noop } from "../../../../../common/utils/operations";
import {
	ClickUnit,
	ContractPeriodType,
} from "../../../../../requests_geco/contractsApi/contractsApi.types";
import { Box, CircularProgress } from "@mui/material";
import { style } from "./ClickModal.style";
import { FluidButton } from "../../../../../common/components/FluidButton";
import { clickValidator } from "../../../formik/clickabilityTabValidators";
import {
	ClickFormik,
	clickFormikInitalValueMapper,
} from "../../../formik/clickabilityTabFormik";
import { Spacer } from "../../../../../common/components/Spacer";
import { FluidTextField } from "../../../../../common/components/FluidTextField";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import {
	ClickPeriodFrequencyLabels,
	ClickTypesResponseType,
} from "../../../../../requests_geco/referentialApi/referentialApi.types";
import { useMemo } from "react";
import { If } from "../../../../../common/components/If";
import { ErrorText } from "../../../../../common/components/ErrorText";
import Group from "../../../../../common/components/Group";
import FluidSelect from "../../../../../common/components/FluidSelect";
import RadioButtonsGroup from "../../../../../common/components/RadioButtonsGroup";
import { clickSelectYears, datesOptions } from "./ClickModal.helpers";

interface ClickModalProps {
	isOpen: boolean;
	createClickErrorMessage: string | string[];
	contractPeriod: ContractPeriodType;
	clickTypes: ClickTypesResponseType[];
	isLoading?: boolean;
	handleClose: () => void;
	onCreateClick: (click: ClickFormik) => void;
	newClickId: number;
}

const optionsLabel: { [key: string]: string } = {
	43966: "Month",
	2: "Year",
	3: "Quarter",
};

export default function ClickModal(props: ClickModalProps) {
	const clickTypeOptions = useMemo(() => {
		return props.clickTypes.map((clickType) => ({
			value: clickType.name,
			label: `${clickType.name[0].toUpperCase()}${clickType.name
				.substring(1)
				.toLowerCase()}`,
		}));
	}, [props.clickTypes]);

	const { isOpen, handleClose } = props;

	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
			<DialogTitle>{"Create Click"}</DialogTitle>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={clickValidator}
				enableReinitialize
				initialValues={clickFormikInitalValueMapper(
					props.contractPeriod,
					props.clickTypes,
					props.newClickId
				)}
				onSubmit={props.onCreateClick}
			>
				{({
					handleChange,
					handleSubmit,
					setFieldValue,
					values,
					errors,
				}) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Box sx={style.container}>
								<FluidRadioGroup
									orientation="row"
									name={"type_id"}
									errorMessage={getIn(errors, "type_id")}
									value={`${values.type_id}`}
									title={"Type"}
									onChange={(value: unknown | string) =>
										setFieldValue("type_id", value)
									}
									options={clickTypeOptions}
								/>
								<Spacer gap={24} />
								<FluidTextField
									onChange={handleChange}
									value={values.name}
									name={"name"}
									label={"Name"}
									errorMessage={getIn(errors, "name")}
								/>
								<Spacer gap={24} />
								<Group>
									<FluidNumber
										onChange={handleChange}
										errorMessage={getIn(errors, "price")}
										name={"price"}
										title={"Price"}
										value={
											values.price as unknown as number
										}
									/>
									<FluidNumber
										onChange={handleChange}
										errorMessage={getIn(
											errors,
											"mid_price"
										)}
										name={"mid_price"}
										title={"Mid Price"}
										value={
											values.mid_price as unknown as number
										}
									/>
								</Group>
								<Spacer gap={24} />
								<Group>
									<If
										condition={
											values.click_unit === undefined ||
											values.click_unit === ClickUnit.MWH
										}
									>
										<FluidNumber
											onChange={handleChange}
											errorMessage={getIn(
												errors,
												"volume"
											)}
											name={"volume"}
											title={"Volume"}
											value={
												values.volume as unknown as number
											}
										/>
									</If>
									<If
										condition={
											values.click_unit ===
											ClickUnit.PERCENTAGE
										}
									>
										<Box sx={{ width: "162px" }}>
											<FluidSelect
												items={Array.from(
													{ length: 10 },
													(_, i) => {
														const val =
															(i + 1) * 10;

														return {
															value: String(val),
															label: String(val),
														};
													}
												)}
												label="Volume"
												name="volumePct"
												onChange={(val) =>
													setFieldValue(
														"volumePct",
														val
													)
												}
												isLabelStatic
												errorMessage={getIn(
													errors,
													"volumePct"
												)}
												value={values.volumePct}
											/>
										</Box>
									</If>
									<FluidRadioGroup
										orientation="row"
										name={"click_unit"}
										errorMessage={getIn(
											errors,
											"click_unit"
										)}
										value={`${values.click_unit}`}
										title={"Click Unit"}
										onChange={(value: unknown | string) =>
											setFieldValue("click_unit", value)
										}
										options={[
											{
												label: ClickUnit.MWH,
												value: ClickUnit.MWH,
											},
											{
												label: ClickUnit.PERCENTAGE,
												value: ClickUnit.PERCENTAGE,
											},
										]}
									/>
								</Group>
								<Spacer gap={24} />
								<FluidDatePicker
									onChange={handleChange}
									name={"click_date"}
									label={"Click Date"}
									value={values.click_date}
									errorMessage={getIn(errors, "click_date")}
								/>
								<Spacer gap={24} />
								<If
									condition={
										values.type_id ===
										ClickPeriodFrequencyLabels.DEFAULT
									}
								>
									<Group>
										<FluidDatePicker
											onChange={handleChange}
											name={"click_start"}
											label={"Start Date"}
											value={values.click_start}
											errorMessage={getIn(
												errors,
												"click_start"
											)}
										/>

										<FluidDatePicker
											onChange={handleChange}
											name={"click_end"}
											label={"End Date"}
											value={values.click_end}
											errorMessage={getIn(
												errors,
												"click_end"
											)}
										/>
									</Group>
								</If>

								<If
									condition={[
										ClickPeriodFrequencyLabels.QUARTERLY,
										ClickPeriodFrequencyLabels.MONTHLY,
									].includes(values.type_id)}
								>
									<Spacer gap={24} />
									<Box sx={{ width: "162px" }}>
										<FluidSelect
											items={clickSelectYears(
												props.contractPeriod,
												props.clickTypes
											)}
											label="Year"
											name="clickYear"
											onChange={(val) => {
												setFieldValue("clickYear", val);
											}}
											isLabelStatic
											value={values.clickYear}
										/>
									</Box>
								</If>
								{values.clickYear &&
									Object.keys(
										datesOptions(
											props.contractPeriod,
											values.clickYear,
											props.clickTypes
										)
									).includes(values.type_id) &&
									values.type_id !==
										ClickPeriodFrequencyLabels.DEFAULT && (
										<>
											<Spacer gap={24} />
											<RadioButtonsGroup
												label={
													optionsLabel[values.type_id]
												}
												options={
													datesOptions(
														props.contractPeriod,
														values.clickYear,
														props.clickTypes
													)[
														values.type_id ??
															ClickPeriodFrequencyLabels.YEARLY
													]
												}
												value={`${values.click_start}#${values.click_end}`}
												handleChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => {
													const [startDate, endDate] =
														e.target.value.split(
															"#"
														);

													setFieldValue(
														"click_start",
														startDate
													);
													setFieldValue(
														"click_end",
														endDate
													);
												}}
												name="clickDates"
											/>
										</>
									)}
								<Spacer gap={24} />

								<If condition={!!props.createClickErrorMessage}>
									<ErrorText>
										{props.createClickErrorMessage}
									</ErrorText>
									<Spacer gap={24} />
								</If>
							</Box>
						</DialogContent>

						<DialogActions>
							<If condition={!props.isLoading}>
								<PrimaryButton
									onClick={handleClose}
									text="Cancel"
									type="button"
									color="secondary"
								/>
								<FluidButton
									type="submit"
									icon={"add_circle_outline"}
									label={"Add"}
									onClick={noop}
								/>
							</If>
							<If condition={!!props.isLoading}>
								<CircularProgress />
							</If>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
}
