import { ReactNode } from "react";
import { secondaryColor } from "../../../core/theme";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, SxProps, TableCell, Theme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SystemProps } from "@mui/system";
import IconButton from "@mui/material/IconButton";

interface HeaderCellProps {
	id?: string;
	text?: string;
	children?: ReactNode;
	filterBy?: boolean;
	align?: string;
	sx?: SystemProps<Theme>;
}
const style: {
	[key: string]: SxProps<Theme>;
} = {
	headerCellContainer: {
		borderLeft: 0,
		backgroundColor: secondaryColor,
		color: "#000",
		fontWeight: 700,
		fontSize: 12,
		padding: 0,
		height: "54px",
	},
	orderByButton: {
		color: "#000",
		padding: 0,
		backgroundColor: secondaryColor,
		marginRight: 0,
	},
};

function getJustifyContent(align?: string) {
	if (align === "left") {
		return "start";
	}
	if (align === "right") {
		return "end";
	}
	return "center";
}

function HeaderCell(props: HeaderCellProps) {
	return (
		<TableCell sx={{ ...style.headerCellContainer, ...props.sx }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: getJustifyContent(props.align),
				}}
			>
				{!!props.text && (
					<>
						<IconButton
							aria-label="orderBy"
							sx={style.orderByButton}
						>
							<UnfoldMoreIcon sx={{ fontSize: 20 }} />
						</IconButton>
						{props.text}
					</>
				)}
				{!!props.children && props.children}
				{!!props.filterBy && (
					<ArrowDropDownIcon sx={{ fontSize: 20 }} />
				)}
			</Box>
		</TableCell>
	);
}

export default HeaderCell;
