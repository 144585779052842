import { useMemo } from "react";
import { useRtkQueryDynamicEndpoint } from "../../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useBuildCurtailmentObjectsMutation } from "../../../../../requests_geco/sitesApi";
import { GenerateCurtailmentObjects } from "./GenerateCurtailmentObjects";
import { If } from "../../../../../common/components/If";
import { GeneratingCurtailmentLoadingModal } from "./GeneratingCurtailmentLoadingModal";
import { GeneratingCurtailmentNothingModal } from "./GeneratingCurtailmentNothingModal";
import { GeneratingCurtailmentSuccessModal } from "./GeneratingCurtailmentSuccessModal";
import { GeneratingCurtailmentObjectIncompleteModal } from "./GeneratingCurtailmentObjectIncompleteModal";
import { GeneratingCurtailmentObjectFailedModal } from "./GeneratingCurtailmentObjectFailedModal";
import ErrorMessageModal from "../../../../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../../common/utils/formatApiErrorMessage";

export enum BuildStatuses {
	initial = "initial",
	success = "success",
	loading = "loading",
	empty = "empty",
	failed = "failed",
	incomplete = "incomplete",
}

export interface BuildCurtailmentObjectsModalContainerProps {
	siteId: number;
	isOpen: boolean;
	onClose: () => void;
}
export const BuildCurtailmentObjectsModalContainer = ({
	siteId,
	isOpen,
	onClose,
}: BuildCurtailmentObjectsModalContainerProps) => {
	const [baseBuildCurtailmentObjects, { data, error, isLoading, reset }] =
		useBuildCurtailmentObjectsMutation();
	const buildCurtailmentObjects = useRtkQueryDynamicEndpoint(
		baseBuildCurtailmentObjects
	);
	const currentStatus = useMemo(() => {
		if (isLoading) {
			return BuildStatuses.loading;
		}
		if (data && data.errors.length && data.success.length) {
			return BuildStatuses.incomplete;
		}
		if (data && !data.errors.length && data.success.length) {
			return BuildStatuses.success;
		}
		if (data && data.errors.length && !data.success.length) {
			return BuildStatuses.failed;
		}
		if (data && !data.errors.length && !data.success.length) {
			return BuildStatuses.empty;
		}
		return BuildStatuses.initial;
	}, [data, isLoading]);

	const onCloseModal = () => {
		onClose();
		reset();
	};

	return (
		<>
			<If condition={isOpen}>
				<GenerateCurtailmentObjects
					isOpen={currentStatus === BuildStatuses.initial}
					onClose={onCloseModal}
					onSubmit={({
						startDate,
						endDate,
					}: {
						startDate: string;
						endDate: string;
					}) =>
						buildCurtailmentObjects({ startDate, endDate, siteId })
					}
				/>
				<GeneratingCurtailmentLoadingModal
					isOpen={currentStatus === BuildStatuses.loading}
				/>
				<GeneratingCurtailmentNothingModal
					isOpen={currentStatus === BuildStatuses.empty}
					onClose={onCloseModal}
				/>
				<GeneratingCurtailmentSuccessModal
					isOpen={currentStatus === BuildStatuses.success}
					onClose={onCloseModal}
				/>
				<GeneratingCurtailmentObjectFailedModal
					errors={data?.errors || []}
					success={data?.success || []}
					isOpen={currentStatus === BuildStatuses.failed}
					onClose={onCloseModal}
				/>
				<GeneratingCurtailmentObjectIncompleteModal
					errors={data?.errors || []}
					success={data?.success || []}
					isOpen={currentStatus === BuildStatuses.incomplete}
					onClose={onCloseModal}
				/>
			</If>
			<ErrorMessageModal
				title={"Failed to generate curtailment object"}
				content={formatApiErrorMessage(error as ErrorType)}
				actionAfterClose={reset}
			/>
		</>
	);
};
