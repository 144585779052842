import { ReactNode } from "react";
import { Box } from "@mui/material";
import { style } from "./TitleWithIcon.style";

export interface TitleWithIconProps extends React.PropsWithChildren {
	icon?: ReactNode;
	gap?: number;
	sx?: any;
}
export const TitleWithIcon = ({
	icon,
	children,
	sx = {},
}: TitleWithIconProps) => {
	return (
		<Box sx={{ ...style.container, ...sx }}>
			{icon} {children}
		</Box>
	);
};
