import { useEffect } from "react";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useLazyGetContractsQuery } from "../../../../requests_geco/contractsApi";
import { GetOneSiteResponseType } from "../../../../requests_geco/sitesApi/sitesApi.types";
import ContractTab from "./ContractsTab";

export const ContractsTabContainer = ({
	site,
}: {
	site: GetOneSiteResponseType;
}) => {
	const [baseGetContracts, { data: contracts, isLoading }] =
		useLazyGetContractsQuery();

	const getContracts = useRtkQueryDynamicEndpoint(baseGetContracts);

	useEffect(() => {
		if (site) {
			getContracts({
				filters: {
					contractperiods__site_contractperiods__site__name__ilike:
						site.name,
				},
				page: 1,
			});
		}
	}, [site]);

	return (
		<ContractTab
			isLoading={isLoading}
			contracts={contracts?.result || []}
		/>
	);
};
