import {
	Portfolio,
	Site,
	isPortfolio,
} from "../../features/sites/sites.module";

export interface SiteTechnicalDetailsProps {
	site: Site | Portfolio;
}

export function SiteTechnicalDetails(props: SiteTechnicalDetailsProps) {
	if (isPortfolio(props.site)) {
		return <></>;
	}
	switch (props.site.techno?.name) {
		case "wind_onshore":
		case "wind_offshore":
			return <div>Hub height: {props.site.hub_height}</div>;
		case "solar":
			return (
				<>
					<div>Tilt angle: {props.site.tilt_angle}</div>
					<div>Azimuth: {props.site.azimuth}</div>
				</>
			);
	}
	return <>Unknown technology</>;
}
