import {
	ServicePointType,
	TechnologyType,
} from "../../../../../requests_geco/sitesApi/sitesApi.types";
import AssetFormSolar from "./AssetFormSolar";
import AssetFormWind from "./AssetFormWind";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { colorPaletteBlue600 } from "../../../../../core/theme";
import Modal from "../../../../../common/components/Modal";
import { FormikValues } from "formik";
import { GenericReferential } from "../../../../../requests_geco/referentialApi/referentialApi.types";

const EditAssetModal = ({
	assetId,
	machines,
	onClose,
	open,
	servicePoints,
	site,
	updateAsset,
}: {
	assetId: number;
	machines: GenericReferential[];
	onClose: () => void;
	open: boolean;
	servicePoints: ServicePointType[];
	site: { name: string; country: string; technology: string };
	updateAsset: (assetValues: FormikValues) => void;
}) => {
	const selectedAsset = servicePoints
		.flatMap((servicePoint) => servicePoint.assets)
		.find((asset) => asset.id === assetId);

	const handleUpdateAsset = (newAssets: FormikValues) => {
		updateAsset(newAssets);
	};

	return (
		<Modal
			onClose={onClose}
			open={open}
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<AddCircleOutlineIcon sx={{ color: colorPaletteBlue600 }} />{" "}
					Add asset
				</Box>
			}
		>
			{site.technology === TechnologyType.Solar && (
				<AssetFormSolar
					asset={selectedAsset}
					machines={machines}
					onClose={onClose}
					servicePoints={servicePoints}
					technology={site.technology}
					upsertSingleAsset={handleUpdateAsset}
				/>
			)}
			{(site.technology === TechnologyType.WindOnshore ||
				site.technology === TechnologyType.WindOffshore) && (
				<AssetFormWind
					asset={selectedAsset}
					machines={machines}
					onClose={onClose}
					servicePoints={servicePoints}
					technology={site.technology}
					upsertSingleAsset={handleUpdateAsset}
				/>
			)}
		</Modal>
	);
};

export default EditAssetModal;
