import Modal from "../../../../../common/components/Modal";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { colorPaletteBlue600 } from "../../../../../core/theme";
import InfoBlock from "../../../../../common/components/InfoBlock/InfoBlock";
import Country from "../../../../../common/components/Country";
import TechnologyIcon from "../../../../../common/components/TechnologyIcon";
import {
	ServicePointType,
	TechnologyType,
} from "../../../../../requests_geco/sitesApi/sitesApi.types";
import AssetFormSolar from "./AssetFormSolar";
import AssetFormWind from "./AssetFormWind";
import { FormikValues } from "formik";
import { GenericReferential } from "../../../../../requests_geco/referentialApi/referentialApi.types";

const AddAssetsFormModal = ({
	machines,
	onClose,
	open,
	servicePoints,
	site,
	upsertSingleAsset,
}: {
	machines: GenericReferential[];
	onClose: () => void;
	open: boolean;
	servicePoints: ServicePointType[];
	site: { name: string; country: string; technology: string };
	upsertSingleAsset: (newAssets: FormikValues) => void;
}) => {
	return (
		<Modal
			onClose={onClose}
			open={open}
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<AddCircleOutlineIcon sx={{ color: colorPaletteBlue600 }} />{" "}
					Add asset
				</Box>
			}
		>
			<InfoBlock
				head={[{ value: site.name }]}
				info={[
					{
						label: "Techno",
						value: site.technology,
						icon: (
							<TechnologyIcon
								technology={site.technology as TechnologyType}
							/>
						),
					},
					{
						label: "Country",
						value: <Country country={site.country} />,
					},
				]}
				withBackground
				fullWidth
			/>
			{site.technology === TechnologyType.Solar && (
				<AssetFormSolar
					machines={machines}
					onClose={onClose}
					servicePoints={servicePoints}
					technology={site.technology}
					upsertSingleAsset={upsertSingleAsset}
				/>
			)}
			{(site.technology === TechnologyType.WindOnshore ||
				site.technology === TechnologyType.WindOffshore) && (
				<AssetFormWind
					machines={machines}
					onClose={onClose}
					servicePoints={servicePoints}
					upsertSingleAsset={upsertSingleAsset}
					technology={site.technology}
				/>
			)}
		</Modal>
	);
};

export default AddAssetsFormModal;
