import { Box, IconButton, SxProps, Theme } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useMemo, useRef, useState } from "react";
import { style } from "./Slider.style";

interface SLiderProps {
	children: React.ReactNode;
	sx?: SxProps<Theme>;
	innerSx?: SxProps<Theme>;
}

const Slider = ({ sx, innerSx, children }: SLiderProps) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [sliderPosition, setSliderPosition] = useState(0);
	const [isPpreviousDisabled, setIsPpreviousDisabled] = useState(false);
	const [isNextDisabled, setIsNextDisabled] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);

	const positionLimit = useMemo(() => {
		return -(
			(sliderRef.current?.offsetWidth ?? 0) -
			(wrapperRef.current?.offsetWidth ?? 0)
		);
	}, [sliderRef.current?.offsetWidth, wrapperRef.current?.offsetWidth]);

	const positions = useMemo(() => {
		const positionsArray: number[] = [];

		if (sliderRef.current) {
			Array.from(sliderRef.current.children).forEach((child: any) => {
				const currentPosition = -child.offsetLeft;

				if (currentPosition >= positionLimit) {
					positionsArray.push(currentPosition);
				} else if (
					positionsArray[positionsArray.length - 1] !== positionLimit
				) {
					positionsArray.push(positionLimit);
				}
			});
		}

		return positionsArray;
	}, [positionLimit]);

	useEffect(() => {
		setSliderPosition(positions[activeIndex] ?? 0);
	}, [activeIndex]);

	useEffect(() => {
		setIsPpreviousDisabled(activeIndex === 0);
		setIsNextDisabled(
			activeIndex === positions.length - 1 ||
				(sliderRef.current?.offsetWidth ?? 0) <=
					(wrapperRef.current?.offsetWidth ?? 0)
		);
	}, [activeIndex, positions.length, sliderPosition, positionLimit]);

	const handleNext = () => {
		if (activeIndex < positions.length - 1) {
			setActiveIndex(activeIndex + 1);
		}
	};

	const handlePrevious = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		}
	};

	return (
		<Box
			sx={{
				...style.container,
				...sx,
			}}
		>
			<IconButton
				id="left"
				onClick={handlePrevious}
				disabled={isPpreviousDisabled}
			>
				<ArrowBackIosNewIcon />
			</IconButton>
			<Box ref={wrapperRef} sx={style.wrapper}>
				{!isPpreviousDisabled && <Box sx={style.gradientLeft}></Box>}
				<Box
					ref={sliderRef}
					sx={{ ...style.slider(sliderPosition), ...innerSx }}
				>
					{children}
				</Box>
				{!isNextDisabled && <Box sx={style.gradientRight}></Box>}
			</Box>
			<IconButton
				id="right"
				onClick={handleNext}
				disabled={isNextDisabled}
			>
				<ArrowForwardIosIcon />
			</IconButton>
		</Box>
	);
};

export default Slider;
