import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";
import {
	SearchPartiesResultType,
	TprPartyDetailType,
} from "./gepoTprProxyApi.types";

const gepoTprProxyApi = createApi({
	reducerPath: "gepoTprProxyApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_API_PATH!,
	}),
	endpoints: (builder) => ({
		searchParties: builder.query<
			SearchPartiesResultType,
			{ search: string }
		>({
			query: ({ search }) => {
				return {
					url: "external-services/parties",
					method: "POST",
					data: { search },
				};
			},
		}),
		getPartyDetail: builder.query<TprPartyDetailType, { id: string }>({
			query: ({ id }) => {
				return {
					url: `external-services/parties_detail/${id}`,
					method: "POST",
				};
			},
		}),
	}),
});

export const { useLazyGetPartyDetailQuery, useLazySearchPartiesQuery } =
	gepoTprProxyApi;

export default gepoTprProxyApi;
