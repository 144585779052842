import Dotdotdot from "react-dotdotdot";

export interface TextVerticalClampProps extends React.PropsWithChildren<{}> {
	maxLine: number;
}

const TextVerticalClamp = (props: TextVerticalClampProps) => {
	return <Dotdotdot clamp={props.maxLine}>{props.children}</Dotdotdot>;
};

export default TextVerticalClamp;
