import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/default";
import {
	selectPricingSelectedGroupingFields,
	selectPricingsGroupCount,
	selectPricingsGroupCountError,
	selectPricingsGroupCountIsLoading,
} from "../grouping/grouping.selector";
import usePrevious from "../../common/hooks/usePrevious";
import { loadPricingsGroupCount } from "../grouping/grouping.thunk";
import {
	Alert,
	Box,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { If } from "../../common/components/If";
import { PricingGroup } from "./pricingGroup";
import { selectPricingsGroupingMetadata } from "../metadata/metadata.selector";
import { borderGrey } from "../../core/theme";
import wsPublisher$ from "../../core/wsPublisher";
import { Pricing } from "../pricing_list/pricingListSlice";
import { WsMessage } from "../../core/socketProvider";
import {
	selectPersistedFilters,
	selectPricingFilters,
} from "../filters/filters.selector";
import { buildPredicates, setSelectedFilterValues } from "../filters/utils";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { Column } from "../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../pricing_list/hooks/columns/common";

const styles = {
	wrapper: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		width: "100%",
		overflowY: "scroll",
		marginTop: "40px",
		borderTop: `1px solid ${borderGrey}`,
	},
	fixed: {
		tableLayout: "fixed",
	},
};

export interface PricingGroupsProps {
	tenderId?: number;
	odataQuery?: string;
	selectedColumns: Column<ColumnIds>[];
}

export function PricingGroups(props: PricingGroupsProps) {
	const dispatch = useAppDispatch();
	const selectedGroups = useAppSelector(selectPricingSelectedGroupingFields);
	const availableGroups = useAppSelector(selectPricingsGroupingMetadata);
	const previousGroups = usePrevious(selectedGroups);
	const pricingsGroupsCount = useAppSelector(selectPricingsGroupCount);
	const groupCountIsLoading = useAppSelector(
		selectPricingsGroupCountIsLoading
	);
	const groupCountError = useAppSelector(selectPricingsGroupCountError);
	const persistedFilters = useAppSelector(selectPersistedFilters);
	const pricingsFiltersData = useAppSelector(selectPricingFilters);

	const rootFiltersPredicates = useMemo(() => {
		const values = setSelectedFilterValues(
			pricingsFiltersData,
			persistedFilters.filters?.pricings_filters ?? ""
		);
		return buildPredicates(pricingsFiltersData, values, "Pricings")
			.linqPredicates;
	}, [pricingsFiltersData, persistedFilters]);

	useEffect(() => {
		if (props.tenderId && selectedGroups?.length) {
			dispatch(
				loadPricingsGroupCount(props.tenderId, props.odataQuery, false)
			);
		}
	}, [
		selectedGroups,
		previousGroups,
		dispatch,
		props.tenderId,
		props.odataQuery,
	]);

	useEffect(() => {
		const onPricingChange = (messages: WsMessage<Pricing>[]) => {
			if (props.tenderId && selectedGroups?.length) {
				if (
					messages.filter(
						(message) => message.data.tender_id === props.tenderId
					).length
				) {
					dispatch(
						loadPricingsGroupCount(
							props.tenderId,
							props.odataQuery,
							true
						)
					);
				}
			}
		};

		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_CREATED, WsEventTypes.PRICING_UPDATED],
			onPricingChange
		);
	}, [dispatch, selectedGroups, props.tenderId]);

	return (
		<Box sx={styles.wrapper}>
			<If condition={groupCountError}>
				<Alert severity="error">
					{"" + JSON.stringify(groupCountError)}
				</Alert>
			</If>
			<If condition={groupCountIsLoading}>
				<LinearProgress />
			</If>
			<If
				condition={!groupCountIsLoading && !pricingsGroupsCount?.length}
			>
				<Alert severity="warning">No items found</Alert>
			</If>
			<If condition={pricingsGroupsCount?.length}>
				<Table
					sx={{
						height: "100%",
						width: "unset",
						backgroundColor: "white",
						"& .MuiTableCell-root": {
							border: "0px solid rgba(0, 0, 0, .2)",
						},
						"& .MuiTableRow-root": {
							borderBottom: "1px solid rgba(0, 0, 0, .2)",
						},
						borderLeft: "1px solid rgba(0, 0, 0, .2)",
					}}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							{selectedGroups.map((group) => (
								<TableCell
									key={group.field_key}
									sx={{ padding: "8px" }}
								>
									<Typography
										sx={{ fontSize: "18px", margin: 0 }}
									>
										{group.display_name}
									</Typography>
								</TableCell>
							))}
							<TableCell sx={{ width: "100%" }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{pricingsGroupsCount?.map((group) => (
							<PricingGroup
								selectedColumns={props.selectedColumns}
								rootPredicates={rootFiltersPredicates}
								odataQuery={props.odataQuery}
								tenderId={props.tenderId}
								key={group.field_key + group.field_value}
								depth={0}
								maxDepth={availableGroups.length}
								groupedField={group}
							/>
						))}
					</TableBody>
				</Table>
			</If>
		</Box>
	);
}
