import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { If } from "../../../../common/components/If";
import { DISPLAY_TECHNO_NAME_MAP } from "../../pricingList.constant";
import {
	Box,
	CircularProgress,
	Typography,
	SxProps,
	Theme,
} from "@mui/material";
import { formatStringDate } from "../../../../common/utils/dateUtils";

const style: { [key: string]: SxProps<Theme> } = {
	loaderContainer: {
		minHeight: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

interface WindSpeedRadiationGraphProps extends React.PropsWithChildren<{}> {
	data: any;
	installedCapacity: number;
	noData: boolean;
	techno: string;
	error?: string;
	horizon?: { start_date: string; end_date: string };
}

export default function WindSpeedRadiationGraph(
	props: WindSpeedRadiationGraphProps
) {
	const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
	const { data, installedCapacity, noData, techno, error, horizon } = props;

	const [chartData, setChartData] = React.useState<any>({});

	React.useEffect(() => {
		const chart = chartComponentRef.current?.chart;

		if (data.length === 0) {
			chart?.showLoading("Loading...");
		} else {
			chart?.hideLoading();
			const plotLine = {
				color: "#FF0000",
				width: 2,
				value: installedCapacity,
				id: "plotLineInstalledCapacity",
			};
			setChartData({
				chart: {
					zoomType: "xy",
					animation: false,
				},
				title: {
					text: `${formatStringDate(
						horizon?.start_date,
						"MMM M yyyy"
					)} - ${formatStringDate(horizon?.end_date, "MMM M yyyy")}`,
				},
				subtitle: {
					text: "Click and drag in the plot area to zoom in",
					align: "left",
				},

				xAxis: {
					title: {
						text: `${DISPLAY_TECHNO_NAME_MAP[techno]}`,
					},
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true,
				},
				yAxis: {
					title: {
						text: "Production",
					},
					max: installedCapacity + 2,
					plotLines: [plotLine],
				},
				tooltip: {
					animation: false,
					pointFormat: `${DISPLAY_TECHNO_NAME_MAP[techno]}: {point.x}  <br/> Production: {point.y} Mw`,
				},
				plotOptions: {
					series: {
						turboThreshold: 2000,
						shadow: false,
						animation: false,
					},
					scatter: {
						marker: {
							radius: 2.5,
							symbol: "circle",
						},
					},
				},
				legend: {
					enabled: true,
					itemHiddenStyle: {
						textDecoration: "none",
					},
				},

				series: [
					{
						// Series that mimics the plot line
						color: "#FF0000",
						name: "Installed Capacity",
						showInLegend: true,
						lineWidth: 2,
						marker: {
							enabled: false,
						},
						events: {
							legendItemClick: function (e: any) {
								if (e.target.visible) {
									chart?.xAxis[0].removePlotLine(
										"plotLineInstalledCapacity"
									);
								} else {
									chart?.xAxis[0].addPlotLine(plotLine);
								}
							},
						},
					},
					...data,
				],
			});
		}
	}, [data]);
	return (
		<>
			<If condition={Object.keys(chartData).length === 0 && !noData}>
				<Box sx={style.loaderContainer}>
					<CircularProgress color="primary" />
				</Box>
			</If>
			<If condition={Object.keys(chartData).length > 0 && !error}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartData}
					ref={chartComponentRef}
					{...props}
					containerProps={{
						style: { minHeight: "60vh" },
					}}
				/>
			</If>
			<If condition={noData}>
				<Typography paragraph>{"No Data Found"}</Typography>
			</If>
			<If condition={error}>
				<Typography paragraph>{error}</Typography>
			</If>
		</>
	);
}
