import { Box } from "@mui/material";
import { ClickabilityFields } from "../../contract_period/ContractPeriodTabs/ClickabilityTab/ClickabilityModal/ClickabilityFields";
import { Formik } from "formik";
import { ContractDetailType } from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	clickabilityTabFormikInitialValueMapper,
	ClickabilityFormik,
} from "../../contract_period/formik/clickabilityTabFormik";
import { clickabilityValidator } from "../../contract_period/formik/clickabilityTabValidators";

export interface ClickabilityFormProps {
	contract: ContractDetailType | undefined;
	contract_period_id: number;
	onSubmit: (data: ClickabilityFormik) => void;
}

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
};

const ClickabilityForm = ({
	contract,
	contract_period_id,
	onSubmit,
}: ClickabilityFormProps) => {
	return (
		<Box sx={style.container}>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={clickabilityValidator}
				enableReinitialize
				initialValues={clickabilityTabFormikInitialValueMapper(
					contract,
					contract_period_id
				)}
				onSubmit={(formData: ClickabilityFormik) => {
					onSubmit(formData);
				}}
			>
				{({
					handleChange,
					submitForm,
					handleSubmit,
					setFieldValue,
					values,
					errors,
				}) => (
					<form onSubmit={handleSubmit}>
						<ClickabilityFields
							errors={errors}
							values={values}
							setFieldValue={(field, value) => {
								setFieldValue(field, value);
								submitForm();
							}}
							handleChange={(event) => {
								handleChange(event);
								submitForm();
							}}
						/>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default ClickabilityForm;
