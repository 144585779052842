import { Box, Tooltip } from "@mui/material";
import {
	ContractDetailType,
	SettlementType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import { Spacer } from "../../../../common/components/Spacer";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetNegativePriceIndexesQuery } from "../../../../requests_geco/referentialApi/referentialApi";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import { If } from "../../../../common/components/If";

const SettlementTab = ({
	contract,
	settlements = [],
}: {
	contract?: ContractDetailType;
	settlements: SettlementType[];
}) => {
	const { isUserAuthorized } = useIsUserWithinGroups();
	const { data: indexes, isLoading } = useRtkQueryDynamicEndpoint(
		useGetNegativePriceIndexesQuery
	)({
		country_code: contract?.country ?? "",
	});
	const [selectedRows, setSelectedRows] = useState<string[]>([]);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const headers: HeadersType<SettlementType>[] = [
		{
			accessor: "type",
			label: "Type",
		},
		{
			accessor: "period",
			label: "Period",
		},
		{
			accessor: "commodityFixing",
			label: "Commodity Fixing",
		},
		{
			accessor: "price.commodity",
			label: "Fix Price",
		},
		{
			accessor: "index.alpha.commodity",
			label: "Alpha",
		},
		{
			accessor: "index.beta.commodity",
			label: "Beta",
		},
		{
			accessor: "price.fees",
			label: "Fix Fees",
		},
		{
			accessor: "index.alpha.fees",
			label: "Alpha Fees",
		},
		{
			accessor: "index.beta.fees",
			label: "Beta Fees",
		},
	];

	const handleSetSelectedRows = (rows: string[]) => {
		setSelectedRows(rows);
	};

	const orderedSettlements = settlements.map((settlement) => {
		return {
			...settlement,
			commodityFixing: indexes?.find(
				(index) =>
					index.orchestrade_id ===
					settlement.index?.commodity_fixing_id
			)?.name,
			"price.fees": (
				<If condition={settlement?.price?.fees?.length}>
					<Tooltip
						title={(settlement?.price?.fees || []).map((fee) => (
							<p key={fee.name}>{`${fee.name}:${fee.value}`}</p>
						))}
					>
						<p>
							{(settlement?.price?.fees || [])
								.reduce(
									(total, fee) =>
										Number(total) + Number(fee.value),
									0
								)
								.toFixed(2)}
						</p>
					</Tooltip>
				</If>
			),
			"index.alpha.fees": (
				<If condition={settlement?.index?.alpha?.fees?.length}>
					<Tooltip
						title={(settlement?.index?.alpha?.fees || []).map(
							(fee) => (
								<p
									key={fee.name}
								>{`${fee.name}:${fee.value}`}</p>
							)
						)}
					>
						<p>
							{(settlement?.index?.alpha?.fees || [])
								.reduce(
									(total, fee) =>
										Number(total) + Number(fee.value),
									0
								)
								.toFixed(2)}
						</p>
					</Tooltip>
				</If>
			),
			"index.beta.fees": (
				<If condition={settlement?.index?.beta?.fees?.length}>
					<Tooltip
						title={(settlement?.index?.beta?.fees || []).map(
							(fee) => (
								<p
									key={fee.name}
								>{`${fee.name}:${fee.value}`}</p>
							)
						)}
					>
						<p>
							{(settlement?.index?.beta?.fees || [])
								.reduce(
									(total, fee) =>
										Number(total) + Number(fee.value),
									0
								)
								.toFixed(2)}
						</p>
					</Tooltip>
				</If>
			),
		};
	});

	return (
		<>
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<>
					{!settlements.length && <NoDataMessage />}
					{!!settlements.length && (
						<Box>
							<Spacer gap={24} />
							<SimpleTable
								headers={headers}
								items={orderedSettlements}
								selectedRows={selectedRows}
								setSelectedRows={handleSetSelectedRows}
								selectableRows={isUserAuthorizedBool}
							/>
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default SettlementTab;
