import {
	Box,
	Button,
	LinearProgress,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	Site,
	SiteKnownStatus as Status,
} from "../../features/sites/sites.module";
import { useAppDispatch } from "../hooks/default";
import { useCallback } from "react";
import { getNewSiteKnownStatus } from "../../features/tender_form/tenderForm.thunk";
import ErrorIcon from "@mui/icons-material/Error";

const styles = {
	error: {
		color: "red",
	},
};

export interface SiteKnownStatusFieldProps {
	site: Site;
}

function getStatusDisplayText(status?: Status) {
	switch (status) {
		case Status.NEW:
			return "New";
		case Status.KNOWN_GEPO:
			return "Known in GEPO";
		case Status.KNOWN_GECO:
			return "Known in GECO";
		default:
			return "";
	}
}

export function SiteKnownStatusField(props: SiteKnownStatusFieldProps) {
	const dispatch = useAppDispatch();

	const reload = useCallback(() => {
		dispatch(getNewSiteKnownStatus(props.site));
	}, [dispatch, props.site]);

	if (props.site.known_status_loading) {
		return <LinearProgress />;
	}
	if (props.site.known_status_error) {
		return (
			<Tooltip title={"" + props.site.known_status_error}>
				<Box sx={styles.error}>
					<ErrorIcon sx={styles.error}></ErrorIcon>
					<Button variant="text" onClick={reload}>
						Reload
					</Button>
				</Box>
			</Tooltip>
		);
	}
	return (
		<Typography>{getStatusDisplayText(props.site.known_status)}</Typography>
	);
}
