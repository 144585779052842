import { createSlice, current } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

export enum NotificationPriority {
	HIGH = "HIGH",
	MEDIUM = "MEDIUM",
	LOW = "LOW",
}

export enum NotificationEventType {
	NEW_PRICING = "NEW_PRICING",
	NEW_TENDER = "NEW_TENDER",
	PICKUP = "PICKUP",
	PRICING_FIXED = "PRICING_FIXED",
	PRICING_FIXED_APPROVED = "PRICING_FIXED_APPROVED",
	PRICING_FIXING_REQUEST = "PRICING_FIXING_REQUEST",
	CONTRACT_CHANGED = "CONTRACT_CHANGED",
	CONTRACT_APPROVED = "CONTRACT_APPROVED",
	CONTRACT_REJECTED = "CONTRACT_REJECTED",
}

export type NotificationItemType = {
	event_type: NotificationEventType;
	message: string;
	timestamp: number;
	read: boolean;
	priority: NotificationPriority;
	entity_id?: number;
	url?: string;
	created_at: string;
	extra_params: any;
	id: number;
};
interface NotificationsState {
	opened: boolean;
	isNewNotificationReceived: boolean;
	notificationsList: NotificationItemType[];
	isLoading: boolean;
	hasNextPage: boolean;
	oldestReadNotificationId?: number;
	oldestUnReadNotificationId?: number;
}

const initialState: NotificationsState = {
	opened: false,
	isNewNotificationReceived: false,
	notificationsList: [],
	isLoading: false,
	hasNextPage: false,
};

export const notificationsSlice = createSlice({
	name: "Notifications",
	initialState,
	reducers: {
		setNotificationsDrawerOpened(state, { payload }) {
			state.opened = payload.opened;
			state.isNewNotificationReceived = false;
		},
		setNotificationItem(state, { payload }) {
			state.isNewNotificationReceived = true;
			state.notificationsList.unshift(payload);
			state.notificationsList = uniqBy(state.notificationsList, "id");
		},
		setIsLoading(state, { payload }) {
			state.isLoading = payload;
		},
		setHasNextPage(state, { payload }) {
			state.hasNextPage = payload;
		},
		setOldestReadNotificationId(state, { payload }) {
			state.oldestReadNotificationId = payload;
		},
		setOldestUnReadNotificationId(state, { payload }) {
			state.oldestUnReadNotificationId = payload;
		},
		setNotificationItemAsRead(state, { payload }) {
			const listNotifs = state.notificationsList;
			const ids = payload.ids;
			state.notificationsList = listNotifs.map((notif) => {
				if (ids.includes(current(notif).id)) notif.read = true;
				return notif;
			});
		},
		setAllNotifications(state, { payload }) {
			state.notificationsList = uniqBy(
				[
					...state.notificationsList,
					...(payload.data as Array<NotificationItemType>),
				],
				"id"
			);

			if (state.notificationsList.length > 0) {
				if (payload.read) {
					state.oldestReadNotificationId =
						state.notificationsList[
							state.notificationsList.length - 1
						].id;
				} else {
					state.oldestUnReadNotificationId =
						state.notificationsList[
							state.notificationsList.length - 1
						].id;
				}
			}
		},
		setAllNotificationItemAsRead(state) {
			const listNotifs = state.notificationsList;
			state.notificationsList = listNotifs.map((notif) => {
				notif.read = true;
				return notif;
			});
		},
	},
});

export const {
	setNotificationsDrawerOpened,
	setNotificationItem,
	setNotificationItemAsRead,
	setAllNotifications,
	setAllNotificationItemAsRead,
	setIsLoading,
	setHasNextPage,
	setOldestReadNotificationId,
	setOldestUnReadNotificationId,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
