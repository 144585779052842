export interface BadRequestResponseItem {
	loc: string[];
	msg: string;
	type: string;
}

export interface BadRequestResponse {
	Reason: string;
	errors: BadRequestResponseItem[];
}

export function formatErrorResponse(response: BadRequestResponse): string[] {
	return response.errors.map((error) => {
		let singleError = "";

		if (error.loc.length === 0) {
			return error.msg;
		}
		if (error.loc.length !== 1 || error.loc[0] !== "__root__") {
			singleError += error.loc.join("/") + ": ";
		}
		singleError += error.msg;
		return singleError;
	});
}

export function isBadRequestResponseWithErrors(
	response: any
): response is BadRequestResponse {
	return (
		!!response &&
		"Reason" in response &&
		"errors" in response &&
		Array.isArray(response.errors)
	);
}
