export function floorToSecondDigit(value: number) {
	const multiplied = value * 100;
	const rounded = Math.floor(multiplied);
	return rounded / 100;
}

export const noop = () => {
	// default function that does literally nothing
	// used for disabled onclick for example
};
