import React, { useMemo, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { HomeIcon } from "./HomeIcon";
import { BreadCrumbsLink } from "./BreadCrumbsLink";
import { BreadCrumbsSeparator } from "./Separators";
import { MakeClickable } from "../MakeClickable";
import { BreadCrumbsContext } from "../../contexts/BreadCrumbsContext";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../router-path";

const style = {
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "6px",
	},
};

export interface BreadCrumbsProps {
	crumbsLabels?: { path: string; name: string }[];
}

export const BreadCrumbs = ({ crumbsLabels }: BreadCrumbsProps) => {
	const { breadCrumbs, renameCrumbs } = useContext(BreadCrumbsContext);
	const lastIndex = useMemo(
		() => (breadCrumbs || []).length - 1,
		[breadCrumbs]
	);
	const navigate = useNavigate();
	useEffect(() => {
		if (crumbsLabels && crumbsLabels.length) {
			renameCrumbs(
				crumbsLabels.map((crumbsLabel) => ({
					...crumbsLabel,
					newName: crumbsLabel.name,
				}))
			);
		}
	}, [crumbsLabels]);

	return (
		<Box sx={style.container}>
			<MakeClickable onClick={() => navigate(PATH.LANDING)}>
				<HomeIcon />
			</MakeClickable>
			{(breadCrumbs || []).map((breadCrumb, index) => {
				return (
					<React.Fragment key={breadCrumb.label}>
						<BreadCrumbsSeparator />
						<BreadCrumbsLink
							path={breadCrumb.path}
							active={index == lastIndex}
						>
							{breadCrumb.label}
						</BreadCrumbsLink>
					</React.Fragment>
				);
			})}
		</Box>
	);
};
