import {
	Box,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
} from "@mui/material";
import { style } from "./SimpleTable.style";
import CircularProgress from "@mui/material/CircularProgress";
import { Waypoint } from "react-waypoint";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useEffect, useMemo, useState } from "react";
import { OrderType } from "../../../requests_geco/contractsApi/contractsApi.types";
import { SimpleTableCell } from "./SimpleTableCell";
import { PageInfoType } from "../../../requests_geco/gecoTypes";

export interface HeadersType<T extends { id: number | string }> {
	accessor: string;
	label: string;
	colHeader?: boolean;
	sortable?: boolean;
	format?: (value: string | boolean | number) => string | boolean | number;
	accessorOverride?: (data: T) => string;
	getAnchorTag?: (data: T) => string;
}

export interface SimpleTableType<T extends { id: number | string }> {
	clickableRows?: boolean;
	dataTestid?: string;
	handleRowClick?: (id: number | string) => void;
	headers: HeadersType<T>[];
	infiniteScroll?: boolean;
	isFetching?: boolean;
	isLastPage?: boolean | null;
	isLoading?: boolean;
	items: any[];
	pageInfo?: { sortOn?: string; order?: "ASC" | "DESC" };
	selectableRows?: boolean;
	selectedRows?: string[];
	setPageInfo?: React.Dispatch<React.SetStateAction<PageInfoType>>;
	setSelectedRows?: (rows: string[]) => void;
}

function SimpleTable<T extends { id: number | string }>({
	clickableRows,
	dataTestid,
	handleRowClick = () => {},
	headers,
	infiniteScroll,
	isFetching,
	isLastPage,
	isLoading,
	items,
	pageInfo,
	selectableRows,
	selectedRows,
	setPageInfo = () => {},
	setSelectedRows,
}: SimpleTableType<T>) {
	const [pendingSortingAccessor, setPendingSortingAccessor] = useState<
		string | null
	>(null);

	useEffect(() => {
		if (!isFetching) {
			setPendingSortingAccessor(null);
		}
	}, [isFetching]);

	const colsLength = headers.length + (!!selectedRows ? 1 : 0);
	const handleSelectRow = (id: string) => {
		if (selectableRows && selectedRows && setSelectedRows) {
			if (selectedRows.includes(id)) {
				setSelectedRows(selectedRows.filter((itemId) => itemId !== id));
			} else {
				setSelectedRows([...selectedRows, id]);
			}
		}
	};

	const handleOrder = (columnAccessor: string) => {
		setPendingSortingAccessor(columnAccessor);
		setPageInfo((current) => ({
			...current,
			page: 1,
			sortOn: columnAccessor,
			order:
				current.order === "ASC" && current.sortOn === columnAccessor
					? "DESC"
					: ("ASC" as OrderType),
		}));
	};

	const handleNextPage = () => {
		setPageInfo((current) => ({
			...current,
			page: current.page + 1,
		}));
	};

	const itemsIds = useMemo(
		() => items.map((item) => item.id).sort(),
		[items]
	);
	const areAllItemsSelected = useMemo(
		() => JSON.stringify(itemsIds) === JSON.stringify(selectedRows?.sort()),
		[itemsIds, selectedRows]
	);

	const handleSelectAllRows = () => {
		if (setSelectedRows) {
			if (areAllItemsSelected) {
				setSelectedRows([]);
			} else {
				setSelectedRows(itemsIds);
			}
		}
	};

	return (
		<Table data-testid={dataTestid}>
			<TableHead>
				<TableRow sx={style.headerRow}>
					{selectableRows && (
						<TableCell>
							<Checkbox
								checked={areAllItemsSelected}
								onClick={() => handleSelectAllRows()}
							/>
						</TableCell>
					)}
					{headers.map((header) => (
						<TableCell key={header.accessor}>
							<Box sx={style.thWrapper}>
								{header.sortable && (
									<>
										{pendingSortingAccessor ===
											header.accessor && (
											<CircularProgress
												size={14}
												sx={{ marginRight: "2px" }}
											/>
										)}
										{pendingSortingAccessor !==
											header.accessor && (
											<IconButton
												aria-label="orderBy"
												sx={style.orderByButton}
												onClick={() =>
													handleOrder(header.accessor)
												}
												disabled={
													pendingSortingAccessor !==
													null
												}
											>
												<KeyboardArrowUp
													sx={{
														fontSize: 14,
														margin: "-3px 0",
														color:
															pageInfo?.sortOn ===
																header.accessor &&
															pageInfo?.order ===
																"ASC"
																? "#007ACD"
																: "currentColor",
													}}
												/>
												<KeyboardArrowDown
													sx={{
														fontSize: 14,
														margin: "-3px 0",
														color:
															pageInfo?.sortOn ===
																header.accessor &&
															pageInfo?.order ===
																"DESC"
																? "#007ACD"
																: "currentColor",
													}}
												/>
											</IconButton>
										)}
									</>
								)}
								{header.label}
							</Box>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody sx={style.tableBody}>
				{pendingSortingAccessor !== null && (
					<Box sx={style.loaderLayer}>
						<CircularProgress />
					</Box>
				)}
				{isLoading && (
					<TableRow>
						<TableCell colSpan={colsLength}>
							<CircularProgress data-testid="loader" />
						</TableCell>
					</TableRow>
				)}
				{!isLoading &&
					items.map((item: T) => {
						const rowStyle = {
							...style.bodyRow,
							...(clickableRows ? style.bodyRow.clickable : {}),
						};

						return (
							<TableRow
								key={item.id}
								sx={rowStyle}
								onClick={() =>
									clickableRows && handleRowClick(item.id)
								}
							>
								{selectableRows && (
									<TableCell>
										<Checkbox
											checked={selectedRows?.includes(
												item.id as string
											)}
											onClick={() =>
												handleSelectRow(
													item.id as string
												)
											}
										/>
									</TableCell>
								)}
								{headers.map((header) => (
									<SimpleTableCell
										key={header.accessor}
										cellType={header}
										item={item}
									/>
								))}
							</TableRow>
						);
					})}
			</TableBody>
			{(infiniteScroll || isFetching) &&
				pendingSortingAccessor === null && (
					<TableFooter>
						<TableRow>
							<TableCell colSpan={colsLength} sx={{ border: 0 }}>
								{isFetching && (
									<CircularProgress data-testid="loader" />
								)}
								{!isFetching && isLastPage === false && (
									<Waypoint
										onEnter={handleNextPage}
									></Waypoint>
								)}
							</TableCell>
						</TableRow>
					</TableFooter>
				)}
		</Table>
	);
}

export default SimpleTable;
