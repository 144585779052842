import { Box, DialogTitle, Typography } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Spacer } from "../../../../../common/components/Spacer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { successPrimary } from "../../../../../core/theme";

export interface GeneratingCurtailmentSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
}
export const GeneratingCurtailmentSuccessModal = ({
	isOpen,
	onClose,
}: GeneratingCurtailmentSuccessModalProps) => {
	const style = {
		container: {
			width: "580px",
			padding: "24px",
		},
		bodyContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		successIcon: {
			fontSize: "64px",
			color: successPrimary,
			lineHeight: 0,
		},
	};
	return (
		<Modal sx={style.container} open={isOpen} onClose={onClose}>
			<Box sx={style.bodyContainer}>
				<span style={style.successIcon}>
					<CheckCircleIcon fontSize="inherit" />
				</span>
				<DialogTitle>Success</DialogTitle>
				<Typography>
					Curtailment objects have been successfully generated.
				</Typography>
				<Spacer gap={32} />
				<PrimaryButton
					onClick={onClose}
					text="Close"
					type="button"
					color="primary"
				/>
			</Box>
		</Modal>
	);
};
