import * as React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getPathWithParameters, PATH } from "../../../../router-path";

const style: { [key: string]: SxProps<Theme> } = {
	dialog: {
		minHeight: "28%",
		minWidth: "45%",
	},
	dialogText: {
		marginBottom: "30px",
	},
};

interface ErrorModalProps extends React.PropsWithChildren<{}> {
	open: boolean;
	onClose: () => void;
	message?: string;
	pricingsIds?: { tender_id: number; id: number }[];
}

export default function ErrorModal(props: ErrorModalProps) {
	const { open, onClose, message, pricingsIds } = props;
	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
				PaperProps={{ sx: style.dialog }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Error :"}</DialogTitle>
				<DialogContent>
					<DialogContentText
						sx={style.dialogText}
						id="alert-dialog-description"
					>
						{message}
					</DialogContentText>
					<DialogContentText
						sx={style.dialogText}
						id="alert-dialog-description"
						component="div"
					>
						{pricingsIds?.map(
							(pid: { tender_id: number; id: number }) => (
								<Typography key={pid.id}>
									P_ID: {pid.id}, Tender ID: {pid.tender_id},
									Pricing Details:{" "}
									<Link
										onClick={() => onClose()}
										to={getPathWithParameters(
											PATH.TENDER_PRICING_RUN,
											{ tenderId: pid.tender_id }
										)}
									>
										{"here"}
									</Link>
								</Typography>
							)
						)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
