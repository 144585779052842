import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
	SxProps,
	Theme,
	SelectChangeEvent,
} from "@mui/material";

interface CustomSelectProps extends SelectProps {
	onChange?: (
		event: SelectChangeEvent<unknown>,
		child: React.ReactNode
	) => void;
	value: string | number;
	error?: boolean;
	errorText?: string;
	touched?: boolean;
	name: string;
	label: string;
	items: { key: string | number; value: string | number }[];
	sx?: SxProps<Theme>;
	sxFC?: SxProps<Theme>;
	Icon?: (props: any) => JSX.Element | null;
	datatestId?: string;
}

function CustomSelect(props: CustomSelectProps) {
	const {
		value,
		error,
		touched,
		name,
		label,
		onChange,
		items,
		errorText,
		sxFC,
		Icon,
		datatestId,
		...other
	} = props;

	return (
		<FormControl
			fullWidth
			size="small"
			error={!!error && !!touched}
			sx={sxFC}
		>
			<InputLabel>{label}</InputLabel>
			<Select
				datatest-id={datatestId}
				{...other}
				name={name}
				value={value}
				label={label}
				onChange={onChange}
				error={!!error && !!touched}
			>
				{items.map(
					(item: {
						key: string | number;
						value: string | number;
						label?: string;
					}) => (
						<MenuItem key={item.key} value={item.value}>
							{Icon && (
								<>
									<Icon item={item.value} />
									{"\u00A0\u00A0"}
								</>
							)}

							{item.label ? item.label : item.value}
						</MenuItem>
					)
				)}
			</Select>
			{!!error && touched && <FormHelperText>{errorText}</FormHelperText>}
		</FormControl>
	);
}

export default CustomSelect;
