import { Backdrop, CircularProgress } from "@mui/material";

export const OverlayLoader = () => {
	return (
		<Backdrop
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={true}
			invisible={true}
		>
			<CircularProgress color="primary" />
		</Backdrop>
	);
};
