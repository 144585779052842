import { useMemo } from "react";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { Pricing, PricingRun, PricingStatus } from "../../pricingListSlice";
import {
	pricingHasFixedPrice,
	pricingHasMargin,
} from "../../pricingList.model";
import { ColumnIds } from "./common";
import * as _ from "lodash";

export const pricingListColumns: Column<ColumnIds>[] = [
	{ label: "GR ID", id: ColumnIds.PGID },
	{ label: "P ID", id: ColumnIds.PID },
	{ label: "PR ID", id: ColumnIds.PRID },
	{ label: "cluster", id: ColumnIds.TOURBILLON_REF },
	{ label: "status", id: ColumnIds.PR_STATUS },
	{ label: "site/port", id: ColumnIds.PR_SITE },
	{ label: "result", id: ColumnIds.PR_ALPHA_OR_BETA },
	{ label: "type name", id: ColumnIds.P_TYPE_NAME },
	{ label: "technology", id: ColumnIds.P_TECHNO_NAME },
	{ label: "pricing date", id: ColumnIds.PRICING_DATE },
	{ label: "pricing validity", id: ColumnIds.P_VALIDITY_DATE },
	{ label: "Party / Book", id: ColumnIds.P_PARTY },
	{ label: "Base", id: ColumnIds.P_BASE_CAPA },
	{ label: "clickability", id: ColumnIds.CLICKABILITY },
	{ label: "Click granularity", id: ColumnIds.CLICKABILITY_GRANULARITY },
	{ label: "delivery period", id: ColumnIds.DELIVERY_PERIOD },
	{ label: "installed capacity", id: ColumnIds.P_INSTALL_CAPA },
	{ label: "chapter 51", id: ColumnIds.P_CHAPTER_51 },
	{ label: "customer p50", id: ColumnIds.CUSTOMER_P50 },
	{ label: "p50", id: ColumnIds.P50 },
	{ label: "proxy", id: ColumnIds.PROXY },
	{ label: "lf", id: ColumnIds.LF },
	{ label: "qty", id: ColumnIds.QTY },
	{ label: "cross", id: ColumnIds.CROSS },
	{ label: "goo_cross", id: ColumnIds.GOO_CROSS },
	{ label: "goo_adj", id: ColumnIds.GOO_ADJ },
	{ label: "xbc", id: ColumnIds.XBC },
	{ label: "xpc", id: ColumnIds.XPC },
	{ label: "xdpc", id: ColumnIds.XDPC },
	{ label: "p50 prov", id: ColumnIds.P50_PROV },
	{ label: "shape", id: ColumnIds.SHAPE },
	{ label: "margin prov", id: ColumnIds.MARGIN_PROV },
	{ label: "Setup fee", id: ColumnIds.SETUPFEE },
	{ label: "Imbalance cost provision", id: ColumnIds.IMBALANCECOSTPROVISION },
	{ label: "Profile cost provision", id: ColumnIds.PROFILECOSTPROVISION },
	{
		label: "Delta profile cost provision",
		id: ColumnIds.DELTAPROFILECOSTPROVISION,
	},
	{ label: "Shape provision", id: ColumnIds.SHAPEPROVISION },
	{ label: "Shape Bid Ask provision", id: ColumnIds.SHAPEBIDASKPROVISION },
	{ label: "goo_price", id: ColumnIds.GOO_PRICE },
	{ label: "margin provision", id: ColumnIds.MARGIN_PROVISION },
	{ label: "alpha", id: ColumnIds.ALPHA },

	{ label: "Beta contract", id: ColumnIds.PR_BETA },

	{ label: "indic", id: ColumnIds.INDIC },

	{ label: "CM", id: ColumnIds.PR_CM },
	{ label: "Alpha Client", id: ColumnIds.PR_ALPHACLIENT },
	{ label: "Indic Client", id: ColumnIds.PR_INDICCLIENT },

	{ label: "Negative price", id: ColumnIds.NEGATIVEPRICEVALUE },

	{ label: "Fixing Date Time (UTC)", id: ColumnIds.PR_FIXING_DT },
	{ label: "Baseload Price", id: ColumnIds.PR_BASELOAD_PRICE },
	{ label: "Baseload Mid", id: ColumnIds.PR_BASELOAD_MID },
	{ label: "Fixing CM", id: ColumnIds.PR_COMMERCIAL_MARGIN },
	{ label: "Goo Commercial Margin", id: ColumnIds.FIXINGGOOCOMMERCIALMARGIN },
	{ label: "Fix Price", id: ColumnIds.PR_FIXPRICE },
	{ label: "Goo Final Price", id: ColumnIds.FINALGOOPRICE },

	{ label: "Fix Beta", id: ColumnIds.PR_BETAFIX },
	{ label: "Hedge Ratio %", id: ColumnIds.HEDGERATIOPERCENT },
	{ label: "Contract Id", id: ColumnIds.PR_CONTRACT_ID },
	{ label: "Warning", id: ColumnIds.CLEANING_WARNING },
];

export function useAvailablePricingListColumns(pricings?: Pricing[] | null) {
	const showFixPriceMargin = useMemo(
		() => (pricings?.filter(pricingHasFixedPrice).length || 0) > 0,
		[pricings]
	);

	const showMarginColumns = useMemo(
		() => (pricings?.filter(pricingHasMargin).length || 0) > 0,
		[pricings]
	);

	const showContractId = useMemo(
		() =>
			(pricings?.filter((p) => p.status === PricingStatus.BOOKED)
				.length || 0) > 0,
		[pricings]
	);

	const showBetaContractColumn = useMemo(
		() => (pricings?.filter((p: Pricing) => p.beta).length || 0) > 0,
		[pricings]
	);

	const showFixBetaColumn = useMemo(
		() =>
			(pricings?.filter(
				(p: Pricing) =>
					p.pricing_runs.filter(
						(pr: PricingRun) => pr.summary?.BetaFix?.value
					).length > 0
			).length || 0) > 0,
		[pricings]
	);

	const showWarningColumn = useMemo(
		() => _.some(pricings, (pricing) => !!pricing.timeserie?.warning),
		[pricings]
	);

	const mappedColumns = useMemo(() => {
		return pricingListColumns.map((column) => {
			if (
				[
					ColumnIds.PR_CM,
					ColumnIds.PR_ALPHACLIENT,
					ColumnIds.PR_INDICCLIENT,
				].includes(column.id)
			) {
				return { ...column, disabled: !showMarginColumns };
			}
			if (
				[
					ColumnIds.PR_FIXING_DT,
					ColumnIds.PR_BASELOAD_PRICE,
					ColumnIds.PR_BASELOAD_MID,
					ColumnIds.PR_COMMERCIAL_MARGIN,
					ColumnIds.PR_FIXPRICE,
				].includes(column.id)
			) {
				return { ...column, disabled: !showFixPriceMargin };
			}
			if ([ColumnIds.PR_CONTRACT_ID].includes(column.id)) {
				return { ...column, disabled: !showContractId };
			}
			if ([ColumnIds.PR_BETA].includes(column.id)) {
				return { ...column, disabled: !showBetaContractColumn };
			}
			if ([ColumnIds.PR_BETAFIX].includes(column.id)) {
				return { ...column, disabled: !showFixBetaColumn };
			}
			if (column.id === ColumnIds.CLEANING_WARNING) {
				return { ...column, disabled: !showWarningColumn };
			}
			return { ...column, disabled: false };
		});
	}, [
		showFixPriceMargin,
		showMarginColumns,
		showContractId,
		showBetaContractColumn,
		showFixBetaColumn,
		showWarningColumn,
	]);

	return mappedColumns;
}
