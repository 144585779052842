import {
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	SxProps,
	Theme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	selectIsFiltersDrawerOpened,
	selectPersistedFilters,
} from "./filters.selector";
import {
	setFiltersDrawerOpened,
	setPersistedFilters,
	setLinqPredicates,
	setPricingsOdataQuery,
	setTendersOdataQuery,
} from "./filtersSlice";
import useFetchFilters from "../../common/hooks/useFetchFilters";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import FilterRow from "./FilterRow";
import { PrimaryButton } from "../../common/components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";

import * as yup from "yup";
import { buildPredicates, setSelectedFilterValues } from "./utils";
import { Filter, FilterType } from "../metadata/metadata.module";

const style: { [key: string]: SxProps<Theme> } = {
	drawer: {
		width: 400,
	},
	list: {
		width: "100%",
		maxWidth: 389,
		bgcolor: "background.paper",
	},
	logout: {
		color: "red",
	},
	header: {
		color: "#171D21",
		fontSize: 28,
		weight: 400,
	},
};

interface FilterProps {
	page: string;
	id?: number;
	filtersResetAt?: Date;
	setSelectedtemplate?: React.Dispatch<SetStateAction<string | null>>;
}

export default function FiltersDrawer(props: FilterProps) {
	const isOpened = useSelector(selectIsFiltersDrawerOpened);
	const page = useMemo(() => props.page, [props.page]);
	const dispatch = useDispatch();
	const { data: filtersData } = useFetchFilters(page, props.id);
	const persistedFilters = useSelector(selectPersistedFilters);
	const [initialFilterValues, setInitialFilterValues] = useState<{
		[k: string]: any;
	}>({});

	// Browse filters and create formik bag accordingly with blank values
	const blankFilterValues = useMemo(() => {
		let obj: { [k: string]: any } = {};
		if (filtersData) {
			filtersData.forEach((f: Filter) => {
				if (f.filter_type === FilterType.alphanumeric) {
					obj[`${f.field_key}`] = "";
				} else {
					if (f.filter_type === FilterType.numeric) {
						obj[`${f.field_key}`] = [null, null];
					} else {
						obj[`${f.field_key}`] = null;
					}
				}
			});
		}

		return obj;
	}, [filtersData]);

	const submitFilters = (values: any, close: boolean) => {
		const { predicates, linqPredicates } = buildPredicates(
			filtersData,
			values,
			page
		);
		const odataString = `"${predicates.join(" and ")}"`;
		if (page === "Tenders") {
			dispatch(setTendersOdataQuery({ query: odataString }));
		}
		if (page === "Pricings") {
			dispatch(
				setPricingsOdataQuery({
					query: odataString,
				})
			);
		}
		const filtersToPersist = JSON.stringify(values);

		const filterTemplate = close
			? "CUSTOM"
			: persistedFilters.filters?.tenders_filter_template;

		if (page === "Tenders") {
			dispatch(
				setPersistedFilters({
					...persistedFilters.filters,
					tenders_filter_template: filterTemplate,
					tenders_filters: filtersToPersist,
					tenders_filters_count: predicates.length.toString(),
					tenders_query: odataString,
				})
			);
		}
		if (page === "Pricings") {
			dispatch(
				setPersistedFilters({
					...persistedFilters.filters,
					pricings_filters: filtersToPersist,
					pricings_filters_count: predicates.length.toString(),
					pricings_query: odataString,
				})
			);
		}

		dispatch(
			setLinqPredicates({
				page,
				predicates: linqPredicates,
			})
		);

		if (close) {
			dispatch(
				setFiltersDrawerOpened({
					opened: false,
				})
			);

			if (props.setSelectedtemplate) {
				props.setSelectedtemplate("CUSTOM");
				if (page === "Tenders") {
					dispatch(
						setPersistedFilters({
							...persistedFilters.filters,
							tenders_filter_template: "CUSTOM",
							tenders_filters: filtersToPersist,
							tenders_filters_count: predicates.length.toString(),
							tenders_query: odataString,
						})
					);
				}
				if (page === "Pricings") {
					dispatch(
						setPersistedFilters({
							...persistedFilters.filters,
							tenders_filter_template: "CUSTOM",
							tenders_filters: filtersToPersist,
							tenders_filters_count: predicates.length.toString(),
							tenders_query: odataString,
						})
					);
				}
			}
		}
	};

	useEffect(() => {
		if (!page) {
			return;
		}

		if (!filtersData.length) {
			return;
		}

		const tenderFilters = persistedFilters.filters?.tenders_filters ?? "";
		const pricingFilters = persistedFilters.filters?.pricings_filters ?? "";

		const obj = setSelectedFilterValues(
			filtersData,
			page === "Tenders" ? tenderFilters : pricingFilters
		);
		if (obj) {
			setInitialFilterValues(obj);
		} else {
			setInitialFilterValues({ ...blankFilterValues });
		}
		if (props.filtersResetAt) {
			submitFilters(obj, false);
		}
	}, [
		filtersData,
		page,
		isOpened,
		props.filtersResetAt,
		persistedFilters.filters?.tenders_filters,
		persistedFilters.filters?.pricings_filters,
	]);

	return (
		<Drawer
			anchor={"left"}
			open={isOpened}
			onClose={() => dispatch(setFiltersDrawerOpened({ opened: false }))}
			PaperProps={{
				sx: style.drawer,
			}}
		>
			<List
				sx={style.list}
				component="nav"
				aria-labelledby="filters"
				subheader={
					<ListSubheader
						sx={style.header}
						component="div"
						id="filters-header"
					>
						{page} filters
						<ListItemSecondaryAction
							onClick={() =>
								dispatch(
									setFiltersDrawerOpened({
										opened: false,
									})
								)
							}
						>
							<IconButton edge="end" aria-label="delete">
								<CloseIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListSubheader>
				}
			>
				<Formik
					initialValues={initialFilterValues}
					validationSchema={() => {
						const validationObject: any = {};
						if (filtersData) {
							filtersData.forEach((f: Filter) => {
								if (f.filter_type === FilterType.numeric) {
									validationObject[f.field_key] = yup
										.array()
										.test(
											"is-valid-range",
											"Not a valid range",
											(value) => {
												if (!value) {
													return true;
												}
												if (value.length < 2) {
													return true;
												}

												if (value[0] && value[1]) {
													const val1 = Number(
														value[0]
													);
													const val2 = Number(
														value[1]
													);
													return val1 <= val2;
												}

												return true;
											}
										)
										.nullable();
								}
							});
						}
						return yup.object(validationObject);
					}}
					enableReinitialize={true}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={(values) => {
						submitFilters(values, true);
					}}
				>
					{({ handleSubmit, setValues }) => (
						<form onSubmit={handleSubmit}>
							{filtersData.map((filter: Filter) => {
								return (
									<ListItem key={filter.field_key}>
										<ListItemText
											primary={
												<h6>{filter.display_name}</h6>
											}
											secondary={
												<FilterRow filter={filter} />
											}
										/>
									</ListItem>
								);
							})}
							<ListItem key="submit">
								<ListItemText
									primary={
										<>
											<PrimaryButton
												data-testid="apply-filters"
												text="Apply filters"
												type="submit"
												color="primary"
												sx={{
													width: 130,
												}}
											/>
											<PrimaryButton
												text="Reset"
												type="button"
												color="secondary"
												sx={{
													width: 130,
													marginLeft: "10px",
												}}
												onClick={() => {
													setValues(
														blankFilterValues
													);
												}}
											/>
										</>
									}
								/>
							</ListItem>
						</form>
					)}
				</Formik>
			</List>
		</Drawer>
	);
}
