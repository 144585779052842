import { ChangeEventHandler } from "react";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";
import { white } from "../../core/theme";
import { SystemProps } from "@mui/system";

const style: { [key: string]: SxProps<Theme> } = {
	customInput: {
		bgcolor: white,
		"& div": {
			borderRadius: 0,
		},
	},
};

interface CustomInputProps extends StandardTextFieldProps {
	errorText?: string;
	touched?: boolean;
	onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	sx?: SxProps<Theme>;
	["data-testid"]?: string;
}

function CustomInput(props: CustomInputProps) {
	const { errorText, touched, value, sx, ...other } = props;
	return (
		<TextField
			{...other}
			size="small"
			id={props.id}
			value={value}
			sx={{ ...(style.customInput as SystemProps<Theme>), ...sx }}
			variant="outlined"
			placeholder={props.placeholder}
			onChange={props.onChange}
			error={!!errorText && touched}
			helperText={!!errorText && touched ? errorText : ""}
		/>
	);
}

export default CustomInput;
