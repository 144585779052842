import { Box } from "@mui/material";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import Modal from "../../../../common/components/Modal";
import { ReactComponent as DetailsIcon } from "../../../../common/assets/icons/details.svg";
import { ReactComponent as HistoryIcon } from "../../../../common/assets/icons/history.svg";
// import { ReactComponent as EditIcon } from "../../../../common/assets/icons/edit.svg";
import { CurtailmentObjectGroupType } from "./CurtailmentObject.types";
import Tag from "../../../../common/components/Tag/Tag";
import { style } from "./CurtailmentObject.style";
import { CurtailmentObjectSubtypeType } from "../../../../requests_geco/contractsApi/contractsApi.types";
import { formatStringDate } from "../../../../common/utils/dateUtils";
import CurtailmentDataList from "./CurtailmentDataList";
import { CurtailmentObjectType } from "../../../../requests_geco/sitesApi/sitesApi.types";

interface CurtailmentObjectDetailsModalProps {
	curtailmentKind: string;
	curtailmentObjectGroup: CurtailmentObjectGroupType;
	curtailmentObjects: CurtailmentObjectType[];
	setOpenModal: (open: null) => void;
	setOpenAuditModal: (open: string | null) => void;
	openModal: boolean;
}

const CurtailmentObjectDetailsModal = ({
	curtailmentKind,
	curtailmentObjectGroup,
	curtailmentObjects,
	setOpenModal,
	setOpenAuditModal,
	openModal,
}: CurtailmentObjectDetailsModalProps) => {
	return (
		<Modal
			onClose={() => setOpenModal(null)}
			open={openModal}
			title={
				<Box sx={style.modalTitle}>
					<DetailsIcon />
					{curtailmentObjectGroup.site}
				</Box>
			}
			actions={
				<>
					<PrimaryButton
						onClick={() => {
							setOpenModal(null);
							setOpenAuditModal(curtailmentKind);
						}}
						text="Audit"
						type="button"
						color="secondary"
						startIcon={<HistoryIcon />}
					/>
					{/*<PrimaryButton
						onClick={() => {}}
						startIcon={<EditIcon />}
						text="Edit"
						type="button"
						color="secondary"
					/>*/}
					<PrimaryButton
						onClick={() => setOpenModal(null)}
						text="Close"
						type="button"
						color="primary"
					/>
				</>
			}
		>
			<Tag label={curtailmentKind} />
			<Box sx={style.curtailmentObjectModalDetailsWrapper}>
				{curtailmentObjects.map((curtailmentObject) => (
					<CurtailmentDataList
						key={curtailmentObject.id}
						wrapperSx={style.curtailmentObjectModalDetails(
							curtailmentObject.curtailment_object_subtype
						)}
						list={[
							{
								value: (
									<Box
										sx={{
											...style.cartridge,
											...(curtailmentObject.curtailment_object_subtype ===
											CurtailmentObjectSubtypeType.GRID
												? style.brandCartridge
												: {}),
										}}
									>
										{
											curtailmentObject.curtailment_object_subtype
										}
									</Box>
								),
							},
							{
								label: "Start Date",
								value: formatStringDate(
									curtailmentObject.start_date,
									"yyyy-MM-dd"
								),
							},
							{
								label: "End Date",
								value: formatStringDate(
									curtailmentObject.end_date,
									"yyyy-MM-dd"
								),
							},
							{
								label: "Pricing Method",
								value: curtailmentObject.pricing_method,
							},
							{
								label: "Commodity Index",
								value: curtailmentObject.commodity_index,
							},
							{
								label: "Compensation Method",
								value: curtailmentObject.compensation_method,
							},
							{
								label: "BCV Config",
								value: curtailmentObject.bcv_config,
							},
							{
								label: "Compensation Deal Type",
								value: curtailmentObject.compensation_deal_type,
							},
							{
								label: "Is Compensable",
								value: curtailmentObject.is_compensable
									? "Yes"
									: "No",
							},
							{
								label: "Counterpart",
								value: curtailmentObject.counterpart_kind,
							},
							{
								label: "Curtailment Name",
								value: curtailmentObject.name,
							},
						]}
					/>
				))}
			</Box>
		</Modal>
	);
};

export default CurtailmentObjectDetailsModal;
