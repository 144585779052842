import _ from "lodash";
import { ContractPeriodType } from "../../../requests_geco/contractsApi/contractsApi.types";

export function getContractPeriodFromId(
	contractPeriods: ContractPeriodType[],
	id: number
): ContractPeriodType | undefined {
	return _.find(contractPeriods, { id });
}

export function getContractPeriodIndexFromId(
	contractPeriods: ContractPeriodType[],
	id: number
): number {
	return _.findIndex(contractPeriods, { id });
}
