export const saveObjectAsJSON = (filename: string, dataObjToWrite: {}) => {
	const blob = new Blob([JSON.stringify(dataObjToWrite, undefined, 2)], {
		type: "text/json",
	});
	const link = document.createElement("a");

	link.download = filename + ".json";
	link.href = window.URL.createObjectURL(blob);
	link.dataset.downloadurl = ["text/json", link.download, link.href].join(
		":"
	);

	const evt = new MouseEvent("click", {
		view: window,
		bubbles: true,
		cancelable: true,
	});

	link.dispatchEvent(evt);
	link.remove();
};
