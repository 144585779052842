import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { If } from "../../../common/components/If";
import { PrimaryButton } from "../../../common/components/CustomButton";

interface AfterCreationType {
	title: string;
	content?: string;
	isOpen: boolean;
	handleClose: () => void;
	handleApprove: () => void;
}
export default function AfterCreationModal(props: AfterCreationType) {
	const { isOpen, content, title, handleClose, handleApprove } = props;
	return (
		<div data-testid="after-creation-modal">
			<Dialog onClose={handleClose} open={isOpen}>
				<DialogTitle>{title}</DialogTitle>
				<If condition={content}>
					<DialogContent>
						<DialogContentText>{content}</DialogContentText>
					</DialogContent>
				</If>
				<DialogActions>
					<PrimaryButton
						onClick={handleClose}
						text="Go to blotter"
						type="button"
						color="primary"
					></PrimaryButton>
					<PrimaryButton
						onClick={handleApprove}
						text="Create pricings"
						type="button"
						color="primary"
					></PrimaryButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}
