import { Pricing } from "../../features/pricing_list/pricingListSlice";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import PlaceIcon from "@mui/icons-material/Place";

export const SiteLogoOrPortfolioLogo = (props: { pricing?: Pricing }) => {
	if (props.pricing?.portfolio_id && props.pricing?.portfolio) {
		return <BookmarksIcon />;
	}
	return <PlaceIcon />;
};
