import { Tooltip } from "@mui/material";
import { SiteProxyGenerationStatus } from "../../features/sites/sites.module";
import RefreshIcon from "@mui/icons-material/Refresh";

export function formatProxyGenerationStatus(
	status?: SiteProxyGenerationStatus | null,
	errors?: string | null,
	handleRefresh?: () => void
) {
	const errorMessage = `${errors} click to refresh`;

	switch (status) {
		case SiteProxyGenerationStatus.GENERATED:
			return "Generated";
		case SiteProxyGenerationStatus.IN_PROGRESS:
			return "In progress";
		case SiteProxyGenerationStatus.FAILURE:
			return (
				<div>
					Failure
					<Tooltip
						sx={{ marginLeft: "3px", marginTop: "-3px" }}
						title={errorMessage}
					>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => handleRefresh && handleRefresh()}
						>
							<RefreshIcon />
						</span>
					</Tooltip>
				</div>
			);

		default:
			return "";
	}
}
