import AlertDialogModal from "../../../common/components/AlertDialogModal";
import { Action } from "../../pricing_list/pricingListSlice";
import { ValidationError } from "../../../common/hooks/usePydanticErrorsFormat";
import { Tender } from "../tender.module";
import { Formik, FormikProps, FormikValues } from "formik";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CustomInput from "../../../common/components/CustomInput";
import { If } from "../../../common/components/If";
import { useRef } from "react";

interface ActionTenderModalProps {
	open: boolean;
	onClose: () => void;
	tender: Tender;
	submitModal: (body: Record<string, string | number | boolean>) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

export function ActionTenderModal(props: ActionTenderModalProps) {
	const { action, submitModal, tender, onClose, open } = props;
	const formRef = useRef<FormikProps<FormikValues>>();
	if (props.action?.fields?.hasOwnProperty("confirmationCancel")) {
		return (
			<AlertDialogModal
				title={
					props.action?.fields?.confirmationCancel?.title as string
				}
				isOpen={props.open}
				handleApprove={() => {
					submitModal({
						endpoint: props.action?.endpoint as string,
						tender_id: tender.id,
						action: action?.action as string,
					});
				}}
				handleClose={props.onClose}
			/>
		);
	}

	return (
		<Dialog
			PaperProps={{ style: { minHeight: "28%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "45%" } }}
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
		>
			<DialogTitle>{action?.display_name}</DialogTitle>
			<DialogContent
				sx={{
					marginTop: "24px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Formik
					key={"formik-action-pricing-modal"}
					//@ts-ignore
					innerRef={formRef}
					initialValues={{}}
					enableReinitialize={true}
					onSubmit={async (values) => {
						submitModal({
							endpoint: action?.endpoint as string,
							action: action?.action as string,
							tender_id: tender.id,
							...values,
						});
					}}
				>
					{({ handleChange, handleSubmit, values }) => {
						return (
							<form
								key={"form-action-pricing-modal"}
								onSubmit={handleSubmit}
							>
								{Object.entries(action?.fields || {}).map(
									(value, index) => (
										<>
											<Box
												key={`${value[0]}_container`}
												sx={{
													display: "flex",
												}}
											>
												{value[1].component ==
													"input" && (
													<CustomInput
														key={value[0] + "input"}
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	{
																		value[1]
																			.label
																	}
																</InputAdornment>
															),
															type: value[1].type,
														}}
														rows={3}
														name={value[0]}
														autoFocus={index === 0}
														placeholder={
															value[1].title
														}
														fullWidth
														onChange={handleChange}
														variant="standard"
														// @ts-ignore
														value={values[value[0]]}
														// @ts-ignore
														errorText={""}
														touched={true}
													/>
												)}
											</Box>
										</>
									)
								)}
							</form>
						);
					}}
				</Formik>
				<DialogActions>
					<Button onClick={() => onClose()}>Close</Button>
					<If condition={!!submitModal}>
						<Button
							onClick={() => {
								if (formRef.current) {
									formRef.current.submitForm();
								}
							}}
						>
							Submit
						</Button>
					</If>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
