import { Box, CircularProgress, DialogActions } from "@mui/material";
import { PrimaryButton } from "../../common/components/CustomButton";
import { If } from "../../common/components/If";
import FluidMessageBox from "../../common/components/FluidMessageBox/FluidMessageBox";
import { ErrorText } from "../../common/components/ErrorText";

export interface UpdateResultStepProps {
	onGoBack: () => void;
	onClose: () => void;
	isLoading: boolean;
	isSuccess: boolean;
	errorMessage?: string | string[];
}

const UpdateResultStep = ({
	onGoBack,
	onClose,
	isLoading,
	isSuccess,
	errorMessage,
}: UpdateResultStepProps) => {
	const style = {
		container: {
			width: "100%",
		},
		actionContainer: {
			width: "100%",
		},
		loaderContainer: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	};
	return (
		<Box sx={style.container}>
			<If condition={isLoading}>
				<Box sx={style.loaderContainer}>
					<CircularProgress />
				</Box>
			</If>
			<If condition={!isLoading}>
				<FluidMessageBox
					variant={isSuccess ? "success" : "error"}
					message={errorMessage ? "" : "You can now close this modal"}
					title={
						isSuccess
							? "Horizon Change has been updated successfully"
							: "We encounter an issue while updating horizon change"
					}
				>
					<ErrorText>{errorMessage}</ErrorText>
				</FluidMessageBox>
				<Box sx={style.actionContainer}>
					<DialogActions>
						<PrimaryButton
							disabled={isSuccess}
							onClick={onGoBack}
							text={"Back"}
							type="button"
							color="secondary"
						></PrimaryButton>
						<PrimaryButton
							text={"Close"}
							onClick={onClose}
							type="button"
							color="primary"
						></PrimaryButton>
					</DialogActions>
				</Box>
			</If>
		</Box>
	);
};

export default UpdateResultStep;
