import { Box } from "@mui/material";
import Modal from "../../../../../../common/components/Modal";

import { PrimaryButton } from "../../../../../../common/components/CustomButton";
import {
	colorPaletteBlue600,
	textNeutralTertiary,
} from "../../../../../../core/theme";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { InputNumber } from "rsuite";

const CopyNumberModal = ({
	numberOfAssets,
	setAssetsNumber,
	open,
	onClose,
	onConfirm,
}: {
	numberOfAssets: number;
	setAssetsNumber: (AssetsNumber: number) => void;
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
}) => {
	return (
		<Modal
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<AddCircleOutlineIcon sx={{ color: colorPaletteBlue600 }} />{" "}
					Add asset(s)
				</Box>
			}
			open={open}
			onClose={onClose}
			actions={
				<>
					<PrimaryButton
						onClick={onClose}
						text="Cancel"
						type="button"
						color="secondary"
					/>
					<PrimaryButton
						onClick={onConfirm}
						text="Confirm"
						type="button"
						color="primary"
					/>
				</>
			}
		>
			Please edit below the of time you want to copy the item:
			<Box sx={{ width: "150px" }}>
				<InputNumber
					min={1}
					value={numberOfAssets}
					onChange={(nextValue) => setAssetsNumber(Number(nextValue))}
				/>
				<Box sx={{ color: textNeutralTertiary }}>Minimum: 1</Box>
			</Box>
		</Modal>
	);
};

export default CopyNumberModal;
