import {
	addMonths,
	addYears,
	format,
	isBefore,
	setDate,
	setMonth,
} from "date-fns";
import { ContractPeriodType } from "../../../../../requests_geco/contractsApi/contractsApi.types";
import { TypeIdOptionListType } from "./ClickModal.types";

import {
	isAfterOrEqual,
	isBeforeOrEqual,
	isBetween,
} from "../../../../../common/utils/dateUtils";
import {
	ClickPeriodFrequencyLabels,
	ClickTypesResponseType,
} from "../../../../../requests_geco/referentialApi/referentialApi.types";

export const getClickTypeIdFromLabel = (
	label: string,
	clickTypes: ClickTypesResponseType[]
): string | null =>
	clickTypes.find((ct) => label === ct.ot_name || label === ct.name)?.id ??
	null;

export const firstDateSelectableForClick = (
	contractPeriod: ContractPeriodType,
	clickTypes: ClickTypesResponseType[]
): Date => {
	const contractPeriodStartDate = new Date(contractPeriod.start_date);
	const clickTypeId = getClickTypeIdFromLabel(
		contractPeriod?.clickability?.clickability_information
			?.click_period_frequency,
		clickTypes
	);

	let defaultStartDate = new Date(contractPeriod.start_date);

	if (String(clickTypeId) === ClickPeriodFrequencyLabels.MONTHLY) {
		defaultStartDate = setDate(addMonths(new Date(), 1), 1);
	}
	if (String(clickTypeId) === ClickPeriodFrequencyLabels.QUARTERLY) {
		defaultStartDate = setDate(
			setMonth(new Date(), Number(format(new Date(), "q")) * 3),
			1
		);
	}
	if (String(clickTypeId) === ClickPeriodFrequencyLabels.YEARLY) {
		defaultStartDate = setDate(setMonth(addYears(new Date(), 1), 0), 1);
	}

	return isBefore(defaultStartDate, contractPeriodStartDate)
		? contractPeriodStartDate
		: defaultStartDate;
};

export const lastDateSelectableForClick = (
	contractPeriod: ContractPeriodType,
	clickTypes: ClickTypesResponseType[]
): Date => {
	const startDate = firstDateSelectableForClick(contractPeriod, clickTypes);

	const clickTypeId = getClickTypeIdFromLabel(
		contractPeriod?.clickability?.clickability_information
			?.click_period_frequency,
		clickTypes
	);

	let defaultEndDate = new Date(contractPeriod.end_date);

	if (String(clickTypeId) === ClickPeriodFrequencyLabels.MONTHLY) {
		defaultEndDate = addMonths(startDate, 1);
	}
	if (String(clickTypeId) === ClickPeriodFrequencyLabels.QUARTERLY) {
		defaultEndDate = addMonths(startDate, 3);
	}
	if (String(clickTypeId) === ClickPeriodFrequencyLabels.YEARLY) {
		defaultEndDate = addYears(startDate, 1);
	}

	return defaultEndDate;
};

export const clickSelectYears = (
	contractPeriod: ContractPeriodType,
	clickTypes: ClickTypesResponseType[]
): { label: string; value: string }[] => {
	if (!contractPeriod.end_date || !contractPeriod.start_date) {
		return [];
	}

	const startDate = firstDateSelectableForClick(contractPeriod, clickTypes);

	const startYear = Number(format(startDate, "yyyy"));
	const endYear = Number(format(new Date(contractPeriod.end_date), "yyyy"));

	return Array.from({ length: endYear - startYear + 1 }, (_, index) => ({
		label: String(startYear + index),
		value: String(startYear + index),
	}));
};

const clickButtonsYears = (
	contractPeriod: ContractPeriodType,
	clickTypes: ClickTypesResponseType[]
): { label: string; value: string }[] => {
	const validYears = clickSelectYears(contractPeriod, clickTypes);

	return validYears
		.filter((year) => {
			return (
				isAfterOrEqual(
					new Date(Number(year.value), 0, 1),
					firstDateSelectableForClick(contractPeriod, clickTypes)
				) &&
				isBeforeOrEqual(
					new Date(Number(year.value), 11, 31),
					new Date(contractPeriod.end_date)
				)
			);
		})
		.map((year) => ({
			label: year.label,
			value: `${new Date(Number(year.value), 0, 1)}#${new Date(
				Number(year.value),
				11,
				31
			)}`,
		}));
};

const isDateValid = (date: Date, contractPeriod: ContractPeriodType) =>
	isBetween(
		date,
		new Date(contractPeriod.start_date),
		new Date(contractPeriod.end_date)
	) && isAfterOrEqual(date, new Date());

export const datesOptions = (
	contractPeriod: ContractPeriodType,
	year: string,
	clickTypes: ClickTypesResponseType[]
): TypeIdOptionListType => {
	if (!year) {
		return {};
	}

	const monthlyOptions = Array.from({ length: 12 }, (_, index) => {
		const date = new Date(Number(year), index, 1);

		const formattedMonth = {
			id: Number(format(date, "M")),
			label: format(date, "MMMM"),
		};

		const startDate = new Date(Number(year), formattedMonth.id - 1, 1);
		const endDate = new Date(Number(year), formattedMonth.id, 1);

		if (isDateValid(startDate, contractPeriod)) {
			const startDateValue = format(startDate, "yyyy-MM-dd");
			const endDateValue = format(endDate, "yyyy-MM-dd");

			return {
				label: formattedMonth.label,
				value: `${startDateValue}#${endDateValue}`,
			};
		} else {
			return {
				label: "",
				value: "invalid",
			};
		}
	}).filter((option) => {
		return option.value !== "invalid";
	});

	const quarterlyOptions = Array.from({ length: 4 }, (_, index) => {
		const startDate = new Date(Number(year), index * 3, 1);
		const endDate = addMonths(startDate, 3);

		if (isDateValid(startDate, contractPeriod)) {
			const startDateValue = format(startDate, "yyyy-MM-dd");
			const endDateValue = format(endDate, "yyyy-MM-dd");

			return {
				label: format(startDate, "qqqq"),
				value: `${startDateValue}#${endDateValue}`,
			};
		} else {
			return {
				label: "",
				value: "invalid",
			};
		}
	}).filter((option) => {
		return option.value !== "invalid";
	});

	const yearlyOptions = clickButtonsYears(contractPeriod, clickTypes).map(
		(yearItem) => {
			const valueStartDate = format(
				new Date(Number(year), 0, 1),
				"yyyy-MM-dd"
			);
			const valueEndDate = format(
				new Date(Number(year) + 1, 0, 1),
				"yyyy-MM-dd"
			);

			return {
				label: yearItem.label,
				value: `${valueStartDate}#${valueEndDate}`,
			};
		}
	);

	return {
		[ClickPeriodFrequencyLabels.MONTHLY]: monthlyOptions,
		[ClickPeriodFrequencyLabels.QUARTERLY]: quarterlyOptions,
		[ClickPeriodFrequencyLabels.YEARLY]: yearlyOptions,
	};
};
