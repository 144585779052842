import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	selectFiltersErrors,
	selectFiltersLoading,
	selectTenderFilters,
	selectPricingFilters,
} from "../../features/filters/filters.selector";
import { getMetadata } from "../../features/metadata/metadata.thunk";
import { useAppDispatch } from "./default";
import { Filter } from "../../features/metadata/metadata.module";

const useFetchFilters = (page: string, tenderId?: number) => {
	const dispatch = useAppDispatch();

	const [data, setdata] = useState<Filter[]>([]);
	const [loading, setloading] = useState(true);
	const [error, seterror] = useState<string | undefined>("");

	useEffect(() => {
		if (page && page === "Tenders") {
			dispatch(getMetadata(page));
		}
		if (page && page === "Pricings") {
			if (tenderId) {
				dispatch(getMetadata(page, tenderId));
			}
		}
	}, [page]);

	const tenderFilters = useSelector(selectTenderFilters);
	const pricingFilters = useSelector(selectPricingFilters);
	const errors = useSelector(selectFiltersErrors);
	const isLoading = useSelector(selectFiltersLoading);

	useEffect(() => {
		if (page === "Tenders") {
			setdata(tenderFilters);
		}
		if (page === "Pricings") {
			setdata(pricingFilters);
		}
	}, [tenderFilters, pricingFilters]);

	useEffect(() => {
		seterror(errors);
	}, [errors]);

	useEffect(() => {
		setloading(isLoading);
	}, [isLoading]);

	return { data, loading, error };
};

export default useFetchFilters;
