import {
	RenewexSubmission,
	UploadedFile,
} from "../../common/files/files.module";
import {
	Action,
	Pricing,
	TenderStatus,
} from "../pricing_list/pricingListSlice";
import { Site, Portfolio } from "../sites/sites.module";

export interface MasterSiteTimeserie {
	ts_type: "master_site";
	id: number;
	timeserie_name: string;
	cleaned: boolean;
	master_site: Site;
}

export interface SiteTimeserie {
	ts_type: "site";
	id: number;
	timeserie_name: string;
	cleaned: boolean;
	site: Site;
}

export interface RawTimeserie {
	ts_type: "raw";
	id?: number;
	timeserie_name: string;
	cleaned: boolean;
}

export type TenderTimeserie =
	| MasterSiteTimeserie
	| SiteTimeserie
	| RawTimeserie;

export function isMasterSiteTimeserie(
	it: TenderTimeserie
): it is MasterSiteTimeserie {
	return it.ts_type === "master_site";
}

export function isSiteTimeserie(it: TenderTimeserie): it is SiteTimeserie {
	return it.ts_type === "site";
}

export function isRawTimeserie(it: TenderTimeserie): it is RawTimeserie {
	return it.ts_type === "raw";
}

export interface Tender {
	id: number;
	name: string;
	creation_date: string;
	originator?: {
		first_name: string;
		last_name: string;
		gaia_id: string;
	};
	trader?: {
		first_name: string;
		last_name: string;
		gaia_id: string;
	};
	status: TenderStatus;
	capacity: string;
	p50: string;
	proxy: string;
	validity_date: string;
	feedbacks: string;
	account: {
		id: number;
		name: string;
		external_id: string;
	};
	installed_capacity_mw: number;
	countries: string[];
	site_count: number;
	count_attached_sites: number;
	technologies: string;
	due_date: string;
	earliest_pricing_expiration_date: string;
	direction: string;
	transaction_type: string;
	sites?: Site[];
	portfolios?: Portfolio[];
	historical_data: UploadedFile[];
	historical_data_count?: number;
	updated_at: string;
	created_at?: string;
	pricings?: Pricing[];
	actions: Action[];
	actions_blotter: Action[];
	isNewUpdate?: boolean;
	latest_renewex_submission?: RenewexSubmission;
	timeseries?: object;
	ts_list?: TenderTimeserie[];
	realized_data_will_be_added?: boolean;
	sites_info: { site_name: string; asset_id: string }[];
	cor_opportunity_id?: string;
	cor_sync_datetime?: string;
	pricing_stage?: string;
	graph_status: "NEW" | "PENDING" | "READY";
	graph_file_id?: number;
	graph_error?: string;
	has_negative_price_clause: boolean;
}
export interface TSData {
	sheetName?: string;
	assetId: string;
	fileId: number;
	id: string;
	productionCol: number;
	indexDate: number;
	unit: string;
	indexTime?: number;
	offset: number;
	timezone: string;
}
