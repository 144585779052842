import { Box, Typography } from "@mui/material";
import React from "react";
import { style } from "./PageTitle.style";
import { PageTitleProps } from "./PageTitle.types";

function PageTitle({
	label,
	leftSide,
	rightSide,
	sx,
	fontWeight,
	hideRightSide,
}: PageTitleProps) {
	return (
		<Box sx={{ ...style.container, ...sx }}>
			<Box sx={style.leftSideContainer}>
				{leftSide}
				<Typography
					variant="h2"
					component="h2"
					sx={{
						...style.label,
						...(fontWeight ? { fontWeight: fontWeight } : {}),
					}}
				>
					{label}
				</Typography>
			</Box>
			{!hideRightSide && rightSide}
		</Box>
	);
}

export default PageTitle;
