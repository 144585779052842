import { Formik } from "formik";
import PricingTab from "../PricingTab";
import {
	PricingFormik,
	pricingTabFormikInitialValueMapper,
} from "../formik/pricingFormik";
import {
	ContractDetailType,
	TradeType,
} from "../../../requests_geco/contractsApi/contractsApi.types";

export interface PricingTabFormProps {
	contract: ContractDetailType | undefined;
	contract_period_id: number;
	indexes: { label: string; value: string }[];
	tradeType: TradeType;
	onSubmit: (data: PricingFormik) => void;
}

const PricingTabForm = ({
	contract,
	contract_period_id,
	tradeType,
	indexes,
	onSubmit,
}: PricingTabFormProps) => {
	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={pricingTabFormikInitialValueMapper(
				contract,
				contract_period_id,
				tradeType
			)}
			onSubmit={onSubmit}
		>
			{({
				handleChange,
				setFieldValue,
				handleSubmit,
				values,
				errors,
				submitForm,
			}) => (
				<form onSubmit={handleSubmit}>
					<PricingTab
						indexes={indexes}
						errors={errors}
						values={values}
						setFieldValue={(field, value) => {
							setFieldValue(field, value);
							submitForm();
						}}
						handleChange={(event) => {
							handleChange(event);
							submitForm();
						}}
					/>
				</form>
			)}
		</Formik>
	);
};

export default PricingTabForm;
