import { ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTender } from "../../features/tender_form/tenderForm.thunk";
import { useDispatch, useSelector } from "react-redux";
import useIsAuthorized from "../hooks/useIsAuthorized";
import { selectTender } from "../../features/tender_form/tenderForm.selector";
import { OverlayLoader } from "./OverlayLoader";
import ForbiddenAccess from "./ForbiddenAccess";
import { selectAuthenticatedUser } from "../../features/authentication/auth.selector";
import { isUserAuthorizedToCreatePricings } from "../authorization/tender.authorization";
import { Tender } from "../../features/tender_page/tender.module";

interface TenderWrapperProps {
	children: ReactNode;
	isOwner?: boolean;
	allPortfolios?: boolean;
}

function TenderWrapper({
	children,
	isOwner = false,
	allPortfolios = false,
}: TenderWrapperProps) {
	const currentUser = useSelector(selectAuthenticatedUser);
	const { tenderId } = useParams();
	const dispatch = useDispatch();
	const tender = useSelector(selectTender);
	const isUserAutorized = useIsAuthorized<Tender>(
		isUserAuthorizedToCreatePricings,
		tender
	);

	useEffect(() => {
		if (!tenderId) {
			return;
		}
		dispatch(getTender(Number(tenderId), allPortfolios));
	}, [tenderId]);
	if (isOwner && tenderId) {
		if (!currentUser || !tender?.id) {
			return <OverlayLoader />;
		}
		if (!isUserAutorized) return <ForbiddenAccess />;
	}
	return <>{children}</>;
}

export default TenderWrapper;
