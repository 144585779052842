const style = {
	countdownTimer: ({ color, size }: { color: string; size: number }) => ({
		position: "relative",
		display: "flex",
		width: `${size}px`,
		height: `${size}px`,
		fontSize: `${size}px`,
		color,
	}),
	countdownSvg: {
		width: "inherit",
		height: "inherit",
		stroke: "currentColor",
	},
	countdownText: {
		width: "inherit",
		height: "inherit",
		position: "absolute",
		top: "0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "0.6em",
		color: "currentColor",
	},
};

export default style;
