import { useRtkQueryDynamicEndpoint } from "../../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useGetContractPeriodCurtailmentObjectsAuditsQuery } from "../../../../../requests_geco/contractsApi";
import CurtailmentObjectHistorySlider from "./CurtailmentObjectHistorySlider";
import { CircularProgress } from "@mui/material";

interface CurtailmentObjectHistorySliderContainerProps {
	curtailmentId: number;
	width: string;
}

const CurtailmentObjectHistorySliderContainer = ({
	curtailmentId,
	width,
}: CurtailmentObjectHistorySliderContainerProps) => {
	const { data, isLoading } = useRtkQueryDynamicEndpoint(
		useGetContractPeriodCurtailmentObjectsAuditsQuery
	)({ curtailment_object_id: curtailmentId });

	return (
		<>
			{isLoading && <CircularProgress />}
			{!isLoading && data && (
				<CurtailmentObjectHistorySlider data={data} width={width} />
			)}
		</>
	);
};

export default CurtailmentObjectHistorySliderContainer;
