import { AppDispatch } from "../../store";
import { getMetadata } from "../metadata/metadata.thunk";
import { Pricing } from "../pricing_list/pricingListSlice";

export function updatePricingTypeFilters(pricings: Pricing[]): any {
	return async function (dispatch: AppDispatch, getState: any) {
		const state = getState();
		pricings.forEach((pricing: Pricing) => {
			const pickListIndex = state.filters.lists.findIndex(
				(l: any) => l.id === `pricing_type_/${pricing.tender_id}`
			);
			if (pickListIndex > -1) {
				const index = state.filters.lists[
					pickListIndex
				].items.findIndex(
					(item: any) => Number(item.id) === pricing.pricing_type_id
				);

				if (index < 0) {
					dispatch(
						getMetadata("Pricings", pricing.tender_id, {
							id: pricing.pricing_type_id.toString(),
							name: pricing.pricing_type.name,
							filterKey: "pricing_type",
						})
					);
				}
			}
		});
	};
}

export function updateDeliveryPeriodsFilters(pricings: Pricing[]): any {
	return async function (dispatch: AppDispatch, getState: any) {
		const state = getState();
		pricings.forEach((pricing: Pricing) => {
			const pickListIndex = state.filters.lists.findIndex(
				(l: any) => l.id === `delivery-periods_/${pricing.tender_id}`
			);

			if (pickListIndex > -1) {
				const index = state.filters.lists[
					pickListIndex
				].items.findIndex(
					(item: any) => item.id === pricing.delivery_period
				);

				if (index < 0) {
					dispatch(
						getMetadata("Pricings", pricing.tender_id, {
							id: pricing.delivery_period,
							name: pricing.delivery_period,
							filterKey: "delivery-periods",
						})
					);
				}
			}
		});
	};
}

export function updatePartiesFilters(pricings: Pricing[]): any {
	return async function (dispatch: AppDispatch, getState: any) {
		const state = getState();
		pricings.forEach((pricing: Pricing) => {
			const pickListIndex = state.filters.lists.findIndex(
				(l: any) => l.id === `parties_/${pricing.tender_id}`
			);
			if (pickListIndex > -1) {
				pricing.parties?.forEach((pricingParty) => {
					const index = state.filters.lists[
						pickListIndex
					].items.findIndex(
						(item: any) => Number(item.id) === pricingParty.party_id
					);

					if (index < 0) {
						dispatch(
							getMetadata("Pricings", pricing.tender_id, {
								id: pricingParty.party_id.toString(),
								name: pricingParty.party.name,
								filterKey: "parties",
							})
						);
					}
				});
			}
		});
	};
}
