import { RootState } from "../../core/rootReducers";

export const selectPricingParamState = (state: RootState) => {
	return state.parameters.pricingParams;
};

export const selectAllPricingParams = (state: RootState) => {
	return state.parameters.parameters;
};

export function selectGetPricingParamLoader(state: RootState) {
	return state.parameters.loader.getPricingParams;
}
export function selectSendPricingParamLoader(state: RootState) {
	return state.parameters.loader.sendPricingParams;
}

export function selectFeatureFlags(state: RootState) {
	return state.parameters.featureFlags;
}
