import { ReactCountryFlag } from "react-country-flag";
import { Box } from "@mui/material";

const Country = ({ country }: { country: string }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: "8px",
			}}
		>
			{country}{" "}
			<ReactCountryFlag
				countryCode={country ?? ""}
				svg
				title={country ?? ""}
			/>
		</Box>
	);
};

export default Country;
