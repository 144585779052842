import { createSlice } from "@reduxjs/toolkit";
import { Tender } from "./tender.module";
import uniqBy from "lodash.uniqby";

export interface Account {
	accountId: number;
	accountName: string;
}
interface TenderState {
	addedTenderCount: number;
	latestTenderId?: number;
	latestUpdatedAt?: Date;
	isPaginationStopped: boolean;
	tenders: Tender[];
	accounts: Account[];
	tsImporter?: string[];
	loader: {
		tender: boolean;
		pricingsByTender: Record<number, boolean>;
		actions: boolean;
		tsImporter: boolean;
	};
	errors: {
		tenders: string[];
		tsImporter?: { [key: string]: string }[];
	};
	warning: {
		tsImporter?: { site_name: string; message: string }[];
	};
}
const initialState: TenderState = {
	latestUpdatedAt: undefined,
	latestTenderId: undefined,
	isPaginationStopped: false,
	addedTenderCount: 0,
	tenders: [],
	accounts: [],
	tsImporter: undefined,
	loader: {
		tender: false,
		pricingsByTender: {},
		actions: false,
		tsImporter: false,
	},
	errors: {
		tenders: [],
		tsImporter: undefined,
	},
	warning: {
		tsImporter: undefined,
	},
};

export const tenderSlice = createSlice({
	name: "tender",
	initialState,
	reducers: {
		initTenders: (state) => {
			state.tenders = [];
			state.isPaginationStopped = false;
			state.latestUpdatedAt = undefined;
			state.latestTenderId = undefined;
			state.addedTenderCount = 0;
			state.loader.tender = false;
		},
		setTenderLoading: (state) => {
			state.loader.tender = true;
		},
		getTendersSuccess: (state, action) => {
			const { tenders } = action.payload;
			state.loader.tender = false;
			state.tenders = uniqBy([...state.tenders, ...tenders], "id");
			state.loader.tender = false;
			state.isPaginationStopped = !action.payload.hasNextPage;
			return;
		},
		getTendersError: (state, action) => {
			state.loader.tender = false;
			state.errors.tenders += action.payload;
		},
		updateNewTendersCount: (state) => {
			state.addedTenderCount = 0;
		},
		updateTenderBlotter: (state, action) => {
			const tenderId = action.payload.id;
			state.tenders = state.tenders.map((tender) => {
				if (tender.id == tenderId)
					return { ...tender, ...action.payload };
				return tender;
			});
		},
		updateTenderStatus: (state, action) => {
			const tenderId = action.payload.tenderId;
			const newStatus = action.payload.status;
			state.tenders = state.tenders.map((tender) => {
				if (tender.id == tenderId) return { ...tender, ...newStatus };
				return tender;
			});
		},
		setTenderPricings: (state, action) => {
			const tenderId = action.payload.tenderId;
			const pricings = action.payload.pricings;
			state.tenders = state.tenders.map((tender) =>
				tender.id === tenderId ? { ...tender, pricings } : tender
			);
		},
		setPricingsByTenderIsLoading: (state, action) => {
			state.loader.pricingsByTender[action.payload.tenderId] =
				action.payload.isLoading;
		},
		setTenders: (state, action) => {
			state.tenders = action.payload;
		},
		setTenderActionLoading: (state, action) => {
			state.loader.actions = action.payload;
		},
		tsImporterSuccess: (state, action) => {
			state.tsImporter = action.payload;
		},
		tsImporterWarning: (state, action) => {
			state.warning.tsImporter = action.payload;
		},
		setTsImporterLoading: (state, action) => {
			state.loader.tsImporter = action.payload;
		},
		tsImporterError: (state, action) => {
			state.errors.tsImporter = action.payload;
			state.loader.tsImporter = false;
		},
	},
});

export const {
	getTendersSuccess,
	getTendersError,
	initTenders,
	updateTenderBlotter,
	setTenderLoading,
	updateTenderStatus,
	updateNewTendersCount,
	setTenderPricings,
	setPricingsByTenderIsLoading,
	setTenders,
	setTenderActionLoading,
	setTsImporterLoading,
	tsImporterError,
	tsImporterSuccess,
	tsImporterWarning,
} = tenderSlice.actions;

export default tenderSlice.reducer;
