import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { getOktaToken } from "./getOktaToken";
import { enqueueSnackbar } from "notistack";

const customBaseQuery =
	(
		{ baseURL }: { baseURL: string } = { baseURL: "" }
	): BaseQueryFn<
		{
			url: string;
			method: AxiosRequestConfig["method"];
			data?: AxiosRequestConfig["data"];
			params?: AxiosRequestConfig["params"];
		},
		unknown,
		unknown
	> =>
	async ({ url, method, data, params }) => {
		const token = await getOktaToken();

		const headers: Record<string, string> = {
			Authorization: `Bearer ${token}`,
		};

		try {
			const newAxiosInstance = axios.create({
				baseURL,
			});
			const result = await newAxiosInstance({
				url,
				method,
				data,
				params,
				paramsSerializer: { indexes: null },
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			if ([0, 503, 500, 400].includes(err.request.status)) {
				enqueueSnackbar(
					err?.message ||
						"We've encountered an issue, please contact the development team.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
						autoHideDuration: 10000,
					}
				);
			}
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};

export default customBaseQuery;
