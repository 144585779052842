import React, { useMemo } from "react";
import { PricingRun } from "../../features/pricing_list/pricingListSlice";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { getStatusValues } from "../../features/pricing_list/pricingList.constant";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { If } from "./If";

export interface PricingStatusProps extends React.PropsWithChildren<{}> {
	loader?: boolean;
	pricingRun?: PricingRun;
	isScrolling?: boolean;
	width?: string;
}

export function PricingStatusComp(props: PricingStatusProps) {
	const status = useMemo(() => props.pricingRun?.status, [props.pricingRun]);
	const { loader, isScrolling } = props;

	if (status && getStatusValues(status)) {
		const Icon = getStatusValues(status).icon;
		return (
			<>
				{!loader && (
					<Box
						sx={{
							display: "Flex",
						}}
					>
						<If condition={!isScrolling}>
							<Icon
								sx={{
									marginRight: "5px",
									color: getStatusValues(status).color,
								}}
								data-testid={
									"status-" + getStatusValues(status).text
								}
							/>
						</If>
						<If condition={isScrolling}>
							<Box
								sx={{
									height: "24px",
									width: "26px",
									marginRight: "5px",
									borderRadius: "5px",
									backgroundColor:
										getStatusValues(status).color ||
										"rgb(0, 122, 205)",
									opacity: 0.8,
								}}
								data-testid={
									"status-" + getStatusValues(status).text
								}
							/>
						</If>
						<Tooltip title={getStatusValues(status).text}>
							<Box
								sx={{
									alignSelf: "center",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
									width: props.width ?? "100%",
								}}
							>
								{getStatusValues(status).text}
							</Box>
						</Tooltip>
					</Box>
				)}
				{loader && !isScrolling && (
					<>
						<Box
							sx={{
								display: "flex",
								marginRight: "5px",
							}}
							data-testid={"status-LOADING"}
						>
							<CircularProgress size={20} />
						</Box>
						Loading...
					</>
				)}
			</>
		);
	}
	return (
		<>
			<HourglassBottomIcon
				sx={{
					marginRight: "5px",
					color: "inherit",
				}}
				data-testid={"status-UNDER PRICING"}
			/>
			UNDER PRICING
		</>
	);
}
