import { Box } from "@mui/material";
import Tag from "../../../../common/components/Tag/Tag";
import { CurtailmentObjectGroupType } from "./CurtailmentObject.types";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { style } from "./CurtailmentObject.style";
import CurtailmentObjects from "../../../../common/components/CurtailmentObjects";

const CurtailmentObjectBySite = ({
	curtailmentObjectGroup,
}: {
	curtailmentObjectGroup: CurtailmentObjectGroupType;
}) => {
	const [isActiveSiteAccordion, setIsActiveSiteAccordion] = useState(false);

	const handleToggleOpen = () => {
		setIsActiveSiteAccordion((currentOpen) => !currentOpen);
	};

	return (
		<Box sx={style.wrapper}>
			<Box sx={style.siteBlockHeader}>
				<Box onClick={handleToggleOpen} sx={style.siteBlockHandler}>
					{isActiveSiteAccordion ? (
						<KeyboardArrowUpIcon />
					) : (
						<KeyboardArrowDownIcon />
					)}
					<Box sx={style.siteName}>{curtailmentObjectGroup.site}</Box>
				</Box>
				{!isActiveSiteAccordion && (
					<Box sx={style.tagsWrapper}>
						{curtailmentObjectGroup?.curtailmentKinds.map(
							(curtailmentKind) => (
								<Tag
									key={curtailmentKind}
									label={curtailmentKind}
								/>
							)
						)}
					</Box>
				)}
			</Box>
			{isActiveSiteAccordion && (
				<CurtailmentObjects
					curtailmentObjectGroup={curtailmentObjectGroup}
				/>
			)}
		</Box>
	);
};

export default CurtailmentObjectBySite;
