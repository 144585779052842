import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import React, { useEffect } from "react";
import { useLazyGetDealsQuery } from "../../../../requests_geco/contractsApi";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import CircularProgress from "@mui/material/CircularProgress";
import { Spacer } from "../../../../common/components/Spacer";
import DealTabCancelButton from "./DealTabCancelButton";
import DealTabStatusButton from "./DealTabStatusButton";
import { ContractPeriodDealsType } from "./DealsTab.type";
import { formatStringDate } from "../../../../common/utils/dateUtils";
import { Box } from "@mui/material";
import { DealType } from "../../../../requests_geco/contractsApi/contractsApi.types";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import Tag from "../../../../common/components/Tag/Tag";

const DealsTab = ({ contractPeriodId }: { contractPeriodId: number }) => {
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [getDealsBase, { data: dataDeals, isLoading: isLoadingDeals }] =
		useLazyGetDealsQuery();
	const getDeals = useRtkQueryDynamicEndpoint(getDealsBase);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	useEffect(() => {
		getDeals({ contract_period_id: contractPeriodId }, false);

		const interval = setInterval(() => {
			getDeals({ contract_period_id: contractPeriodId }, false);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	let headers: HeadersType<ContractPeriodDealsType>[] = [
		{ label: "Trade ID", accessor: "trade_id" },
		{ label: "Year", accessor: "year" },
		{
			label: "Start Date",
			accessor: "start_date",
			format: (value: any): any =>
				formatStringDate(value as string, "yyyy-MM-dd"),
		},
		{
			label: "End Date",
			accessor: "end_date",
			format: (value: any): any =>
				formatStringDate(value as string, "yyyy-MM-dd"),
		},
		{ label: "Deal Type", accessor: "deal_type" },
		{ label: "Deal Status", accessor: "deal_status" },
		{ label: "Curtailment Object", accessor: "curtailment_object" },
		{ label: "Actions", accessor: "actions" },
	];
	headers = headers.filter(
		(header) => header.accessor !== "actions" || isUserAuthorizedBool
	);

	const dealsObjects = dataDeals?.map((deal) => ({
		...deal,
		deal_type: <Tag label={deal.deal_type} size="small" />,
		deal_status: <DealTabStatusButton deal={deal} />,
		curtailment_object: deal.curtailment_object_name,
		actions:
			isUserAuthorizedBool && deal.trade_id ? (
				<DealTabCancelButton
					contractPeriodId={contractPeriodId}
					dealId={deal.id}
					isCompensation={deal.deal_type?.includes(
						DealType.COMPENSATION
					)}
				/>
			) : null,
	})) as ContractPeriodDealsType[];

	return (
		<>
			<Spacer gap={24} />
			{isLoadingDeals && (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			)}
			{!isLoadingDeals && (
				<>
					{dataDeals && !dealsObjects?.length && <NoDataMessage />}
					{!!dealsObjects?.length && (
						<SimpleTable
							dataTestid="clickabilityTable"
							headers={headers}
							items={dealsObjects}
							infiniteScroll
						/>
					)}
				</>
			)}
		</>
	);
};

export default DealsTab;
