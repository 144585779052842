import PageTitle from "../../../common/components/PageTitle/PageTitle";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { NJButton } from "@engie-group/fluid-design-system-react";
import CustomDatePicker from "../../../common/components/CustomDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { style } from "./ValidateContractPeriodPage.style";

const ValidateContractPeriodPage = () => {
	const initialValues = {
		startDate_1: "",
		endDate_1: "",
		startDate_2: "",
		endDate_2: "",
		startDate_3: "",
		endDate_3: "",

		startDate_4: "",
		endDate_4: "",
		startDate_5: "",
		endDate_5: "",
		startDate_6: "",
		endDate_6: "",
	};

	const handleSubmit = (values: typeof initialValues) => {
		console.log(values);
	};

	return (
		<>
			<PageTitle label="Conflicts to resolve" />
			<Box sx={style.contractInfos}>
				<Box sx={style.infoBlock}>
					<span>ContractPeriod name</span>
					<span>
						<b>OverwriteMarkwerteEMSVPP</b>
					</span>
				</Box>
				<Box sx={style.infoBlock}>
					<span>ContractPeriod dates</span>
					<span>
						Start Date: <b>2023-05-01 00:00</b>
					</span>
				</Box>
				<Box sx={style.infoBlock}>
					<span>
						End Date: <b>2026-01-01 00:00</b>
					</span>
				</Box>
			</Box>
			<Box sx={style.pageWrapper}>
				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					{() => (
						<Form>
							<Typography component="h2" sx={style.h2}>
								Resolve changes
							</Typography>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={en}
							>
								<Box sx={style.section}>
									<Typography component="h3" sx={style.h3}>
										Clicks
									</Typography>
									<Box sx={style.rows}>
										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												OTC/Seller/Previous Month
											</Typography>
											<CustomDatePicker
												name="startDate_1"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_1"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>

										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												OTC/Seller/Previous Month
											</Typography>
											<CustomDatePicker
												name="startDate_2"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_2"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>

										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												OTC/Seller/Previous Month
											</Typography>
											<CustomDatePicker
												name="startDate_3"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_3"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>
									</Box>
								</Box>
								<Box sx={style.section}>
									<Typography component="h3" sx={style.h3}>
										Servicepoint
									</Typography>
									<Box sx={style.rows}>
										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												SP_Stuhr_2 504215725576
											</Typography>
											<CustomDatePicker
												name="startDate_4"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_4"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>

										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												SP_WP Frotheim 50516447072
											</Typography>
											<CustomDatePicker
												name="startDate_5"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_5"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>

										<Box sx={style.cols}>
											<Typography
												component="div"
												sx={style.label}
											>
												SP_Beckum_II 50565586227
											</Typography>
											<CustomDatePicker
												name="startDate_6"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "Start date",
													},
												}}
											/>
											<CustomDatePicker
												name="endDate_6"
												sx={style.dateInput}
												slotProps={{
													textField: {
														size: "small",
														label: "End date",
													},
												}}
											/>
										</Box>
									</Box>
								</Box>
							</LocalizationProvider>

							<Box sx={style.buttons}>
								<NJButton
									icon="arrow_back"
									label="Abandon & back"
									variant="secondary"
								/>
								<NJButton
									emphasis="subtle"
									icon="warning_amber"
									label="Validate with errors"
								/>
								<NJButton
									icon="done"
									label="Validate"
									type="submit"
								/>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>
		</>
	);
};

export default ValidateContractPeriodPage;
