import React from "react";
import * as Sentry from "@sentry/react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

export function initSentry() {
	console.log("Init Sentry");
	if (process.env.REACT_APP_SENTRY_KEY) {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_KEY,
			environment: process.env.REACT_APP_ENV,

			integrations: [
				new Sentry.BrowserTracing({
					// See docs for support of different versions of variation of react router
					// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
					routingInstrumentation: Sentry.reactRouterV6Instrumentation(
						React.useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes
					),
				}),
				new Sentry.Replay(),
			],
			replaysOnErrorSampleRate: 1.0,
			tracesSampleRate: parseFloat(
				process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "0.5"
			),
		});
		console.log("Sentry has been initialized");
	}
	console.log(`Sentry dsn: ${process.env.REACT_APP_SENTRY_KEY}`);
}
