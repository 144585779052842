import { white } from "../../core/theme";

export const style = {
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		marginBottom: "30px",
	},
	stepperHeader: {
		marginTop: "40px",
		backgroundColor: white,
		minHeight: "72px",
		paddingLeft: "100px",
		paddingRight: "100px",
		minWidth: "1000px",
		border: "1px solid #000000",
	},
	stepContainer: {
		paddingTop: "40px",
	},
	dateChangeContainer: {
		display: "flex",
		width: "100%",
		flexDirection: "column",
		flex: 1,
		datePickersContainer: {
			display: "flex",
			flexDirection: "row",
			gap: "24px",
		},
		fieldTypeContainer: {
			width: 160,
		},
	},
};
