import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { primaryColor, secondaryColor } from "../../core/theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { If } from "./If";

const style: any = {
	menuItem: {
		"&:hover": {
			borderLeft: `2px ${primaryColor} solid`,
		},
		padding: 0,
	},
};

export interface HamburgerMenuProps extends React.PropsWithChildren<{}> {
	disabled?: boolean;
	datatestId?: string;
}

export function HamburgerMenu({
	children,
	disabled,
	datatestId = "hamburgerIcon",
}: HamburgerMenuProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<If
			condition={React.Children.toArray(children).reduce(
				(acc, prev) => !!acc || !!prev,
				false
			)}
		>
			<IconButton
				data-testid={datatestId}
				onClick={handleClick}
				sx={{ height: "24px" }}
			>
				<MoreVertIcon
					sx={{
						color: disabled ? secondaryColor : primaryColor,
						height: "24px",
					}}
				/>
			</IconButton>
			<Menu
				id="tender-menu"
				aria-labelledby="tender-menu"
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						margin: 0,
						padding: "0 !important",
						borderRadius: 0,
					},
				}}
			>
				{React.Children.map(children, (child, index) => (
					<If condition={child != null}>
						<MenuItem
							key={index}
							sx={style.menuItem}
							onClick={(event) => {
								if (!disabled) {
									event.stopPropagation();
									handleClose();
								}
							}}
						>
							{child}
						</MenuItem>
					</If>
				))}
			</Menu>
		</If>
	);
}
