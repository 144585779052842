import {
	Box,
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableRow,
	Typography,
} from "@mui/material";
import { Waypoint } from "react-waypoint";
import { grey, pagePadding, secondaryColor } from "../../../core/theme";
import { headerCells } from "../tender.constant";
import { Tender } from "../tender.module";
import Row from "./TableRow";
import { TargetIds } from "../../../common/utils/scroll";
import { TenderTableHeader } from "./TenderTableHeader";
import { useSelector } from "react-redux";
import { selectIsPaginationStopped } from "../tender.selector";
import { Action } from "../../pricing_list/pricingListSlice";

interface CollapsibleTableProps {
	onHandleNextPage: () => void;
	isLoading: boolean;
	tenders: Tender[];
	currentPage: number;
	selectedIds: number[];
	toggleTenderSelection: (tender: Tender) => void;
	selectAll: () => void;
	clearSelection: () => void;
	actions: Action[];
	onTenderBatchAction: (action: Action) => void;
}

const style = {
	tableContainer: {
		maxHeight: "100%",
	},
	table: {
		borderSpacing: "0px 5px",
		maxHeight: "100%",
		padding: `0 ${pagePadding}`,
	},
	headersRow: {
		height: "56px",
		border: `1px solid ${grey}`,
		background: secondaryColor,
	},
	footerCell: {
		border: 0,
	},
};

export default function CollapsibleTable({
	onHandleNextPage,
	isLoading,
	tenders,
	currentPage,
	selectedIds,
	toggleTenderSelection,
	selectAll,
	clearSelection,
	actions,
	onTenderBatchAction: tenderBatchActions,
}: CollapsibleTableProps) {
	const isPaginationStopped = useSelector(selectIsPaginationStopped);

	return (
		<TableContainer
			id={TargetIds.TENDER_TABLE_CONTAINER}
			sx={style.tableContainer}
		>
			<Table stickyHeader aria-label="sticky table" sx={style.table}>
				<TenderTableHeader
					selectedIds={selectedIds}
					actions={actions}
					clearSelection={clearSelection}
					selectAll={selectAll}
					onTenderBatchActions={tenderBatchActions}
				/>
				<TableBody>
					{tenders.length === 0 && !isLoading && (
						<TableRow>
							<TableCell colSpan={6} sx={{ borderBottom: 0 }}>
								No tenders found
							</TableCell>{" "}
						</TableRow>
					)}
					{tenders.map((tender, index) => (
						<Row
							selectedIds={selectedIds}
							toggleTenderSelection={toggleTenderSelection}
							targetId={index === 0 ? TargetIds.FIRST_ROW : ""}
							key={tender.id}
							tender={tender}
							colsLength={headerCells.length + 1}
						/>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell
							sx={style.footerCell}
							colSpan={headerCells.length + 1}
						>
							{isLoading && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<CircularProgress data-testid="loader" />
								</Box>
							)}
							{!isPaginationStopped && !isLoading && (
								<Grid
									alignItems="center"
									justifyContent="center"
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Waypoint
											onEnter={onHandleNextPage}
										></Waypoint>
									</Box>
								</Grid>
							)}
							{tenders.length === 0 && currentPage === -1 && (
								<Grid
									alignItems="center"
									justifyContent="center"
								>
									<Typography
										sx={{ marginTop: "40px" }}
										variant="h3"
										component="h1"
									>
										No Tenders Found
									</Typography>
								</Grid>
							)}
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
