import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../ParametersPage.selector";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const style = {
	container: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
};

export interface RequiresFeatureFlagsProps
	extends React.PropsWithChildren<{}> {}

export const RequiresFeatureFlags = (props: RequiresFeatureFlagsProps) => {
	const features = useSelector(selectFeatureFlags);
	if (Object.keys(features || {}).length > 0) {
		return <>{props.children}</>;
	} else {
		return (
			<Box style={style.container}>
				<CircularProgress />
			</Box>
		);
	}
};
