import * as _ from "lodash";
import { useCallback, useRef, useState } from "react";
import { EditHeadersType } from "../components/SimpleEditableTable/SimpleEditableTable";

export interface UseSimpleEditTableProps {
	handleRowClick: (index: number | string) => void;
	clearColumn: (header: EditHeadersType) => void;
	fillInColumn: (header: EditHeadersType, value: any) => void;
	deleteRows: () => void;
	addRow: () => void;
	formikRef: React.RefObject<HTMLInputElement>;
	selectedRows: (number | string)[];
	submitForm: () => void;
	duplicate: (
		nbrOfTimes: number,
		positionOfCopiedItem: number,
		uniquePropertyName?: string
	) => void;
}

export const useSimpleEditTable = (
	headers: EditHeadersType[]
): UseSimpleEditTableProps => {
	const [clickedRows, setClickedRows] = useState<(string | number)[]>([]);
	const formikRef = useRef<HTMLInputElement | null>(null);

	const onAddNewLine = useCallback(() => {
		if (formikRef.current) {
			const newAsset = {};
			for (const header of headers) {
				_.set(newAsset, header.accessor, undefined);
			}
			const newValues = {
				//@ts-ignore
				...formikRef.current.values,
				//@ts-ignore
				items: [...formikRef.current.values.items, newAsset],
			};
			//@ts-ignore
			formikRef.current.setValues(newValues);
		}
	}, [headers]);

	const onDuplicate = useCallback(
		(
			nbrOfTimes: number,
			positionOfCopiedItem: number,
			uniquePropertyName?: string
		) => {
			if (formikRef.current) {
				const basedItem = {
					//@ts-ignore
					...formikRef.current.values.items[positionOfCopiedItem],
					id: undefined,
				};
				const newAssets = _.times(nbrOfTimes, (index) => {
					const clonedItem = _.cloneDeep(basedItem);
					if (uniquePropertyName) {
						clonedItem[uniquePropertyName] = `${
							clonedItem[uniquePropertyName]
						}_${index + 1}`;
					}

					return clonedItem;
				});

				const startSlice = _.slice(
					//@ts-ignore

					formikRef.current.values.items,
					0,
					positionOfCopiedItem + 1
				);
				const endSlice = _.slice(
					//@ts-ignore
					formikRef.current.values.items,
					positionOfCopiedItem + 1
				);
				const newValues = {
					//@ts-ignore
					...formikRef.current.values,
					//@ts-ignore
					items: _.concat(startSlice, newAssets, endSlice),
				};
				//@ts-ignore
				formikRef.current.setValues(newValues);
			}
		},
		[headers]
	);

	const onDeleteRows = useCallback(() => {
		if (formikRef.current && clickedRows.length) {
			//@ts-ignore
			const items = formikRef.current.values.items;
			for (let i = clickedRows.length - 1; i >= 0; i--) {
				items.splice(clickedRows[i], 1);
			}
			setClickedRows([]);
			//@ts-ignore
			formikRef.current.setValues({ ...formikRef.current.value, items });
		}
	}, [clickedRows, setClickedRows]);

	const onClearColumn = useCallback((header: EditHeadersType) => {
		if (formikRef.current) {
			//@ts-ignore
			const items = formikRef.current.values.items.map((item) =>
				_.set(item, header.accessor, undefined)
			);
			//@ts-ignore
			formikRef.current.setValues({ ...formikRef.current.value, items });
		}
	}, []);

	const onFillInColumn = useCallback(
		(header: EditHeadersType, value: any) => {
			if (formikRef.current) {
				//@ts-ignore
				const items = formikRef.current.values.items.map((item) =>
					_.set(item, header.accessor, value)
				);
				//@ts-ignore
				formikRef.current.setValues({
					//@ts-ignore
					...formikRef.current.value,
					items,
				});
			}
		},
		[]
	);

	const submitForm = useCallback(() => {
		if (formikRef.current) {
			//@ts-ignore
			formikRef.current.submitForm();
		}
	}, []);

	const selectRow = useCallback(
		(identifier: number | string) => {
			const updatedRowsClicked = [...clickedRows];
			const identifierIndex = _.findIndex(
				updatedRowsClicked,
				(o) => o === identifier
			);
			if (identifierIndex !== -1) {
				updatedRowsClicked.splice(identifierIndex, 1);
			} else {
				updatedRowsClicked.push(identifier);
			}
			setClickedRows(updatedRowsClicked);
		},
		[clickedRows, setClickedRows]
	);

	return {
		handleRowClick: selectRow,
		clearColumn: onClearColumn,
		fillInColumn: onFillInColumn,
		addRow: onAddNewLine,
		deleteRows: onDeleteRows,
		duplicate: onDuplicate,
		formikRef,
		selectedRows: clickedRows,
		submitForm,
	};
};
