import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { If } from "../../../../common/components/If";
import {
	Box,
	CircularProgress,
	Typography,
	SxProps,
	Theme,
} from "@mui/material";
import { formatStringDate } from "../../../../common/utils/dateUtils";
import bellcurve from "highcharts/modules/histogram-bellcurve";
import { DashStyleValue } from "highcharts";
bellcurve(Highcharts);

const style: { [key: string]: SxProps<Theme> } = {
	loaderContainer: {
		minHeight: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

interface FrequencyGraphProps extends React.PropsWithChildren<{}> {
	data: any;
	installedCapacity: number;
	noData: boolean;
	error?: string;
	horizon?: { start_date: string; end_date: string };
}

export default function FrequencyGraph(props: FrequencyGraphProps) {
	const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
	const { data, installedCapacity, noData, error, horizon } = props;

	const [chartData, setChartData] = React.useState<any>({});

	React.useEffect(() => {
		let chart = chartComponentRef.current?.chart;
		if (data.length === 0) {
			chart?.showLoading("Loading...");
		} else {
			chart?.hideLoading();

			const plotLine = {
				color: "#FF0000",
				width: 2,
				value: installedCapacity,
				id: "plotLineInstalledCapacity",
			};
			const plotLine70 = {
				color: "#FF0000",
				width: 2,
				dashStyle: "ShortDash" as DashStyleValue,
				id: "plotLineInstalledCapacity70",
				value: installedCapacity * 0.7,
			};
			const plotLine105 = {
				color: "#FF0000",
				width: 2,
				dashStyle: "ShortDash" as DashStyleValue,
				id: "plotLineInstalledCapacity105",
				value: installedCapacity * 1.05,
			};

			setChartData({
				title: {
					text: `${formatStringDate(
						horizon?.start_date,
						"MMM M yyyy"
					)} - ${formatStringDate(horizon?.end_date, "MMM M yyyy")}`,
				},

				xAxis: [
					{
						alignTicks: false,
						visible: false,
					},
					{
						title: { text: "Production" },
						alignTicks: false,
						max: installedCapacity + 0.5,
						plotLines: [plotLine, plotLine70, plotLine105],
					},
				],

				yAxis: [
					{
						visible: false,
					},
					{
						title: { text: "Frequency" },
					},
				],

				plotOptions: {
					histogram: {
						accessibility: {
							point: {
								valueDescriptionFormat:
									"{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.",
							},
						},
						binsNumber: 20,
					},
				},
				legend: {
					enabled: true,
					itemHiddenStyle: {
						textDecoration: "none",
					},
				},
				series: [
					{
						// Series that mimics the plot line
						color: "#FF0000",
						name: "Installed Capacity",
						marker: {
							enabled: false,
						},
						events: {
							legendItemClick: function (e: any) {
								if (e.target.visible) {
									chart?.xAxis[0].removePlotLine(
										"plotLineInstalledCapacity"
									);
								} else {
									chart?.xAxis[0].addPlotLine(plotLine);
								}
							},
						},
					},
					{
						// Series that mimics the plot line
						color: "#FF0000",
						name: "70% Installed Capacity",
						dashStyle: "ShortDash",
						marker: {
							enabled: false,
						},
						events: {
							legendItemClick: function (e: any) {
								if (e.target.visible) {
									chart?.xAxis[0].removePlotLine(
										"plotLineInstalledCapacity70"
									);
								} else {
									chart?.xAxis[0].addPlotLine(plotLine70);
								}
							},
						},
					},
					{
						// Series that mimics the plot line
						color: "#FF0000",
						name: "105% Installed Capacity",
						dashStyle: "ShortDash",
						marker: {
							enabled: false,
						},
						events: {
							legendItemClick: function (e: any) {
								if (e.target.visible) {
									chart?.xAxis[0].removePlotLine(
										"plotLineInstalledCapacity105"
									);
								} else {
									chart?.xAxis[0].addPlotLine(plotLine105);
								}
							},
						},
					},
					...data,
				],
			});
		}
	}, [data]);

	return (
		<>
			<If condition={Object.keys(chartData).length === 0 && !noData}>
				<Box sx={style.loaderContainer}>
					<CircularProgress color="primary" />
				</Box>
			</If>
			<If condition={Object.keys(chartData).length > 0 && !error}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartData}
					ref={chartComponentRef}
					{...props}
					containerProps={{
						style: { minHeight: "60vh" },
					}}
				/>
			</If>
			<If condition={noData}>
				<Typography paragraph>{"No Data Found"}</Typography>
			</If>
			<If condition={error}>
				<Typography paragraph>{error}</Typography>
			</If>
		</>
	);
}
