import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { PrimaryButton } from "../../common/components/CustomButton";
import { ContactCard } from "../../common/components/ContactCard";
import { ContactCardProps } from "../../common/components/ContactCard/ContactCard";
import { Spacer } from "../../common/components/Spacer";

export interface ContactModalProps {
	contacts: ContactCardProps[];
	isOpen: boolean;
	onClose: () => void;
}

export const ContactModal = ({
	contacts,
	isOpen,
	onClose,
}: ContactModalProps) => {
	return (
		<Dialog maxWidth={"xl"} onClose={onClose} open={isOpen}>
			<DialogTitle>{"Contacts"}</DialogTitle>
			<DialogContent>
				{contacts.map((contact) => (
					<>
						<ContactCard
							id={contact.id}
							email={contact.email}
							recipient_types={contact?.recipient_types || []}
						/>
						<Spacer gap={16} />
					</>
				))}
			</DialogContent>
			<DialogActions>
				<PrimaryButton
					onClick={onClose}
					text="Close"
					type="button"
					color="secondary"
				></PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};
