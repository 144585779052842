import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";
import { ContractChangeResponse } from "./contractChangesApi.types";
import { ContractDetailType } from "../contractsApi/contractsApi.types";

const contractChangesApi = createApi({
	reducerPath: "contractChangesApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_API_PATH!,
	}),
	tagTypes: ["ContractChange"],
	endpoints: (builder) => ({
		getOneContractChange: builder.query<ContractChangeResponse, number>({
			query: (id) => {
				return {
					url: `/contracts/${id}`,
					method: "GET",
				};
			},
			providesTags: ["ContractChange"],
		}),
		createContractChange: builder.mutation<void, ContractDetailType>({
			query: (contract) => {
				return {
					url: `/contracts/${contract.id}/change_requests`,
					method: "POST",
					data: { payload: contract },
				};
			},
			invalidatesTags: ["ContractChange"],
		}),
		rejectContractChange: builder.mutation<
			void,
			{ contractId: number; reason: string }
		>({
			query: ({ contractId, reason }) => {
				return {
					url: `/contracts/${contractId}/rejected`,
					method: "PUT",
					data: { comment: reason },
				};
			},
			invalidatesTags: ["ContractChange"],
		}),
		approveContractChange: builder.mutation<void, { contractId: number }>({
			query: ({ contractId }) => {
				return {
					url: `/contracts/${contractId}/approved`,
					method: "PUT",
				};
			},
			invalidatesTags: ["ContractChange"],
		}),
	}),
});

export const {
	useCreateContractChangeMutation,
	useApproveContractChangeMutation,
	useRejectContractChangeMutation,
	useLazyGetOneContractChangeQuery,
} = contractChangesApi;

export default contractChangesApi;
