import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Link, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { borderGrey, labelColor, linkColor } from "../../core/theme";
import { UploadedFile, UploadingFile } from "../files/files.module";
import { enqueueSnackbar } from "notistack";
import { capitalize } from "../utils/capitalize";
import { useUploadFiles } from "../files/files.hook";
import ErrorMessageModal from "./ErrorMessageDialog";

export interface FlePreviewProps {
	index: number;
	displayError: (file: any) => void;
	file: UploadedFile | UploadingFile;
	cancel: (file: UploadingFile) => void;
	onRemove: (index: number) => void;
}

export interface DropZoneInputProps {
	name: string;
	setFieldValue: (
		fieldName: string,
		value: Array<UploadingFile | UploadedFile>
	) => void;
	setSubmitting?: (submitting: boolean) => void;
	onClear?: (
		fieldName: string,
		value: Array<UploadingFile | UploadedFile>
	) => void;
	value: Array<UploadingFile | UploadedFile>;
	label?: string;
	loading?: boolean;
	restrictionText?: string;
	accept: Record<string, string[]>;
	maxSize?: number;
	disabled?: boolean;
	hideInput?: boolean;
	maxFiles?: number;
	renderPreview: (props: FlePreviewProps) => React.ReactElement;
	extraData?: any;
}

const commonStyle: SxProps = {
	display: "flex",
	flexDirection: "column",
	backgroundColor: "white",
	border: `1px dashed ${borderGrey}`,
	borderRadius: "2px",
	alignItems: "center",
	justifyContent: "center",
	padding: "40px",
};
const dragActiveStyle: SxProps = {
	border: `2px dashed ${borderGrey}`,
	padding: "39px",
};

const labelStyle = {
	fontWeight: "400",
	fontSize: "14px",
	lineHeight: "20px",
	color: labelColor,
};
const dropZoneStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};
const dropZoneMainTextStyle = {
	color: labelColor,
	fontSize: "16px",
	weight: 400,
};
const dropZoneLinkStyle = { color: linkColor, fontSize: "16px" };
const dropZoneRestrictionStyle = {
	fontWeight: "400",
	fontSize: "14px",
	lineHeight: "20px",
	color: labelColor,
};

const filesWrapperStyle = {
	margin: "8px 0",
};

const DEFAULT_SIZE_LIMIT = 5 * 1000 * 1000;

interface RejectedFile {
	file: File;
	errors: Array<{ code: string; message: string }>;
}

export const DropZoneInput: React.FC<DropZoneInputProps> = (
	props: DropZoneInputProps
) => {
	const maxFiles = props?.maxFiles ?? 0;
	const maxSize = useMemo(
		() => props.maxSize || DEFAULT_SIZE_LIMIT,
		[props.maxSize]
	);

	const [fileError, setFileError] = useState<{
		title: string;
		content?: string;
	}>({ title: "", content: undefined });

	const onSucessUpload = useCallback(
		(files: UploadedFile[]) => {
			props.setFieldValue(props.name, [...props.value, ...files]);
			if (props.setSubmitting) {
				props.setSubmitting(false);
			}
		},
		[props.setFieldValue, props.value, props.setSubmitting]
	);

	const [pendingFiles, upload, cancel] = useUploadFiles(
		onSucessUpload,
		props.extraData
	);

	const onDrop = useCallback(
		(acceptedFiles: File[], rejectedFiles: RejectedFile[]) => {
			rejectedFiles.forEach((rejectedFile) => {
				const errorMessage = `${
					rejectedFile.file.name
				}: ${rejectedFile.errors
					.map((error) =>
						error.code === "file-too-large"
							? `File is larger than ${maxSize / 1000000}mb`
							: error.message
					)
					.join(",")}`;
				enqueueSnackbar(errorMessage, {
					variant: "error",
					autoHideDuration: 3000,
				});
			});
			acceptedFiles.forEach((file) => {
				if (file.size === 0) {
					enqueueSnackbar(
						`${file.name}: File size is 0, if you are uploading a file from a zip folder try to unzip before uploading`,
						{
							variant: "error",
							autoHideDuration: 3000,
						}
					);
				}
			});
			const validFiles = acceptedFiles.filter((file) => file.size > 0);
			if (validFiles.length) {
				if (props.setSubmitting) {
					props.setSubmitting(true);
				}
				upload(validFiles);
			}
		},
		[props.setFieldValue, props.value, props.name, maxSize, upload]
	);

	const onRemove = useCallback(
		(index: number) => {
			const newFiles = [...props.value];
			newFiles.splice(index, 1);
			props.setFieldValue(props.name, newFiles);
			if (props.onClear) {
				props.onClear(props.name, newFiles);
			}
		},
		[props.setFieldValue, props.value, props.name, props.onClear]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: props.accept,
		multiple: true,
		maxSize: maxSize,
		disabled: !!props.disabled,
		maxFiles: maxFiles,
	});

	const style = useMemo(() => {
		if (props.disabled) {
			return {
				...commonStyle,
				...dragActiveStyle,
				opacity: 0.2,
				cursor: "disabled",
			};
		}
		if (!isDragActive) {
			return {
				...commonStyle,
				"&:hover": dragActiveStyle,
				cursor: "pointer",
			};
		}
		return { ...commonStyle, ...dragActiveStyle };
	}, [isDragActive, props.disabled]);

	const hideInput = useMemo(() => {
		if (props.hideInput) {
			return true;
		}
		if (maxFiles > 0 && props.value.length >= maxFiles) {
			return true;
		}
		return false;
	}, [maxFiles, props.hideInput, props.value]);

	const allFiles = useMemo(
		() => [...props.value, ...pendingFiles],
		[props.value, pendingFiles]
	);

	const displayError = (file: any) => {
		if (file?.errors) {
			setFileError({
				title: file?.file_name,
				content: file?.errors,
			});
		}
	};
	const afterClosingErrorModal = () => {
		setFileError({ title: "", content: undefined });
	};

	return (
		<>
			<Typography sx={labelStyle}>{capitalize(props.label)}</Typography>
			{!hideInput && (
				<>
					<Box sx={style} {...getRootProps()}>
						<input {...getInputProps()} />
						<Box sx={dropZoneStyle}>
							<Typography
								variant="body1"
								component="p"
								sx={dropZoneMainTextStyle}
							>
								Drag & drop or{" "}
								<Link sx={dropZoneLinkStyle}>Browse</Link>
							</Typography>
						</Box>
					</Box>
					<Typography sx={dropZoneRestrictionStyle}>
						{capitalize(props.restrictionText)}
					</Typography>
				</>
			)}
			<Box sx={filesWrapperStyle}>
				{allFiles.map((file, index) => (
					<props.renderPreview
						key={index}
						index={index}
						displayError={displayError}
						file={file}
						cancel={cancel}
						onRemove={onRemove}
					/>
				))}
			</Box>
			<ErrorMessageModal
				title={fileError.title}
				content={fileError.content}
				actionAfterClose={afterClosingErrorModal}
			></ErrorMessageModal>
		</>
	);
};
export default DropZoneInput;
