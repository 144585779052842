import { ReactNode } from "react";
import { HeadersType } from "./SimpleTable";
import _ from "lodash";
import { Link } from "react-router-dom";
import { TableCell } from "@mui/material";
import { style } from "./SimpleTable.style";

export interface SimpleTableCellProps<T extends { id: number | string }> {
	cellType: HeadersType<T>;
	item: T;
}

export function SimpleTableCell<T extends { id: number | string }>({
	cellType,
	item,
}: SimpleTableCellProps<T>) {
	const getValue = () => {
		return _.get(
			item,
			cellType.accessorOverride
				? cellType.accessorOverride(item)
				: cellType.accessor
		);
	};
	const formatValue = (value: any) => {
		return cellType?.format ? cellType?.format(value) : value;
	};

	const wrapIntoAlink = (value: any): ReactNode => {
		if (cellType?.getAnchorTag) {
			return <Link to={cellType?.getAnchorTag(item)}>{value}</Link>;
		}
		return <>{value}</>;
	};

	return (
		<TableCell sx={cellType.colHeader ? style.tdHead : style.td}>
			{wrapIntoAlink(formatValue(getValue()))}
		</TableCell>
	);
}
