import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormHelperText,
	SxProps,
	Theme,
	Typography,
} from "@mui/material";
import { PrimaryButton } from "../../../common/components/CustomButton";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";

const style: { [key: string]: SxProps<Theme> } = {
	buttonContainer: {
		display: "flex",
		justifyContent: "space-around",
	},
	actionButton: {
		padding: "40px 20px",
		width: "260px",

		"@media only screen and (max-width: 2350px)": {
			width: "180px",
		},
	},
};
interface PricingSuccessActionModalProps {
	open: boolean;
	onSubmit: (action: string) => void;
}

export default function PricingSuccessActionModal(
	props: PricingSuccessActionModalProps
) {
	const { open, onSubmit } = props;
	return (
		<Dialog
			PaperProps={{ style: { minHeight: "28%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "35%" } }}
			open={open}
		>
			<DialogTitle>Pricing Created successfully</DialogTitle>
			<DialogContent>
				<Typography
					display="block"
					marginTop={"1em"}
					marginBottom={"4em"}
					gutterBottom
				>
					what do you want to do next
				</Typography>
				<Box sx={style.buttonContainer}>
					<Box>
						<PrimaryButton
							type="button"
							text="Continue pricing these sites"
							color="primary"
							sx={style.actionButton}
							onClick={() => onSubmit("pricing-same-sites")}
						>
							<AddCircleIconOutline
								sx={{
									color: "#fff",
								}}
							/>
						</PrimaryButton>
						<FormHelperText id="test_id">
							*Restart from step 2
						</FormHelperText>
					</Box>
					<Box>
						<PrimaryButton
							type="button"
							text="Add other pricings"
							color="primary"
							sx={style.actionButton}
							onClick={() => onSubmit("new-pricing")}
						>
							<AddCircleIconOutline
								sx={{
									color: "#fff",
								}}
							/>
						</PrimaryButton>
						<FormHelperText id="test_id">
							*Restart from step 1
						</FormHelperText>
					</Box>
					<Box>
						<PrimaryButton
							text="Go To Pricing Details"
							type="button"
							color="secondary"
							sx={style.actionButton}
							onClick={() => onSubmit("pricing-details")}
						/>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}
