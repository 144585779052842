import { useSelector } from "react-redux";
import { selectAuthenticatedUser } from "../../features/authentication/auth.selector";
import { User } from "../../features/authentication/auth.model";

function useIsAuthorized<T>(
	action: (object: T, currentUser: User | undefined) => boolean,
	object: T | undefined,
	isAuthorized: boolean = false
): boolean {
	const currentUser: User | undefined = useSelector(selectAuthenticatedUser);

	if (isAuthorized) {
		return true;
	}
	if (!object) {
		return false;
	}
	return action(object, currentUser);
}

export default useIsAuthorized;
