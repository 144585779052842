import { Action } from "../../pricing_list/pricingListSlice";
import { ValidationError } from "../../../common/hooks/usePydanticErrorsFormat";
import { TSData, Tender } from "../tender.module";
import { useFormik } from "formik";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	IconButton,
	SxProps,
	Theme,
} from "@mui/material";
import CustomInput from "../../../common/components/CustomInput";
import { If } from "../../../common/components/If";
import { CSSProperties, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomSelect from "../../../common/components/CustomSelect";
import { v4 as uuid } from "uuid";
import { backgroundNeutralSecondary } from "../../../core/theme";
import { useAppDispatch } from "../../../common/hooks/default";
import { getFileSheetNames, tsImporter } from "../tender.thunk";
import { addRowSchema, tsInfoSchema } from "../utils/tsImporter.schema";
import {
	selectTSImporterData,
	selectTSImporterErrors,
	selectTSImporterLoader,
	selectTSImporterWarning,
} from "../tender.selector";
import { useSelector } from "react-redux";
import { tsImporterSuccess, tsImporterWarning } from "../tenderSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

const style: { [key: string]: SxProps<Theme> } = {
	formAddRow: {
		display: "flex",
	},
	formControl: {
		display: "flex",
		flexDirection: "row",
		alignItems: "baseline",
		"& label": {
			marginRight: "10px",
		},
	},
	gridItemTitle: {
		margin: "auto",
		textAlign: "center",
		padding: "30px 0",
	},
	gridItemTitleWarning: {
		margin: "auto",
		textAlign: "center",
	},
	gridItem: {
		textAlign: "center",
	},
	tsInfoForm: (isEmpty) => ({
		display: "flex",
		justifyContent: isEmpty ? "flex-end" : "space-between",
		alignItems: "flex-end",
		flexDirection: "column",
		flexGrow: 2,
	}),
};

interface ActionTenderModalProps {
	open: boolean;
	onClose: () => void;
	tender: Tender;
	submitModal: (body: Record<string, string | number | boolean>) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

export function TsImporterModal(props: ActionTenderModalProps) {
	const { action, submitModal, tender, onClose, open } = props;
	const loading = useSelector(selectTSImporterLoader);
	const tsImporterResponse = useSelector(selectTSImporterData);
	const tsImporterErrors = useSelector(selectTSImporterErrors);
	const tsImpWarning = useSelector(selectTSImporterWarning);
	const dispatch = useAppDispatch();
	const [tsRows, setTsRows] = useState<
		{
			id: string;
			siteName: string;
			fileName: string;
			fileId: number;
			assetId: string;
			sheetNames?: string[] | null;
		}[]
	>([]);

	const [initValues, setInitValues] = useState({});

	const formikAddRow = useFormik({
		initialValues: {
			siteName: "",
			fileName: "",
		},
		validationSchema: addRowSchema,

		onSubmit: async (values: any) => {
			let rowId = uuid();
			let sheets: string[] = [];
			let fileExtension = tender.historical_data.find(
				(element) => element.file_name === values.fileName
			)?.file_extension;
			if (fileExtension !== "csv") {
				sheets = await dispatch(
					getFileSheetNames(values.fileId, rowId)
				);
			}
			setTsRows((prevValues) => [
				...prevValues,
				{
					id: rowId,
					siteName: values.siteName,
					fileName: values.fileName,
					assetId: values.assetId,
					fileId: values.fileId,
					sheetNames: sheets,
				},
			]);
		},
	});

	const formikTSInfo: any = useFormik({
		initialValues: initValues as any,
		enableReinitialize: true,
		validationSchema: tsInfoSchema(tsRows),
		onSubmit: (values: { [key: string]: TSData }) => {
			dispatch(
				tsImporter({ tsInfo: values, ignoreWarning: !!tsImpWarning })
			);
		},
	});
	useEffect(() => {
		tsRows.forEach((row) => {
			setInitValues(() => {
				return {
					[row.id]: {
						sheetName: "",
						productionCol: 2,
						indexDate: 1,
						indexTime: undefined,
						unit: "kW",
						timezone: "CET",
						offset: 0,
						fileId: row.fileId,
						assetId: row.assetId,
						id: row.id,
					},
					...formikTSInfo.values,
				};
			});
		});
		dispatch(tsImporterWarning(undefined));
	}, [tsRows]);

	useEffect(() => {
		dispatch(tsImporterSuccess(undefined));
	}, []);
	useEffect(() => {
		if (tsImporterResponse) {
			onClose();
		}
	}, [tsImporterResponse]);

	const deleteTsRow = (id: string) => {
		delete formikTSInfo.values[id];
		setTsRows((prev) => {
			return prev.filter((row) => row.id !== id);
		});
	};
	return (
		<Dialog
			PaperProps={{ style: { minHeight: "80%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "80%" } }}
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
		>
			<DialogTitle>{action?.display_name}</DialogTitle>
			<DialogContent
				sx={{
					marginTop: "24px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<form
					onSubmit={formikAddRow.handleSubmit}
					style={{ minHeight: "88px" }}
				>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<FormControl sx={style.formControl}>
								<FormLabel id="site-select-label">
									Site
								</FormLabel>
								<CustomSelect
									labelId="site-select-label"
									id="site-select"
									name="siteName"
									items={tender.sites_info.map((element) => ({
										key: element.asset_id,
										value: element.site_name,
									}))}
									label=""
									onChange={(event) => {
										let site = tender.sites_info.find(
											(element) =>
												element.site_name ===
												event.target.value
										);
										formikAddRow.handleChange(event);
										formikAddRow.setFieldValue(
											"assetId",
											site?.asset_id
										);
									}}
									value={formikAddRow.values.siteName}
									touched={!!formikAddRow.touched.siteName}
									error={!!formikAddRow.errors.siteName}
									errorText={
										formikAddRow.errors.siteName as string
									}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl sx={style.formControl}>
								<FormLabel id="file-select-label">
									File
								</FormLabel>
								<CustomSelect
									labelId="file-select-label"
									id="file-select"
									name="fileName"
									items={tender.historical_data.map(
										(element) => ({
											key: element.id,
											value: element.file_name,
										})
									)}
									label=""
									onChange={(event) => {
										let file = tender.historical_data.find(
											(element) =>
												element.file_name ===
												event.target.value
										);
										formikAddRow.handleChange(event);
										formikAddRow.setFieldValue(
											"fileId",
											file?.id
										);
									}}
									value={formikAddRow.values.fileName}
									touched={!!formikAddRow.touched.fileName}
									error={!!formikAddRow.errors.fileName}
									errorText={
										formikAddRow.errors.fileName as string
									}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<If condition={!formikAddRow.isSubmitting}>
								<Button
									disabled={formikAddRow.isSubmitting}
									type="submit"
								>
									Add Row
								</Button>
							</If>
							<If condition={formikAddRow.isSubmitting}>
								<CircularProgress color="primary" size={20} />
							</If>
						</Grid>
					</Grid>
				</form>

				<form
					onSubmit={formikTSInfo.handleSubmit}
					style={
						(style.tsInfoForm as any)(
							tsRows.length === 0
						) as CSSProperties
					}
				>
					{tsRows.length > 0 && (
						<Grid container spacing={2}>
							<Grid
								container
								sx={{
									backgroundColor: backgroundNeutralSecondary,
								}}
							>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">Site</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1.8}>
									<Typography variant="h3">
										Input file
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Sheet name
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Production Column
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Index (Date)
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Index (Time)
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">Unit</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Timezone
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={1}>
									<Typography variant="h3">
										Row Offset
									</Typography>
								</Grid>
								<Grid sx={style.gridItemTitle} item xs={2}>
									<Typography variant="h3">
										Technical Error
									</Typography>
								</Grid>

								<Grid
									sx={style.gridItemTitle}
									item
									xs={0.2}
								></Grid>
							</Grid>

							{tsRows.map((row) => (
								<Grid
									container
									sx={{ marginTop: "20px" }}
									spacing={1}
									key={row.id}
								>
									<Grid sx={style.gridItem} item xs={1}>
										<Typography paragraph>
											{row.siteName}
										</Typography>
									</Grid>
									<Grid sx={style.gridItem} item xs={1.8}>
										<Typography paragraph>
											{row.fileName}
										</Typography>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomSelect
											labelId="file-select-label"
											id="file-select"
											items={
												row.sheetNames
													? row.sheetNames.map(
															(
																element: string
															) => ({
																key: element,
																value: element,
															})
													  )
													: []
											}
											label=""
											name={`${row.id}.sheetName`}
											onChange={formikTSInfo.handleChange}
											value={
												formikTSInfo.values[row.id]
													?.sheetName || ""
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.sheetName
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.sheetName
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.sheetName as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomInput
											type="number"
											name={`${row.id}.productionCol`}
											onChange={(event) => {
												formikTSInfo.setFieldValue(
													`${row.id}.assetId`,
													row.assetId
												);
												formikTSInfo.setFieldValue(
													`${row.id}.fileId`,
													row.fileId
												);
												formikTSInfo.setFieldValue(
													`${row.id}.id`,
													row.id
												);
												formikTSInfo.handleChange(
													event
												);
											}}
											value={
												formikTSInfo.values[row.id]
													?.productionCol ?? 2
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.productionCol
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.productionCol
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.productionCol as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomInput
											type="number"
											name={`${row.id}.indexDate`}
											onChange={formikTSInfo.handleChange}
											value={
												formikTSInfo.values[row.id]
													?.indexDate ?? 1
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.indexDate
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.indexDate
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.indexDate as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomInput
											type="number"
											name={`${row.id}.indexTime`}
											onChange={formikTSInfo.handleChange}
											value={
												formikTSInfo.values[row.id]
													?.indexTime
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.indexTime
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.indexTime
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.indexTime as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomSelect
											labelId="file-select-label"
											id="file-select"
											items={[
												{ key: "kW", value: "kW" },
												{ key: "mW", value: "mW" },
												{ key: "kWh", value: "kWh" },
												{ key: "mWh", value: "mWh" },
											]}
											label=""
											name={`${row.id}.unit`}
											onChange={formikTSInfo.handleChange}
											value={
												formikTSInfo.values[row.id]
													?.unit ?? "kW"
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.unit
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.unit
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.unit as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomSelect
											labelId="file-select-label"
											id="file-select"
											items={[
												{ key: "CET", value: "CET" },
												{ key: "UTC", value: "UTC" },
											]}
											label=""
											name={`${row.id}.timezone`}
											onChange={formikTSInfo.handleChange}
											defaultValue={"CET"}
											value={
												formikTSInfo.values[row.id]
													?.timezone ?? "CET"
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.timezone
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.timezone
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.timezone as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={1}>
										<CustomInput
											type="number"
											name={`${row.id}.offset`}
											onChange={formikTSInfo.handleChange}
											value={
												formikTSInfo.values[row.id]
													?.offset ?? 0
											}
											touched={
												!!formikTSInfo.touched[row.id]
													?.offset
											}
											error={
												!!formikTSInfo.errors[row.id]
													?.offset
											}
											errorText={
												formikTSInfo.errors[row.id]
													?.offset as string
											}
										/>
									</Grid>
									<Grid sx={style.gridItem} item xs={2}>
										<Typography paragraph>
											{tsImporterErrors &&
												tsImporterErrors.length > 0 &&
												tsImporterErrors.map(
													(error, index) => {
														const errorKey =
															Object.keys(
																error
															)[0];
														if (
															errorKey === row.id
														) {
															return (
																<span
																	key={index}
																>
																	{
																		error[
																			errorKey
																		]
																	}
																</span>
															);
														}
														return null;
													}
												)}
										</Typography>
									</Grid>
									<Grid sx={style.gridItem} item xs={0.2}>
										<IconButton
											onClick={() => deleteTsRow(row.id)}
											aria-label="delete"
										>
											<DeleteIcon />
										</IconButton>
									</Grid>
								</Grid>
							))}
						</Grid>
					)}
					<If condition={!!tsImpWarning && tsImpWarning.length > 0}>
						<Grid container spacing={1} columns={4}>
							<Grid
								container
								sx={{
									backgroundColor: backgroundNeutralSecondary,
								}}
							>
								<Grid
									sx={style.gridItemTitleWarning}
									item
									xs={4}
								>
									<Typography variant="h3">Site</Typography>
								</Grid>
								<Grid
									sx={style.gridItemTitleWarning}
									item
									xs={4}
								>
									<Typography variant="h3">
										Message
									</Typography>
								</Grid>
								<Grid
									sx={style.gridItemTitleWarning}
									item
									xs={4}
								></Grid>
							</Grid>

							{tsImpWarning?.map((warn) => (
								<Grid container key={warn.site_name}>
									<Grid sx={style.gridItem} item xs={4}>
										<Typography paragraph>
											{warn.site_name}
										</Typography>
									</Grid>
									<Grid sx={style.gridItem} item xs={4}>
										<Typography paragraph>
											{warn.message}
										</Typography>
									</Grid>
									<Grid
										sx={style.gridItem}
										item
										xs={4}
									></Grid>
								</Grid>
							))}
						</Grid>
					</If>

					<DialogActions>
						<Button disabled={loading} onClick={() => onClose()}>
							Close
						</Button>
						<If
							condition={
								!!submitModal &&
								tsRows.length > 0 &&
								!tsImpWarning &&
								!loading
							}
						>
							<Button type="submit">Submit</Button>
						</If>
						<If
							condition={
								!!tsImpWarning && tsRows.length > 0 && !loading
							}
						>
							<Button type="submit">Continue</Button>
						</If>
						<If condition={loading}>
							<CircularProgress color="primary" size={20} />
						</If>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>
	);
}
