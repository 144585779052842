import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { If } from "./If";

const style = {
	containerWithIcon: {
		display: "flex",
		gap: "16px",
		alignItems: "center",
		width: "100%",
	},
};

export function HamburgerOptions(props: {
	title: string;
	action: () => void;
	testId?: string;
	icon?: ReactNode;
	textStyle?: Record<string, string>;
}) {
	return (
		<Box
			sx={{
				...(props.icon ? style.containerWithIcon : {}),
				display: "flex",
				flex: 1,
				padding: "8px",
			}}
			onClick={props.action}
		>
			<If condition={props.icon}>
				<Box sx={props.textStyle || {}}>{props.icon}</Box>
			</If>
			<Typography
				variant="h3"
				style={props.textStyle || {}}
				data-testid={props.testId}
				component={"span"}
				sx={{ width: "100%" }}
			>
				{props.title}
			</Typography>
		</Box>
	);
}
