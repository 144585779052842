import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const DealTabCancelButton = ({
	handleCancelDeal,
	isLoadingCancelDeal,
}: {
	handleCancelDeal: any;
	isLoadingCancelDeal: boolean;
}) => {
	return (
		<Box
			sx={{
				height: "24px",
				width: "68px",
				textAlign: "center",
			}}
		>
			{isLoadingCancelDeal && <CircularProgress size="24px" />}
			{!isLoadingCancelDeal && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "2px",
					}}
					role="button"
					onClick={handleCancelDeal}
				>
					<HighlightOffIcon />
					Cancel
				</Box>
			)}
		</Box>
	);
};

export default DealTabCancelButton;
