import { createApi } from "@reduxjs/toolkit/dist/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";
import {
	BuildCurtailementParamsTypeType,
	BuildCurtailmentObjectsResponseType,
	ControlObject,
	CurtailmentObjectType,
	GetOneSiteParamsType,
	GetOneSiteResponseType,
	GetSitesResponseType,
	RedispatchConstraint,
	NewRedispatchConstraintParamsType,
	EditRedispatchConstraintParamsType,
} from "./sitesApi.types";
import { merge, serializeQueryArgs } from "../infiniteScroll.helpers";
import { DynamicEndpointQuery, PageInfoType } from "../gecoTypes";

export const sitesSortableColumns = ["id", "name", "tpr_system_id"];
export const sitesSearchableColumns = [
	"name",
	"tpr_system_id",
	"site_contractperiods__contractperiod__contract__name",
];

const sitesApi = createApi({
	reducerPath: "sitesApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_GECO_API_PATH!,
	}),
	tagTypes: ["Sites", "ControlObjects", "RedispatchConstraints"],
	endpoints: (builder) => ({
		getSites: builder.query<
			GetSitesResponseType,
			PageInfoType & DynamicEndpointQuery
		>({
			query: ({ page = 1, sortOn, order, search, filters, endpoint }) => {
				const searchParams = search
					? sitesSearchableColumns.reduce(
							(acc, curr) => ({
								...acc,
								[`search__${curr}`]: search,
							}),
							{}
					  )
					: {};

				return {
					url: `${endpoint || ""}/sites`,
					method: "GET",
					params: {
						page,
						...filters,
						sort:
							sortOn && order
								? `${order === "DESC" ? "-" : ""}${sortOn}`
								: "-id",
						...searchParams,
					},
				};
			},
			serializeQueryArgs: serializeQueryArgs,
			merge: merge,
			providesTags: ["Sites"],
		}),
		getOneSite: builder.query<
			GetOneSiteResponseType,
			GetOneSiteParamsType & DynamicEndpointQuery
		>({
			query: ({ siteId, endpoint }) => {
				return {
					url: `${endpoint || ""}/sites/${siteId}`,
					method: "GET",
				};
			},
			providesTags: ["Sites"],
		}),
		getSiteControlObjects: builder.query<
			ControlObject[],
			GetOneSiteParamsType & DynamicEndpointQuery
		>({
			query: ({ siteId, endpoint }) => {
				return {
					url: `${endpoint || ""}/sites/${siteId}/control_objects`,
					method: "GET",
				};
			},
		}),
		updateOneSite: builder.mutation<
			void,
			GetOneSiteResponseType & DynamicEndpointQuery
		>({
			query: ({ endpoint, ...site }) => {
				return {
					url: `${endpoint || ""}/sites/${site.id}`,
					method: "PUT",
					data: site,
				};
			},
			invalidatesTags: ["Sites"],
		}),
		getSiteCurtailmentObjects: builder.query<
			CurtailmentObjectType[],
			GetOneSiteParamsType & DynamicEndpointQuery
		>({
			query: ({ siteId, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${siteId}/curtailment_objects`,
					method: "GET",
				};
			},
		}),
		getSiteRedispatchConstraints: builder.query<
			RedispatchConstraint[],
			GetOneSiteParamsType & DynamicEndpointQuery
		>({
			query: ({ siteId, endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${siteId}/redispatch_constraints`,
					method: "GET",
				};
			},
			providesTags: ["RedispatchConstraints"],
		}),

		addRedispatchConstraint: builder.mutation<
			void,
			NewRedispatchConstraintParamsType & DynamicEndpointQuery
		>({
			query: ({ endpoint, site_id, data }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${site_id}/redispatch_constraints`,
					method: "POST",
					data,
				};
			},
			invalidatesTags: ["RedispatchConstraints"],
		}),
		buildCurtailmentObjects: builder.mutation<
			BuildCurtailmentObjectsResponseType,
			BuildCurtailementParamsTypeType & DynamicEndpointQuery
		>({
			query: ({ endpoint, siteId, startDate, endDate }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${siteId}/curtailment_objects/build?start_date=${startDate}&end_date=${endDate}`,
					method: "POST",
				};
			},
		}),
		addContact: builder.mutation<
			void,
			{
				site_id: number;
				contact: { id: number; name: string };
			} & DynamicEndpointQuery
		>({
			query: ({ endpoint, site_id, contact }) => {
				return {
					url: `${endpoint || ""}/sites/${site_id}/contact`,
					method: "POST",
					data: contact,
				};
			},
			invalidatesTags: ["Sites"],
		}),
		removeContact: builder.mutation<
			void,
			{ site_id: number; contact_id: number } & DynamicEndpointQuery
		>({
			query: ({ endpoint, site_id, contact_id }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${site_id}/contact/${contact_id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: ["Sites"],
		}),
		editRedispatchConstraint: builder.mutation<
			void,
			EditRedispatchConstraintParamsType & DynamicEndpointQuery
		>({
			query: ({ endpoint, site_id, constraint_id, data }) => {
				return {
					url: `${
						endpoint || ""
					}/sites/${site_id}/redispatch_constraints/${constraint_id}`,
					method: "PUT",
					data,
				};
			},
			invalidatesTags: ["RedispatchConstraints"],
		}),
	}),
});

export const {
	useAddContactMutation,
	useAddRedispatchConstraintMutation,
	useEditRedispatchConstraintMutation,
	useGetOneSiteQuery,
	useGetSiteCurtailmentObjectsQuery,
	useGetSitesQuery,
	useLazyGetOneSiteQuery,
	useLazyGetSiteControlObjectsQuery,
	useLazyGetSiteRedispatchConstraintsQuery,
	useRemoveContactMutation,
	useUpdateOneSiteMutation,
	useBuildCurtailmentObjectsMutation,
} = sitesApi;

export default sitesApi;
