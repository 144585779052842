import { BookingLogsListType } from "../../../requests_geco/contractsApi/contractsApi.types";
import { Box } from "@mui/material";
import { style } from "./BookingLogs.style";
import SimpleTable from "../../../common/components/SimpleTable/SimpleTable";

const BookingLogs = ({
	logs,
	isFetching,
}: {
	logs?: BookingLogsListType;
	isFetching: boolean;
}) => {
	const headers = [
		{
			label: "timestamp",
			accessor: "timestamp",
		},
		{
			label: "log_level",
			accessor: "log_level",
		},
		{
			label: "system",
			accessor: "system",
		},
		{
			label: "message",
			accessor: "message",
		},
	];

	return (
		<Box sx={style.logWrapper}>
			<SimpleTable
				headers={headers}
				items={logs ?? []}
				isFetching={isFetching}
			/>
		</Box>
	);
};

export default BookingLogs;
