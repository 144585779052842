import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import { PrimaryButton } from "../../../common/components/CustomButton";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";
import CustomInput from "../../../common/components/CustomInput";
import { useEffect, useState } from "react";
import {
	selectPricingTemplateErrorMessage,
	selectIsPricingTemplateAdded,
	selectPricingTemplateAddLoader,
} from "../PricingRequestStepper.selector";
import { useDispatch, useSelector } from "react-redux";
import { If } from "../../../common/components/If";
import { addNewPricingTemplatesErrors } from "../PricingRequestStepper.slice";
import { enqueueSnackbar } from "notistack";

interface PricingTemplateModalProps extends React.PropsWithChildren<{}> {
	open: boolean;
	onClose: () => void;
	onSubmit: (title: string) => void;
}
export default function PricingTemplateModal(props: PricingTemplateModalProps) {
	const { open, onClose, onSubmit } = props;
	const [templateTitle, setTemplateTitle] = useState("");
	const dispatch = useDispatch();
	const selectPricingTemplateError = useSelector(
		selectPricingTemplateErrorMessage
	);
	const selectPricingTemplateLoader = useSelector(
		selectPricingTemplateAddLoader
	);
	const selectIsPricingTemplateAddedSuccess = useSelector(
		selectIsPricingTemplateAdded
	);

	const resetErrorAndTemplateTitle = () => {
		dispatch(addNewPricingTemplatesErrors(""));
		setTemplateTitle("");
	};
	const triggerClosePricingTemplateModal = () => {
		resetErrorAndTemplateTitle();
		onClose();
	};
	useEffect(() => {
		if (selectIsPricingTemplateAddedSuccess) {
			enqueueSnackbar("Pricing Template created successfully", {
				variant: "success",
				autoHideDuration: 3000,
			});
			onClose();
		}
		dispatch(addNewPricingTemplatesErrors(""));
	}, [selectIsPricingTemplateAddedSuccess, templateTitle]);

	useEffect(() => {
		resetErrorAndTemplateTitle();
	}, []);
	return (
		<Dialog
			PaperProps={{ style: { minHeight: "28%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "45%" } }}
			open={open}
			onClose={() => triggerClosePricingTemplateModal()}
		>
			<DialogTitle>Pricing Template</DialogTitle>
			<DialogContent>
				<Typography display="block" marginTop={"1em"} gutterBottom>
					Template name
				</Typography>
				<CustomInput
					id="template-title"
					aria-label="template-input"
					value={templateTitle}
					onChange={(e) => setTemplateTitle(e.target.value)}
					sx={{ width: "500px" }}
				></CustomInput>
				<If condition={selectPricingTemplateError}>
					<Typography sx={{ color: "red", fontSize: "13px" }}>
						{selectPricingTemplateError}
					</Typography>
				</If>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" }}>
				<PrimaryButton
					text="Close"
					type="button"
					color="secondary"
					onClick={() => triggerClosePricingTemplateModal()}
				/>
				<PrimaryButton
					type="button"
					text="Save template"
					color="primary"
					disabled={!selectPricingTemplateLoader && !templateTitle}
					onClick={() => onSubmit(templateTitle)}
				>
					<AddCircleIconOutline
						sx={{
							color: "#fff",
						}}
					/>
				</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
}
