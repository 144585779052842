import { NJFormItem } from "@engie-group/fluid-design-system-react";
import { Box } from "@mui/material";
import { ErrorText } from "../ErrorText";

const style = {
	container: {
		width: 162,
	},
};

export interface DatePickerProps {
	onChange: (event: any) => void;
	onBlur?: (event: any) => void;
	name: string;
	label: string;
	value: string | undefined;
	errorMessage?: string;
	disabled?: boolean;
	isReadOnly?: boolean;
	sx?: Record<string, any>;
}

const FluidDatePicker = ({ sx = {}, ...props }: DatePickerProps) => {
	return (
		<Box sx={{ ...style.container, ...sx }}>
			<NJFormItem
				isDisabled={!!props.disabled}
				id={props.name}
				label={props.label}
				labelKind="static"
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				title={props.label}
				type="date"
				onBlur={props.onBlur}
				readOnly={props.isReadOnly}
			/>
			<ErrorText>{props.errorMessage}</ErrorText>
		</Box>
	);
};

export default FluidDatePicker;
