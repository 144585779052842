import { apiGet, apiPost } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import {
	setAllNotificationItemAsRead,
	setAllNotifications,
	setHasNextPage,
	setIsLoading,
	setNotificationItemAsRead,
} from "./notificationsSlice";

export function getAllNotifications(
	readFilter: boolean,
	from_id?: number
): any {
	return function (dispatch: AppDispatch) {
		dispatch(setIsLoading(true));
		const queryString = from_id
			? `?from_id=${from_id}&read=${readFilter ? 1 : 0}&per_page=50`
			: `?read=${readFilter ? 1 : 0}&per_page=50`;
		return apiGet(`notifications${queryString}`).then((response) => {
			dispatch(
				setAllNotifications({
					data: response.data.data,
					read: readFilter,
				})
			);
			dispatch(setIsLoading(false));
			dispatch(setHasNextPage(response.data.has_next_page));
		});
	};
}
export function SetNotificationsAsRead(ids: number[]): any {
	return function (dispatch: AppDispatch) {
		dispatch(setNotificationItemAsRead({ ids }));
		return apiPost("notifications/read", { ids }).then(() => {});
	};
}

export function SetAllNotificationsAsRead(): any {
	return function (dispatch: AppDispatch) {
		dispatch(setAllNotificationItemAsRead());
		return apiPost("notifications/read", { ids: "all" }).then(() => {});
	};
}
