import {
	errorColor,
	primaryColor,
	successColor,
	warningColor,
} from "../../core/theme";
import { ContractChangeStatus } from "../../requests_geco/contractChangesApi/contractChangesApi.types";
import { ContractStatus } from "../../requests_geco/contractsApi/contractsApi.types";

export function getContractStatusColor(
	status?: ContractChangeStatus | ContractStatus
) {
	switch (status) {
		case ContractChangeStatus.REJECTED:
			return errorColor;
		case ContractChangeStatus.PENDING_VALIDATION:
			return warningColor;
		case ContractStatus.VALID:
			return successColor;
		default:
			return primaryColor;
	}
}

export function getValidationButtonColor(
	status?: ContractChangeStatus | ContractStatus
) {
	switch (status) {
		case ContractChangeStatus.REJECTED:
			return "destructive";
		case ContractChangeStatus.PENDING_VALIDATION:
			return "primary";
		case ContractStatus.VALID:
			return "secondary";
		default:
			return "primary";
	}
}

export function getContractStatusText(
	status?: ContractChangeStatus | ContractStatus
): string {
	switch (status) {
		case ContractStatus.VALID:
			return "Valid";
		case ContractStatus.TO_REVIEW:
			return "To Review";
		case ContractStatus.DRAFT:
			return "Draft";
		case ContractChangeStatus.PENDING_VALIDATION:
			return "Waiting trader approval";
		case ContractChangeStatus.APPROVED:
			return "Trader applied changes";
		case ContractChangeStatus.REJECTED:
			return "Trader rejected changes";
		default:
			return "";
	}
}

export function getValidationButtonText(
	status?: ContractChangeStatus | ContractStatus
): string {
	switch (status) {
		case ContractStatus.VALID:
			return "Review Validation";
		case ContractStatus.TO_REVIEW:
			return "Review contract";
		case ContractStatus.DRAFT:
			return "Draft";
		case ContractChangeStatus.PENDING_VALIDATION:
			return "Review changes";
		case ContractChangeStatus.APPROVED:
			return "Validate contract";
		case ContractChangeStatus.REJECTED:
			return "Review trader comments";
		default:
			return "";
	}
}

export function jsonStringToObjectString(jsonString: string) {
	try {
		const jsonObject = JSON.parse(jsonString);
		const formattedObjectString = JSON.stringify(jsonObject, null, 2);
		return "`\n" + formattedObjectString + "\n`";
	} catch (error) {
		console.error("Error parsing JSON:", error);
		return "";
	}
}
