import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Typography,
	IconButton,
	Box,
	Collapse,
	Chip,
	Checkbox,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { ReactCountryFlag } from "react-country-flag";
import {
	primaryColor,
	grey,
	successColor,
	warningPrimary,
} from "../../../core/theme";
import { Tender } from "../tender.module";
import CommentIcon from "@mui/icons-material/Comment";
import FileIcon from "@mui/icons-material/AttachFile";
import PricingStatusCell from "./PricingStatusCell";
import { Link, useNavigate } from "react-router-dom";
import TextVerticalClamp from "../../../common/components/TextVerticalClamp";
import { useAppDispatch } from "../../../common/hooks/default";
import { updateTenders, loadTenderPricings } from "../tender.thunk";
import { useSelector } from "react-redux";
import { CollapseArrow } from "../../../common/components/CollapseArrow";
import { If } from "../../../common/components/If";
import { formatDecimal } from "../../../common/utils/formatDecimal";
import PricingTable from "./PricingTable";
import usePrevious from "../../../common/hooks/usePrevious";
import { selectAreTenderPricingsLoading } from "../tender.selector";
import { FlashUpdateTableCell } from "../../../common/components/FlashUpdateTableCell";
import {
	selectAuthenticatedUser,
	selectUserGroups,
} from "../../authentication/auth.selector";
import {
	getTenderRenewexSubmissionColor,
	getTenderRenewexSubmissionText,
} from "../tender.model";
import { useTenderActions } from "../hooks/useTenderActions";
import { getTenderColorByStatusOrDeadline } from "../tender.constant";
import * as _ from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const style: any = {
	rowContainer: {
		padding: "0 8px",
		border: `1px solid ${grey}`,
	},
	tableCell: {
		padding: "0 0 0 10px",
		"& svg, & img": {
			float: "left",
			padding: "2px 5px 0 0",
		},
		"& p": {
			overflow: "hidden",
		},
	},
	menuCell: {
		padding: 0,
		width: "66px",
	},
	menuItem: {
		"&:hover": {
			borderLeft: `2px ${primaryColor} solid`,
		},
	},
	collapsibleCell: {
		padding: 0,
		border: 0,
	},
	pricingTableContainer: {
		overflowX: "auto",
		overflowY: "hidden",
		"--ms-overflow-style": "none" /* IE and Edge */,
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		background: "white",
		padding: "8px",
		position: "relative",
		bottom: "6px",
	},
	pickupMe: {
		opacity: 0,
		"&:hover": {
			opacity: 1,
			border: "1px dashed rgba(0,0,0,0.2)",
			padding: "18px",
			textAlign: "center",
		},
	},
	checkICon: {
		color: successColor,
	},
	nokIcon: {
		color: warningPrimary,
	},
};

export default function Row({
	tender,
	colsLength,
	targetId,
	selectedIds,
	toggleTenderSelection,
}: {
	tender: Tender;
	colsLength: number;
	targetId?: string;
	selectedIds: number[];
	toggleTenderSelection: (tender: Tender) => void;
}) {
	const [open, setOpen] = useState(false);
	const wasOpen = usePrevious(open);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const currentUser = useSelector(selectAuthenticatedUser);
	const pricingsAreLoading = useSelector(
		selectAreTenderPricingsLoading(tender.id)
	);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const isUserTrader = useSelector(selectUserGroups)?.isTrader;

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (open && !wasOpen) {
			dispatch(loadTenderPricings(tender.id));
		}
	}, [open, wasOpen]);

	const { tenderAction, tenderActionModal } = useTenderActions(tender);

	const pickupAction = useMemo(
		() =>
			_.find(
				tender.actions_blotter,
				(action) => action.action === "PICKUP"
			),
		[tender]
	);

	return (
		<>
			<TableRow
				id={targetId || ""}
				sx={{
					...style.rowContainer,
					backgroundColor: getTenderColorByStatusOrDeadline(tender),
					boxShadow: `inset 0px 0px 0px 5px ${getTenderColorByStatusOrDeadline(
						tender
					)}`,
				}}
			>
				<TableCell align="center">
					<Checkbox
						checked={selectedIds.includes(tender?.id)}
						onChange={() => toggleTenderSelection(tender)}
					/>
				</TableCell>
				<TableCell>
					<CollapseArrow
						isOpen={open}
						setIsOpen={setOpen}
					></CollapseArrow>
				</TableCell>
				<FlashUpdateTableCell
					field={tender?.status}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<PricingStatusCell
						status={(tender?.status as any) ?? "UNDER_PRICING"}
					></PricingStatusCell>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.name}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Link to={`/tenders/${tender?.id}/my-pricings`}>
						{tender?.name}
					</Link>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.account?.name}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<TextVerticalClamp maxLine={3}>
						<Typography variant="subtitle2" component="p">
							{tender?.account?.name}
						</Typography>
					</TextVerticalClamp>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.countries?.[0] ?? ""}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<ReactCountryFlag
						countryCode={
							tender?.countries ? tender?.countries[0] : ""
						}
						svg
						title={tender?.countries ? tender?.countries[0] : ""}
					/>{" "}
					{tender?.countries ? tender?.countries[0] : ""}
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.installed_capacity_mw}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{formatDecimal(tender?.installed_capacity_mw)}
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.site_count}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender.count_attached_sites}/{tender?.site_count}
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={tender?.direction}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.direction}
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={
						tender?.technologies?.length > 1
							? "Mixed"
							: tender?.technologies?.[0]
					}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.technologies?.length > 1
							? "Mixed"
							: tender?.technologies}
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={tender?.originator?.gaia_id}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.originator?.first_name}{" "}
						{tender?.originator?.last_name}
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.trader?.gaia_id}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<If condition={tender?.trader}>
						<Typography variant="subtitle2" component="p">
							{tender?.trader?.first_name}{" "}
							{tender?.trader?.last_name}
						</Typography>
					</If>
					<If
						condition={
							!tender?.trader && isUserTrader && pickupAction
						}
					>
						<Typography
							variant="subtitle2"
							component="p"
							sx={style.pickupMe}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								dispatch(
									updateTenders(
										[tender.id],
										pickupAction!,
										currentUser?.name
									)
								);
							}}
						>
							Pickup
						</Typography>
					</If>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.due_date}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.due_date}
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.validity_date}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.validity_date}
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={tender?.creation_date}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.creation_date}
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={0}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						<CommentIcon
							sx={{ fontSize: 16, color: primaryColor }}
						/>
						0
					</Typography>
				</FlashUpdateTableCell>
				<FlashUpdateTableCell
					field={
						"" +
						tender?.historical_data_count +
						tender?.latest_renewex_submission?.status
					}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography
						variant="subtitle2"
						component="p"
						sx={{ display: "flex", alignItems: "center" }}
					>
						<If condition={tender?.historical_data_count}>
							<FileIcon
								sx={{
									fontSize: 20,
									color: getTenderRenewexSubmissionColor(
										tender.latest_renewex_submission?.status
									),
								}}
							/>
							<If
								condition={getTenderRenewexSubmissionText(
									tender.latest_renewex_submission?.status
								)}
							>
								<Chip
									label={getTenderRenewexSubmissionText(
										tender.latest_renewex_submission?.status
									)}
									sx={{
										background:
											getTenderRenewexSubmissionColor(
												tender.latest_renewex_submission
													?.status
											),
										color: "white",
									}}
								/>
							</If>
						</If>
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={tender?.cor_opportunity_id}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.cor_opportunity_id}
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={tender?.pricing_stage}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.pricing_stage}
					</Typography>
				</FlashUpdateTableCell>

				<FlashUpdateTableCell
					field={tender?.has_negative_price_clause}
					isUpdate={tender?.isNewUpdate}
					sx={style.tableCell}
				>
					<Typography variant="subtitle2" component="p">
						{tender?.has_negative_price_clause ? (
							<CheckIcon sx={style.checkICon} />
						) : (
							<ClearIcon sx={style.nokIcon} />
						)}
					</Typography>
				</FlashUpdateTableCell>

				<TableCell sx={style.menuCell}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<IconButton onClick={handleClickMenu}>
							<MoreVertIcon sx={{ color: primaryColor }} />
						</IconButton>
					</Box>
					<Menu
						id="tender-menu"
						aria-labelledby="tender-menu"
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleCloseMenu}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						PaperProps={{ style: { borderRadius: 0 } }}
					>
						{tender.actions_blotter?.map(
							(action: any, key: number) => (
								<MenuItem
									key={action + key}
									sx={style.menuItem}
									component={
										action.redirection_url ? "a" : "li"
									}
									href={
										action.redirection_url
											? action.redirection_url
											: ""
									}
									onClick={(event: any) => {
										event.preventDefault();
										if (action.redirection_url) {
											navigate(action.redirection_url);
											return false;
										}
										handleCloseMenu();
										tenderAction(action);
									}}
								>
									{action.display_name}
								</MenuItem>
							)
						)}
					</Menu>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell sx={style.collapsibleCell} colSpan={colsLength + 1}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={style.pricingTableContainer}>
							<PricingTable
								isLoading={pricingsAreLoading}
								pricings={tender.pricings}
								tenderId={tender.id}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			{tenderActionModal}
		</>
	);
}
