import contractChangesApi, {
	useCreateContractChangeMutation,
	useLazyGetOneContractChangeQuery,
	useApproveContractChangeMutation,
	useRejectContractChangeMutation,
} from "./contractChangesApi";

export {
	useCreateContractChangeMutation,
	useLazyGetOneContractChangeQuery,
	useApproveContractChangeMutation,
	useRejectContractChangeMutation,
};
export default contractChangesApi;
