import React from "react";

export interface IfProps extends React.PropsWithChildren<{}> {
	condition: any;
}

export function If(props: IfProps) {
	if (!props.condition) {
		return null;
	}

	return <>{props.children}</>;
}
