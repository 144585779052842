export interface ValidationErrorType {
	loc: string[];
	msg: string;
	type: string;
}

export interface ErrorType {
	data:
		| string
		| { errors: string[] | ValidationErrorType[] }
		| ValidationErrorType[];
}

function formatDataIsString(error: ErrorType): string | undefined {
	if (typeof error?.data === "string") {
		return error?.data;
	}
}

function formatErrorObjectData(error: ErrorType): string[] | undefined {
	const data = error?.data as { errors: string[] | ValidationErrorType[] };
	if (Array.isArray(data?.errors)) {
		return data?.errors.map((singleError: string | ValidationErrorType) =>
			typeof singleError === "string" ? singleError : singleError.msg
		);
	}
}

function formatValidationErrorData(error: ErrorType): string[] | undefined {
	const data = error?.data as ValidationErrorType[];
	if (Array.isArray(data)) {
		return data.map((entry: ValidationErrorType) => {
			return `location: ${
				entry.loc.length > 0 ? entry.loc.join("") : "Global"
			} | issue: ${entry.msg} ${entry.type}`;
		});
	}
}

export function formatApiErrorMessage(error: ErrorType) {
	return (
		formatDataIsString(error) ??
		formatErrorObjectData(error) ??
		formatValidationErrorData(error) ??
		""
	);
}
