export const style = {
	container: {
		display: "flex",
		flexDirection: "row",
		left: {
			flex: 1,
		},
		right: {
			flex: 2,
			gap: 10,
		},
	},
};
