import { Typography } from "@mui/material";
import { style } from "./PrimaryTitle.style";

export interface PrimaryTitleProps {
	title: string;
	underline?: boolean;
}

export const PrimaryTitle = ({ title, underline }: PrimaryTitleProps) => {
	return (
		<Typography
			style={{
				...style.override,
				textDecoration: underline ? "underline" : "",
			}}
			variant="h3"
		>
			{title}
		</Typography>
	);
};
