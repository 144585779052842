export const style = {
	wrapper: {
		display: "flex",
		flexDirection: "row",
		gap: "8px",
		flexWrap: "wrap",
		maxWidth: "600px",
	},
	item: {
		border: "1px solid #9eadb8",
	},
};
