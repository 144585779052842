import { Box } from "@mui/material";
import {
	isColorLight,
	adjustBrightness,
	stringToPastelColor,
} from "../../utils/colorsHelpers";
import { style, TagSizeType } from "./Tag.style";

const Tag = ({
	hexColor,
	label,
	rounded,
	size,
}: {
	hexColor?: string;
	label: string;
	rounded?: boolean;
	size?: TagSizeType;
}) => {
	const backgroundColor = hexColor ?? stringToPastelColor(label);
	const borderColor = adjustBrightness(backgroundColor, -90);
	const textColor = isColorLight(backgroundColor) ? "black" : "white";

	return (
		<Box
			sx={style.tag({
				backgroundColor,
				borderColor,
				rounded,
				textColor,
				size,
			})}
		>
			{label}
		</Box>
	);
};

export default Tag;
