import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./core/rootReducers";
import rootThunks from "./core/rootThunks";
import logger from "redux-logger";
import geodeApi from "./features/reporting_page/geodeApi";
import contractsApi from "./requests_geco/contractsApi";
import contractChangesApi from "./requests_geco/contractChangesApi";
import referentialApi from "./requests_geco/referentialApi/referentialApi";
import gepoTprProxyApi from "./requests_geco/gepoTprProxyApi/gepoTprProxyApi";
import sitesApi from "./requests_geco/sitesApi";

const useReduxlogger = process.env.REACT_APP_USE_REDUX_LOGGER;

const middlewareList = [
	geodeApi.middleware,
	contractsApi.middleware,
	contractChangesApi.middleware,
	referentialApi.middleware,
	gepoTprProxyApi.middleware,
	sitesApi.middleware,
];

if (useReduxlogger && JSON.parse(useReduxlogger)) {
	middlewareList.push(logger);
}

export function setupStore() {
	return configureStore({
		reducer: rootReducers,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
				thunk: {
					extraArgument: rootThunks,
				},
			}).concat(...middlewareList),
	});
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
