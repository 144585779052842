import { apiPost } from "../utils/request.utils";
import axios from "axios";
import {
	UploadedFile,
	UploadingFile,
	UploadingFileStatus,
} from "./files.module";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

export interface PreSignedUploadResponse {
	parts: string[];
	file_id: number;
	upload_id: string;
	on_failure: string;
}

const CHUNK_SIZE = 5242880; // 5Mib

function sliceFile(file: File) {
	let start = 0;
	const chunks = [];
	while (start < file.size) {
		chunks.push(file.slice(start, start + CHUNK_SIZE));
		start += CHUNK_SIZE;
	}
	return chunks;
}

export function useUploadFiles(
	onSuccess: (files: UploadedFile[]) => void,
	extraData: any = {},
	prefix: string = ""
): [
	UploadingFile[],
	(files: Array<File>) => void,
	(file: UploadingFile) => void
] {
	const [pendingFiles, setPendingFiles] = useState<UploadingFile[]>([]);

	const uploadSingleFile = useCallback(
		async (uploadingFile: UploadingFile) => {
			try {
				const file = uploadingFile.file;
				const response = await apiPost("files", {
					file_name: file.name,
					file_extension: file.name.split(".").reverse()[0],
					file_size: file.size,
					prefix,
					...extraData,
				});
				setPendingFiles((prev) =>
					prev.map((pending) => {
						if (pending.id !== uploadingFile.id) {
							return pending;
						}
						return { ...pending, progress: 10 };
					})
				);
				const presignedUpload: PreSignedUploadResponse = response.data;
				// using a custom axios instance to avoid the interceptor adding headers
				// and to be able to use another url
				const axiosInstance = axios.create();
				const chunks = sliceFile(file);
				const chunkPercentage = 80 / chunks.length;
				try {
					const proms = chunks.map(async (chunk, index) => {
						const config = {
							headers: {
								"content-type": false,
								accept: "application/json",
							},
						};
						const partresponse = await axiosInstance.put(
							presignedUpload.parts[index],
							chunk,
							config
						);
						setPendingFiles((prev) =>
							prev.map((pending) => {
								if (pending.id !== uploadingFile.id) {
									return pending;
								}
								return {
									...pending,
									progress:
										pending.progress + chunkPercentage,
								};
							})
						);
						return {
							ETag: partresponse.headers.etag?.slice(1, -1),
							PartNumber: index + 1,
						};
					});
					const partsResponse = await Promise.all(proms);
					await apiPost("files/complete", {
						upload_id: presignedUpload.upload_id,
						file_id: presignedUpload.file_id,
						parts: partsResponse,
					});
					setPendingFiles((prev) =>
						prev.map((pending) => {
							if (pending.id !== uploadingFile.id) {
								return pending;
							}
							return { ...pending, progress: 100 };
						})
					);
				} catch (err: any) {
					await axiosInstance.delete(presignedUpload.on_failure);
					throw err;
				}

				const uploaded = response.data.file as UploadedFile;
				setPendingFiles((prev) =>
					prev.map((pending) => {
						if (pending.id !== uploadingFile.id) {
							return pending;
						}
						return {
							...pending,
							progress: 100,
							uploaded_file: uploaded,
							status: UploadingFileStatus.SUCCESS,
						};
					})
				);
			} catch (err: any) {
				setPendingFiles((prev) =>
					prev.map((pending) => {
						if (pending.id !== uploadingFile.id) {
							return pending;
						}
						return {
							...pending,
							progress: 0,
							status: UploadingFileStatus.FAILURE,
						};
					})
				);
			}
		},
		[setPendingFiles, onSuccess]
	);

	const upload = useCallback(
		(files: Array<File>) => {
			const newPendingFiles = [];
			for (const file of files) {
				newPendingFiles.push({
					file,
					progress: 0,
					id: uuid(),
					status: UploadingFileStatus.PENDING,
				});
			}
			setPendingFiles(newPendingFiles);
			for (const file of newPendingFiles) {
				uploadSingleFile(file);
			}
		},
		[setPendingFiles, uploadSingleFile]
	);

	useEffect(() => {
		if (
			pendingFiles.length > 0 &&
			pendingFiles.filter(
				(file) => file.status !== UploadingFileStatus.SUCCESS
			).length === 0
		) {
			onSuccess(
				// @ts-ignore
				pendingFiles
					.map((file) => file.uploaded_file)
					.filter((file) => !!file)
			);
			setPendingFiles((prev) =>
				prev.filter(
					(file) => file.status === UploadingFileStatus.FAILURE
				)
			);
		}
	}, [pendingFiles, onSuccess, setPendingFiles]);

	const cancel = useCallback(
		(toCancel: UploadingFile) => {
			setPendingFiles((prev) =>
				prev.filter((file) => file.id !== toCancel.id)
			);
		},
		[setPendingFiles]
	);

	return [pendingFiles, upload, cancel];
}
