import { Box } from "@mui/material";
import { InfoBlockItem } from "../InfoBlockItem";
import { style } from "./ContactCard.style";
import { FluidButton } from "../FluidButton";

export interface ContactCardProps {
	id: number;
	email: string;
	recipient_types: string[];
	removeContact?: (id: number) => void;
}

export const ContactCard = ({
	id,
	email,
	recipient_types,
	removeContact,
}: ContactCardProps) => {
	return (
		<Box sx={style.container}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<a style={style.container.email_link} type="email">
					{email}
				</a>
				{!!removeContact && (
					<FluidButton
						label="Remove"
						icon="remove_circle_outline"
						onClick={() => removeContact(id)}
						type="button"
						variant="inverse"
					/>
				)}
			</Box>
			<InfoBlockItem
				label="Recipient Type"
				value={recipient_types.join(" - ")}
			/>
		</Box>
	);
};
