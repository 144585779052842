import { Box, DialogTitle, Typography } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Spacer } from "../../../../../common/components/Spacer";
import WarningIcon from "@mui/icons-material/Warning";
import { warningPrimary, warningTertiary } from "../../../../../core/theme";
import { TitleWithIcon } from "../../../../../common/components/TitleWithIcon";
import ErrorIcon from "@mui/icons-material/Error";
import { GeneratedCurtailmentObjectItem } from "./GeneratedCurtailmentObjectItem";

const style = {
	container: {
		width: "580px",
		padding: "24px",
	},
	bodyContainer: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	waringIcon: {
		fontSize: "64px",
		color: warningPrimary,
		lineHeight: 0,
	},
	errorIcon: {
		color: warningPrimary,
		lineHeight: 0,
	},
	errorMessageContainer: {
		border: `1px solid ${warningPrimary}`,
		background: warningTertiary,
		padding: "16px",
		width: "400px",
	},
};

export interface GeneratingCurtailmentObjectIncompleteModalProps {
	isOpen: boolean;
	onClose: () => void;
	errors: string[];
	success: { name: string; start_date: string; end_date: string }[];
}

export const GeneratingCurtailmentObjectIncompleteModal = ({
	isOpen,
	onClose,
	errors,
	success,
}: GeneratingCurtailmentObjectIncompleteModalProps) => {
	return (
		<Modal sx={style.container} open={isOpen} onClose={onClose}>
			<Box sx={style.bodyContainer}>
				<span style={style.waringIcon}>
					<WarningIcon fontSize="inherit" />
				</span>
				<DialogTitle>Generation incomplete</DialogTitle>
				<Typography>
					Some curtailment objects could not be generated.
				</Typography>
				<Typography>Please check and retry.</Typography>
				<Spacer gap={32} />
				<Box sx={style.errorMessageContainer}>
					<TitleWithIcon
						icon={
							<span style={style.errorIcon}>
								<ErrorIcon />
							</span>
						}
					>
						<Typography>
							2 curtailment objects could not be generated
						</Typography>
					</TitleWithIcon>
				</Box>
				<Spacer gap={32} />
				{(errors || []).map((message, index) => (
					<>
						<GeneratedCurtailmentObjectItem
							key={index}
							message={message}
						/>
						<Spacer gap={8} />
					</>
				))}
				{(success || []).map((curtailmentObject, index) => (
					<>
						<GeneratedCurtailmentObjectItem
							key={index}
							curtailment={curtailmentObject}
						/>
						<Spacer gap={8} />
					</>
				))}
				<Spacer gap={32} />
				<PrimaryButton
					onClick={onClose}
					text="Close"
					type="button"
					color="primary"
				/>
			</Box>
		</Modal>
	);
};
