export const getSyntheticEvent = (name: string, value: any) => {
	return {
		preventDefault: () => {
			console.log("Prevent default called");
		},
		target: {
			name,
			value,
		},
	};
};
