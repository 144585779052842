import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Table,
	Box,
	CircularProgress,
	Typography,
	Checkbox,
	FormControlLabel,
	AccordionDetails,
	SxProps,
	Theme,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { PricingHeader } from "./PricingModal/TableHeader";
import { Pricing, PricingParty } from "../pricingListSlice";
import { MarginTableRow } from "./PricingModal/TableRow";
import { grey } from "@mui/material/colors";
import { If } from "../../../common/components/If";
import { useEffect, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import usePrevious from "../../../common/hooks/usePrevious";
import _ from "lodash";

const style: { [key: string]: SxProps<Theme> } = {
	tableWrapper: {
		border: `1px solid ${grey}`,
	},
	accordionContent: {
		maxHeight: 300,
		overflow: "scroll",
	},
	accordionSummary: {
		"& div:nth-of-type(1)": {
			display: "flex",
			flexDirection: "column",
		},
		"& input": {
			alignItems: "start",
		},
	},
};

interface BookingModalProps extends React.PropsWithChildren<{}> {
	open: boolean;
	onClose: () => void;
	onSubmit: (
		values: {},
		pricingIds: number[],
		pricingPartyIds: { id: number; party_id: number }[]
	) => void;
	forPricings: Pricing[];
	isLoading: boolean;
}
export default function BookingModal(props: BookingModalProps) {
	const { open, forPricings, isLoading, onClose, onSubmit } = props;
	const wasOpen = usePrevious(open);
	const [selectedParties, setSelectedParties] = useState<number[]>([]);

	const groupedByParty = useMemo(() => {
		const grouped: Record<
			number,
			Record<
				number,
				{
					party: PricingParty[];
					percentage: number;
					pricings: Pricing[];
				}
			>
		> = {};
		props.forPricings.forEach((pricing) => {
			pricing.parties?.forEach((party) => {
				const partyId = party.party.party_id || 0;
				const percentage = party.percentage || 0;
				if (!(partyId in grouped)) {
					grouped[partyId] = {
						[percentage]: {
							party: [],
							percentage: party.percentage,
							pricings: [],
						},
					};
				} else if (partyId in grouped) {
					if (!(percentage in grouped[partyId])) {
						grouped[partyId] = {
							...grouped[partyId],
							[percentage]: {
								party: [],
								percentage: party.percentage,
								pricings: [],
							},
						};
					}
				}
				grouped[partyId][percentage].party.push(party);
				grouped[partyId][percentage].pricings.push(pricing);
			});
		});
		let asArray: Array<{
			party: PricingParty[];
			percentage: number;
			pricings: Pricing[];
		}> = [];
		for (const partyId in grouped) {
			asArray = asArray.concat(_.flatMapDeep(grouped[partyId]));
		}
		return asArray;
	}, [props.forPricings]);

	useEffect(() => {
		if (!open && wasOpen) {
			setSelectedParties([]);
		}
	}, [open, wasOpen, setSelectedParties]);

	return (
		<Dialog
			PaperProps={{ style: { minHeight: "28%" } }}
			sx={{ "& .MuiDialog-paper": { minWidth: "45%" } }}
			open={open}
			onClose={() => onClose()}
		>
			<DialogTitle>Booking pricings</DialogTitle>
			<DialogContent>
				<DialogContentText sx={style.contentText}>
					{"Please select which party's pricings should be booked"}
				</DialogContentText>
				<If condition={(forPricings?.length || 0) > 0 && !isLoading}>
					{groupedByParty.map(({ party, pricings, percentage }) => (
						<Box
							sx={style.tableWrapper}
							key={party[0].party?.party_id}
						>
							<Accordion defaultExpanded={true}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
									sx={style.accordionSummary}
								>
									<Box
										onClick={(ev) => {
											ev.preventDefault();
											ev.stopPropagation();
											if (party) {
												if (
													selectedParties.includes(
														party[0].id
													)
												) {
													setSelectedParties((prev) =>
														prev.filter(
															(id) =>
																id !==
																party[0].id
														)
													);
												} else {
													setSelectedParties(
														(prev) => [
															...prev,
															party[0].id,
														]
													);
												}
											}
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														party &&
														selectedParties.includes(
															party[0].id
														)
													}
												/>
											}
											label={`${party[0].party?.name}  ${percentage}%`}
										/>
									</Box>
									<Box
										onClick={(ev) => {
											ev.preventDefault();
											ev.stopPropagation();
										}}
										sx={{
											maxWidth: "400px",
											marginTop: "10px",
										}}
									></Box>
								</AccordionSummary>
								<AccordionDetails>
									<Box sx={style.accordionContent}>
										<Table
											stickyHeader
											size="small"
											aria-label="simple table"
											style={{ tableLayout: "fixed" }}
										>
											<PricingHeader />
											<TableBody>
												{pricings.map(
													(pricing, index) => (
														<MarginTableRow
															key={index}
															pricingRow={pricing}
														/>
													)
												)}
											</TableBody>
										</Table>
										<If
											condition={
												(forPricings?.length || 0) ===
													0 && !isLoading
											}
										>
											<Typography component="h5">
												No pricings available
											</Typography>
										</If>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Box>
					))}
				</If>
				<If condition={isLoading}>
					<CircularProgress color="inherit" size={20} />
				</If>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()}>Close</Button>
				<Button
					disabled={(selectedParties?.length || 0) === 0}
					onClick={() => {
						let pricingPartyIds: {
							id: number;
							party_id: number;
						}[] = [];
						let pricingIds: number[] = [];

						selectedParties.forEach((partyId) => {
							groupedByParty.forEach(({ party, pricings }) => {
								if (party[0].id === partyId) {
									pricingIds = [
										...pricingIds,
										...pricings.map(
											(pricing) => pricing.id
										),
									];
									pricingPartyIds = [
										...pricingPartyIds,
										...pricings.map((pricing: any, i) => ({
											id: pricing.id,
											party_id: party[i].id as number,
										})),
									];
								}
							});
						});
						onSubmit({}, pricingIds, pricingPartyIds);
					}}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
