import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ForbiddenAccess() {
	const navigate = useNavigate();
	useEffect(() => {
		const timeout = setTimeout(() => {
			navigate("/");
		}, 5000);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<Typography component="h5">
			Access denied, you will be returned to the home page in 5 seconds
		</Typography>
	);
}

export default ForbiddenAccess;
