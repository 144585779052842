import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../features/parameters/ParametersPage.selector";
import { useCallback } from "react";

export enum FeatureName {
	live_pricing = "live_pricing",
	use_market_data = "use_market_data",
	use_tpr_site = "use_tpr_site",
	contract_pages = "contract_pages",
	geco_endpoint = "geco_endpoint",
	is_goo_only_enabled = "is_goo_only_enabled",
	use_react_virtualisation = "use_react_virtualisation",
}

export interface UseFeatureFlags {
	isFeature: (name: FeatureName, is_active: boolean) => boolean;
}

export function useFeatureFlags(): UseFeatureFlags {
	const features = useSelector(selectFeatureFlags);

	const isFeature = useCallback(
		(name: FeatureName, is_active: boolean): boolean => {
			const keys = Object.keys(features as any);
			const featureName: string | undefined = keys.find(
				(featureE: string) => featureE === name
			);

			return !!(
				features &&
				featureName &&
				features[featureName as string] === is_active
			);
		},
		[features]
	);
	return {
		isFeature,
	};
}
