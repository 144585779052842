import { Action, Pricing } from "../../pricingListSlice";
import PricingModal from "../PricingModal";
import { ValidationError } from "../../../../common/hooks/usePydanticErrorsFormat";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import GenericTable from "../../../../common/components/GenericTable";
import { Site, isPortfolio } from "../../../sites/sites.module";
import { useTenderFormSitePricingModel } from "../../../../common/TableGenericModel/useTenderFormSitePricingModel";
import { selectTender } from "../../../tender_form/tenderForm.selector";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
	SiteTimeserie,
	isSiteTimeserie,
} from "../../../tender_page/tender.module";

interface AddRealizedPortModalProps {
	open: boolean;
	onClose: () => void;
	forPricings: Pricing[];
	onSubmit: (
		body: Record<string, string | number | boolean | object>
	) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

export function AddRealizedPortModal(props: AddRealizedPortModalProps) {
	const {
		open,
		isLoading,
		forPricings,
		action,
		errorMessage,
		onClose,
		onSubmit,
	} = props;
	const tender = useSelector(selectTender);

	const [sites, setSites] = useState<Site[]>([]);
	const [validationSchema, setValidationSchema] = useState({});

	useEffect(() => {
		if (tender && tender.ts_list) {
			setSites(
				forPricings[0]?.site_pricings.map((element: any) => ({
					...element.site,
					ts: (tender?.ts_list || [])
						.filter(isSiteTimeserie)
						.filter(
							(ts: SiteTimeserie) =>
								ts.site.id === element.site.id
						),
				}))
			);
		}
	}, [forPricings]);

	useEffect(() => {
		let schema = {};
		sites.forEach((site: Site) => {
			schema = {
				...schema,
				[site.asset_id || ""]: yup
					.object()
					.required("Tb ref is a required field"),
			};
		});
		setValidationSchema(yup.object(schema));
	}, [sites]);

	let formik = useFormik({
		initialValues: {},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: (values: any) => {
			onSubmit({ ts_map: values });
		},
	});

	const table = useTenderFormSitePricingModel({
		showRefreshCluster: false,
		handleChange: formik.handleChange,
		values: formik.values,
		errors: formik.errors,
		touched: formik.touched,
		setFieldValue: formik.setFieldValue,
	});

	return (
		<>
			<PricingModal
				open={open}
				onClose={onClose}
				onSubmit={() => {
					if (formik) {
						formik.submitForm();
					}
				}}
				forPricings={forPricings}
				title={action?.display_name || ""}
				subTitle={"Applicable for the following pricings:"}
				isSubmitDisabled={isLoading}
				isLoading={isLoading}
				errorMessage={errorMessage}
			>
				<form
					key={`form-action-pricing-modal`}
					onSubmit={formik.handleSubmit}
				>
					<GenericTable<Site, Site>
						keyExtractor={(data: Site) => {
							if (isPortfolio(data)) {
								return `portfolio_${data.id}`;
							}
							return `site_${data.id}`;
						}}
						virtualScrollable={true}
						selection={() => {}}
						itemName="Sites"
						updatePage={() => {}}
						page={0}
						model={sites}
						rowModel={table}
						isSelectable={false}
						loading={false}
					/>
				</form>
			</PricingModal>
		</>
	);
}
