import * as yup from "yup";
export const editSiteModalSchema = yup.object({
	name: yup.string().required("Name is a required field"),
	tpr_system_id: yup.string().required("Site tpr id reference is required"),

	max_grid_injection: yup.number().notRequired().nullable(),
	is_self_consumer: yup.bool().default(false),
	self_sufficiency_ratio: yup.number().when("is_self_consumer", {
		is: true,
		then: (schema) =>
			schema
				.required("Volume is required")
				.max(1, "self_sufficiency_ratio cannot be greater than 100%")
				.min(0, "self_sufficiency_ratio cannot be smaller that 0%"),
		otherwise: (schema) => schema.nullable(),
	}),
	self_consumption_ratio: yup.number().when("is_self_consumer", {
		is: true,
		then: (schema) =>
			schema
				.required("Volume is required")
				.max(1, "self_consumption_ratio cannot be greater than 100%")
				.min(0, "self_consumption_ratio cannot be smaller that 0%"),
		otherwise: (schema) => schema.nullable(),
	}),
});
