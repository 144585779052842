import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	SxProps,
	Tab,
	Tabs,
	Theme,
} from "@mui/material";
import * as React from "react";
import { getGraphData, getGraphTypes } from "../../pricingList.thunk";
import ProductionOverTimeGraph from "./ProductionOverTimeGraph";
import { useDispatch, useSelector } from "react-redux";
import { selectGraphTypes } from "../../pricingList.selector";
import { Pricing, initGraphTypes } from "../../pricingListSlice";
import {
	formatFrequencyGraphData,
	formatGraphData,
	formatWindSpeedRadiationGraphData,
} from "../../pricingList.constant";
import Series from "highcharts";
import { siteNameOrPortfolio } from "../../../../common/utils/siteNameOrPortfolio";
import WindSpeedRadiationGraph from "./WindSpeedRadiationGraph";
import FrequencyGraph from "./FrequencyGraph";
import _ from "lodash";
import { isBefore, parseISO } from "date-fns";

const style: { [key: string]: SxProps<Theme> } = {
	dialog: {
		"@media only screen and (min-height: 400px)": {
			minHeight: "95%",
		},
		"@media only screen and (min-height: 1000px)": {
			minHeight: "90%",
		},
		minWidth: "50%",
	},
	dialogText: {
		marginBottom: "30px",
	},
};

interface GraphModalProps extends React.PropsWithChildren<{}> {
	open: boolean;
	onClose: () => void;
	pricing?: Pricing;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
		</div>
	);
}

export default function GraphModal(props: GraphModalProps) {
	const { open, onClose, pricing } = props;
	const [productionOverTimeData, setProductionOverTimeData] = React.useState<
		(typeof Series)[]
	>([]);
	const [windSpeedRadiationData, setWindSpeedRadiationData] = React.useState<
		(typeof Series)[]
	>([]);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [activeTab, setActiveTab] = React.useState(0);

	const dispatch = useDispatch();
	const graphTypes = useSelector(selectGraphTypes);

	const [installedCapacity, setInstalledCapacity] = React.useState<number>(0);
	const [horizonWSR, setHorizonWSR] = React.useState<
		{ start_date: string; end_date: string } | undefined
	>(undefined);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};
	const fetchData = async (pricingId: number, kinds: string) => {
		let { result, installedCapa, horizon } = await getGraphData(
			pricingId,
			kinds
		)();
		setInstalledCapacity(installedCapa);

		setHorizonWSR(
			(prev: { start_date: string; end_date: string } | undefined) => ({
				start_date:
					!!prev &&
					isBefore(
						parseISO(prev?.start_date),
						parseISO(horizon?.start_date)
					)
						? prev?.start_date
						: horizon?.start_date,
				end_date:
					!!prev &&
					isBefore(
						parseISO(horizon?.end_date),
						parseISO(prev?.end_date)
					)
						? prev?.end_date
						: horizon?.end_date,
			})
		);
		return result;
	};

	function* generator(
		graphTypesP: { id: number; ts_name: string }[],
		kinds: string = ""
	) {
		yield* graphTypesP.map((graphType: { id: number; ts_name: string }) => {
			return Promise.resolve(fetchData(graphType.id, kinds));
		});
	}

	React.useEffect(() => {
		if (pricing) {
			dispatch(getGraphTypes(pricing?.pricing_group_id));
		}

		return () => {
			dispatch(initGraphTypes());
		};
	}, [pricing]);

	React.useEffect(() => {
		if (graphTypes && graphTypes.length > 0) {
			let iter = generator(graphTypes, "proxy,p50,average_monthly_proxy");
			graphTypes.forEach((graphType: { id: number; ts_name: string }) => {
				iter.next().value?.then((response: any) => {
					setProductionOverTimeData((prev: any) =>
						_.uniqBy(
							[
								...prev,
								...formatGraphData(graphType.ts_name, response),
							],
							"id"
						)
					);
				});
			});
		}
	}, [graphTypes]);

	React.useEffect(() => {
		if (activeTab === 1 && windSpeedRadiationData.length === 0) {
			if (graphTypes && graphTypes.length > 0) {
				let iter = generator(graphTypes, "wind_speed_radiation");
				graphTypes.forEach(
					(graphType: { id: number; ts_name: string }) => {
						iter.next().value?.then((response: any) => {
							if (response.error) {
								setError(response.error);
							}
							setWindSpeedRadiationData((prev: any) => [
								...prev,
								...formatWindSpeedRadiationGraphData(
									graphType.ts_name,
									response
								),
							]);
						});
					}
				);
			}
		}
	}, [activeTab]);

	return (
		<Box>
			<Dialog
				open={open}
				onClose={onClose}
				PaperProps={{ sx: style.dialog }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{`Graph: ${siteNameOrPortfolio(pricing)}`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						sx={style.dialogText}
						id="alert-dialog-description"
						component="div"
					>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={activeTab}
								onChange={handleTabChange}
								aria-label="basic tabs example"
							>
								<Tab label="Production Over Time" />
								<Tab label="Production vs Wind Speed" />
								<Tab label="Realized Distribution" />
							</Tabs>
						</Box>
						<CustomTabPanel value={activeTab} index={0}>
							<ProductionOverTimeGraph
								data={productionOverTimeData}
								noData={
									!!graphTypes &&
									graphTypes.length === 0 &&
									productionOverTimeData.length === 0
								}
								installedCapacity={installedCapacity}
							/>
						</CustomTabPanel>
						<CustomTabPanel value={activeTab} index={1}>
							<WindSpeedRadiationGraph
								data={windSpeedRadiationData}
								noData={
									!!graphTypes &&
									graphTypes.length === 0 &&
									windSpeedRadiationData.length === 0
								}
								installedCapacity={installedCapacity}
								techno={pricing?.techno?.name || ""}
								error={error}
								horizon={horizonWSR}
							/>
						</CustomTabPanel>
						<CustomTabPanel value={activeTab} index={2}>
							<FrequencyGraph
								data={formatFrequencyGraphData(
									productionOverTimeData
								)}
								noData={
									!!graphTypes &&
									graphTypes.length === 0 &&
									productionOverTimeData.length === 0
								}
								installedCapacity={installedCapacity}
								error={error}
								horizon={horizonWSR}
							/>
						</CustomTabPanel>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
