import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import FluidDatePicker from "../../../../common/components/FluidDatePicker";
import { noop } from "../../../../common/utils/operations";
import { truncate } from "../../../../common/utils/truncate";
import { Spacer } from "../../../../common/components/Spacer";
import { borderNeutralSecondary } from "../../../../core/theme";

export interface HorizonUnitProps {
	name: string;
	oldValue: string;
	newValue: string;
	isChecked: boolean;
	cardWidth?: number;
	oldValueErrorMessage?: string;
	newValueErrorMessage?: string;
	onChangeOldValue?: (event: any) => void;
	onChangeNewValue?: (event: any) => void;
	onChangeIsChecked?: (event: any) => void;
}

const HorizonUnit = ({
	name,
	oldValue,
	newValue,
	isChecked,
	cardWidth = 380,
	oldValueErrorMessage = "",
	newValueErrorMessage = "",
	onChangeOldValue = noop,
	onChangeNewValue = noop,
	onChangeIsChecked = noop,
}: HorizonUnitProps) => {
	const style = {
		container: {
			backgroundColor: "#052B5014",
			padding: "16px",
			width: `${cardWidth}px`,
			border: `1px solid ${borderNeutralSecondary}`,
		},
		dateContainer: {
			display: "flex",
			flexDirection: "row",
			gap: "24px",
		},
		nameContainer: {
			display: "flex",
			flexDirection: "row",
			gap: "8px",
			alignItems: "center",
		},
	};

	return (
		<Box sx={style.container}>
			<Box sx={style.nameContainer}>
				<Checkbox checked={isChecked} onChange={onChangeIsChecked} />
				<Tooltip arrow title={name}>
					<Typography component="h3">{truncate(name, 20)}</Typography>
				</Tooltip>
			</Box>
			<Spacer gap={8} />
			<Box sx={style.dateContainer}>
				<FluidDatePicker
					disabled
					onChange={onChangeOldValue}
					name={"old_value"}
					label={"Old Value"}
					value={oldValue}
					errorMessage={oldValueErrorMessage}
				/>
				<FluidDatePicker
					onChange={onChangeNewValue}
					name={"new_value"}
					label={"New Value"}
					value={newValue}
					errorMessage={newValueErrorMessage}
				/>
			</Box>
		</Box>
	);
};

export default HorizonUnit;
