import { NJLink } from "@engie-group/fluid-design-system-react";
import { useNavigate } from "react-router-dom";

export interface BreadCrumbsLinkProps extends React.PropsWithChildren<{}> {
	active?: boolean;
	path: string;
}

export const BreadCrumbsLink = (props: BreadCrumbsLinkProps) => {
	const navigate = useNavigate();
	return (
		<NJLink
			variant={props?.active ? "contextual" : "grayed"}
			href={props.path}
			onClick={(e) => {
				e.preventDefault();
				navigate(props.path);
				return false;
			}}
		>
			{props.children}
		</NJLink>
	);
};
