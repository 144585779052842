import { Box, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryButton } from "../CustomButton";
import React, { useCallback, useEffect, useState } from "react";
import { Spacer } from "../Spacer";
import { FieldValue } from "./FiltersDrawer.type";

export interface FilterDrawerProps extends React.PropsWithChildren<{}> {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	onApplyFilters: (filters: Record<string, FieldValue>) => void;
	defaultFilters?: Record<string, FieldValue>;
}

const FiltersDrawer = ({
	isOpen,
	title,
	children,
	defaultFilters = {},
	onClose,
	onApplyFilters,
}: FilterDrawerProps) => {
	const [filters, setFilters] =
		useState<Record<string, FieldValue>>(defaultFilters);

	useEffect(() => {
		setFilters(defaultFilters);
	}, [defaultFilters]);

	const onChangeFilters = useCallback(
		(prevKey: string, newKey: string, value: FieldValue) => {
			setFilters((prevFilters) => {
				const newFilters = { ...prevFilters };
				if (prevKey in newFilters) {
					delete newFilters[prevKey];
				}
				newFilters[newKey] = value;
				return newFilters;
			});
		},
		[filters]
	);

	const onClear = useCallback(() => {
		setFilters({});
		onApplyFilters({});
		onClose();
	}, [filters]);

	const onSubmit = useCallback(() => {
		onApplyFilters(filters);
		onClose();
	}, [filters]);

	return (
		<Drawer
			anchor={"left"}
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: { padding: "16px", width: "400px" },
			}}
		>
			<Box
				sx={{
					color: "#171D21",
					fontSize: 28,
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				{title}
				<IconButton edge="end" aria-label="delete" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					flexDirection: "column",
				}}
			>
				{React.Children.map(children, (child) => {
					return (
						<>
							<Spacer gap={24} />
							{React.cloneElement(child as React.ReactElement, {
								onChange: onChangeFilters,
							})}
						</>
					);
				})}
			</Box>

			<Spacer gap={32} />
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-start",
					gap: "10px",
					marginTop: "auto",
				}}
			>
				<PrimaryButton
					data-testid="apply-filters"
					text="Apply filters"
					type="button"
					color="primary"
					onClick={onSubmit}
					sx={{
						width: 130,
					}}
				/>
				<PrimaryButton
					text="Reset"
					type="button"
					color="secondary"
					sx={{
						width: 130,
					}}
					onClick={() => onClear()}
				/>
			</Box>
		</Drawer>
	);
};

export default FiltersDrawer;
