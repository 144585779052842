import { SxProps, Theme } from "@mui/material";

export type Stylesheet = Record<string, SxProps<Theme>>;

export function mergeStyles(...styles: Array<SxProps<Theme> | undefined>) {
	const truthyStyles = styles.filter((style) => !!style);
	let newStyle = {};
	for (const style of truthyStyles) {
		newStyle = { ...newStyle, ...style };
	}
	return newStyle as SxProps<Theme>;
}
