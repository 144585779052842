export const filtersLabels: { [key: string]: { label: string; key: string } } =
	{
		status: { label: "Status", key: "status" },
		country_id: { label: "Country", key: "country_id" },
		book_id: { label: "Book", key: "book_id" },
		contract_type_id: { label: "Contract type", key: "contract_type_id" },
		contractperiods__dealconfigs__method: {
			label: "Deal config method",
			key: "contractperiods__dealconfigs__method",
		},
		start_date: { label: "Start date", key: "start_date" },
		end_date: { label: "End date", key: "end_date" },
		signature_date: { label: "Signature date", key: "signature_date" },
		agreement_type_id: {
			label: "Aggreement type",
			key: "agreement_type_id",
		},
		contractperiods__site_contractperiods__site__name: {
			label: "Site name",
			key: "contractperiods__site_contractperiods__site__name",
		},
	};
