import { Box } from "@mui/system";
import { style } from "./InfoBlock.style";
import { ReactNode } from "react";

interface InfoType {
	label?: string;
	value?: ReactNode;
	icon?: ReactNode;
}

export interface InfoBlockProps extends React.PropsWithChildren<{}> {
	head?: InfoType[];
	info: InfoType[];
	withBackground?: boolean;
	fullWidth?: boolean;
}

const InfoBlock = ({
	children,
	head,
	info,
	withBackground,
	fullWidth,
}: InfoBlockProps) => (
	<Box
		sx={{
			...style.container,
			...(withBackground ? style.withBackground : {}),
			...(fullWidth ? style.fullWidth : {}),
		}}
	>
		{children}
		{head && (
			<Box sx={style.container.infoBlock}>
				{head.map((h) => (
					<Box
						key={h.label ?? String(h.value)}
						sx={{
							...style.container.infoBlock.item,
							...style.container.infoBlock.head,
						}}
					>
						{h.label && (
							<Box sx={style.container.infoBlock.title}>
								{h.label}:
							</Box>
						)}
						<Box sx={style.container.infoBlock.content}>
							{h.value}
						</Box>
					</Box>
				))}
			</Box>
		)}
		<Box sx={style.container.infoBlock}>
			{info.map((i) => (
				<Box key={i.label} sx={{ display: "flex", gap: "8px" }}>
					<Box sx={style.container.infoBlock.item}>
						<Box sx={style.container.infoBlock.title}>
							{i.label}
						</Box>
						<Box sx={style.container.infoBlock.content}>
							{i.value}
						</Box>
					</Box>
					{i.icon}
				</Box>
			))}
		</Box>
	</Box>
);

export default InfoBlock;
