import { Theme } from "@mui/material";

export const style = {
	active: {
		border: "1px solid #63be74",
		backgroundColor: "#e2f4e3",
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0 4px",
		gap: "2px",
		minWidth: "68px",
	},
	error: {
		border: "1px solid #f24822",
		backgroundColor: "#f5c7bd",
	},
	icon: { fontSize: "16px" },
	activeIcon: { color: "#63be74" },
	errorIcon: { color: "#f24822" },
	statusDialogTitle: { m: 0, p: 2, display: "flex", alignItems: "center" },
	statusDialogClose: {
		position: "absolute",
		right: 8,
		top: 8,
		color: (theme: Theme) => theme.palette.grey[500],
	},
	statusDialogTitleIcon: {
		fontSize: "18px",
		marginRight: "4px",
		color: "#007acd",
	},
	dealCopyErrorButton: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		gap: "4px",
	},
	dialogActions: { display: "flex", justifyContent: "center" },
};
