import { Box } from "@mui/material";
import { lightGrey, pagePadding } from "../../core/theme";

export enum PageContentVariantType {
	normal = "normal",
	fillScreen = "fillScreen",
	noPadding = "noPadding",
}

interface PageContentType {
	children: React.ReactNode;
	sx?: {};
	variant?: PageContentVariantType;
}

const style = {
	[PageContentVariantType.normal]: {
		backgroundColor: lightGrey,
		padding: `0 ${pagePadding}`,
	},
	[PageContentVariantType.fillScreen]: {
		height: "100%",
		overflow: "hidden",
	},
	[PageContentVariantType.noPadding]: {
		backgroundColor: lightGrey,
		padding: `0`,
	},
};

const PageContent = ({
	children,
	sx,
	variant = PageContentVariantType.normal,
}: PageContentType) => (
	<Box
		id="PageContent"
		sx={{ position: "relative", ...style[variant], ...sx }}
	>
		{children}
	</Box>
);

export default PageContent;
