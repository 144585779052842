import { isUploadedFile } from "../../../common/files/files.module";
import { Tender } from "../../tender_page/tender.module";
import { TenderCreation } from "../tenderForm.module";
import { TenderCreationModel } from "../tenderForm.schema";
import { format } from "date-fns";
import { ensureArray } from "../../../common/utils/ensureArray";

export function mapTenderStateToEndpointBody(
	tender: Tender,
	submitValue: TenderCreationModel
): TenderCreation {
	const newTender: TenderCreation = {
		account: {
			id: 0,
			external_id:
				submitValue.account?.external_id! ?? submitValue.account?.id!,
			name: submitValue.account ? submitValue.account.name : "",
		},
		name: submitValue.tenderName!,
		validity_date: format(
			submitValue.validityDate!,
			"yyyy-MM-dd'T'HH:mm:ss'.000Z'"
		),
		due_date: format(submitValue.deadline!, "yyyy-MM-dd'T'HH:mm:ss'.000Z'"),
		portfolios: ensureArray(tender.portfolios),
		direction: submitValue.direction,
		transaction_type: submitValue.transactionType,
		sites: ensureArray(tender.sites),
		historicalDataIds: submitValue.historicalData
			.filter(isUploadedFile)
			.map((file) => file.id),
		realized_data_will_be_added: submitValue.realized_data_will_be_added,
		pricing_stage: submitValue.pricing_stage,
		has_negative_price_clause: submitValue.has_negative_price_clause,
	};
	return newTender;
}
