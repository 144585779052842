import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SxProps, Theme, Box, Chip } from "@mui/material";
import { PrimaryButton } from "../../common/components/CustomButton";
import CustomInput from "../../common/components/CustomInput";
import { borderGrey } from "../../core/theme";
import CollapsibleTable from "./components/TenderTable";
import { Formik } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import {
	getTenders,
	mergeIncomingTenderUpdates,
	mergeIncomingTenders,
} from "./tender.thunk";
import { initTenders } from "./tenderSlice";
import { useEffect, useCallback, useState, useMemo } from "react";
import {
	selectUserGroups,
	selectUserIdentity,
} from "../authentication/auth.selector";
import {
	selectIsTenderListLoading,
	selectTenders,
	selectIsPaginationStopped,
} from "./tender.selector";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent, {
	PageContentVariantType,
} from "../../common/components/PageContent";
import { useSearchPaginate } from "../../common/hooks/useSearchPaginate";
import {
	filterTemplates,
	setFiltersDrawerOpened,
	setPersistedFilters,
} from "../filters/filtersSlice";
import {
	selectPersistedFilters,
	selectTendersOdataQuery,
} from "../filters/filters.selector";
import TuneIcon from "@mui/icons-material/Tune";
import FiltersDrawer from "../filters/Filters";
import { parseFromTemplate } from "../filters/utils";
import { startOfTomorrow } from "date-fns";
import useTimeout from "../../common/hooks/useSetTimeout";
import { Groups } from "../authentication/authentication.constant";
import CustomSelect from "../../common/components/CustomSelect";
import wsPublisher$ from "../../core/wsPublisher";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { WsMessage } from "../../core/socketProvider";
import { useAppDispatch } from "../../common/hooks/default";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../router-path";
import { useTenderSelection } from "./hooks/useTenderSelection";
import { useTenderBatchActions } from "./hooks/useTenderBatchActions";

const style: { [key: string]: SxProps<Theme> } = {
	searchInput: {
		minWidth: "325px",
		"& svg": {
			color: borderGrey,
		},
	},
	snackbar: {
		cursor: "pointer",
	},
};

function TenderPage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const groups = useSelector(selectUserGroups);
	const tenders = useSelector(selectTenders);
	const isTenderListLoading = useSelector(selectIsTenderListLoading);
	const isPaginationStopped = useSelector(selectIsPaginationStopped);
	const odataQuery = useSelector(selectTendersOdataQuery);
	const identity = useSelector(selectUserIdentity);
	const templates = useMemo(() => {
		const userGroups: Groups[] = [];
		if (groups?.isOriginator) {
			userGroups.push(Groups.originator);
		}
		if (groups?.isTrader) {
			userGroups.push(Groups.trader);
		}

		return filterTemplates.filter((f: any) =>
			f.roles.some((r: any) => userGroups.includes(r))
		);
	}, [groups]);

	const delayUntilNextDay = () =>
		startOfTomorrow().getTime() - new Date().getTime();

	const [filterRefreshDelay, setFilterRefreshDelay] =
		useState<number>(delayUntilNextDay);

	const fetchTenders = (page: number, search: string) => {
		dispatch(getTenders(page, search));
	};

	const { nextPage, currentPage, currentSearch, onSearch } =
		useSearchPaginate(fetchTenders);

	const onTenderMessageCreated = useCallback((messages: WsMessage<any>[]) => {
		dispatch(mergeIncomingTenders(messages.map((message) => message.data)));
	}, []);

	const onTenderMessageUpdated = useCallback(
		(messages: WsMessage<any>[]) => {
			dispatch(
				mergeIncomingTenderUpdates(
					messages.map((message) => message.data)
				)
			);
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_CREATED],
			onTenderMessageCreated
		);
	}, [onTenderMessageCreated]);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_UPDATED],
			onTenderMessageUpdated
		);
	}, [onTenderMessageUpdated]);

	const persistedFilters = useSelector(selectPersistedFilters);
	const filterCount = useMemo(() => {
		return persistedFilters.filters?.tenders_filters_count ?? 0;
	}, [persistedFilters.filters?.tenders_filters_count]);

	const [filtersResetAt, setFiltersResetAt] = useState<Date | undefined>();

	const [selectedTemplate, setSelectedTemplate] = useState<string | null>(
		persistedFilters.filters?.tenders_filter_template ?? ""
	);

	const {
		selectedTenders,
		selectedIds,
		toggleTenderSelection,
		selectAllTenders,
		clearSelectedTenders,
		availableActions,
	} = useTenderSelection(tenders);

	const { onTenderBatchAction, modal: BatchActionModal } =
		useTenderBatchActions(selectedTenders, clearSelectedTenders);

	const refreshFilters = (forceRefresh?: boolean, templ?: string) => {
		const filterTemplate =
			templ ??
			persistedFilters.filters?.tenders_filter_template ??
			"CUSTOM";
		if (filterTemplate !== "CUSTOM") {
			// load filter definition
			const template = templates.find(
				(t: any) => t.key === filterTemplate
			);
			if (!template) {
				setSelectedTemplate("CUSTOM");

				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_filter_template: "CUSTOM",
					})
				);
			} else {
				const transformedTemplate = parseFromTemplate(
					template.filter,
					identity
				);
				dispatch(
					setPersistedFilters({
						...persistedFilters.filters,
						tenders_filter_template: filterTemplate,
						tenders_filters: transformedTemplate,
					})
				);
			}
		}

		setFilterRefreshDelay(delayUntilNextDay);
		if (forceRefresh) {
			setFiltersResetAt(new Date());
		}
	};

	useTimeout(() => refreshFilters(), filterRefreshDelay);

	useEffect(() => {
		refreshFilters();
		return () => {
			dispatch(initTenders());
		};
	}, []);

	useEffect(() => {
		dispatch(initTenders());
		dispatch(getTenders(1, currentSearch));
	}, [odataQuery]);

	const onHandleNextPage = useCallback(() => {
		if (!isPaginationStopped) {
			nextPage(currentPage + 1);
		}
	}, [isPaginationStopped, currentPage]);

	return (
		<>
			<FiltersDrawer
				page="Tenders"
				filtersResetAt={filtersResetAt}
				setSelectedtemplate={setSelectedTemplate}
			/>
			<PageTitle
				label="My Tenders"
				rightSide={
					<Box sx={{ display: "flex", gap: "16px" }}>
						<Formik
							initialValues={{ search: "" }}
							onSubmit={(values) => {
								dispatch(initTenders());
								onSearch(values?.search);
							}}
						>
							{({ handleChange, handleSubmit, values }) => {
								return (
									<form onSubmit={handleSubmit}>
										<CustomInput
											id="search"
											aria-label="search-input"
											value={values.search}
											onChange={handleChange}
											sx={style.searchInput}
											InputProps={{
												startAdornment: <SearchIcon />,
											}}
											placeholder="Search"
										/>
										<PrimaryButton
											text="Search"
											type="submit"
											color="secondary"
											sx={{
												width: 106,
												top: "-3px",
											}}
										/>
										<Box sx={{ display: "inline-flex" }}>
											<CustomSelect
												datatestId="pricing_template"
												name="template"
												label=""
												value={
													selectedTemplate ?? "CUSTOM"
												}
												onChange={(event) => {
													setSelectedTemplate(
														event.target
															.value as string
													);

													dispatch(
														setPersistedFilters({
															...persistedFilters.filters,
															tenders_filter_template:
																event.target
																	.value as string,
														})
													);

													refreshFilters(
														true,
														event.target
															.value as string
													);
													setFiltersResetAt(
														new Date()
													);
												}}
												sx={{
													width: "250px",
													background: "#fff",
													borderRadius: "0px",
													marginLeft: "30px",
													marginRight: "20px",
												}}
												items={templates}
											></CustomSelect>
										</Box>

										<PrimaryButton
											text="Filters"
											type="button"
											color="secondary"
											sx={{
												width: 120,
												marginLeft: "10px",
												top: "-3px",
											}}
											onClick={() =>
												dispatch(
													setFiltersDrawerOpened({
														opened: true,
													})
												)
											}
											startIcon={<TuneIcon />}
											endIcon={
												<Chip
													label={
														<div
															style={{
																fontSize:
																	"13px",
															}}
														>
															{filterCount.toString()}
														</div>
													}
													size="small"
												/>
											}
										/>
									</form>
								);
							}}
						</Formik>
						{groups.isOriginator && (
							<PrimaryButton
								testId="create-tender"
								text="Add Tender"
								role="link"
								href={PATH.NEW_TENDER}
								onClick={(e) => {
									e.preventDefault();
									navigate(PATH.NEW_TENDER);
									return false;
								}}
							>
								<AddCircleIcon sx={{ color: "#fff" }} />
							</PrimaryButton>
						)}
					</Box>
				}
			/>

			<PageContent variant={PageContentVariantType.fillScreen}>
				<CollapsibleTable
					onTenderBatchAction={onTenderBatchAction}
					actions={availableActions}
					clearSelection={clearSelectedTenders}
					selectAll={selectAllTenders}
					toggleTenderSelection={toggleTenderSelection}
					selectedIds={selectedIds}
					tenders={tenders || []}
					isLoading={isTenderListLoading}
					onHandleNextPage={onHandleNextPage}
					currentPage={currentPage}
				/>
			</PageContent>
			{BatchActionModal}
		</>
	);
}

export default TenderPage;
