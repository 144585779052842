import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { labelColor } from "../../core/theme";
import {
	ImporterFileStatus,
	isUploadedFile,
	isUploadedFileSubmitted,
	RenewexSubmissionStatus,
	UploadingFileStatus,
} from "../files/files.module";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { If } from "./If";
import {
	getFileImportStatusColor,
	getFileImporterText,
	getTenderRenewexSubmissionColor,
	getTenderRenewexSubmissionText,
} from "../../features/tender_page/tender.model";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { downloadFile } from "../../features/tender_form/tenderForm.thunk";
import HelpIcon from "@mui/icons-material/Help";
import { FlePreviewProps } from "./DropZoneInput";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const style = {
	name: {
		color: labelColor,
	},
	wrapper: {
		margin: "4px 0",
		padding: "10px 10px",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		flex: 1,
		justifyContent: "space-between",
		borderRadius: "2px",
		borderLeft: `2px solid ${labelColor}`,
		position: "relative",
		width: "450px",
	},
	statusStyle: {
		minWidth: "60px",
		color: "white",
		fontSize: "10px",
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		padding: "2px 8px",
		borderRadius: "25px",
		marginBottom: "0",
	},
	linkSection: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		width: "20px",
	},
	fileNameWrapperSection: {
		flex: 1,
		display: "flex",
		alignItems: "center",
	},
	downloadSection: {
		display: "flex",
		justifyContent: "center",
		width: "50px",
		alignItems: "center",
	},
	statusSection: {
		display: "flex",
		justifyContent: "center",
		width: "80px",
		alignItems: "center",
	},
	helpSection: {
		display: "flex",
		width: "20px",
		alignItems: "center",
	},
	actionSection: {
		display: "flex",
		width: "20px",
		alignItems: "center",
	},
};

export const fileNameStyle: any = {
	display: "block",
	fontSize: "12px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	width: "200px",
};
export const iconStyle = {
	cursor: "pointer",
	color: labelColor,
};
export const failedStyle = {
	color: "red",
};

export const submitedRenewexFileWrapperStyle = (
	status: RenewexSubmissionStatus | undefined
) => {
	if (!status) return null;
	return {
		backgroundColor: getTenderRenewexSubmissionColor(status),
	};
};

export const fileImportStatusStyle = (
	status: ImporterFileStatus | undefined
) => {
	if (!status || status === ImporterFileStatus.NEW) return null;

	return {
		backgroundColor: getFileImportStatusColor(status),
	};
};

export interface HistoricalDataFilePreviewProps extends FlePreviewProps {}

export function HistoricalDataFilePreview(
	props: HistoricalDataFilePreviewProps
): JSX.Element {
	const { index, displayError, file, cancel, onRemove } = props;
	return (
		<Box sx={style.wrapper}>
			<Box sx={style.linkSection}>
				{isUploadedFile(file) && file.associated_site_name ? (
					<Tooltip title={`linked to ${file.associated_site_name}`}>
						<LinkIcon />
					</Tooltip>
				) : (
					<LinkOffIcon />
				)}
			</Box>
			<Box
				sx={style.fileNameWrapperSection}
				onClick={() => displayError(file)}
			>
				<InsertDriveFileIcon sx={style.name} />
				<Tooltip
					title={
						isUploadedFile(file) ? file.file_name : file.file.name
					}
				>
					<span style={fileNameStyle}>
						{isUploadedFile(file) ? file.file_name : file.file.name}
					</span>
				</Tooltip>
			</Box>
			<Box sx={style.downloadSection}>
				<If condition={isUploadedFile(file) && file?.id}>
					<CloudDownloadIcon
						sx={iconStyle}
						onClick={() =>
							isUploadedFile(file) && downloadFile(file.id)
						}
					/>
				</If>
			</Box>
			<Box sx={style.statusSection}>
				<Typography
					paragraph
					sx={
						isUploadedFileSubmitted(file)
							? [
									style.statusStyle,
									submitedRenewexFileWrapperStyle(
										file?.latest_renewex_submission?.status
									),
							  ]
							: [
									style.statusStyle,
									fileImportStatusStyle(file?.import_status),
							  ]
					}
				>
					{isUploadedFileSubmitted(file)
						? getTenderRenewexSubmissionText(
								file?.latest_renewex_submission?.status
						  )
						: getFileImporterText(file?.import_status)}
				</Typography>
			</Box>
			<Box sx={style.helpSection}>
				<If
					condition={
						file?.import_status === ImporterFileStatus.IMPORT_FAILED
					}
				>
					<HelpIcon
						sx={iconStyle}
						onClick={() => displayError(file)}
					/>
				</If>
			</Box>
			<Box sx={style.actionSection}>
				{!isUploadedFile(file) ? (
					<>
						<If
							condition={
								file.status === UploadingFileStatus.PENDING
							}
						>
							<CircularProgressWithLabel value={file.progress} />
						</If>
						<If
							condition={
								file.status === UploadingFileStatus.FAILURE
							}
						>
							<Typography sx={failedStyle}>Failed</Typography>
							<CloseIcon
								sx={iconStyle}
								onClick={() => cancel(file)}
							/>
						</If>
					</>
				) : (
					<CloseIcon sx={iconStyle} onClick={() => onRemove(index)} />
				)}
			</Box>
		</Box>
	);
}
