import { SxProps, Theme } from "@mui/material";
import { darkGrey } from "../../../core/theme";

export const style: { [key: string]: SxProps<Theme> } = {
	card: {
		height: "225px",
		width: "270px",
		borderRadius: "unset",
		margin: "0 12px 48px",
	},
	cardActionArea: {
		height: "100%",
	},
	cardContent: {
		boxSizing: "border-box",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		padding: "24px",
	},
	title: {
		fontWeight: 700,
		fontSize: "24px",
	},
	description: {
		color: darkGrey,
		fontSize: "14px",
		lineHeight: "20px",
		textAlign: "start",
	},
	icon: {
		marginTop: "auto",
		width: "48px",
		height: "48px",
		lineHeight: "48px",
	},
	lowerBorder: {
		position: "absolute",
		bottom: 0,
		left: 0,
		background: "linear-gradient(90deg, #00AAFF 0%, #23D2B5 100%)",
		width: "100%",
		height: "4px",
	},
};
