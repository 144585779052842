import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../features/parameters/ParametersPage.selector";
import { FeatureName } from "./useFeatureFlags";
import { useCallback } from "react";

export function useRtkQueryDynamicEndpoint<T extends (data?: any) => any>(
	baseFunction: T
): T {
	const features = useSelector(selectFeatureFlags);
	const gecoEndpoint = features[FeatureName.geco_endpoint];
	//@ts-ignore
	return useCallback(
		(data: any = {}) =>
			baseFunction({ ...(data || {}), endpoint: gecoEndpoint }),
		[gecoEndpoint]
	);
}
