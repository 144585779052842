import { enqueueSnackbar } from "notistack";
import { apiGet } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import { getMeRequestSuccess } from "./authSlice";

export function getMe(): any {
	return function (dispatch: AppDispatch) {
		return apiGet("me").then(
			(me) => {
				const data = me.data;
				return dispatch(getMeRequestSuccess(data));
			},
			(error) => {
				enqueueSnackbar(
					"" + (error.response?.data?.message || "Unknown Error"),
					{
						variant: "error",
						autoHideDuration: 3000,
					}
				);
			}
		);
	};
}
