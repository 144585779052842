import { useSelector } from "react-redux";
import { User } from "../../features/authentication/auth.model";
import { selectAuthenticatedUser } from "../../features/authentication/auth.selector";
import { Groups } from "../../features/authentication/authentication.constant";

export interface UseIsUserWithinGroups {
	isUserAuthorized: (groups: Groups[]) => boolean;
}

export const useIsUserWithinGroups = (): UseIsUserWithinGroups => {
	const currentUser: User | undefined = useSelector(selectAuthenticatedUser);

	const isUserAuthorized = (groups: Groups[]) => {
		const intersection = groups.filter((item) =>
			currentUser?.groups.includes(item)
		);

		return intersection.length > 0;
	};

	return {
		isUserAuthorized,
	};
};
