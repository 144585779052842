import { ReactNode } from "react";
import { style } from "./CurtailmentObject.style";
import { Box, SxProps, Theme } from "@mui/material";

interface CurtailmentDataListProps {
	list: { label?: ReactNode; value?: ReactNode; changed?: boolean }[];
	wrapperSx?: SxProps<Theme>;
}

const CurtailmentDataList = ({ list, wrapperSx }: CurtailmentDataListProps) => {
	return (
		<Box sx={wrapperSx}>
			{list.map((item, i) => (
				<Box key={i}>
					<Box sx={style.dataTitle}>
						{item.label}
						{item.changed && (
							<Box sx={style.changedCartridge}>Changed</Box>
						)}
					</Box>
					<Box sx={item.changed ? style.dataValueChanged : {}}>
						{item.value ?? <br />}
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default CurtailmentDataList;
