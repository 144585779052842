import { backgroundBrandSecondary, secondaryColor } from "../../../core/theme";

export const style = {
	thWrapper: { display: "flex", alignItems: "center" },
	headerRow: {
		th: { backgroundColor: "#E6EAED", fontWeight: "bold" },
	},
	tableBody: { position: "relative" },
	loaderLayer: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		bgcolor: "rgba(255, 255, 255, 0.5)",
		zIndex: 1,
		display: "flex",
		justifyContent: "center",
		paddingTop: "17px",
	},
	bodyRow: {
		clickable: {
			cursor: "pointer",
		},
	},
	td: {
		backgroundColor: "white",
	},
	tdHead: {
		backgroundColor: backgroundBrandSecondary,
	},
	orderByButton: {
		color: "#000",
		padding: 0,
		backgroundColor: secondaryColor,
		marginRight: "2px",
		display: "flex",
		flexDirection: "column",
	},
};
