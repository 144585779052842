import {
	ImporterFileStatus,
	RenewexSubmissionStatus,
} from "../../common/files/files.module";
import {
	errorColor,
	primaryColor,
	successColor,
	warningColor,
} from "../../core/theme";

export function getTenderRenewexSubmissionColor(
	status?: RenewexSubmissionStatus
) {
	switch (status) {
		case RenewexSubmissionStatus.ERROR:
			return errorColor;
		case RenewexSubmissionStatus.IN_PROGRESS:
			return warningColor;
		case RenewexSubmissionStatus.DONE:
		case RenewexSubmissionStatus.SUBMITTED:
			return successColor;
		case RenewexSubmissionStatus.OUT_OF_DATE:
			return "#FFA500";
		default:
			return primaryColor;
	}
}

export function getFileImportStatusColor(status?: ImporterFileStatus) {
	switch (status) {
		case ImporterFileStatus.IMPORT_FAILED:
		case ImporterFileStatus.SITE_NOT_FOUND:
			return errorColor;
		case ImporterFileStatus.PENDING_IMPORT:
			return warningColor;
		case ImporterFileStatus.IMPORTED:
			return successColor;
		default:
			return primaryColor;
	}
}

export function getTenderRenewexSubmissionText(
	status?: RenewexSubmissionStatus
): string {
	switch (status) {
		case RenewexSubmissionStatus.ERROR:
			return "Error";
		case RenewexSubmissionStatus.IN_PROGRESS:
			return "Pending";
		case RenewexSubmissionStatus.SUBMITTED:
			return "Submitted";
		case RenewexSubmissionStatus.OUT_OF_DATE:
			return "Out of date";
		case RenewexSubmissionStatus.DONE:
			return "Processed";
		default:
			return "";
	}
}

export function getFileImporterText(status?: ImporterFileStatus): string {
	switch (status) {
		case ImporterFileStatus.IMPORTED:
			return "Imported";
		case ImporterFileStatus.IMPORT_FAILED:
			return "Import Failed";
		case ImporterFileStatus.PENDING_IMPORT:
			return "Pending Import";
		case ImporterFileStatus.SITE_NOT_FOUND:
			return "Site Not Found";
		default:
			return "";
	}
}
