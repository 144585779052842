export const style = {
	container: {
		content: {
			display: "flex",
			flexDirection: "column",
			minWidth: 600,
			gap: "24px",
		},
		tprIdContainer: {
			width: "162px",
		},
		minHeight: "100px",
	},
	title: {
		paddingLeft: "16px",
	},
	actionContainer: {
		paddingRight: "16px",
		paddingBottom: "16px",
	},
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
};
