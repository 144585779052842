import { Formik } from "formik";
import FluidSelect from "../../../../common/components/FluidSelect";
import { Spacer } from "../../../../common/components/Spacer";
import {
	AdjustmentsFrequency,
	ContractDetailType,
	ContractPeriodType,
	TradeType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import { DatePickers } from "./DatePickers";
import { CommodityFixing } from "./CommodityFixing";
import { StatusField } from "./StatusField";
import { Toggle } from "../../../../common/components/Toggle";
import {
	GeneralTabFormikData,
	generalTabFormikInitialValueMapper,
	formikDataToContractModelMapper,
} from "../../formik/generalTabFormik";
import { If } from "../../../../common/components/If";
import { Box, CircularProgress } from "@mui/material";
import { getSyntheticEvent } from "../../../../common/utils/getSyntheticEvent";
import { useCallback, useState } from "react";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { NegativePriceInputs } from "./NegativePriceInputs";
import { NegativePriceIndex } from "../../../../requests_geco/referentialApi/referentialApi.types";
import { FluidNumber } from "../../../../common/components/FluidNumberField";
import { FluidTextField } from "../../../../common/components/FluidTextField";
import { HorizonChange } from "../../../horizon_change/HorizonChange";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
	},
	submitWrapper: {
		marginLeft: "auto",
	},
};

export interface GeneralTabProps {
	contract?: ContractDetailType;
	contractPeriod: ContractPeriodType | undefined;
	onSaveDraftContract: (contract: ContractDetailType) => void;
	negativePriceIndexes: NegativePriceIndex[] | undefined;
}

const GeneralTab = ({
	contract,
	contractPeriod,
	negativePriceIndexes,
	onSaveDraftContract,
}: GeneralTabProps) => {
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [isHorizonChangeOpen, setHorizonChangeOpen] = useState(false);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const onUpdateGeneralTab = useCallback(
		(data: GeneralTabFormikData) => {
			if (isUserAuthorizedBool) {
				return onSaveDraftContract(
					formikDataToContractModelMapper(
						data,
						contract as ContractDetailType,
						contractPeriod?.id!
					)
				);
			}
		},
		[contract, contractPeriod?.id]
	);

	return (
		<>
			<If condition={!contract}>
				<CircularProgress
					sx={{ marginTop: "40px" }}
					data-testid="loader"
				/>
			</If>
			<If condition={!!contract}>
				<Formik
					enableReinitialize
					initialValues={generalTabFormikInitialValueMapper(
						contract as ContractDetailType,
						contractPeriod?.id!
					)}
					onSubmit={onUpdateGeneralTab}
				>
					{({ handleChange, handleSubmit, values, dirty }) => (
						<form onSubmit={handleSubmit}>
							<Box sx={style.container}>
								<StatusField
									onChange={(status: string) => {
										handleChange(
											getSyntheticEvent("status", status)
										);
									}}
									value={values.status}
									isDisabled={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<FluidTextField
									onChange={handleChange}
									value={values.name}
									name={"name"}
									label={"Name"}
									isReadOnly={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<DatePickers
									onChange={handleChange}
									tradeDate={values.trade_date}
									startDate={values.start_date}
									endDate={values.end_date}
									onOpenChangeHorizon={() =>
										setHorizonChangeOpen(true)
									}
									isDisabled={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<Toggle
									onChange={handleChange}
									isTrue={values.test_phase || false}
									name={"test_phase"}
									label={"Test Phase"}
									isDisabled={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<FluidSelect
									isReadOnly
									isLabelStatic
									items={[
										{
											value: TradeType.PPA_BASE_PHYSICAL,
											label: "Ppa Base Physical",
										},
										{
											value: TradeType.PPA_PHYSICAL,
											label: "Ppa Physical",
										},
									]}
									label={"PPA Type"}
									name={"trade_type"}
									onChange={(value: unknown) => {
										// SelectFluid sends us only the value of the option and not the all input event
										handleChange(
											getSyntheticEvent(
												"trade_type",
												value
											)
										);
									}}
									value={values.trade_type}
								/>
								<Spacer gap={24} />
								<If
									condition={
										values.trade_type ===
										TradeType.PPA_BASE_PHYSICAL
									}
								>
									<FluidNumber
										onChange={handleChange}
										value={values.base_prod_capacity || 0}
										name={"base_prod_capacity"}
										title={"Base Prod Capacity (MW)"}
									/>
								</If>
								<Spacer gap={24} />
								<CommodityFixing
									onChange={handleChange}
									commodity={values.commodity}
								/>
								<Spacer gap={24} />
								<Toggle
									name={"has_negative_price"}
									label={"Condition Negative Price"}
									onChange={handleChange}
									isTrue={!!values.has_negative_price}
									isDisabled={!isUserAuthorizedBool}
								/>
								<If condition={values.has_negative_price}>
									<Spacer gap={24} />
									<NegativePriceInputs
										onChange={handleChange}
										negativePriceValue={
											values.negative_price_value
										}
										negativePriceType={
											values.negative_price_type
										}
										currentNegativePriceIndex={
											values.negative_price_index
										}
										negativePriceIndexes={
											negativePriceIndexes || []
										}
										isReadOnly={!isUserAuthorizedBool}
									/>
								</If>
								<Spacer gap={24} />
								<Toggle
									name={"invoice_decomposition"}
									label={"Invoice Decomposition"}
									description={
										"True if the invoiced is decomposed by post (Click, Unclick, Negative Price etc) or not"
									}
									onChange={handleChange}
									isTrue={!!values.invoice_decomposition}
									isDisabled={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<Toggle
									name={"has_adjustments"}
									label={"Has Adjustments"}
									description={
										"For trades with final settlements only : says if monthly settlements are adjusted or not"
									}
									onChange={handleChange}
									isTrue={!!values.adjustment_frequency}
									isDisabled={!isUserAuthorizedBool}
								/>
								<Spacer gap={24} />
								<FluidSelect
									isLabelStatic
									items={[
										{
											label: AdjustmentsFrequency.MONTHLY,
											value: AdjustmentsFrequency.MONTHLY,
										},
										{
											label: AdjustmentsFrequency.YEARLY,
											value: AdjustmentsFrequency.YEARLY,
										},
										{
											label: AdjustmentsFrequency.QUARTERLY,
											value: AdjustmentsFrequency.QUARTERLY,
										},
										{
											label: AdjustmentsFrequency.DAILY,
											value: AdjustmentsFrequency.DAILY,
										},
										{
											label: AdjustmentsFrequency.WEEKLY,
											value: AdjustmentsFrequency.WEEKLY,
										},
										{
											label: "Semi Annually",
											value: AdjustmentsFrequency.SEMI_ANNUALLY,
										},
										{
											label: "At Maturity",
											value: AdjustmentsFrequency.AT_MATURITY,
										},
									]}
									label={"Adjustment Frequency"}
									name={"adjustment_frequency"}
									description="Used only for BaseProd deals"
									onChange={(value: unknown) => {
										// SelectFluid sends us only the value of the option and not the all input event
										handleChange(
											getSyntheticEvent(
												"adjustment_frequency",
												value
											)
										);
									}}
									value={values.adjustment_frequency}
									isReadOnly={!isUserAuthorizedBool}
								/>
								{values.goo_price && (
									<>
										<Spacer gap={24} />
										<FluidNumber
											onChange={handleChange}
											name={"goo_price"}
											title={"GOO Price"}
											value={values.goo_price || 0}
											isReadOnly={!isUserAuthorizedBool}
										/>
									</>
								)}
								<If condition={!!dirty}>
									<Spacer gap={24} />
									<Box sx={style.submitWrapper}>
										<PrimaryButton
											type="submit"
											text={"Save Draft"}
											isDisabled={!isUserAuthorizedBool}
										/>
									</Box>
								</If>
							</Box>
						</form>
					)}
				</Formik>
			</If>
			<HorizonChange
				contractPeriod={contractPeriod}
				isOpen={isHorizonChangeOpen}
				onClose={() => setHorizonChangeOpen(false)}
			/>
		</>
	);
};

export default GeneralTab;
