export enum TargetIds {
	TENDER_TABLE_CONTAINER = "tender-table-container",
	FIRST_ROW = "first-row",
}

export const getScrollTopById = (id: string) => {
	return document?.getElementById(id)?.scrollTop;
};

export const scrollToTargetId = (id: string) => {
	const element = document.getElementById(id);
	// @ts-ignore element should be defined, UI should crash if not
	element.scrollIntoView({
		behavior: "smooth",
		block: "start",
		inline: "nearest",
	});
};
