import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTender } from "../../tender_form/tenderForm.selector";
import { useCallback, useEffect, useMemo, useState } from "react";
import SiteAndPortfolioTable from "../../../common/components/SiteAndPortfolioTable";
import { PrimaryButton } from "../../../common/components/CustomButton";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";
import SiteSelection from "../../../common/components/SiteSelection";
import { TriggerUnfoldType } from "../../../common/components/GenericTableRow";
import { Portfolio, Site, isPortfolio } from "../../sites/sites.module";
import { sortSitesOrPortfoliosByNameAscending } from "../utils/helpers.utils";
import { PortfolioAllNames } from "../utils/Portfolio.constant";
import { ensureArray } from "../../../common/utils/ensureArray";
import { useTemporaryFetch } from "../../../common/hooks/useTemporaryFetch";
import { Tender } from "../../tender_page/tender.module";
import { WsMessage } from "../../../core/socketProvider";
import { useAppDispatch } from "../../../common/hooks/default";
import wsPublisher$ from "../../../core/wsPublisher";
import { WsEventTypes } from "../../../common/constants/wsEvents";

interface SiteSelectionStepProps {
	sites: (Site | Portfolio)[];
	onSelectionChanged: (value: (Site | Portfolio)[]) => void;
}

const style: { [key: string]: SxProps<Theme> } = {
	subTitle: {
		marginBottom: "30px",
		fontSize: 24,
	},
};

export default function SiteSelectionStep({
	sites,
	onSelectionChanged,
}: SiteSelectionStepProps) {
	const tenderId = useSelector(selectTender)?.id || 0;
	const dispatch = useAppDispatch();

	// TODO: type is site | Portfolio, name of the state should be changed to reflect that
	const [sitesAndPortFolioSelected, setSitesAndPortFolioSelected] =
		useState<(Site | Portfolio)[]>(sites);
	const [tenderSites, setTenderSites] = useState<Array<Site | Portfolio>>([]);
	const { data: tender, fetchTemporaryData: fetchTender } =
		useTemporaryFetch<Tender>((response) => response.data);
	const [openSiteSelection, setOpenSiteSelection] = useState(false);
	const [search, setSearch] = useState("");
	const [sitesToRemove, setSitesToRemove] = useState<(Site | Portfolio)[]>(
		[]
	);

	useEffect(() => {
		if (!tenderId) {
			return;
		}
		fetchTender("/tenders/" + tenderId + "?portfolio=All");
	}, [tenderId, search]);

	const onSearch = useCallback(
		(newSearch: string) => {
			fetchTender("/tenders/" + tenderId + "?portfolio=All");
			setSearch(newSearch);
		},
		[setSearch]
	);

	useEffect(() => {
		let filteredSites = [
			...ensureArray(tender?.sites),
			...ensureArray(tender?.portfolios),
		];
		if (search) {
			filteredSites = filteredSites.filter(
				(site) =>
					site.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
			);
		}

		const tenderSitesFilter = filteredSites.map((siteOrPortfolio) =>
			!siteOrPortfolio?.children
				? // @ts-ignore
				  { ...siteOrPortfolio, children: siteOrPortfolio.sites }
				: siteOrPortfolio
		);

		setTenderSites(tenderSitesFilter);
	}, [tender?.sites]);

	useEffect(() => {
		onSelectionChanged([...sitesAndPortFolioSelected]);
	}, [sitesAndPortFolioSelected]);

	const handleCloseSiteSelection = (sitelist: (Site | Portfolio)[]) => {
		setOpenSiteSelection(false);
		setSitesAndPortFolioSelected([
			...sitesAndPortFolioSelected,
			...sitelist.filter(
				(el: Site | Portfolio) =>
					!sitesAndPortFolioSelected.includes(el)
			),
		]);
	};

	const isSiteOrPortfolioSelectable = useCallback(
		(siteOrPortfolio: Site | Portfolio) => {
			if (isPortfolio(siteOrPortfolio)) {
				// cannot price a portfolio with only one site
				return (siteOrPortfolio?.children || []).length > 1;
			}
			return true;
		},
		[]
	);

	const sortedPortfolioAndSites = useMemo(() => {
		const sortedSites = sortSitesOrPortfoliosByNameAscending(tenderSites);
		return sortedSites.filter(
			(s) => !(PortfolioAllNames.includes(s.name) && s.sites.length <= 1)
		);
	}, [tenderSites]);

	const [sitesUpdateMessages, setSitesUpdateMessages] = useState<
		WsMessage<any>[]
	>([]);

	const onProxyMessageUpdated = useCallback(
		(messages: WsMessage<any>[]) => {
			setSitesUpdateMessages(messages);
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[
				WsEventTypes.PROXY_IN_PROGRESS,
				WsEventTypes.PROXY_SUCCEEDED,
				WsEventTypes.PROXY_FAILED,
			],
			onProxyMessageUpdated
		);
	}, [onProxyMessageUpdated]);

	return (
		<>
			<Box
				sx={{
					width: "100%",
				}}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography component="div" sx={style.subTitle}>
						Site(s)
					</Typography>
					<Box display="flex" justifyContent="flex-end">
						<PrimaryButton
							autoFocus
							onClick={() => {
								setSitesAndPortFolioSelected([
									...sitesAndPortFolioSelected.filter(
										(s: Site | Portfolio) =>
											sitesToRemove
												.map((sm) => sm.id)
												.indexOf(s.id) === -1
									),
								]);
								setSitesToRemove([]);
							}}
							text="Delete item"
							type="button"
							color="secondary"
							disabled={!sitesToRemove.length}
							sx={{
								marginRight: "16px",
								width: 106,
							}}
						></PrimaryButton>
						<PrimaryButton
							text="add sites"
							type="button"
							color="secondary"
							sx={{
								marginRight: "16px",
								width: 140,
							}}
							onClick={() => setOpenSiteSelection(true)}
						>
							<AddCircleIconOutline sx={{ color: "#000" }} />
						</PrimaryButton>
					</Box>
				</Box>
				<Box
					sx={{
						height: "460px",
						maxHeight: "460px",
						overflowY: "auto",
					}}
				>
					<SiteAndPortfolioTable
						virtualScrollable={false}
						getSelectedSites={(sitelist: (Site | Portfolio)[]) => {
							setSitesToRemove(sitelist);
						}}
						model={sitesAndPortFolioSelected}
						page={1}
						isSelectable={true}
						triggerUnfoldBy={TriggerUnfoldType.Icon}
						updatePage={() => {}}
						onOpenPortfolioSiteModal={() => {}}
						updateMessages={sitesUpdateMessages}
					/>
					{sitesAndPortFolioSelected.length === 0 && (
						<Box
							display="flex"
							sx={{
								padding: "15px",
								marginTop: "-320px",
							}}
							justifyContent="flex-start"
							alignItems="center"
						>
							No site yet
							<PrimaryButton
								text="add sites"
								type="button"
								color="secondary"
								sx={{
									marginRight: "16px",
									marginLeft: "10px",
									width: 140,
								}}
								onClick={() => setOpenSiteSelection(true)}
							>
								<AddCircleIconOutline sx={{ color: "#000" }} />
							</PrimaryButton>
						</Box>
					)}
				</Box>
			</Box>
			<SiteSelection
				isSiteOrPortfolioSelectable={isSiteOrPortfolioSelectable}
				localModel={sortedPortfolioAndSites}
				open={openSiteSelection}
				onClose={handleCloseSiteSelection}
				isLoading={false}
				onUpdatePage={() => {}}
				currentPage={1}
				onSearch={onSearch}
				updateMessages={sitesUpdateMessages}
			/>
		</>
	);
}
