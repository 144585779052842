import { primaryColor } from "../../core/theme";

export const style = {
	progressWrapper: {
		display: "flex",
		justifyContent: "center",
	},
	searchWrapper: {
		minWidth: "50%",
		width: "60vw",
		marginBottom: "32px",
		display: "flex",
		alignItems: "center",
	},
	searchInput: {
		flexGrow: 1,
	},
	errorMessages: {
		backgroundColor: "#E6EAED",
		padding: 3,
		color: "red",
	},
	errorMessagesTitle: {
		fondWeight: "bold",
		color: "black",
	},
	buttonGroup: {
		display: "flex",
		gap: "8px",
	},
	editContainer: {
		cursor: "pointer",
		color: primaryColor,
	},
};
