import {
	Box,
	Checkbox,
	CircularProgress,
	Link,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { BaseSyntheticEvent } from "react";
import GenericHeaderCell from "../../common/components/GenericHeaderCell";

import GenericTableRow, {
	ColModel,
	TriggerUnfoldType,
} from "../../common/components/GenericTableRow";
import { GenericModel } from "./GenericTable";

export interface GenericTableProps<
	T extends GenericModel,
	SubT extends GenericModel
> {
	isSelectable?: boolean;
	isRowSelectable?: (model: T) => boolean;
	page: number;
	model: T[];
	setModel: (model: T, index: number) => void;
	selectAll: (shouldSelectAll: boolean) => void;
	rowModel: ColModel<T>[];
	subRowModel?: ColModel<SubT>[];
	itemName: string;
	virtualScrollable: boolean;
	displaySubRowheader?: boolean;
	tableHeight?: number | string;
	loading?: boolean;
	triggerUnfoldBy?: TriggerUnfoldType.Click | TriggerUnfoldType.Icon;
	updatePage?: (page: number) => void;
	keyExtractor?: (entry: T) => string | number;
}

export default function StatelessGenericTable<
	T extends GenericModel,
	SubT extends GenericModel
>({
	updatePage,
	isRowSelectable = (modelData: T) => !!modelData,
	setModel,
	selectAll,
	page,
	model,
	rowModel,
	subRowModel,
	itemName,
	isSelectable,
	virtualScrollable,
	displaySubRowheader = false,
	loading = false,
	tableHeight,
	triggerUnfoldBy = TriggerUnfoldType.Icon,
}: GenericTableProps<T, SubT>) {
	const checkBoxSize = isSelectable ? 0.3 : 0;
	const arrowSize = 0.3;
	return (
		<Box sx={{ width: "100%" }}>
			<Grid
				container
				spacing={0}
				sx={{ width: "100%", height: 56, border: `1px solid ${grey}` }}
				columns={
					rowModel
						.filter((f: ColModel<T>) => !f.hidden)
						.reduce((sum, current) => sum + (current.xs ?? 0), 0) +
					checkBoxSize +
					arrowSize
				}
			>
				{isSelectable && (
					<GenericHeaderCell
						id="-1"
						key="-1"
						header={() =>
							isSelectable && (
								<Checkbox
									onChange={(e: BaseSyntheticEvent) => {
										selectAll(e.target.checked);
									}}
								/>
							)
						}
						xs={checkBoxSize}
						accessor={() => <Checkbox />}
					/>
				)}
				<GenericHeaderCell
					id="0"
					key="0"
					header={() => ""}
					xs={arrowSize}
					accessor={() => {}}
				/>

				{rowModel.map((headerCell: ColModel<T>) => (
					<GenericHeaderCell key={headerCell.id} {...headerCell} />
				))}
			</Grid>
			<Box sx={{ width: "100%", overflowY: "auto", height: "100%" }}>
				<Grid
					container
					spacing={0}
					sx={{
						width: "100%",
						border: `1px solid ${grey}`,
						...(tableHeight ? { maxHeight: tableHeight } : {}),
					}}
					columns={
						rowModel
							.filter((f: ColModel<T>) => !f.hidden)
							.reduce(
								(sum, current) => sum + (current.xs ?? 0),
								0
							) +
						checkBoxSize +
						arrowSize
					}
				>
					{(model || []).map((m, i) => (
						<GenericTableRow
							displaySubRowheader={displaySubRowheader}
							isSelected={!!m?.isSelected}
							isSelectable={isSelectable}
							isRowSelectable={isRowSelectable}
							key={i}
							data={m}
							cols={rowModel}
							subCols={subRowModel}
							triggerUnfoldBy={triggerUnfoldBy}
							handleRowSelection={(data, isSelected) =>
								setModel({ ...data, isSelected }, i)
							}
							index={i}
						/>
					))}
					<Box
						sx={{
							width: "100%",
							display: "flex",
							alignItems: "start",
							justifyContent: "center",
						}}
					>
						{virtualScrollable && !loading && (
							<Link
								sx={{ cursor: "pointer" }}
								onClick={() =>
									updatePage && updatePage(page + 1)
								}
							>
								Load more
							</Link>
						)}
						{virtualScrollable && loading && (
							<CircularProgress data-testid="loader" />
						)}
					</Box>
				</Grid>
			</Box>
			{model.length === 0 && page === -1 && (
				<Typography
					sx={{ marginTop: "40px" }}
					variant="h3"
					component="h1"
				>
					No {itemName} Found
				</Typography>
			)}
		</Box>
	);
}
