import { ChangeEvent } from "react";
import { FluidTextField } from "./FluidTextField";

interface ArrayInputProps {
	value: string[] | string;
	onChange: (value: string[]) => void;
	name: string;
	label: string;
}

const ArrayInput = ({ value, onChange, name, label }: ArrayInputProps) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event.target.value.split(","));
	};

	return (
		<FluidTextField
			name={name}
			label={label}
			value={Array.isArray(value) ? value.join(",") : value}
			onChange={handleChange}
		/>
	);
};

export default ArrayInput;
