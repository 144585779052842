export const DEAL_TYPE_STYLE: {
	[key: string]: {
		borderColor: string;
		backgroundColor: string;
	};
} = {
	COMPENSATION: {
		borderColor: "#fe80bd",
		backgroundColor: "#ffe9f4",
	},
	COMPENSATION_GOO: {
		borderColor: "#3bcdbc",
		backgroundColor: "#e2fffc",
	},
	GOO: {
		borderColor: "#e6c543",
		backgroundColor: "#fff0b5",
	},
	POWER: {
		borderColor: "#7eacee",
		backgroundColor: "#e7edfc",
	},
};

export const DEAL_SYNC = {
	SYNCED: "Synced",
	FAILED: "Failed",
};

export const DEAL_STATUS = {
	ACTIVE: "Active",
	ERROR: "Error",
};
