import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	SxProps,
	Theme,
	Box,
	Typography,
	IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../../../common/components/CustomInput";
import { PrimaryButton } from "../../../common/components/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch } from "react-redux";
import { createNewPorfolio, editPortfolioName } from "../tenderForm.thunk";
import { Portfolio } from "../../sites/sites.module";

const style: { [key: string]: SxProps<Theme> } = {
	createPortfolioButton: {
		width: 160,
	},
	closePortfolioButton: {
		width: 106,
	},
	actionButtons: {
		width: "97%",
		justifyContent: "space-between",
	},
};

interface PortfolioModalProps {
	open: boolean;
	editMode?: boolean;
	portfolio?: Portfolio;
	closeModal: () => void;
}

export default function PortfolioModal({
	open,
	closeModal,
	editMode = false,
	portfolio,
}: PortfolioModalProps) {
	const dispatch = useDispatch();
	const [portfolioName, setPortfolioName] = useState("");
	const handlePortfolioCreation = () => {
		if (portfolio?.id)
			dispatch(editPortfolioName(portfolio?.id, portfolioName));
		else dispatch(createNewPorfolio(portfolioName));
		setPortfolioName(() => "");
		closeModal();
	};

	useEffect(() => {
		setPortfolioName(() => portfolio?.name ?? "");
	}, [portfolio]);

	if (!open) {
		return <></>;
	}

	return (
		<Dialog
			PaperProps={{ style: { height: "300px" } }}
			sx={{ "& .MuiDialog-paper": { width: "600px" } }}
			fullWidth={true}
			maxWidth="xl"
			open={open}
		>
			<DialogTitle>
				<Box
					sx={{
						display: "flex",
						p: 1,
						bgcolor: "background.paper",
						borderRadius: 1,
					}}
				>
					<Box sx={{ flexGrow: 1, alignSelf: "center" }}>
						<Typography
							paragraph
							sx={{ fontSize: "30px", marginBottom: "-10px" }}
						>
							Portfolio Name
						</Typography>
					</Box>
					<IconButton onClick={closeModal}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Typography>Please enter the name of the portfolio</Typography>
				<CustomInput
					id="portfolio-name"
					aria-label="portfolio-input"
					onChange={(e: any) => setPortfolioName(e.target.value)}
					placeholder="Porfolio name..."
					sx={{ width: "100%" }}
					value={portfolioName}
				></CustomInput>
			</DialogContent>
			<Box
				sx={{
					display: "flex",
					p: 1,
					bgcolor: "background.paper",
					borderRadius: 1,
				}}
			>
				<DialogActions sx={style.actionButtons}>
					<PrimaryButton
						onClick={closeModal}
						text="Cancel"
						type="button"
						color="secondary"
						sx={style.closePortfolioButton}
					></PrimaryButton>
					<PrimaryButton
						text={editMode ? "Save" : "Create Portfolio"}
						type="button"
						color="primary"
						disabled={!portfolioName.length}
						sx={style.createPortfolioButton}
						onClick={handlePortfolioCreation}
					>
						<AddCircleIcon sx={{ color: "#fff" }} />
					</PrimaryButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
