import { Box } from "@mui/material";
import { noop } from "../../utils/operations";

export interface MakeClickableProps extends React.PropsWithChildren<{}> {
	onClick: () => void;
	disabled?: boolean;
}
export const MakeClickable = (props: MakeClickableProps) => {
	return (
		<Box
			onClick={props?.disabled ? noop : props.onClick}
			sx={{ cursor: props?.disabled ? "default" : "pointer" }}
		>
			{props.children}
		</Box>
	);
};
