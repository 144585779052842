import _ from "lodash";
import {
	ContractDetailType,
	ContractParty,
} from "../../../requests_geco/contractsApi/contractsApi.types";

export interface CounterpartyFormik {
	party: ContractParty | undefined;
}

export const counterpartyTabFormikInitialValueMapper = (
	data: ContractDetailType | undefined
): CounterpartyFormik => {
	return {
		party: data?.party,
	};
};

export const counterpartyFormikToContractDetailMapper = (
	formikData: CounterpartyFormik,
	initialContract: ContractDetailType
): ContractDetailType => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	updatedContract.party = formikData.party as ContractParty;
	return updatedContract;
};
