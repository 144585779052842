import { Theme } from "@mui/material";

export const style = {
	title: {
		m: 0,
		p: 2,
		display: "flex",
		alignItems: "center",
		marginRight: "48px",
	},
	closeButton: {
		position: "absolute",
		right: 8,
		top: 8,
		color: (theme: Theme) => theme.palette.grey[500],
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		gap: "8px",
	},
};
