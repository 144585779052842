import { primaryColor } from "../../../core/theme";

export type TagSizeType = "small" | "medium" | "large";

export const style = {
	tag: ({
		backgroundColor = primaryColor,
		borderColor = primaryColor,
		rounded,
		textColor = "white",
		size = "medium",
	}: {
		backgroundColor?: string;
		borderColor?: string;
		rounded?: boolean;
		textColor?: string;
		size?: TagSizeType;
	}) => {
		const sizeMap: { [key: string]: string } = {
			small: "0 4px",
			medium: "4px 8px",
			large: "8px 16px",
		};

		return {
			display: "inline-block",
			color: textColor,
			backgroundColor: backgroundColor,
			border: `1px solid ${borderColor}`,
			padding: sizeMap[size],
			borderRadius: rounded ? "9999px" : "0px",
		};
	},
};
