import { Box, Checkbox, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import GenericHeaderCell from "../../common/components/GenericHeaderCell";

import GenericTableRow, {
	ColModel,
	TriggerUnfoldType,
} from "../../common/components/GenericTableRow";

export interface GenericModel {
	id: number | string;
	children?: GenericModel[];
	isSelected?: boolean;
	isNewUpdate?: boolean;
}

export interface GenericTableProps<
	T extends GenericModel,
	SubT extends GenericModel
> {
	isSelectable?: boolean;
	isRowSelectable?: (model: T) => boolean;
	page: number;
	model: T[];
	rowModel: ColModel<T>[];
	subRowModel?: ColModel<SubT>[];
	itemName: string;
	virtualScrollable: boolean;
	displaySubRowheader?: boolean;
	tableHeight?: number | string;
	loading?: boolean;
	triggerUnfoldBy?: TriggerUnfoldType.Click | TriggerUnfoldType.Icon;
	selection: (data: T[]) => void;
	updatePage?: (page: number) => void;
	keyExtractor?: (entry: T) => string | number;
}

export default function GenericTable<
	T extends GenericModel,
	SubT extends GenericModel
>({
	updatePage,
	selection,
	keyExtractor = (entry) => entry.id,
	isRowSelectable = (modelData: T) => !!modelData,
	page,
	model,
	rowModel,
	subRowModel,
	itemName,
	isSelectable,
	virtualScrollable,
	displaySubRowheader = false,
	loading = false,
	tableHeight,
	triggerUnfoldBy = TriggerUnfoldType.Icon,
}: GenericTableProps<T, SubT>) {
	const [selectedItems, setSelectedItems] = useState<T[]>([]);
	const handleRowSelection = (data: T, selected: boolean) => {
		if (selected) {
			setSelectedItems([...selectedItems, data]);
		} else {
			setSelectedItems(
				selectedItems.filter(
					(f: T) => keyExtractor(f) !== keyExtractor(data)
				)
			);
		}
	};

	const handleAllSelection = (selected: boolean) => {
		if (selected) {
			setSelectedItems(model);
		} else {
			setSelectedItems([]);
		}
	};

	useEffect(() => {
		setSelectedItems((prevSelectedItems) =>
			prevSelectedItems.filter((item) =>
				model.some(
					(modelItem) =>
						keyExtractor(modelItem) === keyExtractor(item)
				)
			)
		);
	}, [model]);

	useEffect(() => {
		selection([...selectedItems]);
	}, [selectedItems]);

	const handleEnter = () => {
		if (!updatePage) {
			return;
		}
		updatePage(page + 1);
	};

	const checkBoxSize = isSelectable ? 0.3 : 0;
	const arrowSize = 0.3;
	return (
		<Box sx={{ width: "100%" }}>
			<Grid
				container
				spacing={0}
				sx={{ width: "100%", height: 56, border: `1px solid ${grey}` }}
				columns={
					rowModel
						.filter((f: ColModel<T>) => !f.hidden)
						.reduce((sum, current) => sum + (current.xs ?? 0), 0) +
					checkBoxSize +
					arrowSize
				}
			>
				{isSelectable && (
					<GenericHeaderCell
						id="-1"
						key="-1"
						header={() =>
							isSelectable && (
								<Checkbox
									onChange={(e: BaseSyntheticEvent) => {
										handleAllSelection(e.target.checked);
									}}
								/>
							)
						}
						xs={checkBoxSize}
						accessor={() => <Checkbox />}
					/>
				)}
				<GenericHeaderCell
					id="0"
					key="0"
					header={() => ""}
					xs={arrowSize}
					accessor={() => {}}
				/>

				{rowModel.map((headerCell: ColModel<T>) => (
					<GenericHeaderCell key={headerCell.id} {...headerCell} />
				))}
			</Grid>
			<Box sx={{ width: "100%", overflowY: "auto", height: "100%" }}>
				<Grid
					container
					spacing={0}
					sx={{
						width: "100%",
						border: `1px solid ${grey}`,
						...(tableHeight ? { maxHeight: tableHeight } : {}),
					}}
					columns={
						rowModel
							.filter((f: ColModel<T>) => !f.hidden)
							.reduce(
								(sum, current) => sum + (current.xs ?? 0),
								0
							) +
						checkBoxSize +
						arrowSize
					}
				>
					{(model || []).map((m, i) => (
						<GenericTableRow
							displaySubRowheader={displaySubRowheader}
							isSelected={
								selectedItems.findIndex(
									(f: T) =>
										keyExtractor(f) === keyExtractor(m)
								) >= 0
							}
							isSelectable={isSelectable}
							isRowSelectable={isRowSelectable}
							key={i}
							data={m}
							cols={rowModel}
							subCols={subRowModel}
							triggerUnfoldBy={triggerUnfoldBy}
							handleRowSelection={handleRowSelection}
							index={i}
						/>
					))}
				</Grid>
			</Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{virtualScrollable && (
					<Waypoint onEnter={handleEnter}>
						<Box component={"span"}>
							{loading && page > 1 && (
								<CircularProgress
									sx={{ marginTop: "40px" }}
									data-testid="loader"
								/>
							)}
						</Box>
					</Waypoint>
				)}
				{virtualScrollable && loading && page === 1 && (
					<CircularProgress
						sx={{ marginTop: "40px" }}
						data-testid="loader"
					/>
				)}
			</Box>
			{model.length === 0 && page === -1 && (
				<Typography
					sx={{ marginTop: "40px" }}
					variant="h3"
					component="h1"
				>
					No {itemName} Found
				</Typography>
			)}
		</Box>
	);
}
