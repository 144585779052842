import Typography from "@mui/material/Typography";
import * as React from "react";

const OktaError: React.FC<{ error: Error }> = () => {
	return (
		<>
			<Typography paragraph={true}>
				You do not have access to this application.
			</Typography>
			<Typography paragraph={true}>
				<a
					href="https://gemprod.service-now.com/gem"
					rel="noreferrer"
					target="_blank"
				>
					Create a SNOW request.
				</a>
			</Typography>
		</>
	);
};

export default OktaError;
