import { Box } from "@mui/material";
import Modal from "./Modal";
import { PrimaryButton } from "./CustomButton";
import { dangerPrimary } from "../../core/theme";

export interface ActionConfirmationModalProps {
	title: string;
	text?: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const ActionConfirmationModal = ({
	title,
	text = "",
	isOpen,
	onClose,
	onConfirm,
}: ActionConfirmationModalProps) => {
	return (
		<Modal
			title={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					{title}
				</Box>
			}
			actions={
				<>
					<PrimaryButton
						color="secondary"
						text="No"
						onClick={onClose}
					/>
					<PrimaryButton
						sx={{
							backgroundColor: dangerPrimary,
							"&:hover": {
								backgroundColor: "#ff4d4d",
							},
						}}
						text="Yes"
						onClick={() => {
							onConfirm();
							onClose();
						}}
					/>
				</>
			}
			open={isOpen}
			onClose={onClose}
		>
			{text}
		</Modal>
	);
};
