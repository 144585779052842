import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	SxProps,
	Theme,
	Box,
	Typography,
	IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import SiteAndPortfolioTable from "./SiteAndPortfolioTable";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryButton } from "./CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { borderGrey } from "../../core/theme";
import { TriggerUnfoldType } from "../../common/components/GenericTableRow";
import { Site, Portfolio } from "../../features/sites/sites.module";
import { SearchField } from "./SearchField";
import { WsMessage } from "../../core/socketProvider";

const style: { [key: string]: SxProps<Theme> } = {
	searchInput: {
		minWidth: "325px",
		"& svg": {
			color: borderGrey,
		},
	},
	button: {
		marginRight: "16px",
		width: 106,
	},
};

interface SiteSelectionProps {
	open: boolean;
	enableSiteCreation?: boolean;
	isLoading: boolean;
	localModel: (Site | Portfolio)[];
	currentPage: number;
	title?: string;
	enableSearch?: boolean;
	enableSiteSelection?: boolean;
	onSearch: (search: string) => void;
	onClose: (value: (Site | Portfolio)[]) => void;
	onUpdatePage: (number: number) => void;
	addNewSite?: () => void;
	openImportSitesModal?: () => void;
	updateMessages?: WsMessage<any>[];
	isSiteOrPortfolioSelectable?: (
		siteOrPortfolio: Site | Portfolio
	) => boolean;
}

export default function SiteSelection({
	open,
	enableSiteCreation,
	localModel,
	title,
	isLoading,
	enableSearch = true,
	enableSiteSelection = true,
	currentPage,
	onSearch,
	onClose,
	onUpdatePage,
	addNewSite,
	openImportSitesModal,
	updateMessages = [],
	isSiteOrPortfolioSelectable = (siteOrPortfolio: Site | Portfolio) =>
		!!siteOrPortfolio,
	...other
}: SiteSelectionProps) {
	const [selectedSites, setSelectedSites] = useState<(Site | Portfolio)[]>(
		[]
	);

	useEffect(() => {
		if (open) {
			onSearch("");
		}
	}, [open]);

	const handleCancel = () => {
		onClose([]);
	};

	const handleOk = () => {
		onClose(selectedSites);
	};

	if (!open) {
		return <></>;
	}

	return (
		<Dialog
			PaperProps={{ style: { height: "80%" } }}
			sx={{ "& .MuiDialog-paper": { width: "80%" } }}
			fullWidth={true}
			maxWidth="xl"
			open={open}
			{...other}
		>
			<DialogTitle>
				<Box
					sx={{
						display: "flex",
						p: 1,
						bgcolor: "background.paper",
						borderRadius: 1,
					}}
				>
					<Box sx={{ flexGrow: 1, alignSelf: "center" }}>
						<Typography
							paragraph
							sx={{ fontSize: "30px", marginBottom: "-10px" }}
						>
							{title ?? "Add Site"}
						</Typography>
					</Box>
					<IconButton onClick={() => handleCancel()}>
						<CloseIcon />
					</IconButton>
				</Box>
				{enableSearch && <SearchField onSearch={onSearch} />}
			</DialogTitle>
			<DialogContent dividers>
				<SiteAndPortfolioTable
					isSiteOrPortfolioSelectable={isSiteOrPortfolioSelectable}
					virtualScrollable={true}
					loading={isLoading}
					getSelectedSites={setSelectedSites}
					model={localModel || []}
					updatePage={onUpdatePage}
					page={currentPage}
					isSelectable={enableSiteSelection}
					triggerUnfoldBy={TriggerUnfoldType.Icon}
					onOpenPortfolioSiteModal={() => {}}
					updateMessages={updateMessages}
				/>
			</DialogContent>
			<Box
				sx={{
					display: "flex",
					p: 1,
					bgcolor: "background.paper",
					borderRadius: 1,
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						alignSelf: "center",
						marginLeft: "20px",
						marginTop: "10px",
					}}
				>
					{enableSiteSelection && (
						<Typography paragraph>
							{`${selectedSites.length} site(s) selected`}
						</Typography>
					)}
				</Box>
				<DialogActions sx={{ marginRight: "10px" }}>
					{enableSiteCreation && (
						<>
							<PrimaryButton
								autoFocus
								{...(openImportSitesModal && {
									onClick: openImportSitesModal,
								})}
								text="Import site"
								type="button"
								color="secondary"
								sx={style.button}
							></PrimaryButton>
							<PrimaryButton
								autoFocus
								{...(addNewSite && { onClick: addNewSite })}
								text="Create site"
								type="button"
								color="secondary"
								sx={style.button}
							></PrimaryButton>
						</>
					)}
					<PrimaryButton
						text={enableSiteSelection ? "Add selection" : "Close"}
						type="button"
						color="primary"
						sx={{
							...style.button,
							width: 160,
						}}
						onClick={handleOk}
					>
						{enableSiteSelection && (
							<AddCircleIcon sx={{ color: "#fff" }} />
						)}
					</PrimaryButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
}
