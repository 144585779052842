import { ReactCountryFlag } from "react-country-flag";
import { ColModel } from "../components/GenericTableRow";
import { formatCoordinates } from "./helpers";
import {
	Portfolio,
	Site,
	isPortfolio,
} from "../../features/sites/sites.module";
import { HamburgerMenu } from "../components/HamburgerMenu";
import { HamburgerOptions } from "../components/HamburgerOptions";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { If } from "../components/If";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AddIcon from "@mui/icons-material/Add";
import { useMemo } from "react";
import { SiteTechnicalDetails } from "../components/SiteTechnicalDetails";

export interface TenderFormSiteTableModelParams {
	showRefreshCluster?: boolean;
	handleChange?: any;
	values?: any;
	errors?: any;
	touched?: any;
	setFieldValue?: any;
}

export const useTenderFormSitePricingModel = (
	params: TenderFormSiteTableModelParams
) => {
	const {
		showRefreshCluster = true,
		handleChange,
		values,
		setFieldValue,
		errors,
		touched,
	} = params;

	const rowModel: ColModel<Site>[] = useMemo(() => {
		const test = [
			{
				id: "1",
				header: () => "Id",
				xs: 1,
				hidden: true,
				accessor: (m: Site) => m.id,
			},
			{
				id: "2",
				header: () => "Name",
				xs: 2,
				accessor: (m: Site) => {
					return (
						<>
							{m.name}{" "}
							{"children" in m && m.children
								? `(${m.children.length})`
								: ""}
						</>
					);
				},
			},
			{
				id: "4",
				header: () => "Country",
				xs: 1,
				accessor: (m: Site) => (
					<>
						{"country" in m && (
							<>
								<ReactCountryFlag
									countryCode={m.country ?? ""}
									svg
									title={m.country ?? ""}
								/>

								{m.country}
							</>
						)}
					</>
				),
			},

			{
				id: "8",
				header: () => "Technology",
				xs: 1,
				accessor: (m: Site) => "techno" in m && m?.techno?.name,
			},
			{
				id: "9",
				header: () => "Installed capacity",
				xs: 1,
				accessor: (m: Site) => (
					<>
						{"installed_capacity_mw" in m &&
							(m.installed_capacity_mw ?? "")}
					</>
				),
			},
			{
				id: "10",
				header: () => "Park age",
				xs: 1,
				accessor: (m: Site) => {
					return m?.park_age;
				},
			},
			{
				id: "11",
				header: () => "Technical details",
				xs: 2,
				accessor: (m: Site | Portfolio) => (
					<SiteTechnicalDetails site={m} />
				),
			},
			{
				id: "12",
				header: () => "Latitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).latitude,
			},
			{
				id: "13",
				header: () => "Longitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).longitude,
			},
			{
				id: "14",
				header: () => " ",
				hidden: !showRefreshCluster,
				xs: 1,
				accessor: (m: Site) =>
					isPortfolio(m) ? null : (
						<HamburgerMenu key={`hamburger${m.asset_id}`}>
							<HamburgerOptions
								title={"Refresh cluster"}
								action={() => {}}
							/>
						</HamburgerMenu>
					),
			},
			{
				id: "15",
				header: () => "Realized Timeseries",
				xs: 3,
				accessor: (m: Site) => {
					return (
						<CustomAutocomplete
							key={`autocomplete_${m.asset_id}`}
							sx={{
								"& .MuiInputLabel-shrink": {
									display: "none",
								},
								width: "100%",
								marginBottom: 0,
							}}
							fetchData={() => {}}
							data={m.ts as any[]}
							label={"Tourbillon Ref"}
							name={m.asset_id}
							setFieldValue={(field, value) => {
								if (typeof value === "string") {
									return setFieldValue(field, {
										ts_type: "raw",
										timeserie_name: value,
										cleaned: false,
									});
								} else {
									return setFieldValue(field, value);
								}
							}}
							renderOption={(optionProps, option: any, state) => {
								if (option === state.inputValue) {
									return (
										<li {...optionProps} key={option}>
											<AddIcon />
											<span
												style={{
													marginLeft: 8,
												}}
											>
												{option}
											</span>
										</li>
									);
								}
								return (
									<li {...optionProps} key={option.name}>
										<If condition={option.mapped}>
											<LinkIcon />
										</If>
										<If condition={!option.mapped}>
											<LinkOffIcon />
										</If>
										<span
											style={{
												marginLeft: 8,
											}}
										>
											{option.timeserie_name}
										</span>
									</li>
								);
							}}
							value={
								values[m.asset_id || ""]?.timeserie_name || ""
							}
							onChange={handleChange}
							freeSolo={true}
							paperSx={{
								paper: {
									sx: {
										width: "fit-content",
										maxWidth: "500px",
									},
								},
							}}
							error={!!errors[m.asset_id || ""]}
							helperText={
								!!errors[m.asset_id || ""] &&
								Object.entries(touched).length > 0 &&
								!touched[m.asset_id || ""]
									? errors[m.asset_id || ""]
									: ""
							}
						/>
					);
				},
			},
		];
		return test;
	}, [values, errors, touched]);

	return rowModel;
};
