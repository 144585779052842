import { TableCell, TableHead, TableRow, SxProps, Theme } from "@mui/material";
import { PricingTableHeaderCell } from "./PricingTableHeaderCell";
import { secondaryColor } from "../../../../core/theme";
import { Action } from "../../pricingListSlice";
import { HamburgerMenu } from "../../../../common/components/HamburgerMenu";
import { HamburgerOptions } from "../../../../common/components/HamburgerOptions";
import { memo, useState } from "react";
import { usePricingTableRowModel } from "../../hooks/columns/usePricingTableRowModel";
import AlertDialogModal from "../../../../common/components/AlertDialogModal";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../hooks/columns/common";

const style: { [key: string]: SxProps<Theme> } = {
	stickyHeader: {
		position: "sticky",
		left: 0,
		background: secondaryColor,
		zIndex: 1000,
		paddingLeft: "5px !important",
		"& > th": { padding: "0px" },
	},
};

interface PricingTableHeaderProps {
	availableActionsForSelection: Action[];
	onAction: (action: Action) => void;
	selectedRunIds?: number[];
	clearPricingSelection: () => void;
	selectAll: () => void;
	onDownloadExcel: (ids: number[]) => void;
	selectedColumns?: Column<ColumnIds>[];
}

function PricingTableHeaderComponent(props: PricingTableHeaderProps) {
	const {
		selectedRunIds,
		clearPricingSelection,
		availableActionsForSelection,
		selectAll,
		onAction,
		onDownloadExcel,
		selectedColumns,
	} = props;

	const rowModel = usePricingTableRowModel(selectedColumns);

	const [selectedAction, setSelectedAction] = useState<Action | undefined>(
		undefined
	);
	return (
		<TableHead>
			<TableRow
				sx={{
					"&:last-child td, &:last-child th": {
						border: 0,
					},
					"& > th": { padding: "0px" },
				}}
			>
				<TableCell sx={style.stickyHeader}>
					<PricingTableHeaderCell size={40}>
						<HamburgerMenu datatestId="hamburgerMenuList">
							{selectedRunIds &&
								selectedRunIds?.length > 0 &&
								availableActionsForSelection.map((action) => (
									<HamburgerOptions
										key={action.action}
										title={action.display_name}
										action={() =>
											!!Object.keys(
												action.confirmation_modal ?? {}
											).length
												? setSelectedAction(action)
												: onAction(action)
										}
									/>
								))}
							{selectedRunIds && selectedRunIds?.length > 0 && (
								<HamburgerOptions
									title={"Export Pricings"}
									action={() =>
										onDownloadExcel(selectedRunIds)
									}
								/>
							)}
							<HamburgerOptions
								title={"Select All"}
								action={() => selectAll()}
							/>
							{selectedRunIds && selectedRunIds?.length > 0 && (
								<HamburgerOptions
									title={"Clear Selection"}
									action={() => clearPricingSelection()}
								/>
							)}
						</HamburgerMenu>
						<AlertDialogModal
							title={
								selectedAction?.confirmation_modal?.title ?? ""
							}
							content={
								selectedAction?.confirmation_modal?.content
							}
							isOpen={!!selectedAction}
							handleClose={() => setSelectedAction(undefined)}
							handleApprove={() =>
								onAction(selectedAction as Action)
							}
						/>
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={35} sx={{ zIndex: -1 }} />
					{rowModel.stickyColumns.map((column) => (
						<PricingTableHeaderCell
							key={column.columnId}
							size={column.width}
						>
							{column.renderHeader()}
						</PricingTableHeaderCell>
					))}
				</TableCell>
				{rowModel.nonStickyColumns.map((column) => (
					<PricingTableHeaderCell
						key={column.columnId}
						size={column.width}
					>
						{column.renderHeader()}
					</PricingTableHeaderCell>
				))}
				<PricingTableHeaderCell sx={{ alignSelf: "right" }} size={70}>
					Comment
				</PricingTableHeaderCell>

				<PricingTableHeaderCell size={40}></PricingTableHeaderCell>
			</TableRow>
		</TableHead>
	);
}

export const PricingTableHeader = memo(PricingTableHeaderComponent);
