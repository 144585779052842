export const getLocalStorage = (key: string) => {
	return localStorage.getItem(key);
};

export const setLocalStorage = (key: string, value: any) => {
	return localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string) => {
	return localStorage.removeItem(key);
};

export const get = <T>(key: string, defaultValue: T): T => {
	const value = getLocalStorage(key);
	if (!value) {
		return defaultValue;
	}
	return JSON.parse(value);
};

export const set = <T>(key: string, value: T): void => {
	setLocalStorage(key, value);
};
