import {
	Alert,
	Box,
	Grid,
	Stack,
	Step,
	StepLabel,
	Stepper,
	SxProps,
	Theme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { lightGrey, white } from "../../core/theme";
import { PrimaryButton } from "../../common/components/CustomButton";
import { useEffect, useMemo, useState } from "react";
import SiteSelectionStep from "./components/SiteSelectionStep";
import PricingStep, { PricingRequestData } from "./components/PricingStep";
import { Formik } from "formik";
import {
	firstOfNextYear,
	pricingRequestStepSchema,
} from "./pricingRequestStepper.schema";
import OverviewStep from "./components/OverviewStep";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";
import { useAppDispatch } from "../../common/hooks/default";
import { transformPricingRequestDataToAdaptApi } from "./utils/helpers.utils";
import {
	getCommodityFixings,
	createPricingRequest,
	getAllPricingTemplates,
	setNewPricingTemplate,
} from "./PricingRequestStepper.thunk";
import { useSelector } from "react-redux";
import { selectIsPricingTemplateAdded } from "./PricingRequestStepper.selector";
import { resetPricingRequestResult } from "./PricingRequestStepper.slice";

import {
	Portfolio,
	Site,
	getSiteOrPortfolioCountry,
} from "../sites/sites.module";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import PricingTemplateModal from "./components/PricingTemplateModal";
import { If } from "../../common/components/If";
import { MAXIMUM_SITES_IN_ONE_GO } from "./PricingRequestStepper.constant";
import { getPathWithParameters, PATH } from "../../router-path";
import PricingSuccessActionModal from "./components/PricingSuccessActionModal";
import { lastDayOfYear, subMonths } from "date-fns";
import { selectFeatureFlags } from "../parameters/ParametersPage.selector";
import { FeatureName } from "../../common/hooks/useFeatureFlags";
import PricingGooStep from "./components/PricingGooStep";

interface PricingRequestStepperProps {}

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		bgcolor: lightGrey,
		"& form": {
			width: "100%",
		},
	},
	stepContainer: {
		paddingTop: "40px",
	},
	titleContainer: {
		width: "100%",
		alignItems: "center",
		marginBottom: "45px",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		margin: 0,
		fontSize: 36,
	},
	stepperHeader: {
		marginTop: "40px",
		backgroundColor: white,
		minHeight: "72px",
		paddingLeft: "100px",
		paddingRight: "100px",
		border: "1px solid #000000",
	},
};

const getStepSchema = (step: number) => {
	switch (step) {
		case 1:
		case 2:
			return pricingRequestStepSchema;
		default:
			return undefined;
	}
};

export const PRICING_STEPPER_INIT_VALUES = {
	pricingType: [],
	gooPricingType: [],
	startDate: firstOfNextYear,
	endDate: lastDayOfYear(firstOfNextYear),
	gooStartDate: firstOfNextYear,
	gooEndDate: lastDayOfYear(firstOfNextYear),
	pricingDate: undefined,
	live: false,
	baseOptionSwitch: false,
	baseCapacity: "",
	clickOptionSwitch: false,
	clickGranularity: ["NoSplit"],
	clickCountYear: 1,
	clickCountQuarter: 1,
	clickCountMonth: 1,
	clickCountNoSplit: 0,
	gooClickGranularity: "Year",
	clickCount: 1,
	granularityMonthly: false,
	granularityQuarter: false,
	alpha: false,
	beta: false,
	granularityCal: false,
	granularityWholeHorizon: true,
	gooGranularityMonthly: false,
	gooGranularityQuarter: false,
	gooGranularityCal: true,
	gooGranularityWholeHorizon: false,
	includingGoos: false,
	clickabilityPlanningSwitch: false,
	clickabilityPlanning: "",
	tasOrFormula: "",
	formula: "",
	priceCap: "",
	priceFloor: "",
	priceFloorSwitch: false,
	priceCapSwitch: false,
	template: "",
	chapter51Switch: false,
	chapter51Type: "",
	chapter51NumberOfHours: 0,
	gooFixPriceSwitch: false,
	ppaWithGooSwitch: false,
	gooFormulaSwitch: false,
	gooClickSwitch: false,
	gooClickCount: 1,
	gooPowerAdjustment: 0,
	clickLimitDate: subMonths(firstOfNextYear, 3),
	negativePriceValue: 0,
	hasNegativePrice: false,
};

const PricingRequestStepper: React.FC<PricingRequestStepperProps> = () => {
	const { tenderId, backPage } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const features = useSelector(selectFeatureFlags);
	const isGooOnlyEnabled = features[FeatureName.is_goo_only_enabled];
	const [activeStep, setActiveStep] = useState(0);

	const [sites, setSites] = useState<(Site | Portfolio)[]>([]);
	const [pricingStep, setPricingStep] = useState<PricingRequestData>(
		PRICING_STEPPER_INIT_VALUES as PricingRequestData
	);

	const [initState, setInitState] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [isPricingTemplateModalOpen, setPricingTemplateModalOpen] =
		useState<boolean>(false);
	const [pricingSuccessModalOpen, setPricingSuccessModalOpen] =
		useState<boolean>(false);

	const isPricingTemplateAdded = useSelector(selectIsPricingTemplateAdded);

	useEffect(() => {
		if (activeStep) setInitState(pricingStep);
	}, [activeStep]);

	const country = useMemo(
		() => getSiteOrPortfolioCountry(sites?.[0]),
		[sites]
	);

	useEffect(() => {
		const timeout = setTimeout(
			() =>
				dispatch(
					getCommodityFixings(getSiteOrPortfolioCountry(sites?.[0]))
				) && dispatch(getAllPricingTemplates()),
			400
		);
		return () => clearTimeout(timeout);
	}, [country]);

	const onClickBack = (values: PricingRequestData) => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);

		if (activeStep === 1 || activeStep === 2) {
			setPricingStep(values);
		}
	};

	const navigateBack = () => {
		if (backPage) {
			navigate(`/${backPage}`);
		} else {
			navigate(-1);
		}
	};
	const handlePricingTemplateSave = async (title: string) => {
		await dispatch(setNewPricingTemplate(title, pricingStep));
		if (isPricingTemplateAdded) setPricingTemplateModalOpen(false);
	};
	const pricingSuccessAction = (action: string) => {
		switch (action) {
			case "pricing-same-sites":
				setActiveStep(1);
				break;
			case "new-pricing":
				dispatch(resetPricingRequestResult());
				setSites([]);
				setActiveStep(0);
				break;
			case "pricing-details":
				if (window.location.href.includes("maximized")) {
					navigate(
						getPathWithParameters(
							PATH.MAXIMIZED_TENDER_PRICING_RUN,
							{ tenderId }
						)
					);
				} else {
					navigate(
						getPathWithParameters(PATH.TENDER_PRICING_RUN, {
							tenderId,
						})
					);
				}
				break;
			default:
				if (window.location.href.includes("maximized")) {
					navigate(
						getPathWithParameters(
							PATH.MAXIMIZED_TENDER_PRICING_RUN,
							{ tenderId }
						)
					);
				} else {
					navigate(
						getPathWithParameters(PATH.TENDER_PRICING_RUN, {
							tenderId,
						})
					);
				}
				break;
		}
		setPricingSuccessModalOpen(false);
	};
	return (
		<>
			<PageTitle
				label="New pricing request"
				leftSide={
					<PrimaryButton
						text="Exit"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={navigateBack}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
			/>
			<PageContent>
				<Box display={"flex"} justifyContent="center">
					<Stack
						sx={style.container}
						direction="column"
						justifyContent="space-between"
						alignItems="center"
						maxWidth={"1000px"}
						spacing={2}
					>
						<Formik
							enableReinitialize={true}
							validateOnChange={false}
							initialValues={initState}
							validationSchema={getStepSchema(activeStep)}
							onSubmit={async (
								values,
								{ setSubmitting, resetForm }
							) => {
								setSubmitting(false);
								if (activeStep < 2) {
									setActiveStep((prevActiveStep) => {
										if (
											(isGooOnlyEnabled
												? [1, 2]
												: [1]
											).includes(prevActiveStep)
										) {
											setPricingStep(values);
										}
										return prevActiveStep + 1;
									});
								} else {
									setSubmitting(true);
									setLoading(true);
									const data =
										transformPricingRequestDataToAdaptApi(
											{
												pricingValues: pricingStep,
												sitesValues: sites,
											},
											Number(tenderId)
										);
									const success = await dispatch(
										createPricingRequest(data)
									);

									setLoading(false);
									if (success) {
										setPricingStep(
											PRICING_STEPPER_INIT_VALUES as PricingRequestData
										);
										resetForm();
										setPricingSuccessModalOpen(true);
									}
								}
							}}
						>
							{({
								handleChange,
								handleSubmit,
								values,
								errors,
								touched,
								setFieldValue,
								setFieldError,
							}) => {
								return (
									<form onSubmit={handleSubmit}>
										<Grid
											sx={style.titleContainer}
											container
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Grid item xs={12}>
												<Stepper
													activeStep={activeStep}
													sx={style.stepperHeader}
												>
													<Step key={0}>
														<StepLabel>
															Site selection
														</StepLabel>
													</Step>
													<Step key={1}>
														<StepLabel>
															Power pricing
														</StepLabel>
													</Step>
													{/* it will be used when we add gooOnly*/}
													{isGooOnlyEnabled && (
														<Step key={2}>
															<StepLabel>
																GOO
															</StepLabel>
														</Step>
													)}
													<Step
														key={
															isGooOnlyEnabled
																? 3
																: 2
														}
													>
														<StepLabel>
															Overview
														</StepLabel>
													</Step>
												</Stepper>
												<Grid
													sx={style.stepContainer}
													container
													direction="row"
													justifyContent="flex-start"
													alignItems="center"
												>
													{activeStep === 0 && (
														<SiteSelectionStep
															sites={sites}
															onSelectionChanged={(
																selectedSites
															) =>
																setSites(
																	selectedSites
																)
															}
														/>
													)}
													{activeStep === 1 &&
														values.hasOwnProperty(
															"pricingType"
														) && (
															<PricingStep
																tenderId={
																	tenderId
																		? parseInt(
																				tenderId
																		  )
																		: undefined
																}
																values={
																	values as any
																}
																handleChange={
																	handleChange
																}
																setFieldValue={
																	setFieldValue
																}
																errors={errors}
																touched={
																	touched
																}
															/>
														)}
													{/* it will be used when we add gooOnly*/}
													{isGooOnlyEnabled && (
														<>
															{activeStep === 2 &&
																values.hasOwnProperty(
																	"pricingType"
																) && (
																	<PricingGooStep
																		setLoading={
																			setLoading
																		}
																		values={
																			values as any
																		}
																		sitesValues={
																			sites
																		}
																		handleChange={
																			handleChange
																		}
																		setFieldError={
																			setFieldError
																		}
																		setFieldValue={
																			setFieldValue
																		}
																		errors={
																			errors
																		}
																		touched={
																			touched
																		}
																	/>
																)}
														</>
													)}

													{activeStep ===
														(isGooOnlyEnabled
															? 3
															: 2) && (
														<OverviewStep
															pricingValues={
																pricingStep
															}
															sitesValues={sites}
															setActiveStep={
																setActiveStep
															}
														/>
													)}
												</Grid>
											</Grid>
										</Grid>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={2}
											width="100%"
										>
											<PrimaryButton
												text="Cancel"
												type="button"
												color="secondary"
												onClick={navigateBack}
											/>
											{activeStep ==
												(isGooOnlyEnabled ? 3 : 2) && (
												<PrimaryButton
													type="button"
													text="Save as template"
													color="primary"
													disabled={loading}
													onClick={() =>
														setPricingTemplateModalOpen(
															true
														)
													}
												>
													<AddCircleIconOutline
														sx={{
															color: "#fff",
														}}
													/>
												</PrimaryButton>
											)}
											<If
												condition={
													activeStep == 0 &&
													sites.length >
														MAXIMUM_SITES_IN_ONE_GO
												}
											>
												<Alert severity="error">
													Too many sites selected in a
													single request (max{" "}
													{MAXIMUM_SITES_IN_ONE_GO}),
													please do multiple requests
												</Alert>
											</If>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={2}
											>
												<PrimaryButton
													onClick={() =>
														onClickBack(values)
													}
													isDisabled={
														activeStep === 0 ||
														loading
													}
													text="Back"
													type="button"
													color="secondary"
												/>
												{activeStep <
													(isGooOnlyEnabled
														? 3
														: 2) && (
													<PrimaryButton
														type="submit"
														text="Next"
														isDisabled={
															sites.length >
																MAXIMUM_SITES_IN_ONE_GO ||
															!sites.length ||
															loading
														}
														color="primary"
														onClick={() => {
															setFieldValue(
																"$isSubmitting",
																true
															);
														}}
													/>
												)}
												{activeStep ==
													(isGooOnlyEnabled
														? 3
														: 2) && (
													<PrimaryButton
														type="submit"
														text="Add Pricing"
														color="primary"
														disabled={loading}
														onClick={() => {
															setFieldValue(
																"$isSubmitting",
																true
															);
														}}
													>
														<AddCircleIconOutline
															sx={{
																color: "#fff",
															}}
														/>
													</PrimaryButton>
												)}
											</Stack>
										</Stack>
									</form>
								);
							}}
						</Formik>
					</Stack>
				</Box>
				<PricingTemplateModal
					open={isPricingTemplateModalOpen}
					onClose={() => setPricingTemplateModalOpen(false)}
					onSubmit={(title) => handlePricingTemplateSave(title)}
				/>
				<PricingSuccessActionModal
					open={pricingSuccessModalOpen}
					onSubmit={(action) => {
						pricingSuccessAction(action);
					}}
				/>
			</PageContent>
		</>
	);
};

export default PricingRequestStepper;
