import AlertDialogModal from "../../../common/components/AlertDialogModal";
import { useMemo } from "react";
import { Action } from "../../pricing_list/pricingListSlice";
import { Tender } from "../tender.module";

export interface ActionConfirmationModalProps {
	action?: Action | null;
	tenders: Tender[];
	open: boolean;
	onClose: () => void;
	onConfirm: () => any;
}

export function ActionConfirmationModal(props: ActionConfirmationModalProps) {
	const title = useMemo(
		() =>
			props.action
				? `You are about to ${props.action.display_name} ${props.tenders.length} tenders, are you sure ?`
				: "",
		[props.action, props.tenders]
	);

	return (
		<AlertDialogModal
			title={title}
			isOpen={props.open}
			handleApprove={props.onConfirm}
			handleClose={props.onClose}
		/>
	);
}
