import { textNeutralTertiary } from "../../core/theme";
import { Box } from "@mui/material";

const NoDataMessage = () => {
	return (
		<Box sx={{ color: textNeutralTertiary, padding: "24px" }}>
			No data returned from the request yet. Please add one to display
			something.
		</Box>
	);
};

export default NoDataMessage;
