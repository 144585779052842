import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
	Accordion,
	AccordionProps,
	AccordionSummary,
	AccordionSummaryProps,
	styled,
} from "@mui/material";
import { secondaryColor } from "../../../../core/theme";

export const StyledAccordion = styled((props: AccordionProps) => (
	<Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	width: "100%",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

export const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
	<AccordionSummary
		expandIcon={
			<ArrowForwardIosSharpIcon
				sx={{ display: "None", fontSize: "0.9rem" }}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: secondaryColor,
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));
