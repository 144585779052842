import * as React from "react";
import Button from "@mui/material/Button";
import StatelessGenericTable from "../StatelessGenericTable";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { GenericModel } from "../GenericTable";
import { ColModel } from "../GenericTableRow";

// eslint-disable-next-line
export interface Item<T> extends GenericModel {}

export interface SelectableItem<T> extends Item<T> {
	isSelected?: boolean;
}

export interface GenericMultiSelectProps<T> {
	isLoading: boolean;
	itemList: Item<T>[];
	initialSelectedItems: Item<T>[];
	onSelectionChange: (items: Item<T>[]) => void;
	isItemSelectable: (item: Item<T>) => boolean;
	leftRowModel: ColModel<Item<T>>[];
	rightRowModel: ColModel<Item<T>>[];
	keyProperty: (item: SelectableItem<T>) => any;
	itemReadyProperty: (item: SelectableItem<T>) => any;
	evaluatePropertyOnSelectionChanged?: (
		item: SelectableItem<T>[]
	) => SelectableItem<T>[];
	withSearch: boolean;
	updatePage?: (page: number) => void;
	page?: number;
}

export default function GenericMultiSelect<T>(
	props: GenericMultiSelectProps<T>
) {
	const {
		isLoading,
		itemList,
		initialSelectedItems,
		onSelectionChange,
		isItemSelectable,
		leftRowModel,
		rightRowModel,
		keyProperty,
		itemReadyProperty,
		evaluatePropertyOnSelectionChanged,
		withSearch,
		updatePage,
		page,
	} = props;

	const [left, setLeft] = React.useState<SelectableItem<T>[]>([]);
	const [right, setRight] = React.useState<SelectableItem<T>[]>([]);

	function not<Ty>(a: SelectableItem<Ty>[], b: SelectableItem<Ty>[]) {
		const idMap: Record<string, boolean> = {};
		for (const item of b) {
			idMap[keyProperty(item)] = true;
		}
		return a.filter(
			(value: SelectableItem<Ty>) => !idMap[keyProperty(value) as string]
		);
	}

	useEffect(() => {
		setLeft(not(itemList, right));
	}, [itemList]);

	if (withSearch) {
		useEffect(() => {
			setRight(initialSelectedItems);
		}, []);
	} else {
		useEffect(() => {
			setRight(initialSelectedItems);
		}, [initialSelectedItems]);
	}

	useEffect(() => {
		onSelectionChange(right);
	}, [right]);

	const selectLeft = React.useCallback(
		(data: Item<T>, index: number) => {
			const newLeft = [...left];
			newLeft[index] = data;
			setLeft(newLeft);
		},
		[left]
	);

	const selectRight = React.useCallback(
		(data: Item<T>, index: number) => {
			const newRight = [...right];
			newRight[index] = data;
			setRight(newRight);
		},
		[right]
	);

	const selectAllLeft = React.useCallback(
		(selected: boolean) => {
			const newSite: SelectableItem<T>[] = left.map(
				(entry: SelectableItem<T>) => ({
					...entry,
					isSelected: itemReadyProperty(entry) ? selected : !selected,
				})
			);
			setLeft([...newSite]);
		},
		[left]
	);

	const selectAllRight = React.useCallback(
		(selected: boolean) => {
			setRight([
				...right.map((entry: SelectableItem<T>) => ({
					...entry,
					isSelected: selected,
				})),
			]);
		},
		[right]
	);

	const handleCheckedRight = () => {
		const newEntry = left
			.filter((entry) => !!entry?.isSelected && itemReadyProperty(entry))
			.map((entry) => ({ ...entry, isSelected: false }));

		let newRight = right.concat(newEntry);
		if (evaluatePropertyOnSelectionChanged) {
			newRight = evaluatePropertyOnSelectionChanged(newRight);
		}
		setRight(newRight);
		setLeft(not(left, newEntry));
	};

	const handleCheckedLeft = () => {
		const newEntry = right
			.filter((entry) => !!entry?.isSelected)
			.map((entry) => ({ ...entry, isSelected: false }));
		setLeft(left.concat(newEntry));
		let newRight = not(right, newEntry);
		if (evaluatePropertyOnSelectionChanged) {
			newRight = evaluatePropertyOnSelectionChanged(newRight);
		}
		setRight(newRight);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				gap: 4,
				height: "100%",
				width: "100%",
			}}
		>
			<Box
				sx={{
					width: "50%",
				}}
			>
				<StatelessGenericTable<Item<T>, Item<T>>
					virtualScrollable={true}
					itemName="Sites"
					page={page || 1}
					model={left}
					rowModel={leftRowModel}
					isSelectable={true}
					isRowSelectable={isItemSelectable}
					loading={isLoading}
					tableHeight={600}
					setModel={selectLeft}
					selectAll={selectAllLeft}
					updatePage={updatePage}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "80px",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedRight}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant="outlined"
						size="small"
						onClick={handleCheckedLeft}
						aria-label="move selected left"
					>
						&lt;
					</Button>
				</Box>
			</Box>
			<Box
				sx={{
					width: "50%",
				}}
			>
				<StatelessGenericTable<Item<T>, Item<T>>
					virtualScrollable={false}
					itemName="Sites"
					updatePage={() => {}}
					page={1}
					model={right}
					rowModel={rightRowModel}
					isSelectable={true}
					isRowSelectable={() => true}
					loading={false}
					tableHeight={600}
					setModel={selectRight}
					selectAll={selectAllRight}
				/>
			</Box>
		</Box>
	);
}
