import {
	backgroundBrandTertiary,
	primaryColor,
	secondaryColor,
	borderNeutralSecondary,
} from "../../../../../core/theme";

export const style = {
	card: {
		width: "669px",
		minHeight: "496px",
		padding: "16px 24px",
		border: `1px solid ${borderNeutralSecondary}`,
		backgroundColor: "white",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		mb: 2,

		title: {
			fontWeight: "700",
		},
		icons: {},
	},
	infoList: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		mb: 2,
	},
	infoText: { fontWeight: "700" },
	contractPeriodServicePointList: {
		borderTop: `1px solid ${borderNeutralSecondary}`,
		mt: 2,
		pt: 2,
	},
	contractPeriodServicePoint: {
		backgroundColor: backgroundBrandTertiary,
	},
	buttonAdd: {
		width: "100%",
	},
	formWrapper: {
		padding: "16px",
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr) 0.75fr",
		gridColumnGap: "16px",
	},
	deleteWrapper: {
		display: "flex",
		alignItems: "flex-end",
	},
	deleteButton: {
		color: "black",
		textTransform: "capitalize",
		height: "42px",
		width: "100%",
	},
	addButton: {
		width: "100%",
		textTransform: "none",
		mt: 2,
		backgroundColor: secondaryColor,
		borderRadius: 0,
		color: "black",
	},
	saveButton: {
		width: "100%",
		textTransform: "none",
		mt: 2,
		backgroundColor: primaryColor,
		borderRadius: 0,
		color: "white",
		":hover": {
			backgroundColor: "#005aa0",
		},
	},
};
