interface PaginationType {
	total_elements: number;
	page: number;
	pages: number;
	per_page: number;
}

export interface GenericReferential {
	id: string;
	name: string;
}

export interface ContactType {
	email: string;
	id: number;
	name: string;
	recipient_types: string[];
}

export interface GetContactsResponseType {
	pagination: {
		page: number;
		pages: number;
		per_page: number;
		total_elements: number;
	};
	result: ContactType[];
}

export interface CertificatesTypesResponseType extends GenericReferential {}

export interface ClickTypesResponseType extends GenericReferential {
	ot_name: string;
}

export interface NegativePriceIndex extends GenericReferential {
	orchestrade_id: number;
}

export interface ServicePoint {
	id: number;
	name: string;
	code: string | null | undefined;
	tso_id: number;
}

export interface ServicePointsResponseType {
	pagination: PaginationType;
	result: ServicePoint[];
}
export interface Tso extends GenericReferential {}

export interface Books extends GenericReferential {}

export interface DynamicEndpointQuery {
	endpoint?: string;
}

export const ClickPeriodFrequencyLabels: { [key: string]: string } = {
	MONTHLY: "MONTHLY",
	QUARTERLY: "QUARTERLY",
	YEARLY: "YEARLY",
	DEFAULT: "Default",
	TradePeriod: "Default",
	Month: "MONTHLY",
	Quarter: "QUARTERLY",
	CalYear: "YEARLY",
};
