import { ReactNode, memo } from "react";
import { Box, TableCell } from "@mui/material";
import { style } from "./SimpleEditableTable.style";
import { FluidNumber } from "../FluidNumberField";
import { FluidTextField } from "../FluidTextField";
import FluidSelect from "../FluidSelect";
import { getSyntheticEvent } from "../../utils/getSyntheticEvent";
import FluidDatePicker from "../FluidDatePicker";
import CustomAutocomplete, { AutocompleteAble } from "../CustomAutocomplete";
import { noop } from "../../utils/operations";

export enum FieldTypes {
	number = "number",
	text = "text",
	select = "select",
	date = "date",
	search_select = "search_select",
}

export interface FluidComponentProps {
	value: any;
	name: string;
	onSearch?: (search: string) => any;
	errorMessage: string | undefined;
	hasError?: boolean;

	onChange: (e: React.ChangeEvent<any> | any) => void;

	items?: ({ value: any; label: string } | { id: any; name: string })[]; // for select or search select
}

export type FieldTypeToFluidComponentType = Record<
	FieldTypes,
	(props: FluidComponentProps) => ReactNode
>;

export const FieldTypeToFluidComponent: FieldTypeToFluidComponentType = {
	[FieldTypes.number]: ({
		errorMessage,
		name,
		value,
		onChange,
	}: FluidComponentProps) => (
		<FluidNumber
			sx={{ padding: 0, margin: 0, width: "100%" }}
			onChange={onChange}
			name={name}
			title={""}
			value={value}
			errorMessage={errorMessage}
		/>
	),
	[FieldTypes.text]: ({
		errorMessage,
		name,
		value,
		onChange,
	}: FluidComponentProps) => (
		<FluidTextField
			onChange={onChange}
			name={name}
			value={value}
			errorMessage={errorMessage}
			label={""}
		/>
	),
	[FieldTypes.select]: ({
		errorMessage,
		name,
		value,
		items,
		onChange,
	}: FluidComponentProps) => (
		<FluidSelect
			items={
				(items || []) as {
					value: any;
					label: string;
				}[]
			}
			label={""}
			sx={{
				padding: "14px 0 0 0",
				margin: "2px 0 0 0",
				width: "100%",
			}}
			errorMessage={errorMessage}
			name={name}
			value={value}
			onChange={(selected_item: unknown) =>
				onChange(getSyntheticEvent(name, selected_item))
			}
		/>
	),
	[FieldTypes.date]: ({
		errorMessage,
		name,
		value,
		onChange,
	}: FluidComponentProps) => (
		<FluidDatePicker
			onChange={onChange}
			sx={{ padding: 0, margin: 0, width: "100%" }}
			name={name}
			label={""}
			value={value}
			errorMessage={errorMessage}
		/>
	),

	[FieldTypes.search_select]: ({
		errorMessage,
		name,
		value,
		items,
		onSearch = noop,
		onChange,
	}: FluidComponentProps) => (
		<CustomAutocomplete
			fetchData={onSearch}
			sx={{
				margin: "0px",
				padding: "0px",
				width: "150px",
				marginTop: "24px",
			}}
			name={name}
			value={value}
			errorMessage={errorMessage}
			setFieldValue={onChange}
			data={items as AutocompleteAble[]}
			label={""}
		/>
	),
};

export interface SimpleEditableTableCellProps extends FluidComponentProps {
	id?: string;
	type: FieldTypes;
}

function SimpleEditableTableCellComponent({
	id,
	type,
	...props
}: SimpleEditableTableCellProps) {
	return (
		<TableCell id={id} sx={style.td}>
			<Box
				sx={{
					...style.td.container,
					...(props.hasError ? style.td.containerError : {}),
				}}
			>
				{FieldTypeToFluidComponent[type](props)}
			</Box>
		</TableCell>
	);
}

export const SimpleEditableTableCell = memo(SimpleEditableTableCellComponent);
