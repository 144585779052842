import { v4 as uuid } from "uuid";

const listeners: Record<string, (data: any) => void> = {};

export function subscribe<T>(listener: (data: T) => void): () => void {
	const id = uuid();
	listeners[id] = listener;
	return () => {
		delete listeners[id];
	};
}

const worker = new SharedWorker(
	new URL("./webSocketWorker.ts", import.meta.url)
);

worker.port.addEventListener("message", (event: MessageEvent): void => {
	for (const id in listeners) {
		listeners[id](event.data);
	}
});
worker.port.start();

export function send<T>(data: T) {
	worker.port.postMessage(data);
}
