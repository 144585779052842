import { SxProps, TableCell, Theme } from "@mui/material";
import React from "react";
import { secondaryColor } from "../../../../core/theme";

export interface PricingTableHeaderCellProps
	extends React.PropsWithChildren<{}> {
	size: number;
	sx?: SxProps<Theme>;
}

export function PricingTableHeaderCell(props: PricingTableHeaderCellProps) {
	return (
		<TableCell
			sx={{
				backgroundColor: secondaryColor,
				minWidth: props.size,
				width: props.size,
				maxWidth: props.size,
				fontWeight: "bold",
				...props.sx,
			}}
			align="left"
		>
			{props.children}
		</TableCell>
	);
}
