import {
	Box,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "./Modal.style";
import { ReactNode } from "react";
import { TitleWithIcon } from "../TitleWithIcon";

const Modal = ({
	open,
	onClose,
	children,
	title,
	actions,
	maxWidth,
	icon,
	disableClose,
	sx,
}: {
	open: boolean;
	onClose(): void;
	children: ReactNode;
	title?: ReactNode;
	actions?: ReactNode;
	icon?: ReactNode;
	disableClose?: boolean;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	sx?: Record<string, any>;
}) => {
	return (
		<Dialog
			PaperProps={{ sx }}
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
		>
			{!!title && (
				<TitleWithIcon icon={icon}>
					<DialogTitle sx={style.title} id="customized-dialog-title">
						{title}
					</DialogTitle>
				</TitleWithIcon>
			)}
			{!disableClose && (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={style.closeButton}
				>
					<CloseIcon />
				</IconButton>
			)}

			<Box sx={{ padding: "16px" }}>{children}</Box>

			{!!actions && (
				<DialogActions sx={style.actions}>{actions}</DialogActions>
			)}
		</Dialog>
	);
};

export default Modal;
