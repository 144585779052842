export interface PickListItem {
	id: string;
	name: string;
}

export interface PickLists {
	id: string;
	items: PickListItem[];
}

export enum FilterType {
	alphanumeric = "alphanumeric",
	numeric = "numeric",
	date = "date",
	list = "list",
	list_not_in = "list_not_in",
	exact = "exact",
}

export interface Filter {
	field_key: string;
	filter_data: {};
	display_name: string;
	field_path: string;
	http_source?: string;
	pickList?: PickListItem[];
	filter_type: FilterType;
}

export interface ComputedFilter extends Filter {
	value: string;
}

export interface GroupingField {
	field_key: string;
	display_name: string;
}

export interface Filters {
	fields: Filter[];
}

export interface GroupingFields {
	fields: GroupingField[];
}

export interface ModelMetadata {
	filters: Filters;
	groups: GroupingFields;
}
