import * as React from "react";
import { formatStringDate } from "../../../../common/utils/dateUtils";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { If } from "../../../../common/components/If";
import {
	Box,
	CircularProgress,
	Typography,
	SxProps,
	Theme,
} from "@mui/material";

const style: { [key: string]: SxProps<Theme> } = {
	loaderContainer: {
		minHeight: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

interface ProductionOverTimeGraphProps extends React.PropsWithChildren<{}> {
	data: any;
	installedCapacity: number;
	noData: boolean;
}

export default function ProductionOverTimeGraph(
	props: ProductionOverTimeGraphProps
) {
	const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
	const { data, installedCapacity, noData } = props;

	const [chartData, setChartData] = React.useState<any>({});

	React.useEffect(() => {
		const chart = chartComponentRef.current?.chart;
		if (data.length === 0) {
			chart?.showLoading("Loading...");
		} else {
			chart?.hideLoading();
			const plotLine = {
				color: "#FF0000",
				width: 2,
				value: installedCapacity,
				id: "plotLineInstalledCapacity",
			};

			setChartData({
				chart: {
					zoomType: "x,y",
					type: "spline",
					animation: false,
				},
				title: {
					text: "",
				},
				subtitle: {
					text:
						document.ontouchstart === undefined
							? "Click and drag in the plot area to zoom in"
							: "Pinch the chart to zoom in",
					align: "left",
				},
				xAxis: {
					type: "category",
					title: {
						text: "Date",
					},
					labels: {
						formatter(x: Highcharts.PointerAxisCoordinateObject) {
							return formatStringDate(
								new Date(x.value * 1000).toISOString(),
								"MM/yyyy"
							);
						},
					},
				},
				yAxis: {
					title: {
						text: "Production",
					},
					max: installedCapacity + 2,
					plotLines: [plotLine],
				},
				tooltip: {
					useHTML: true,
					formatter(): string {
						const values: any = this;
						let date = formatStringDate(
							new Date(values.x * 1000).toISOString(),
							"EEEE, MMM M yyyy, hh:mm"
						);
						return `${date} (UTC)<br/> <span style="color:${values.point.color}; font-size: 24px">\u25CF</span> ${values.series.name}:  ${values.y}`;
					},
				},
				plotOptions: {
					series: {
						turboThreshold: 2000,
					},
				},
				legend: {
					enabled: true,
					itemHiddenStyle: {
						textDecoration: "none",
					},
				},

				series: [
					{
						// Series that mimics the plot line
						color: "#FF0000",
						name: "Installed Capacity",
						marker: {
							enabled: false,
						},
						events: {
							legendItemClick: function (e: any) {
								if (e.target.visible) {
									chart?.xAxis[0].removePlotLine(
										"plotLineInstalledCapacity"
									);
								} else {
									chart?.xAxis[0].addPlotLine(plotLine);
								}
							},
						},
					},
					...data,
				],
			});
		}
	}, [data]);
	return (
		<>
			<If condition={Object.keys(chartData).length === 0 && !noData}>
				<Box sx={style.loaderContainer}>
					<CircularProgress color="primary" />
				</Box>
			</If>
			<If condition={Object.keys(chartData).length > 0}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartData}
					ref={chartComponentRef}
					{...props}
					containerProps={{
						style: { minHeight: "70vh" },
					}}
				/>
			</If>
			<If condition={noData}>
				<Typography paragraph>{"No Data Found"}</Typography>
			</If>
		</>
	);
}
