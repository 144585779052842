import { useState } from "react";
import { useSelector } from "react-redux";
import { Tender } from "../tender.module";
import { updateTenders } from "../tender.thunk";
import { selectAuthenticatedUser } from "../../authentication/auth.selector";
import { useAppDispatch } from "../../../common/hooks/default";
import { ActionTenderModal } from "../components/ActionTenderModal";
import { Action } from "../../pricing_list/pricingListSlice";
import { selectIsTenderActionsLoading } from "../tender.selector";
import { TsImporterModal } from "../components/TsImporterModal";

export function useTenderActions(tender?: Tender) {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(selectAuthenticatedUser);
	const [isTenderModalOpen, setTenderModalOpen] = useState(false);
	const [tenderModalAction, setTenderModalAction] = useState<Action | null>(
		null
	);
	const actionsAreLoading = useSelector(selectIsTenderActionsLoading);

	const onClose = () => {
		setTenderModalOpen(false);
		setTenderModalAction(null);
	};
	const onSubmit = () => {
		if (tender && tenderModalAction) {
			dispatch(
				updateTenders([tender.id], tenderModalAction, currentUser?.name)
			);
			setTenderModalOpen(false);
			setTenderModalAction(null);
		}
	};

	const tenderAction = async (action: any) => {
		if (tender) {
			if (Object.keys(action.fields).length > 0) {
				setTenderModalAction(action);
				return setTenderModalOpen(true);
			}
			return dispatch(
				updateTenders([tender?.id], action, currentUser?.name)
			);
		}
	};

	const tenderActionModal = tender ? (
		tenderModalAction &&
		tenderModalAction.action === "TRIGGER_TS_IMPORTER" ? (
			<TsImporterModal
				tender={tender}
				action={tenderModalAction as Action}
				submitModal={onSubmit}
				onClose={onClose}
				isLoading={false}
				errorMessage={""}
				open={isTenderModalOpen}
			/>
		) : (
			<ActionTenderModal
				tender={tender}
				action={tenderModalAction as Action}
				submitModal={onSubmit}
				onClose={onClose}
				isLoading={false}
				errorMessage={""}
				open={isTenderModalOpen}
			/>
		)
	) : null;

	return { tenderAction, tenderActionModal, actionsAreLoading };
}
