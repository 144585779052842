import { Box } from "@mui/material";
import Modal from "../Modal";
import { PrimaryButton } from "../CustomButton";
import {
	FieldTypeToFluidComponent,
	FluidComponentProps,
} from "./SimpleEditableTableCell";
import { EditHeadersType } from "./SimpleEditableTable";
import { useMemo, useState } from "react";

export interface ActionConfirmationModalProps {
	title: string;
	header: EditHeadersType;
	onClose: () => void;
	onConfirm: (header: EditHeadersType, value: any) => void;
}

export const ColumnFieldModal = ({
	title,
	header,
	onClose,
	onConfirm,
}: ActionConfirmationModalProps) => {
	const [currentValue, setValue] = useState();

	const inputProps: FluidComponentProps = useMemo(
		() => ({
			value: currentValue,
			onChange: (event: any) => {
				event.preventDefault();
				setValue(event.target.value);
			},
			errorMessage: undefined,
			name: header.accessor,
			items: header?.items,
		}),
		[currentValue, setValue, header]
	);

	return (
		<Modal
			sx={{ overflowY: "initial" }}
			title={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					{title}
				</Box>
			}
			actions={
				<>
					<PrimaryButton
						color="secondary"
						text="No"
						onClick={onClose}
					/>
					<PrimaryButton
						onClick={() => {
							onConfirm(header, currentValue);
							onClose();
						}}
						text="Yes"
						type="button"
						color="primary"
						isDisabled={!currentValue}
					/>
				</>
			}
			open={true}
			onClose={onClose}
		>
			{FieldTypeToFluidComponent[header.type](inputProps)}
		</Modal>
	);
};
