import { add, isAfter, isBefore, isValid, parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

export function formatDateDDMMYYYY(
	dateString: string | number | undefined
): string {
	if (dateString) {
		const date = new Date(dateString);
		return date.toLocaleDateString("en-GB", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
	}

	return "";
}

export function formatDateDDMMYYYYCET(
	dateString: string | number | undefined
): string {
	if (!dateString) return "";

	const date = new Date(dateString);

	const timeZone = "Europe/Paris";

	const zonedDate = utcToZonedTime(date, timeZone);

	return format(zonedDate, "dd/MM/yyyy HH:mm", { timeZone });
}

export const formatDate = (
	date: Date,
	dateFormat: string = "yyyy-MM-dd HH:mm",
	timezone: string = "UTC"
): string =>
	format(utcToZonedTime(date, timezone), dateFormat, {
		timeZone: timezone,
	});

export const formatStringDate = (
	stringDate?: string,
	dateFormat: string = "yyyy-MM-dd HH:mm",
	timezone: string = "UTC"
): string => {
	if (!stringDate) {
		return "";
	}

	const parsedTime = parseISO(stringDate);

	if (!isValid(parsedTime)) {
		return stringDate;
	}

	return formatDate(parsedTime, dateFormat, timezone);
};

export const removeOneDay = (date: string): string => {
	return format(
		add(new Date(date), {
			days: 1,
		}),
		"yyyy-MM-dd"
	);
};

export const isBeforeOrEqual = (date1: Date, date2: Date): boolean =>
	!isAfter(date1, date2);

export const isAfterOrEqual = (date1: Date, date2: Date): boolean =>
	!isBefore(date1, date2);

export const isBetween = (
	date: Date,
	startDate: Date,
	endDate: Date
): boolean => isAfterOrEqual(date, startDate) && isBeforeOrEqual(date, endDate);

export const getTodaysDate = () => {
	const today = new Date();
	const myToday = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate(),
		0,
		0,
		0
	);
	return myToday;
};
