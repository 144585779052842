import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, SxProps, Theme } from "@mui/material";
import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../common/components/CustomButton";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { useAppDispatch } from "../../common/hooks/default";
import { useSearchPaginate } from "../../common/hooks/useSearchPaginate";
import { grey, lightGrey, pagePadding, white } from "../../core/theme";
import { Tender } from "../tender_page/tender.module";
import { TenderSection } from "./components/TenderSection/TenderSection";
import { TenderActions } from "./components/TenderSection/TenderActions";
import {
	selectCanPrintUnderOffer,
	selectPricingsHasNextPage,
	selectPricingsTender,
	selectPricings,
	selectPricingsLoader,
	selectPricingstenderIsLoading as selectPricingsTenderIsLoading,
} from "./pricingList.selector";
import {
	downloadPricingsExport,
	getCancelledPricings,
	getPricings,
	getTender,
	mergeincomingPricings,
	mergeincomingUpdatedPricings,
	resetAllPricings,
} from "./pricingList.thunk";
import {
	Pricing,
	PricingStatus,
	setPricingsStatusLoading,
	updatePricingsTender,
} from "./pricingListSlice";
import { PricingTable } from "./components/PricingTable/PricingTable";
import { usePricingActions } from "./hooks/actions/pricingActions.hook";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import { If } from "../../common/components/If";
import {
	selectPersistedFilters,
	selectPricingsOdataQuery,
} from "../filters/filters.selector";
import FiltersDrawer from "../filters/Filters";
import { selectPricingsGroupingMetadata } from "../metadata/metadata.selector";
import { selectPricingSelectedGroupingFields } from "../grouping/grouping.selector";
import { GroupsSelector } from "../../common/components/GroupsSelector/GroupsSelector";
import { PricingGroups } from "../pricing_groups/pricingGroups";
import * as groupingActions from "../grouping/grouping.slice";
import PageContent, {
	PageContentVariantType,
} from "../../common/components/PageContent";
import { ValueOrPercentContext } from "./ValueOrPercentContext";
import { WsMessage } from "../../core/socketProvider";
import wsPublisher$ from "../../core/wsPublisher";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { getTimestampWithTimeZone } from "../live_update/utils";
import { usePricingListColumnSelection } from "./hooks/columns/usePricingListColumnSelection";
import { useAvailablePricingListColumns } from "./hooks/columns/pricingListColumns";
import * as _ from "lodash";
import { PATH } from "../../router-path";

export const style: { [key: string]: SxProps<Theme> } = {
	container: {
		height: "100%",
		minWidth: "1888px",
		bgcolor: lightGrey,
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		marginBottom: "10px",
		padding: `0 ${pagePadding}`,
	},
	titleContainer: {
		display: "flex",
		height: 80,
		width: "100%",
		alignItems: "center",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		fontWeight: 400,
		fontSize: "36px",
		margin: 0,
		display: "flex",
		alignItems: "center",
	},
	tableWrapper: {
		flex: "1",
		marginTop: "30px",
		border: `1px solid ${grey}`,
		width: "calc(100vw - 80px)",
	},
	siteOrPortfolioContainer: {
		display: "flex",
		gap: 1,
	},

	stickyRow: {
		position: "sticky",
		left: 0,
		paddingLeft: "5px !important",
		paddingTop: "10px !important",
		paddingBottom: "10px !important",
		background: white,
		"& > td": { padding: "0px" },
		width: "50px !important",
	},
	wayPoint: {
		height: 10,
	},
};

interface PricingListProps {
	maximized?: boolean;
}

const PricingListPage: React.FC<PricingListProps> = (
	props: PricingListProps
) => {
	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useAppDispatch();
	const { tenderId } = useParams();
	const [valueOrPercent, setValueOrPercent] = useState<"value" | "percent">(
		"percent"
	);
	const tender: Tender | undefined = useSelector(
		selectPricingsTender,
		_.isEqual
	);
	const loading = useSelector(selectPricingsLoader);
	const canPrintUnderPricing = useSelector(selectCanPrintUnderOffer);
	const tenderLoading = useSelector(selectPricingsTenderIsLoading);
	const availableGroups = useSelector(
		selectPricingsGroupingMetadata,
		_.isEqual
	);
	const selectedGroups = useSelector(selectPricingSelectedGroupingFields);

	const pricings: Pricing[] | null | undefined = useSelector(
		selectPricings(tender?.id),
		_.isEqual
	);

	const hasNextPage = useSelector(selectPricingsHasNextPage);
	const onPricingsStartLoading = useCallback(
		(ids: number[]) => {
			dispatch(setPricingsStatusLoading({ ids, status: true }));
		},
		[dispatch]
	);
	const onPricingsFinishLoading = useCallback(
		(ids: number[]) => {
			dispatch(setPricingsStatusLoading({ ids, status: false }));
		},
		[dispatch]
	);
	const { actOnPricings, actionModal } = usePricingActions(
		pricings,
		onPricingsStartLoading,
		onPricingsFinishLoading
	);

	useEffect(() => {
		(async function () {
			if (tenderId) {
				await dispatch(getTender(parseInt(tenderId)));
			}
		})();
	}, [tenderId, dispatch]);

	const onPricingCreatedMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			dispatch(
				mergeincomingPricings(
					tender?.id || 0,
					messages.map((message) => message.data)
				)
			);
		},
		[dispatch, tender]
	);

	const onPricingUpdatedMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			dispatch(
				mergeincomingUpdatedPricings(
					tender?.id || 0,
					messages.map((message) => message.data)
				)
			);
		},
		[dispatch, tender]
	);

	const onTenderMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			const tenderMessages = messages.filter(
				(message) => message.data.id === tender?.id
			);
			if (tenderMessages.length) {
				let latest: Tender | null = null;
				for (const message of messages) {
					if (!latest) {
						latest = message.data;
					} else if (
						getTimestampWithTimeZone(latest.updated_at) <
						getTimestampWithTimeZone(tender?.updated_at)
					) {
						latest = message.data;
					}
				}
				if (latest) {
					dispatch(updatePricingsTender(latest));
				}
			}
		},
		[tender]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_CREATED],
			onPricingCreatedMessages
		);
	}, [onPricingCreatedMessages]);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.PRICING_UPDATED],
			onPricingUpdatedMessages
		);
	}, [onPricingUpdatedMessages]);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_UPDATED],
			onTenderMessages
		);
	}, [onTenderMessages]);

	const onPricingsCancelledMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			messages.forEach((message) => {
				dispatch(getCancelledPricings(message.url || "", tenderId));
			});
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.PRICINGS_CANCELLED],
			onPricingsCancelledMessages
		);
	}, [onPricingsCancelledMessages]);

	const persistedFilters = useSelector(selectPersistedFilters, _.isEqual);
	const filterCount = useMemo(() => {
		return persistedFilters.filters?.pricings_filters_count ?? 0;
	}, [persistedFilters.filters?.pricings_filters_count]);

	const odataQuery = useSelector(selectPricingsOdataQuery);

	const callGetPricings = (page: number) => {
		dispatch(getPricings(Number(tenderId), page, 100));
	};
	const { nextPage, currentPage } = useSearchPaginate(callGetPricings);

	useEffect(() => {
		dispatch(resetAllPricings());
		callGetPricings(1);
	}, [odataQuery, tenderId]);

	const onTenderPickupMessages = useCallback(
		(messages: WsMessage<any>[]) => {
			const filteredMessages = messages.filter(
				(f) => f.data?.id === tender?.id
			);
			filteredMessages.forEach(() => {
				dispatch(resetAllPricings());
				callGetPricings(1);
			});
		},
		[tender]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_PICKUP],
			onTenderPickupMessages
		);
	}, [onTenderPickupMessages]);

	const onPricingsDownload = useCallback(
		(
			tender_id: number,
			status_in: PricingStatus[] = [],
			format: string = "value"
		) => {
			dispatch(downloadPricingsExport(tender_id, status_in, format));
		},
		[dispatch]
	);

	const displayOverlay = useMemo(
		() =>
			(tenderLoading || loading) &&
			tenderId &&
			parseInt(tenderId) !== tender?.id,
		[tenderLoading, loading, tenderId, tender]
	);

	const availableColumns = useAvailablePricingListColumns(pricings);
	const { selectedColumns, onColumnToggled } =
		usePricingListColumnSelection();

	const selectedAndAvailableColumns = useMemo(
		() =>
			selectedColumns
				.filter(
					(column) =>
						!!_.find(
							availableColumns,
							(available) => available.id === column.id
						)
				)
				.filter(
					(column) =>
						!_.find(
							availableColumns,
							(available) => available.id === column.id
						)?.disabled
				),
		[availableColumns, selectedColumns]
	);

	return (
		<>
			{!props.maximized && (
				<PageTitle
					label="My Pricings"
					leftSide={
						<Box display="flex" justifyContent="flex-start">
							<PrimaryButton
								text="My Tenders"
								type="submit"
								color="info"
								sx={{
									width: 140,
									color: "#171D21",
								}}
								onClick={() => navigate(PATH.TENDERS)}
							>
								<ChevronLeftIcon />
							</PrimaryButton>
						</Box>
					}
				/>
			)}
			<If condition={displayOverlay}>
				<OverlayLoader />
			</If>
			<If condition={!displayOverlay}>
				<Box
					sx={style.container}
					style={
						props.maximized
							? { marginTop: "-126px", zIndex: 0 }
							: {}
					}
					id="page-list-container"
				>
					{!props.maximized && <TenderSection tender={tender} />}
					<FiltersDrawer page="Pricings" id={tender?.id} />

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<GroupsSelector
							onAddgroup={(group) =>
								dispatch(groupingActions.addGroup(group))
							}
							onRemoveGroup={(group) =>
								dispatch(groupingActions.removeGroup(group))
							}
							setGroups={(groups) =>
								dispatch(groupingActions.setGroups({ groups }))
							}
							onReset={() =>
								dispatch(groupingActions.clearGroups())
							}
							availableGroups={availableGroups}
							selectedGroups={selectedGroups}
						/>
						<TenderActions
							availableColumns={availableColumns}
							pricings={pricings}
							valueOrPercent={valueOrPercent}
							canPrintUnderPricing={canPrintUnderPricing}
							tender={tender}
							setValueOrPercent={setValueOrPercent}
							onPricingsDownload={onPricingsDownload}
							actOnPricings={actOnPricings}
							filterCount={Number(filterCount)}
							selectedColumns={selectedColumns}
							onColumnToggled={onColumnToggled}
						/>
					</Box>
					<ValueOrPercentContext.Provider value={valueOrPercent}>
						<If condition={selectedGroups.length === 0}>
							<Box
								sx={style.tableWrapper}
								id="pricing-table-container"
							>
								<PageContent
									variant={PageContentVariantType.noPadding}
									sx={{ height: "100%" }}
								>
									<PricingTable
										selectedColumns={
											selectedAndAvailableColumns
										}
										tender={tender}
										pricings={pricings}
										valueOrPercent={valueOrPercent}
										hasNextPage={hasNextPage}
										currentPage={currentPage}
										loading={loading}
										nextPage={nextPage}
										actOnPricings={actOnPricings}
									/>
								</PageContent>
							</Box>
						</If>
						<If condition={selectedGroups.length > 0}>
							<PricingGroups
								selectedColumns={selectedAndAvailableColumns}
								tenderId={tender?.id}
								odataQuery={odataQuery}
							/>
						</If>
					</ValueOrPercentContext.Provider>
					{actionModal}
				</Box>
			</If>
		</>
	);
};

export default PricingListPage;
