import { Box, FormControlLabel, Menu, Switch } from "@mui/material";
import React from "react";
import { PrimaryButton } from "../CustomButton";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import * as _ from "lodash";

const styles = {
	panel: {
		display: "flex",
		flexDirection: "column",
		padding: "8px",
		gap: "8px",
		maxHeight: "300px",
	},
};

export interface Column<T extends React.Key> {
	label: string;
	id: T;
	disabled?: boolean;
}

export interface ColumnsSelectorProps<T extends React.Key> {
	availableColumns: Column<T>[];
	selectedColumns: Column<T>[];
	onColumnToggled: (column: Column<T>) => void;
}

export function ColumnsSelector<T extends React.Key>(
	props: ColumnsSelectorProps<T>
) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<PrimaryButton
				sx={{
					width: 120,
					marginLeft: "10px",
				}}
				startIcon={<CalendarViewWeekIcon />}
				type="button"
				color="secondary"
				id="column-selector-button"
				aria-controls={open ? "column-selector-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				text="Columns"
			/>
			<Menu
				id="column-selector-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "column-selector-button",
				}}
			>
				<Box sx={styles.panel}>
					{props.availableColumns.map((column) => (
						<FormControlLabel
							key={column.id}
							control={
								<Switch
									disabled={column.disabled}
									defaultChecked
									checked={
										!!_.find(
											props.selectedColumns,
											(selectedColumn) =>
												selectedColumn.id === column.id
										)
									}
									onChange={() =>
										props.onColumnToggled(column)
									}
								/>
							}
							label={column.label}
						/>
					))}
				</Box>
			</Menu>
		</div>
	);
}
