import { NJTab } from "@engie-group/fluid-design-system-react";
import { ReactElement } from "react";

export interface TabProps extends React.PropsWithChildren<{}> {
	label: string;
	id: string;
	ariaControl?: string;
	onClick?: () => void;
	isActive?: boolean;
	isDisabled?: boolean;
}

export const Tab = (props: TabProps) => {
	return (
		<NJTab
			ariaControls={props.ariaControl ?? props.id}
			id={props.id}
			label={props.label}
			isActive={props.isActive}
			isDisabled={props.isDisabled}
			onClick={props.onClick}
		>
			{props.children as ReactElement}
		</NJTab>
	);
};
