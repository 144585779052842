import { useEffect, useState } from "react";
import { OktaAuth } from "@okta/okta-auth-js";

declare global {
	interface Window {
		_paq: { [key: string]: any }[];
	}
}

const useMatomo = (oktaAuth: OktaAuth) => {
	const [userId, setUserId] = useState<string>();

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				const userInfo = await oktaAuth.getUser();
				// using email as user identifier for Matomo
				setUserId(String(userInfo.email));
			} catch (err) {
				console.error(err);
			}
		};

		if (oktaAuth) {
			getUserInfo();
		}
	}, [oktaAuth]);

	useEffect(() => {
		if (userId) {
			const paq = (window._paq = window._paq || []);
			/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
			paq.push(["trackPageView"]);
			paq.push(["enableLinkTracking"]);
			(function () {
				const u =
					process.env.REACT_APP_MATOMO_TAG_MANAGER_CONTAINER_URL;
				paq.push(["setTrackerUrl", u + "matomo.php"]);
				paq.push(["setSiteId", process.env.REACT_APP_MATOMO_SITE_ID]);
				paq.push(["setUserId", userId]);
				const d = document,
					g = d.createElement("script"),
					s = d.getElementsByTagName("script")[0];
				g.async = true;
				g.src = u + "matomo.js";
				s.parentNode?.insertBefore(g, s);
			})();
		}
	}, [userId]);
};

export default useMatomo;
