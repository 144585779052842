import { RootState } from "../../core/rootReducers";

const selectAuthState = (state: RootState) => state.authentication;

export function selectAuthenticatedUser(state: RootState) {
	const authState = selectAuthState(state);
	return authState.user;
}

export function selectUserIdentity(state: RootState) {
	const authState = selectAuthState(state);
	return authState.identity;
}

export function selectUserGroupNames(state: RootState) {
	const authState = selectAuthState(state);
	return authState.groupNames;
}

export function selectUserGroups(state: RootState) {
	const authState = selectAuthState(state);
	return authState.groups;
}

export function selectIsAuthenticated(state: RootState) {
	const isAuthenticatedState = selectAuthState(state);
	return isAuthenticatedState.user !== null;
}

export function selectWsConnected(state: RootState) {
	const authState = selectAuthState(state);
	return authState.connected;
}
