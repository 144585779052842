import { addNonWorkingDays } from "../../common/utils/bankHolidays";
import { TsSource } from "./tenderForm.module";
import { TenderCreationModel } from "./tenderForm.schema";
import { addWeeks } from "date-fns";

export const Countries = [
	{ key: "fr", value: "FR" },
	{ key: "be", value: "BE" },
	{ key: "de", value: "DE" },
	{ key: "nl", value: "NL" },
];

export const TS_SOURCE = {
	[TsSource.REALIZED_ENDPOINT]: "Imported Tourbillon",
	[TsSource.GECO]: "GECO",
	[TsSource.CLUSTER]: "CLUSTER",
	[TsSource.RENEWEX]: "Imported (Renewex)",
	[TsSource.TS_IMPORTER]: "Imported (Importer)",
	[TsSource.TOURBILLON]: "Manual",
};

export const WindTechnologies = ["wind_onshore", "wind_offshore"];
export const Solar = "solar";
export const DISPLAY_TECHNO_NAME_MAP: Record<string, string> = {
	wind_offshore: "Wind offshore",
	wind_onshore: "Wind onshore",
	solar: "Solar",
};

export const SiteImportTypes = [
	{ key: "universal_template", value: "Universal Template " },
];

export const initialTenderValues: TenderCreationModel = {
	direction: "PPA",
	transactionType: "Physical",
	account: undefined,
	tenderName: "",
	deadline: addNonWorkingDays(new Date(), 6),
	validityDate: addWeeks(new Date(), 3),
	historicalData: [],
	bypassWarning: false,
	realized_data_will_be_added: false,
	pricing_stage: "INDICATIVE",
	has_negative_price_clause: false,
};
