import PageTitle from "../../common/components/PageTitle/PageTitle";
import _ from "lodash";
import PageContent from "../../common/components/PageContent";
import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { DefaultTabContent } from "../../common/components/DefaultTabContent";
import { If } from "../../common/components/If";
import { useCallback, useEffect, useMemo, useState } from "react";
import ClickabilityForm from "./forms/ClickabilityForm";
import { FluidButton } from "../../common/components/FluidButton";
import { useNavigate, useParams } from "react-router-dom";
import {
	useLazyGetOneContractQuery,
	useUpdateContractStatusMutation,
	useUpdateOneContractDryRunMutation,
} from "../../requests_geco/contractsApi";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import PricingTabForm from "./forms/PricingTabForm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
	ContractDetailType,
	ContractStatus,
	ErrorType,
	TradeType,
} from "../../requests_geco/contractsApi/contractsApi.types";
import {
	PricingFormik,
	pricingFormikToContractDetailMapper,
} from "./formik/pricingFormik";
import { NegativePriceIndex } from "../../requests_geco/referentialApi/referentialApi.types";
import { useLazyGetNegativePriceIndexesQuery } from "../../requests_geco/referentialApi/referentialApi";
import CounterpartyTabForm from "./forms/CounterpartyForm";
import {
	CounterpartyFormik,
	counterpartyFormikToContractDetailMapper,
} from "./formik/counterpartyFormik";
import {
	ClickabilityFormik,
	clickabilityFormikOverrideToAllContractPeriodMapper,
} from "../contract_period/formik/clickabilityTabFormik";
import { getPathWithParameters, PATH } from "../../router-path";
import { PrimaryButton } from "../../common/components/CustomButton";
import { enqueueSnackbar } from "notistack";
import {
	useCreateContractChangeMutation,
	useLazyGetOneContractChangeQuery,
} from "../../requests_geco/contractChangesApi";
import { ContractChangeStatus } from "../../requests_geco/contractChangesApi/contractChangesApi.types";
import { Spacer } from "../../common/components/Spacer";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";
import { formatApiErrorMessage } from "../../common/utils/formatApiErrorMessage";

const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		padding: "24px",
	},
	buttonContainer: {
		display: "flex",
		marginLeft: "auto",
		gap: "24px",
	},
	errorMessages: {
		padding: 3,
		color: "red",
	},
	errorMessagesTitle: {
		fondWeight: "bold",
		color: "black",
	},
};

const ContractValidation = () => {
	const { contractId } = useParams();
	const navigate = useNavigate();

	const [
		getcontractChangeProposition,
		{
			isLoading: isGetContractChangeLoading,
			isSuccess: isGetContractChangeSuccess,
			data: contractChangeProposition,
		},
	] = useLazyGetOneContractChangeQuery();

	const [
		createContractChangeProposal,
		{
			isLoading: isCreateContractProposalLoading,
			isSuccess: isCreateContractProposalSuccess,
		},
	] = useCreateContractChangeMutation();

	const [
		getContractBase,
		{
			data: contractData,
			isLoading: isGetContractLoading,
			isSuccess: isGetContractSuccess,
		},
	] = useLazyGetOneContractQuery();

	const [getIndexesBase, { data: negativePriceIndexes }] =
		useLazyGetNegativePriceIndexesQuery();

	const [
		checkContractFormatBase,
		{
			isLoading: isDryRunLoading,
			isSuccess: isDryRunSuccess,
			error: contractValidationError,
			reset: resetContractValidation,
		},
	] = useUpdateOneContractDryRunMutation();

	const [
		updateContractStatusBase,
		{ isLoading: isUpdateContractStatusLoading },
	] = useUpdateContractStatusMutation();

	const getContract = useRtkQueryDynamicEndpoint(getContractBase);
	const getIndexes = useRtkQueryDynamicEndpoint(getIndexesBase);
	const checkContractFormat = useRtkQueryDynamicEndpoint(
		checkContractFormatBase
	);
	const updateContractStatus = useRtkQueryDynamicEndpoint(
		updateContractStatusBase
	);

	const [updatedContract, setUpdatedContract] = useState<
		ContractDetailType | undefined
	>();

	const [activeTab, setActiveTab] = useState(0);

	const availableOptions = useMemo(() => {
		return (negativePriceIndexes || []).map(
			(index: NegativePriceIndex) => ({
				value: `${index.orchestrade_id}`,
				label: index.name,
			})
		);
	}, [negativePriceIndexes]);

	const onGoBack = useCallback(() => {
		navigate(
			getPathWithParameters(PATH.CONTRACT_DETAIL, {
				contractId: Number(contractId),
			})
		);
	}, [contractData]);

	const initialContractData = useMemo(() => {
		if (
			contractData &&
			isGetContractSuccess &&
			isGetContractChangeSuccess
		) {
			return contractChangeProposition?.data?.payload || contractData;
		}
	}, [
		contractData,
		contractChangeProposition,
		isGetContractLoading,
		isGetContractChangeLoading,
		isGetContractChangeSuccess,
		isGetContractSuccess,
	]);

	const contractStatus = useMemo(() => {
		if (
			contractData &&
			isGetContractSuccess &&
			isGetContractChangeSuccess
		) {
			return (
				contractChangeProposition?.data?.status || contractData.status
			);
		}
	}, [
		contractData,
		contractChangeProposition,
		isGetContractLoading,
		isGetContractChangeLoading,
		isGetContractChangeSuccess,
		isGetContractSuccess,
	]);

	const contractContainsChanges = useMemo(() => {
		return !_.isEqual(updatedContract, initialContractData);
	}, [updatedContract, initialContractData]);

	useEffect(() => {
		if (contractId) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: false,
			});
			getcontractChangeProposition(Number(contractId));
		}
	}, [contractId]);

	useEffect(() => {
		if (initialContractData) {
			getIndexes({ country_code: initialContractData?.country });
			setUpdatedContract(initialContractData);
		}
	}, [initialContractData]);

	useEffect(() => {
		if (isDryRunSuccess) {
			createContractChangeProposal(updatedContract as ContractDetailType);
		}
	}, [isDryRunSuccess, updatedContract]);

	useEffect(() => {
		if (isCreateContractProposalSuccess) {
			enqueueSnackbar(
				`contract change has been requested for contract ${contractId}`,
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			);
			updateContractStatus({
				contract_id: Number(contractId),
				status: ContractStatus.UNDER_APPROVAL,
			});
			onGoBack();
		}
	}, [isCreateContractProposalSuccess, contractId]);

	const maxTabIndex = useMemo(() => {
		// if clickability then 3 tabs are available
		return initialContractData?.clickability ? 2 : 1;
	}, []);

	const next = () => {
		setActiveTab((currentTab) => currentTab + 1);
	};

	const back = () => {
		setActiveTab((currentTab) => Math.max(currentTab - 1, 0));
	};

	const onPricingSubmit = (data: PricingFormik) => {
		setUpdatedContract(
			pricingFormikToContractDetailMapper(
				data,
				updatedContract as ContractDetailType
			)
		);
	};

	const onCounterPartySubmit = (data: CounterpartyFormik) => {
		setUpdatedContract(
			counterpartyFormikToContractDetailMapper(
				data,
				updatedContract as ContractDetailType
			)
		);
	};

	const onClickabilitySubmit = (data: ClickabilityFormik) => {
		setUpdatedContract(
			clickabilityFormikOverrideToAllContractPeriodMapper(
				data,
				updatedContract as ContractDetailType
			)
		);
	};

	const onValidation = useCallback(() => {
		updateContractStatus({
			contract_id: Number(contractId),
			status: ContractStatus.VALID,
		})
			.unwrap()
			.then(() => {
				enqueueSnackbar(`contract ${contractId} is now valid`, {
					variant: "success",
					autoHideDuration: 3000,
				});
				onGoBack();
			});
	}, [contractId]);

	const onRequireChanges = useCallback(() => {
		checkContractFormat({
			...updatedContract,
			id: initialContractData?.id as number,
		});
	}, [initialContractData, updatedContract]);

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract Validation"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={onGoBack}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				rightSide={
					<>
						<If condition={contractContainsChanges}>
							<If
								condition={
									isDryRunLoading ||
									isCreateContractProposalLoading
								}
							>
								<CircularProgress
									sx={{ marginTop: "40px" }}
									data-testid="loader"
								/>
							</If>
							<If
								condition={
									!isDryRunLoading &&
									!isCreateContractProposalLoading
								}
							>
								<FluidButton
									onClick={onRequireChanges}
									label={
										contractStatus ===
										ContractChangeStatus.REJECTED
											? "Request trader approval"
											: "Request changes"
									}
									type="button"
									variant="destructive"
								></FluidButton>
							</If>
						</If>
						<If condition={!contractContainsChanges}>
							<If
								condition={
									isUpdateContractStatusLoading ||
									isGetContractLoading
								}
							>
								<CircularProgress
									sx={{ marginTop: "40px" }}
									data-testid="loader"
								/>
							</If>
							<If
								condition={
									!isUpdateContractStatusLoading &&
									!isGetContractLoading
								}
							>
								<FluidButton
									onClick={onValidation}
									label="Validate contract"
									type="button"
									variant="primary"
								></FluidButton>
							</If>
						</If>
					</>
				}
			/>
			<Spacer gap={24} />
			<PageContent>
				<If
					condition={
						contractChangeProposition?.data?.status ===
							ContractChangeStatus.REJECTED &&
						contractChangeProposition?.data?.comments?.length
					}
				>
					<Spacer gap={24} />
					<Box sx={style.errorMessages}>
						<Typography
							sx={style.errorMessagesTitle}
							variant="subtitle1"
						>
							Trader did not validate changes for the following
							reasons:
						</Typography>
						{contractChangeProposition?.data?.comments}
					</Box>
				</If>
				<Box
					sx={{
						flexGrow: 1,
						bgcolor: "background.paper",
						display: "flex",
						minHeight: 800,
					}}
				>
					<Tabs
						orientation="vertical"
						value={activeTab}
						onChange={() => {}}
						aria-label="Vertical tabs example"
						sx={{ borderRight: 1, borderColor: "divider" }}
					>
						<Tab onClick={() => setActiveTab(0)} label="Pricing" />
						<Tab
							onClick={() => setActiveTab(1)}
							label="Counter Party"
						/>
						<If condition={initialContractData?.clickability}>
							<Tab
								onClick={() => setActiveTab(2)}
								label="Clickability"
							/>
						</If>
					</Tabs>
					<Box sx={style.container}>
						<If condition={activeTab === 0}>
							<DefaultTabContent>
								<PricingTabForm
									indexes={availableOptions}
									onSubmit={onPricingSubmit}
									contract={initialContractData}
									contract_period_id={
										_.get(
											initialContractData,
											"contract_periods[0].id"
										) || 0
									}
									tradeType={
										_.get(
											initialContractData,
											"contract_periods[0].trade_type"
										) || TradeType.PPA_PHYSICAL
									}
								/>
							</DefaultTabContent>
						</If>
						<If condition={activeTab === 1}>
							<DefaultTabContent>
								<CounterpartyTabForm
									contract={initialContractData}
									onSubmit={onCounterPartySubmit}
								/>
							</DefaultTabContent>
						</If>
						<If condition={activeTab === 2}>
							<DefaultTabContent>
								<ClickabilityForm
									contract={initialContractData}
									contract_period_id={
										_.get(
											initialContractData,
											"contract_periods[0].id"
										) || 0
									}
									onSubmit={onClickabilitySubmit}
								/>
							</DefaultTabContent>
						</If>
						<Box sx={style.buttonContainer}>
							<If condition={activeTab > 0}>
								<FluidButton
									onClick={back}
									label="Back"
									type="button"
									variant="secondary"
								/>
							</If>
							<If condition={activeTab < maxTabIndex}>
								<FluidButton
									type="submit"
									label={"Next"}
									onClick={next}
								/>
							</If>
						</Box>
					</Box>
				</Box>
			</PageContent>
			<ErrorMessageModal
				title={
					"Invalid Proposal, the contract is not in a correct format"
				}
				content={formatApiErrorMessage(
					contractValidationError as ErrorType
				)}
				actionAfterClose={resetContractValidation}
			/>
		</>
	);
};

export default ContractValidation;
