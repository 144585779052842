import { Box } from "@mui/material";

const style = {
	container: {
		display: "flex",
		flexDirection: "row",
		gap: "24px",
	},
};

export interface InputGroupProps extends React.PropsWithChildren<{}> {}

const Group = (props: InputGroupProps) => {
	return <Box sx={style.container}>{props.children}</Box>;
};

export default Group;
