import * as Yup from "yup";
import { TechnologyType } from "../../../../../requests_geco/sitesApi/sitesApi.types";

export const getAssetValidationSchema = (technologyType: TechnologyType) =>
	Yup.object().shape({
		installed_capacity: Yup.number().required(
			"Installed Capacity is required"
		),
		machine: Yup.number().required("Machine is required"),
		name: Yup.string().required("Asset name is required"),
		key: Yup.string().required("Asset key is required"),
		subsidy: Yup.number()
			.transform((_value, originalValue) => parseFloat(originalValue))
			.required("Subsidy is required"),
		sr_code: Yup.string()
			.matches(/^C.+/, "SR code must start with 'C'")
			.required("SR code is required"),
		tr_code: Yup.string()
			.matches(/^D.+/, "TR code must start with 'D'")
			.required("TR code is required"),
		tr_mastr_no: Yup.string()
			.matches(/^S.+/, "TR code must start with 'S'")
			.required("TR code is required"),
		service_point: Yup.number().required("Service Point is required"),
		azimuth:
			technologyType === TechnologyType.Solar
				? Yup.number().max(90).min(0)
				: Yup.number().max(90).min(0),
		tilt_angle:
			technologyType === TechnologyType.Solar
				? Yup.number().max(360).min(0)
				: Yup.number().max(360).min(0),
		hub_height:
			technologyType !== TechnologyType.Solar
				? Yup.number()
				: Yup.number(),
		rotor_diameter:
			technologyType !== TechnologyType.Solar
				? Yup.number()
				: Yup.number(),
		commissioning_date: Yup.date().required(
			"Commissioning Date is required"
		),
		start_date: Yup.date(),
		end_date: Yup.date(),
		manufacturer_serial_number: Yup.string()
			.min(1, "Serial Number must have at least one character")
			.required("Serial Number is required"),
	});

export const getListAssetValidationSchema = (technologyType: TechnologyType) =>
	Yup.object().shape({
		items: Yup.array().of(getAssetValidationSchema(technologyType)),
	});
