import { Box, DialogTitle, Typography } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { Spacer } from "../../../../../common/components/Spacer";
import { primaryColor } from "../../../../../core/theme";
import HelpIcon from "@mui/icons-material/Help";

export interface GeneratingCurtailmentNothingModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const GeneratingCurtailmentNothingModal = ({
	isOpen,
	onClose,
}: GeneratingCurtailmentNothingModalProps) => {
	const style = {
		container: {
			width: "580px",
			padding: "24px",
		},
		bodyContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		helpIcon: {
			fontSize: "64px",
			color: primaryColor,
			lineHeight: 0,
		},
	};
	return (
		<Modal sx={style.container} open={isOpen} onClose={onClose}>
			<Box sx={style.bodyContainer}>
				<span style={style.helpIcon}>
					<HelpIcon fontSize="inherit" />
				</span>
				<DialogTitle>Empty</DialogTitle>
				<Typography>
					No curtailment to build for this period.
				</Typography>
				<Spacer gap={32} />
				<PrimaryButton
					onClick={onClose}
					text="Close"
					type="button"
					color="primary"
				/>
			</Box>
		</Modal>
	);
};
