import { Box, ListItemText, SxProps, Theme, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { primaryColor } from "../../core/theme";
import {
	NotificationEventType,
	NotificationItemType,
} from "./notificationsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TimeAgo } from "../../common/components/TimeAgo";
import { replacePlaceholderWithData } from "../../common/utils/formatNotificationMessage";
import { SetNotificationsAsRead } from "./notificationsThunk";
import { useMemo } from "react";
import LinkIcon from "@mui/icons-material/Link";

const style: { [key: string]: SxProps<Theme> } = {
	notification_item: {
		backgroundColor: primaryColor,
		padding: "10px",
		borderRadius: "5px",
		color: "white",
		width: "100%",
		"& p": {
			color: "white",
			opacity: "0.6",
		},
	},
	notification_item_icon: {
		"&:hover": {
			cursor: "pointer",
			opacity: 0.4,
		},
	},
};

interface NotificationItemProps {
	data: NotificationItemType;
}
export default function NotificationItem(props: NotificationItemProps) {
	const { data } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const title = useMemo(() => {
		switch (props.data.event_type) {
			case NotificationEventType.NEW_PRICING:
				return "PRICINGS (NEW)";
			case NotificationEventType.NEW_TENDER:
				return "TENDERS (NEW)";
			case NotificationEventType.PICKUP:
				return "TENDERS (PICKUP)";
			case NotificationEventType.PRICING_FIXING_REQUEST:
				return "PRICINGS (FIXING REQUESTED)";
			case NotificationEventType.PRICING_FIXED:
				return "PRICINGS (FIXED)";
			case NotificationEventType.PRICING_FIXED_APPROVED:
				return "PRICINGS (FIXED APPROVED)";
			case NotificationEventType.CONTRACT_CHANGED:
				return "CONTRACTS (UPDATED)";
			case NotificationEventType.CONTRACT_REJECTED:
				return "CONTRACT CHANGES (REJECTED)";
			case NotificationEventType.CONTRACT_APPROVED:
				return "CONTRACT CHANGES (APPROVED)";
		}
	}, [props.data]);

	return (
		<Box
			display={"flex"}
			alignItems={"center"}
			sx={style.notification_item}
		>
			<LinkIcon sx={props.data.url ? { opacity: 1 } : { opacity: 0 }} />
			<Box
				display={"flex"}
				alignItems={"center"}
				sx={{
					...style.notification_item,
					cursor: !!data?.url ? "pointer" : "",
					flexDirection: "column",
					alignItems: "flex-start",
				}}
			>
				<Typography sx={{ margin: 0, lineHeight: 0.8, opacity: 0.8 }}>
					{title}
				</Typography>
				<ListItemText
					primary={
						<Box
							component={"span"}
							onClick={() => data?.url && navigate(data?.url)}
							sx={
								props.data.url
									? {
											textDecoration: "underline",
											cursor: "pointer",
									  }
									: {}
							}
						>
							{replacePlaceholderWithData(
								data.message,
								data.extra_params
							)}
						</Box>
					}
					secondary={
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<TimeAgo since={data.created_at} />{" "}
						</Box>
					}
				/>
			</Box>
			{!data.read && (
				<VisibilityOffIcon
					sx={style.notification_item_icon}
					onClick={() => dispatch(SetNotificationsAsRead([data.id]))}
				/>
			)}
		</Box>
	);
}
