import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { primaryColor } from "../../core/theme";
import { useCallback } from "react";
import { SxProps, Theme } from "@mui/material";

export interface CollapseArrowProps {
	setIsOpen: (isOpen: boolean) => void;
	isOpen?: boolean;
	sx?: SxProps<Theme>;
}

export function CollapseArrow(props: CollapseArrowProps) {
	const { isOpen, setIsOpen, sx } = props;

	const onClick = useCallback(
		(event: any) => {
			event.stopPropagation();
			setIsOpen(!isOpen);
		},
		[setIsOpen, isOpen]
	);

	return (
		<IconButton
			aria-label="expand row"
			size="small"
			onClick={onClick}
			sx={sx}
		>
			{isOpen ? (
				<KeyboardArrowDownIcon
					sx={{
						color: primaryColor,
					}}
				/>
			) : (
				<KeyboardArrowRightIcon
					sx={{
						color: primaryColor,
					}}
				/>
			)}
		</IconButton>
	);
}
