import { Box } from "@mui/material";
import { style } from "./FiltersField.style";
import FluidSelect from "../../FluidSelect";
import React, { useCallback, useEffect, useState } from "react";
import { FieldValue, Operations, operationMapper } from "../FiltersDrawer.type";

export interface FiltersFieldProps {
	name: string;
	label?: string;
	defaultOperation: Operations;
	defaultFieldValue: FieldValue;
	selector?: (element: any) => FieldValue;
	allowedOperations: Operations[];
	// method provided by parent component through HOC React.CloneElement
	onChange?: (prevKey: string, newKey: string, value: FieldValue) => void;
	Input: React.ReactElement;
}

export const FiltersField = ({
	defaultOperation,
	allowedOperations,
	name,
	label,
	defaultFieldValue,
	onChange = () => {},
	Input,
	selector = (event) => event.target.value,
}: FiltersFieldProps) => {
	const [currentOperation, setCurrentOperation] =
		useState<Operations>(defaultOperation);
	const [fieldValue, setFieldValue] = useState(defaultFieldValue);

	useEffect(() => {
		if (
			defaultOperation &&
			allowedOperations &&
			!allowedOperations.includes(defaultOperation)
		) {
			throw new Error(
				`Default operation should be within allowed operation for field ${name}`
			);
		}
	}, [defaultOperation, allowedOperations]);

	const getFilterKey = useCallback(
		(operation: Operations) => {
			return `${name}__${operation}`;
		},
		[name]
	);

	const onChangeOperation = (newOperation: Operations) => {
		onChange(
			getFilterKey(currentOperation),
			getFilterKey(newOperation),
			fieldValue
		);
		setCurrentOperation(newOperation);
	};

	const onChangeField = (event: any) => {
		setFieldValue(selector(event));
		onChange(
			getFilterKey(currentOperation),
			getFilterKey(currentOperation),
			selector(event)
		);
	};

	return (
		<Box sx={style.container}>
			<Box sx={style.container.left}>
				<FluidSelect
					isLabelStatic
					items={Object.entries(operationMapper)
						.filter((entry) =>
							allowedOperations.includes(entry[0] as Operations)
						)
						.map((entry) => ({
							label: entry[1],
							value: entry[0],
						}))}
					label={label || name}
					name={""}
					// @ts-ignore
					onChange={onChangeOperation}
					value={currentOperation}
				/>
			</Box>
			<Box sx={style.container.right}>
				{React.cloneElement(Input, {
					value: fieldValue,
					name: name,
					onChange: onChangeField,
				})}
			</Box>
		</Box>
	);
};
