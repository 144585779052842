import { TableHead, TableRow, TableCell, SxProps, Theme } from "@mui/material";
import { PricingTableHeaderCell } from "../PricingTable/PricingTableHeaderCell";
import { secondaryColor } from "../../../../core/theme";

const style: { [key: string]: SxProps<Theme> } = {
	stickyHeader: {
		position: "sticky",
		left: 0,
		background: secondaryColor,
		zIndex: 1000,
		paddingLeft: "5px !important",
		paddingTop: "10px !important",
		paddingBottom: "10px !important",
		width: "940px",
		"& > th": { padding: "0px" },
	},
};

export const PricingHeader = () => {
	return (
		<TableHead>
			<TableRow
				sx={{
					"&:last-child td, &:last-child th": {
						border: 0,
					},
					"& > th": { padding: "0px" },
				}}
			>
				<TableCell sx={style.stickyHeader}>
					<PricingTableHeaderCell size={50}>
						P_ID
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={190}>
						Status
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={200}>
						Site / Port
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={200}>
						Pricing Type
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={50}>
						Base
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={50}>
						Click
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={100}>
						Delivery
					</PricingTableHeaderCell>
					<PricingTableHeaderCell size={100}>
						InstCapa (MW)
					</PricingTableHeaderCell>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};
