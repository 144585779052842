import {
	borderNeutralSecondary,
	primaryColor,
} from "../../../../../core/theme";

export const style = {
	container: {
		padding: "16px 24px",
		display: "flex",
		flexDirection: "column",
		border: `1px solid ${borderNeutralSecondary}`,
		width: "max-content",
		gap: "24px",
		fileContainer: {
			marginBottom: "4px",
		},
		fileText: {
			color: primaryColor,
			fontWeight: "regular",
			textDecoration: "underline",
			cursor: "pointer",
		},
	},
};
