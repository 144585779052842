import { RootState } from "../../core/rootReducers";
import { GroupingField } from "../metadata/metadata.module";
import { GroupedResponseCount } from "./grouping.module";

export function selectPricingsGrouping(state: RootState) {
	return state.grouping.pricings;
}

export function selectPricingSelectedGroupingFields(
	state: RootState
): GroupingField[] {
	return selectPricingsGrouping(state).selectedGroups;
}

export function selectPricingsGroupCountIsLoading(state: RootState): boolean {
	return selectPricingsGrouping(state).count.isLoading;
}

export function selectPricingsGroupCountError(state: RootState) {
	return selectPricingsGrouping(state).count.error;
}

export function selectPricingsGroupCount(
	state: RootState
): GroupedResponseCount | null {
	return selectPricingsGrouping(state).count.data;
}
