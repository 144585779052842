import { Box } from "@mui/material";
import {
	dangerSecondary,
	dangerSubtle,
	successSubtle,
	successSecondary,
	dangerPrimary,
} from "../../../../../core/theme";
import { InfoBlockItem } from "../../../../../common/components/InfoBlockItem";
import { TitleWithIcon } from "../../../../../common/components/TitleWithIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Spacer } from "../../../../../common/components/Spacer";

export interface GeneratedCurtailmentObjectItemProps {
	message?: string;
	curtailment?: { name: string; start_date: string; end_date: string };
}
export const style = {
	errorContainer: {
		border: `1px solid ${dangerSubtle}`,
		background: dangerSecondary,
		padding: "16px",
		width: "400px",
	},
	successContainer: {
		border: `1px solid ${successSubtle}`,
		background: successSecondary,
		padding: "16px",
		width: "400px",
	},
	title: {
		color: "#000",
		fontFamily: "Lato",
		fontSize: "14px",
		fontWeight: 700,
	},
	infoItemsContainer: {
		display: "flex",
		gap: "24px",
	},
	errorMessage: {
		color: dangerPrimary,
		fontFamily: "Lato",
		fontSize: "14px",
		fontWeight: 400,
	},

	errorMessageIcon: {
		color: dangerPrimary,
		fontSize: "14px",
		lineHeight: 0,
	},
	titleError: {
		color: dangerPrimary,
		fontFamily: "Lato",
		fontSize: "14px",
		fontWeight: 700,
	},
};
export const GeneratedCurtailmentObjectItem = ({
	message = "",
	curtailment,
}: GeneratedCurtailmentObjectItemProps) => {
	return (
		<Box sx={curtailment ? style.successContainer : style.errorContainer}>
			<Box sx={curtailment?.name ? style.title : style.titleError}>
				{curtailment?.name || "Curtailment object generation failed"}
			</Box>
			{curtailment && (
				<Box sx={style.infoItemsContainer}>
					<InfoBlockItem label="Start date" value={"2018-05-14"} />
					<InfoBlockItem label="End date" value={"2018-05-14"} />
				</Box>
			)}
			<Spacer gap={8} />
			{message && (
				<TitleWithIcon
					sx={{ gap: "4px", alignItems: "flex-start" }}
					icon={
						<span style={style.errorMessageIcon}>
							<ErrorOutlineIcon fontSize="inherit" />
						</span>
					}
				>
					<Box sx={style.errorMessage}>{message}</Box>
				</TitleWithIcon>
			)}
		</Box>
	);
};
