import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { PrimaryButton } from "../../common/components/CustomButton";
import { FluidButton } from "../../common/components/FluidButton";
import PageContent from "../../common/components/PageContent";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import AlertDialogModal from "../../common/components/AlertDialogModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useCallback, useEffect, useMemo, useState } from "react";
import { jsonStringToObjectString } from "./helpers";
import { useNavigate, useParams } from "react-router-dom";
import { PATH, getPathWithParameters } from "../../router-path";
import {
	useLazyGetOneContractQuery,
	useUpdateContractStatusMutation,
	useUpdateOneContractMutation,
} from "../../requests_geco/contractsApi";
import {
	useApproveContractChangeMutation,
	useLazyGetOneContractChangeQuery,
	useRejectContractChangeMutation,
} from "../../requests_geco/contractChangesApi";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { If } from "../../common/components/If";
import ErrorMessageModal from "../../common/components/ErrorMessageDialog";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../common/utils/formatApiErrorMessage";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import { Box, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import AlertDialogWithTextArea from "../../common/components/AlertDialogWithTextArea";
import { ContractStatus } from "../../requests_geco/contractsApi/contractsApi.types";

const style = {
	buttonGroup: {
		display: "flex",
		gap: "8px",
	},
};

export const CounterValidation = () => {
	const { contractId } = useParams();
	const navigate = useNavigate();
	const [showValidationModal, setValidationModal] = useState(false);
	const [showRejectModal, setRejectModal] = useState(false);

	const [updateContractStatusBase] = useUpdateContractStatusMutation();

	const updateContractStatus = useRtkQueryDynamicEndpoint(
		updateContractStatusBase
	);

	const [
		getContractChangeProposition,
		{
			isLoading: isGetContractChangeLoading,
			data: contractChangeProposition,
		},
	] = useLazyGetOneContractChangeQuery();

	const [
		approveContractChange,
		{
			isLoading: isApproving,
			isSuccess: isApprovingSuccess,
			error: approveError,
		},
	] = useApproveContractChangeMutation();

	const [
		rejectContractChange,
		{
			isLoading: isRejecting,
			isSuccess: isRejectingSuccess,
			error: rejectError,
		},
	] = useRejectContractChangeMutation();

	const [
		getContractBase,
		{ data: contractData, isLoading: isGetContractLoading },
	] = useLazyGetOneContractQuery();

	const [
		updateContractBase,
		{
			isLoading: isContractUpdating,
			error: contractUpdateError,
			isSuccess: isUpdateContractSuccess,
		},
	] = useUpdateOneContractMutation();

	const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);
	const getContract = useRtkQueryDynamicEndpoint(getContractBase);

	const isFetching = useMemo(
		() => isGetContractLoading || isGetContractChangeLoading,
		[isGetContractLoading, isGetContractChangeLoading]
	);

	const onGoBack = useCallback(() => {
		navigate(
			getPathWithParameters(PATH.CONTRACT_DETAIL, {
				contractId: contractData?.id,
			})
		);
	}, [contractData]);

	const onClickValid = useCallback(() => {
		setValidationModal(true);
	}, [setValidationModal]);

	const onCloseValidationModal = useCallback(() => {
		setValidationModal(false);
	}, [setValidationModal]);

	const onClickReject = useCallback(() => {
		setRejectModal(true);
	}, [setRejectModal]);

	const onCloseRejectationModal = useCallback(() => {
		setRejectModal(false);
	}, [setRejectModal]);

	const onValidateChange = useCallback(() => {
		if (contractChangeProposition?.data?.payload) {
			updateContract(contractChangeProposition?.data?.payload);
			approveContractChange({ contractId: Number(contractId) });
			onCloseValidationModal();
		}
	}, [
		updateContract,
		approveContractChange,
		contractId,
		approveContractChange,
		contractChangeProposition,
		onCloseValidationModal,
	]);

	const onRejectChange = useCallback(
		(text: string) => {
			rejectContractChange({
				contractId: Number(contractId),
				reason: text,
			});
			onCloseRejectationModal();
		},
		[contractId, rejectContractChange, onCloseRejectationModal]
	);

	useEffect(() => {
		if (contractId) {
			getContract({
				contract_id: Number(contractId),
				skip_validation: false,
			});
			getContractChangeProposition(Number(contractId));
		}
	}, [contractId]);

	useEffect(() => {
		if (isApprovingSuccess) {
			updateContractStatus({
				contract_id: Number(contractId),
				status: ContractStatus.CHANGE_APPROVED,
			});
		}
	}, [isApprovingSuccess]);

	useEffect(() => {
		if (isUpdateContractSuccess && isApprovingSuccess) {
			enqueueSnackbar(
				`contract has been updated with the changes: ${contractId}`,
				{
					variant: "success",
					autoHideDuration: 3000,
				}
			);
			onGoBack();
		}
	}, [isUpdateContractSuccess, contractId, isApprovingSuccess]);

	useEffect(() => {
		if (isRejectingSuccess) {
			updateContractStatus({
				contract_id: Number(contractId),
				status: ContractStatus.CHANGE_REJECTED,
			});
			enqueueSnackbar(
				`contract change has been rejected: ${contractId}`,
				{
					variant: "warning",
					autoHideDuration: 3000,
				}
			);
			onGoBack();
		}
	}, [isRejectingSuccess, contractId]);

	return (
		<>
			<PageTitle
				fontWeight={"bold"}
				label="Contract Validation"
				leftSide={
					<PrimaryButton
						text="Back"
						type="button"
						color="info"
						sx={{
							width: 106,
							color: "#171D21",
						}}
						onClick={onGoBack}
					>
						<ChevronLeftIcon />
					</PrimaryButton>
				}
				hideRightSide={isFetching}
				rightSide={
					<Box sx={style.buttonGroup}>
						<If condition={isRejecting}>
							<CircularProgress
								sx={{ marginTop: "40px" }}
								data-testid="loader"
							/>
						</If>
						<If condition={!isRejecting}>
							<FluidButton
								onClick={onClickReject}
								label="Reject changes"
								type="button"
								variant="destructive"
							></FluidButton>
						</If>
						<If condition={isContractUpdating || isApproving}>
							<CircularProgress
								sx={{ marginTop: "40px" }}
								data-testid="loader"
							/>
						</If>
						<If condition={!isContractUpdating && !isApproving}>
							<FluidButton
								onClick={onClickValid}
								label="Validate changes"
								type="button"
								variant="primary"
							></FluidButton>
						</If>
					</Box>
				}
			/>
			<If condition={contractData && contractChangeProposition}>
				<PageContent>
					<ReactDiffViewer
						oldValue={
							jsonStringToObjectString(
								JSON.stringify(contractData || {})
							) as string
						}
						newValue={
							jsonStringToObjectString(
								JSON.stringify(
									contractChangeProposition?.data?.payload ||
										{}
								)
							) as string
						}
						compareMethod={DiffMethod.WORDS}
						splitView={true}
					/>
				</PageContent>
			</If>

			<If condition={isGetContractChangeLoading || isGetContractLoading}>
				<OverlayLoader />
			</If>
			<ErrorMessageModal
				title={"Failed to update Contract"}
				content={formatApiErrorMessage(
					(contractUpdateError as ErrorType) ||
						(approveError as ErrorType) ||
						(rejectError as ErrorType)
				)}
			/>
			<AlertDialogModal
				title={"Are you sure you want to validate the changes"}
				isOpen={showValidationModal}
				handleApprove={onValidateChange}
				handleClose={onCloseValidationModal}
			/>
			<AlertDialogWithTextArea
				title={"Please provide the reason for rejecting the changes"}
				textBoxTitle="Reason for rejection"
				isTextRequired
				isOpen={showRejectModal}
				handleClose={onCloseRejectationModal}
				handleApprove={onRejectChange}
			/>
		</>
	);
};
