import { NJToggle } from "@engie-group/fluid-design-system-react";
import { Typography } from "@mui/material";
import { textNeutralTertiary } from "../../core/theme";

export interface ToggleProps {
	name: string;
	label: string;
	description?: string;
	onChange: (event: any) => void;
	isTrue: boolean;
	isDisabled?: boolean;
}

export const Toggle = (props: ToggleProps) => {
	return (
		<>
			<NJToggle
				id={props.name}
				name={props.name}
				label={props.label}
				onChange={props.onChange}
				values={{ off: false, on: true }}
				isChecked={!!props.isTrue}
				isDisabled={props.isDisabled}
			/>
			<Typography color={textNeutralTertiary} variant="subtitle2">
				{props.description}
			</Typography>
		</>
	);
};
