import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	SvgIcon,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { style } from "./MenuCard.style";

interface MenuCardProps {
	title: string;
	description: string;
	icon: React.FunctionComponent;
	link: string;
}

function MenuCard({ description, icon, link, title }: MenuCardProps) {
	return (
		<Card variant="outlined" sx={style.card}>
			<CardActionArea
				component={Link}
				to={link}
				sx={style.cardActionArea}
			>
				<CardContent sx={style.cardContent}>
					<Typography variant="h6" component="h6" sx={style.title}>
						{title}
					</Typography>
					<Typography sx={style.description}>
						{description}
					</Typography>
					<SvgIcon sx={style.icon} component={icon} inheritViewBox />
				</CardContent>
				<Box sx={style.lowerBorder}></Box>
			</CardActionArea>
		</Card>
	);
}

export default MenuCard;
