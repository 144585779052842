import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import usePrevious from "../../../common/hooks/usePrevious";
import { DropZoneInput } from "../../../common/components/DropZoneInput";
import { HistoricalDataFilePreview } from "../../../common/components/HistoricalDataFilePreview";
import {
	UploadedFile,
	UploadingFile,
} from "../../../common/files/files.module";
import { PrimaryButton } from "../../../common/components/CustomButton";

export interface UploadFileModalProps {
	onClose: () => void;
	isOpen: boolean;
	onSubmit: (file: UploadingFile | UploadedFile) => void;
	associatedSiteId?: number | null;
}

export function UploadFileModal(props: UploadFileModalProps) {
	const { onClose, onSubmit, isOpen } = props;
	const prevIsOpen = usePrevious(isOpen);
	const [uploadedFile, setUploadedFile] = useState<
		UploadingFile | UploadedFile | null
	>(null);
	const [isUploading, setIsUploading] = useState(false);

	useEffect(() => {
		if (isOpen && !prevIsOpen) {
			setUploadedFile(null);
			setIsUploading(false);
		}
	}, [isOpen, prevIsOpen, setUploadedFile, setIsUploading]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Upload a file linked to this site</DialogTitle>
			<DialogContent>
				<DropZoneInput
					maxFiles={1}
					renderPreview={HistoricalDataFilePreview}
					setSubmitting={() => {
						setIsUploading(true);
					}}
					label={"historical production data"}
					name={"historicalData"}
					value={uploadedFile ? [uploadedFile] : []}
					disabled={isUploading || !!uploadedFile}
					setFieldValue={(_name, values) => {
						setUploadedFile(values?.length ? values[0] : null);
						setIsUploading(false);
					}}
					accept={{
						"text/csv": [".csv"],
						"application/zip": [".zip"],
						"application/vnd.ms-excel": [".xls"],
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
							[".xlsx"],
					}}
					restrictionText={
						"Only .csv, excel files and zips at 500mb or less"
					}
					maxSize={500 * 1000 * 1000} // 500mb
					extraData={{ associated_site_id: props.associatedSiteId }}
				/>
			</DialogContent>
			<DialogActions>
				<DialogActions>
					<PrimaryButton
						onClick={() => {
							if (uploadedFile) {
								onSubmit(uploadedFile);
							}
						}}
						text="Associate"
						type="button"
						color="primary"
						disabled={!!uploadedFile}
					></PrimaryButton>
					<PrimaryButton
						onClick={onClose}
						text="Cancel"
						type="button"
						color="secondary"
					></PrimaryButton>
				</DialogActions>
			</DialogActions>
		</Dialog>
	);
}
