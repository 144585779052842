import { WsEventTypes } from "../../common/constants/wsEvents";
import { apiGet, apiPatch, apiPost } from "../../common/utils/request.utils";
import { RootState } from "../../core/rootReducers";
import wsPublisher$ from "../../core/wsPublisher";
import { AppDispatch } from "../../store";
import {
	selectPersistedFilters,
	selectTenderFilters,
} from "../filters/filters.selector";
import { buildPredicates, setSelectedFilterValues } from "../filters/utils";
import { TSData, Tender } from "./tender.module";
import {
	getTendersError,
	setTenderLoading,
	getTendersSuccess,
	updateTenderBlotter,
	updateTenderStatus,
	setTenderPricings,
	setPricingsByTenderIsLoading,
	setTenders,
	setTenderActionLoading,
	setTsImporterLoading,
	tsImporterSuccess,
	tsImporterError,
	tsImporterWarning,
} from "./tenderSlice";
import { enqueueSnackbar } from "notistack";
import * as liveDataUpdate from "../live_update/LiveUpdate.merge";
import { selectTenders } from "./tender.selector";
import { Action } from "../pricing_list/pricingListSlice";
import * as _ from "lodash";

export function getTenders(
	page: number,
	search: string = "",
	perPage: number = 20,
	latestUpdatedAtParam: Date | undefined = undefined
): any {
	return function (dispatch: AppDispatch, getState: any) {
		dispatch(setTenderLoading());
		const state = getState();

		const tenderQuery = state.filters.persistedFilters.tenders_query;

		return apiGet(
			`tenders?page=${page}&per_page=${perPage}&search=${search}&filters=${tenderQuery}&sort_by=id&order=desc` +
				(latestUpdatedAtParam
					? `&latest_updated_at=${latestUpdatedAtParam}`
					: "")
		).then(
			(tenders) => {
				return dispatch(
					getTendersSuccess({
						tenders: tenders.data.data,
						latestUpdatedAt:
							tenders.data.max_updated_at_tender_date,
						latestUpdatedAtParam,
						hasNextPage: tenders.data.has_next_page,
					})
				);
			},
			(error) => {
				return dispatch(getTendersError(error.message));
			}
		);
	};
}

export function updateMyTenderStatus(tender_id: number, status: any) {
	return (dispatch: AppDispatch) => {
		dispatch(
			updateTenderStatus({
				tenderId: tender_id,
				status: status,
			})
		);
	};
}

export function loadTenderPricings(tenderId: number) {
	return async function (dispatch: AppDispatch) {
		dispatch(setPricingsByTenderIsLoading({ tenderId, isLoading: true }));
		try {
			const response = await apiGet(
				`pricing?tender_id=${tenderId}&page=1&per_page=11`
			);
			dispatch(
				setTenderPricings({ tenderId, pricings: response.data.data })
			);
		} finally {
			dispatch(
				setPricingsByTenderIsLoading({ tenderId, isLoading: false })
			);
		}
	};
}

const actionMessageStarted = (
	tender_ids: number[],
	action: Action,
	user_name: string | undefined
) => {
	return `Starting process ${action.display_name} on ${tender_ids.length} tenders by ${user_name} started`;
};

const actionMessageDone = (action: Action) => {
	return `Process ${action.display_name} succeeded`;
};

export function updateTenders(
	tender_ids: number[],
	action: Action,
	user_name: string | undefined
) {
	enqueueSnackbar(actionMessageStarted(tender_ids, action, user_name), {
		variant: "warning",
		autoHideDuration: 5000,
	});
	return async function (dispatch: AppDispatch) {
		const endpoint = action.endpoint;
		try {
			dispatch(setTenderActionLoading(true));
			const res = await apiPatch(`${endpoint}`, {
				...action,
				tender_ids,
			});
			enqueueSnackbar(actionMessageDone(action), {
				variant: "success",
				autoHideDuration: 5000,
			});
			res?.data?.data?.map((tender: Tender) => {
				wsPublisher$.publish({
					type: WsEventTypes.TENDER_UPDATED,
					data: { ...tender, isNewUpdate: true },
					at: "" + new Date(),
				});
			});
			dispatch(updateTenderBlotter(res.data));
		} finally {
			dispatch(setTenderActionLoading(false));
		}
	};
}

export function mergeIncomingTenders(incomingTenders: Tender[]) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const tenders = selectTenders(getState());
		const tendersFiltersData = selectTenderFilters(getState());
		const persistedFilters = selectPersistedFilters(getState());
		const tenderValues = setSelectedFilterValues(
			tendersFiltersData,
			persistedFilters.filters?.tenders_filters ?? ""
		);

		const tenderPredicates = buildPredicates(
			tendersFiltersData,
			tenderValues,
			"Tenders"
		);

		dispatch(
			setTenders(
				liveDataUpdate.mergeIncomingTenders(
					tenders,
					incomingTenders,
					[],
					tenderPredicates.linqPredicates
				)
			)
		);
	};
}

export function mergeIncomingTenderUpdates(incomingTenders: Tender[]) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const tenders = selectTenders(getState());
		const tenderIds = new Set(tenders.map((tender) => tender.id));
		const incomingTendersIntersection = incomingTenders.filter((tender) =>
			tenderIds.has(tender.id)
		);
		if (incomingTendersIntersection.length) {
			dispatch(mergeIncomingTenders(incomingTendersIntersection));
		}
	};
}
export function tsImporter(values: {
	tsInfo: { [key: string]: TSData };
	ignoreWarning: boolean;
}): any {
	let mappedTsInfo: any = Object.values(values.tsInfo);
	mappedTsInfo = mappedTsInfo.map((element: any) => ({
		asset_id: element.assetId,
		file_id: element.fileId,
		sheet_name: element.sheetName,
		production_col: element.productionCol,
		index_date: element.indexDate,
		index_time: element.indexTime,
		unit: element.unit,
		timezone: element.timezone,
		id: element.id,
		offset: element.offset,
	}));
	mappedTsInfo = _.groupBy(Object.values(mappedTsInfo), "asset_Id");
	return function (dispatch: AppDispatch) {
		dispatch(setTsImporterLoading(true));
		return apiPost("timeseries/ts-importer", {
			ts_list: Object.values(mappedTsInfo),
			ignore_warning: values.ignoreWarning,
		}).then(
			(t) => {
				if (t.data?.warnings?.length > 0) {
					dispatch(tsImporterWarning(t.data?.warnings));
				} else if (t.data?.errors?.length > 0) {
					dispatch(tsImporterError(t.data?.errors));
				} else {
					dispatch(tsImporterSuccess(t.data?.ts_list));
				}
				dispatch(tsImporterError(undefined));
				dispatch(tsImporterWarning(undefined));
				dispatch(setTsImporterLoading(false));
				return;
			},
			(error: any) => {
				dispatch(tsImporterError(error.response.data.errors));
				return;
			}
		);
	};
}

export function getFileSheetNames(file_id: number, rowId: string) {
	return function (dispatch: AppDispatch) {
		return apiGet(`/files/sheet-names/${file_id}`).then(
			(t) => {
				return t.data?.list;
			},
			() => {
				dispatch(
					tsImporterError([
						{ [rowId]: "Unable to load sheet name for this file" },
					])
				);
				return [];
			}
		);
	};
}

export default {
	getTenders,
	tsImporter,
};
