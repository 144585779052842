import style from "./CountdownTimer.style";

const mapNumber = (
	number: number,
	in_min: number,
	in_max: number,
	out_min: number,
	out_max: number
): number =>
	((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

const polarToCartesian = (
	centerX: number,
	centerY: number,
	radius: number,
	angleInDegrees: number
) => {
	const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
};

const describeArc = (
	x: number,
	y: number,
	radius: number,
	startAngle: number,
	endAngle: number
) => {
	const start = polarToCartesian(x, y, radius, endAngle);
	const end = polarToCartesian(x, y, radius, startAngle);

	const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	return [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		largeArcFlag,
		0,
		end.x,
		end.y,
	].join(" ");
};

export const SVGCircle = ({ seconds }: { seconds: number }) => (
	<svg style={style.countdownSvg} viewBox="0 0 100 100">
		<path
			fill="none"
			strokeWidth="5"
			d={describeArc(50, 50, 48, 0, mapNumber(seconds, 60, 0, 0, 360))}
		/>
	</svg>
);

export const getColor = (minutesLeft: number): string => {
	if (minutesLeft < 5) {
		return "red";
	}
	if (minutesLeft < 10) {
		return "orange";
	}

	return "green";
};
