import _ from "lodash";
import {
	ContractDetailType,
	SettlementType,
	TradeType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import { getContractPeriodFromId } from "../../contract_period/formik/common";

export interface PricingFormik {
	goo_price?: number;
	settlements: SettlementType[];
}

export const pricingTabFormikInitialValueMapper = (
	data: ContractDetailType | undefined,
	contract_period_id: number,
	tradeType: TradeType
): PricingFormik => {
	return {
		goo_price: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.goo_price,
		settlements:
			getContractPeriodFromId(
				data?.contract_periods || [],
				contract_period_id
			)?.[
				tradeType === TradeType.PPA_BASE_PHYSICAL
					? "ppa_base_physical"
					: "ppa_physical"
			].settlements || [],
	};
};

export const pricingFormikToContractDetailMapper = (
	formikData: PricingFormik,
	initialContract: ContractDetailType
) => {
	const updatedContract = _.cloneDeep(initialContract) as ContractDetailType;
	// apply cp change to all cp instance on contract
	for (const cp of updatedContract.contract_periods) {
		if (formikData.goo_price) {
			cp.goo_price = formikData.goo_price;
		}

		if (cp.trade_type === TradeType.PPA_BASE_PHYSICAL) {
			cp.ppa_base_physical.settlements = formikData.settlements;
		}

		if (cp.trade_type === TradeType.PPA_PHYSICAL) {
			cp.ppa_physical.settlements = formikData.settlements;
		}
	}

	return updatedContract;
};
