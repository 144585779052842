import { NJButton } from "@engie-group/fluid-design-system-react";

export interface FluidButtonProps {
	label: string;
	icon?: string;
	type?: "button" | "submit" | "reset";
	onClick: () => void;
	isDisabled?: boolean;
	variant?: "inverse" | "primary" | "secondary" | "destructive";
}
export const FluidButton = ({
	type,
	icon,
	label,
	onClick,
	isDisabled,
	variant = "primary",
}: FluidButtonProps) => {
	return (
		<NJButton
			variant={variant}
			type={type}
			icon={icon}
			label={label}
			onClick={onClick}
			isDisabled={isDisabled}
		/>
	);
};
