export const njSemanticColorBackgroundNeutralPrimaryDefault = "#ffffff"; // Sets the background color to white, provides a clean and minimalist canvas for the interface. Classic foundation for content and components.
export const njSemanticColorBackgroundNeutralPrimaryHovered = "#ebeef1";
export const njSemanticColorBackgroundNeutralPrimaryFocused = "#ebeef1";
export const njSemanticColorBackgroundNeutralPrimaryPressed = "#ccd4da";
export const njSemanticColorBackgroundNeutralPrimarySelected = "#e7eefc";
export const njSemanticColorBackgroundNeutralPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundNeutralPrimaryTranslucentHovered = "rgba(5, 43, 80, 0.08)";
export const njSemanticColorBackgroundNeutralPrimaryTranslucentFocused = "rgba(5, 43, 80, 0.08)";
export const njSemanticColorBackgroundNeutralPrimaryTranslucentPressed = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralPrimaryTranslucentSelected = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundNeutralSecondaryDefault = "#f6f8f9"; // Provides a subtle contrast with the primary background. Use to add a touch of visual interest without overpowering the content.
export const njSemanticColorBackgroundNeutralSecondaryHovered = "#ebeef1";
export const njSemanticColorBackgroundNeutralSecondaryFocused = "#ebeef1";
export const njSemanticColorBackgroundNeutralSecondaryPressed = "#ccd4da";
export const njSemanticColorBackgroundNeutralSecondaryTranslucentDefault = "rgba(30, 80, 105, 0.04)";
export const njSemanticColorBackgroundNeutralSecondaryTranslucentHovered = "rgba(5, 43, 80, 0.08)";
export const njSemanticColorBackgroundNeutralSecondaryTranslucentFocused = "rgba(5, 43, 80, 0.08)";
export const njSemanticColorBackgroundNeutralSecondaryTranslucentPressed = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralTertiaryDefault = "#ebeef1"; // Provides a slightly stronger contrast with the primary background. Use to enhances the visual separation between different sections or elements.
export const njSemanticColorBackgroundNeutralTertiaryHovered = "#ccd4da";
export const njSemanticColorBackgroundNeutralTertiaryFocused = "#ccd4da";
export const njSemanticColorBackgroundNeutralTertiaryPressed = "#9eadb8";
export const njSemanticColorBackgroundNeutralTertiaryTranslucentDefault = "rgba(5, 43, 80, 0.08)";
export const njSemanticColorBackgroundNeutralTertiaryTranslucentHovered = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralTertiaryTranslucentFocused = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralTertiaryTranslucentPressed = "rgba(6, 45, 73, 0.39)";
export const njSemanticColorBackgroundNeutralSolidDefault = "#60798b"; // Provides a distinct and noticeable contrast with the primary background. Adds a robust and substantial presence, use it to make the element stand out.
export const njSemanticColorBackgroundNeutralSolidHovered = "#435c6e";
export const njSemanticColorBackgroundNeutralSolidFocused = "#435c6e";
export const njSemanticColorBackgroundNeutralSolidPressed = "#334551";
export const njSemanticColorBackgroundNeutralInverseDefault = "#ffffff"; // Applies white as the background color, creating a striking visual effect when used on a dark-colored surface. Use to ensure readability and visual appeal in specific UI components or sections.
export const njSemanticColorBackgroundNeutralInverseHovered = "#ebeef1";
export const njSemanticColorBackgroundNeutralInverseFocused = "#ebeef1";
export const njSemanticColorBackgroundNeutralInversePressed = "#ccd4da";
export const njSemanticColorBackgroundNeutralInverseTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundNeutralInverseTranslucentHovered = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralInverseTranslucentFocused = "rgba(0, 40, 70, 0.2)";
export const njSemanticColorBackgroundNeutralInverseTranslucentPressed = "rgba(6, 45, 73, 0.39)";
export const njSemanticColorBackgroundNeutralSpotlightDefault = "#232d35"; // Creates a high contrast with the primary background. Use it for critical elements or components that require maximum emphasis and visual impact.
export const njSemanticColorBackgroundBrandPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundBrandPrimaryTranslucentHovered = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundBrandPrimaryTranslucentFocused = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundBrandPrimaryTranslucentPressed = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundBrandSecondaryDefault = "#f6f8fd"; // Establishes a subtle contrast with the primary background while using the brand color. Use it to add a touch of brand interest without overpowering the content.
export const njSemanticColorBackgroundBrandSecondaryHovered = "#e7eefc";
export const njSemanticColorBackgroundBrandSecondaryFocused = "#e7eefc";
export const njSemanticColorBackgroundBrandSecondaryPressed = "#c0d4f9";
export const njSemanticColorBackgroundBrandSecondaryTranslucentDefault = "rgba(30, 80, 205, 0.04)";
export const njSemanticColorBackgroundBrandSecondaryTranslucentHovered = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundBrandSecondaryTranslucentFocused = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundBrandSecondaryTranslucentPressed = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundBrandTertiaryDefault = "#e7eefc"; // Creates a slightly stronger contrast with the primary background while using the brand color. Use it to enhances the visual separation between different sections or elements.
export const njSemanticColorBackgroundBrandTertiaryHovered = "#c0d4f9";
export const njSemanticColorBackgroundBrandTertiaryFocused = "#c0d4f9";
export const njSemanticColorBackgroundBrandTertiaryPressed = "#7fadef";
export const njSemanticColorBackgroundBrandTertiaryTranslucentDefault = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundBrandTertiaryTranslucentHovered = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundBrandTertiaryTranslucentFocused = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundBrandTertiaryTranslucentPressed = "rgba(4, 94, 224, 0.51);";
export const njSemanticColorBackgroundBrandSolidDefault = "#007acd"; // Use to add a robust and substantial brand presence, making the elements stand out using the brand color.
export const njSemanticColorBackgroundBrandSolidHovered = "#005aa0";
export const njSemanticColorBackgroundBrandSolidFocused = "#005aa0";
export const njSemanticColorBackgroundBrandSolidPressed = "#004478";
export const njSemanticColorBackgroundBrandSpotlightDefault = "#0b2d4e"; // Creates a high contrast with the primary background while reminiscing of the brand identity. Use it for critical elements or components that require maximum emphasis and visual impact.
export const njSemanticColorBackgroundStatusDangerPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusDangerPrimaryTranslucentHovered = "rgba(255, 64, 19, 0.11)";
export const njSemanticColorBackgroundStatusDangerPrimaryTranslucentFocused = "rgba(255, 64, 19, 0.11)";
export const njSemanticColorBackgroundStatusDangerPrimaryTranslucentPressed = "rgba(255, 48, 5, 0.28)";
export const njSemanticColorBackgroundStatusDangerPrimaryTranslucentSelected = "rgba(255, 64, 19, 0.11)";
export const njSemanticColorBackgroundStatusDangerSecondaryDefault = "#fff7f5";
export const njSemanticColorBackgroundStatusDangerSecondaryHovered = "#ffeae5";
export const njSemanticColorBackgroundStatusDangerSecondaryFocused = "#ffeae5";
export const njSemanticColorBackgroundStatusDangerSecondaryPressed = "#ffc5b9";
export const njSemanticColorBackgroundStatusDangerTertiaryDefault = "#ffeae5";
export const njSemanticColorBackgroundStatusDangerTertiaryHovered = "#ffc5b9";
export const njSemanticColorBackgroundStatusDangerTertiaryFocused = "#ffc5b9";
export const njSemanticColorBackgroundStatusDangerTertiaryPressed = "#ff8979";
export const njSemanticColorBackgroundStatusDangerTertiaryTranslucentDefault = "rgba(255, 64, 19, 0.11)";
export const njSemanticColorBackgroundStatusDangerTertiaryTranslucentHovered = "rgba(255, 48, 5, 0.28)";
export const njSemanticColorBackgroundStatusDangerTertiaryTranslucentFocused = "rgba(255, 48, 5, 0.28)";
export const njSemanticColorBackgroundStatusDangerTertiaryTranslucentPressed = "rgba(255, 32, 2, 0.53);";
export const njSemanticColorBackgroundStatusDangerSolidDefault = "#db3735"; // Indicate a state of danger or criticality in your interface. Use for elements that require immediate attention due to errors, warnings, or potentially harmful actions. Serves as a powerful visual cue to alert users to potential issues and encourages careful consideration of their actions.
export const njSemanticColorBackgroundStatusDangerSolidHovered = "#aa2424";
export const njSemanticColorBackgroundStatusDangerSolidPressed = "#7f1d1b";
export const njSemanticColorBackgroundStatusDangerSolidFocused = "#aa2424";
export const njSemanticColorBackgroundStatusDangerSpotlightDefault = "#521814";
export const njSemanticColorBackgroundStatusSuccessPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusSuccessPrimaryTranslucentHovered = "rgba(13, 155, 22, 0.12)";
export const njSemanticColorBackgroundStatusSuccessPrimaryTranslucentFocused = "rgba(13, 155, 22, 0.12)";
export const njSemanticColorBackgroundStatusSuccessPrimaryTranslucentPressed = "rgba(2, 155, 18, 0.32)";
export const njSemanticColorBackgroundStatusSuccessPrimaryTranslucentSelected = "rgba(13, 155, 22, 0.12)";
export const njSemanticColorBackgroundStatusSuccessSecondaryDefault = "#f3faf4";
export const njSemanticColorBackgroundStatusSuccessSecondaryHovered = "#e2f3e3";
export const njSemanticColorBackgroundStatusSuccessSecondaryFocused = "#e2f3e3";
export const njSemanticColorBackgroundStatusSuccessSecondaryPressed = "#aedfb3";
export const njSemanticColorBackgroundStatusSuccessTertiaryDefault = "#e2f3e3";
export const njSemanticColorBackgroundStatusSuccessTertiaryHovered = "#aedfb3";
export const njSemanticColorBackgroundStatusSuccessTertiaryFocused = "#aedfb3";
export const njSemanticColorBackgroundStatusSuccessTertiaryPressed = "#63be74";
export const njSemanticColorBackgroundStatusSuccessTertiaryTranslucentDefault = "rgba(13, 155, 22, 0.12)";
export const njSemanticColorBackgroundStatusSuccessTertiaryTranslucentHovered = "rgba(2, 155, 18, 0.32)";
export const njSemanticColorBackgroundStatusSuccessTertiaryTranslucentFocused = "rgba(2, 155, 18, 0.32)";
export const njSemanticColorBackgroundStatusSuccessTertiaryTranslucentPressed = "rgba(3, 150, 31, 0.62);";
export const njSemanticColorBackgroundStatusSuccessSolidDefault = "#008836"; // Associated with successful or positive states in your interface. Apply it to elements or components that confirm successful actions or completion of tasks.
export const njSemanticColorBackgroundStatusSuccessSolidHovered = "#006725";
export const njSemanticColorBackgroundStatusSuccessSolidFocused = "#006725";
export const njSemanticColorBackgroundStatusSuccessSolidPressed = "#004d1c";
export const njSemanticColorBackgroundStatusSuccessSpotlightDefault = "#0a3315";
export const njSemanticColorBackgroundStatusWarningPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusWarningPrimaryTranslucentHovered = "rgba(255, 115, 8, 0.15)";
export const njSemanticColorBackgroundStatusWarningPrimaryTranslucentFocused = "rgba(255, 115, 8, 0.15)";
export const njSemanticColorBackgroundStatusWarningPrimaryTranslucentPressed = "rgba(255, 114, 4, 0.39)";
export const njSemanticColorBackgroundStatusWarningPrimaryTranslucentSelected = "rgba(255, 115, 8, 0.15)";
export const njSemanticColorBackgroundStatusWarningSecondaryDefault = "#fff5ed";
export const njSemanticColorBackgroundStatusWarningSecondaryHovered = "#ffeada";
export const njSemanticColorBackgroundStatusWarningSecondaryFocused = "#ffeada";
export const njSemanticColorBackgroundStatusWarningSecondaryPressed = "#ffc89d";
export const njSemanticColorBackgroundStatusWarningTertiaryDefault = "#ffeada";
export const njSemanticColorBackgroundStatusWarningTertiaryHovered = "#ffc89d";
export const njSemanticColorBackgroundStatusWarningTertiaryFocused = "#ffc89d";
export const njSemanticColorBackgroundStatusWarningTertiaryPressed = "#ff8c47";
export const njSemanticColorBackgroundStatusWarningTertiaryTranslucentDefault = "rgba(255, 115, 8, 0.15)";
export const njSemanticColorBackgroundStatusWarningTertiaryTranslucentHovered = "rgba(255, 114, 4, 0.39)";
export const njSemanticColorBackgroundStatusWarningTertiaryTranslucentFocused = "rgba(255, 114, 4, 0.39)";
export const njSemanticColorBackgroundStatusWarningTertiaryTranslucentPressed = "rgba(255, 97, 3, 0.73);";
export const njSemanticColorBackgroundStatusWarningSolidDefault = "#ff8c47"; // Conveys a warning or cautionary state in your interface. Use for elements that signify non-critical but important information, such as alerts, notices, or reminders. Helps users recognize situations that require their attention without implying immediate danger.
export const njSemanticColorBackgroundStatusWarningSolidHovered = "#d96929";
export const njSemanticColorBackgroundStatusWarningSolidFocused = "#d96929";
export const njSemanticColorBackgroundStatusWarningSolidPressed = "#bc5921";
export const njSemanticColorBackgroundStatusWarningSpotlightDefault = "#442312";
export const njSemanticColorBackgroundStatusDiscoveryPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusDiscoveryPrimaryTranslucentHovered = "rgba(93, 18, 155, 0.08)";
export const njSemanticColorBackgroundStatusDiscoveryPrimaryTranslucentFocused = "rgba(93, 18, 155, 0.08)";
export const njSemanticColorBackgroundStatusDiscoveryPrimaryTranslucentPressed = "rgba(90, 0, 160, 0.20)";
export const njSemanticColorBackgroundStatusDiscoveryPrimaryTranslucentSelected = "rgba(93, 18, 155, 0.08)";
export const njSemanticColorBackgroundStatusDiscoverySecondaryDefault = "#faf7fc";
export const njSemanticColorBackgroundStatusDiscoverySecondaryHovered = "#f2ecf7";
export const njSemanticColorBackgroundStatusDiscoverySecondaryFocused = "#f2ecf7";
export const njSemanticColorBackgroundStatusDiscoverySecondaryPressed = "#deccec";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryDefault = "#f2ecf7";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryHovered = "#deccec";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryFocused = "#deccec";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryPressed = "#bf9ed9";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryTranslucentDefault = "rgba(93, 18, 155, 0.08)";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryTranslucentHovered = "rgba(90, 0, 160, 0.20)";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryTranslucentFocused = "rgba(90, 0, 160, 0.20)";
export const njSemanticColorBackgroundStatusDiscoveryTertiaryTranslucentPressed = "rgba(91, 6, 158, 0.39);";
export const njSemanticColorBackgroundStatusDiscoverySolidDefault = "#9261b7"; // Employed to highlight discovery or exploration-related content in your interface. Use to draw attention to new features, recommendations, or content suggestions. Invites users to explore and engage with new offerings, creating an exciting and engaging experience.
export const njSemanticColorBackgroundStatusDiscoverySolidHovered = "#744299";
export const njSemanticColorBackgroundStatusDiscoverySolidFocused = "#744299";
export const njSemanticColorBackgroundStatusDiscoverySolidPressed = "#533669";
export const njSemanticColorBackgroundStatusDiscoverySpotlightDefault = "#312838";
export const njSemanticColorBackgroundStatusInformationPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusInformationPrimaryTranslucentHovered = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundStatusInformationPrimaryTranslucentFocused = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundStatusInformationPrimaryTranslucentPressed = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundStatusInformationPrimaryTranslucentSelected = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundStatusInformationSolidDefault = "#007acd"; // Used for informational elements in your interface. Conveys non-critical information or provides additional details. Use for components or supplementary content that enhances the user's understanding of the interface without causing distraction.
export const njSemanticColorBackgroundStatusInformationSolidHovered = "#005aa0";
export const njSemanticColorBackgroundStatusInformationSolidFocused = "#005aa0";
export const njSemanticColorBackgroundStatusInformationSolidPressed = "#004478";
export const njSemanticColorBackgroundStatusInformationSecondaryDefault = "#f6f8fd";
export const njSemanticColorBackgroundStatusInformationSecondaryHovered = "#e7eefc";
export const njSemanticColorBackgroundStatusInformationSecondaryFocused = "#e7eefc";
export const njSemanticColorBackgroundStatusInformationSecondaryPressed = "#c0d4f9";
export const njSemanticColorBackgroundStatusInformationTertiaryDefault = "#e7eefc";
export const njSemanticColorBackgroundStatusInformationTertiaryHovered = "#c0d4f9";
export const njSemanticColorBackgroundStatusInformationTertiaryFocused = "#c0d4f9";
export const njSemanticColorBackgroundStatusInformationTertiaryPressed = "#7fadef";
export const njSemanticColorBackgroundStatusInformationTertiaryTranslucentDefault = "rgba(15, 85, 225, 0.1)";
export const njSemanticColorBackgroundStatusInformationTertiaryTranslucentHovered = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundStatusInformationTertiaryTranslucentFocused = "rgba(3, 83, 231, 0.25)";
export const njSemanticColorBackgroundStatusInformationTertiaryTranslucentPressed = "rgba(4, 94, 224, 0.51);";
export const njSemanticColorBackgroundStatusInformationSpotlightDefault = "#0b2d4e";
export const njSemanticColorBackgroundStatusPlanetPrimaryTranslucentDefault = "rgba(0, 0, 0, 0)";
export const njSemanticColorBackgroundStatusPlanetPrimaryTranslucentHovered = "rgba(8, 182, 175, 0.15)";
export const njSemanticColorBackgroundStatusPlanetPrimaryTranslucentFocused = "rgba(8, 182, 175, 0.15)";
export const njSemanticColorBackgroundStatusPlanetPrimaryTranslucentPressed = "rgba(3, 179, 174, 0.42)";
export const njSemanticColorBackgroundStatusPlanetPrimaryTranslucentSelected = "rgba(8, 182, 175, 0.15)";
export const njSemanticColorBackgroundStatusPlanetSolidDefault = "#008480";
export const njSemanticColorBackgroundStatusPlanetSolidHovered = "#0a6361";
export const njSemanticColorBackgroundStatusPlanetSolidPressed = "#154948";
export const njSemanticColorBackgroundStatusPlanetSolidFocused = "#0a6361";
export const njSemanticColorBackgroundStatusPlanetSecondaryDefault = "#f1fafa";
export const njSemanticColorBackgroundStatusPlanetSecondaryHovered = "#daf4f3";
export const njSemanticColorBackgroundStatusPlanetSecondaryFocused = "#daf4f3";
export const njSemanticColorBackgroundStatusPlanetSecondaryPressed = "#95dfdd";
export const njSemanticColorBackgroundStatusPlanetTertiaryDefault = "#daf4f3";
export const njSemanticColorBackgroundStatusPlanetTertiaryHovered = "#95dfdd";
export const njSemanticColorBackgroundStatusPlanetTertiaryFocused = "#95dfdd";
export const njSemanticColorBackgroundStatusPlanetTertiaryPressed = "#3dbcb8";
export const njSemanticColorBackgroundStatusPlanetTertiaryTranslucentDefault = "rgba(8, 182, 175, 0.15)";
export const njSemanticColorBackgroundStatusPlanetTertiaryTranslucentHovered = "rgba(3, 179, 174, 0.42)";
export const njSemanticColorBackgroundStatusPlanetTertiaryTranslucentFocused = "rgba(3, 179, 174, 0.42)";
export const njSemanticColorBackgroundStatusPlanetTertiaryTranslucentPressed = "rgba(3, 168, 163, 0.77);";
export const njSemanticColorBackgroundStatusPlanetSpotlightDefault = "#192f2f";
export const njSemanticColorBackgroundAccentPinkSecondaryDefault = "#fff4f9"; // Subtle touch of pink with no meaning tied to the color.
export const njSemanticColorBackgroundAccentPinkSecondaryHovered = "#ffe9f3";
export const njSemanticColorBackgroundAccentPinkSecondaryFocused = "#ffe9f3";
export const njSemanticColorBackgroundAccentPinkSecondaryPressed = "#ffc1df";
export const njSemanticColorBackgroundAccentPinkTertiaryDefault = "#ffe9f3"; // Soft pink background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentPinkTertiaryHovered = "#ffc1df";
export const njSemanticColorBackgroundAccentPinkTertiaryFocused = "#ffc1df";
export const njSemanticColorBackgroundAccentPinkTertiaryPressed = "#ff80bd";
export const njSemanticColorBackgroundAccentPinkSolidDefault = "#e01b78"; // Suitable for pink backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentPinkSolidHovered = "#963563";
export const njSemanticColorBackgroundAccentPinkSolidFocused = "#963563";
export const njSemanticColorBackgroundAccentPinkSolidPressed = "#5c3749";
export const njSemanticColorBackgroundAccentPinkSpotlightDefault = "#34282e"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentRedSecondaryDefault = "#fff7f5"; // Subtle touch of red with no meaning tied to the color.
export const njSemanticColorBackgroundAccentRedSecondaryHovered = "#ffeae5";
export const njSemanticColorBackgroundAccentRedSecondaryFocused = "#ffeae5";
export const njSemanticColorBackgroundAccentRedSecondaryPressed = "#ffc5b9";
export const njSemanticColorBackgroundAccentRedTertiaryDefault = "#ffeae5"; // Soft red background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentRedTertiaryHovered = "#ffc5b9";
export const njSemanticColorBackgroundAccentRedTertiaryFocused = "#ffc5b9";
export const njSemanticColorBackgroundAccentRedTertiaryPressed = "#ff8979";
export const njSemanticColorBackgroundAccentRedSolidDefault = "#db3735"; // Suitable for red backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentRedSpotlightDefault = "#521814"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentOrangeSecondaryDefault = "#fff5ed"; // Subtle touch of orange with no meaning tied to the color.
export const njSemanticColorBackgroundAccentOrangeSecondaryHovered = "#ffeada";
export const njSemanticColorBackgroundAccentOrangeSecondaryFocused = "#ffeada";
export const njSemanticColorBackgroundAccentOrangeSecondaryPressed = "#ffc89d";
export const njSemanticColorBackgroundAccentOrangeTertiaryDefault = "#ffeada"; // Soft orange background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentOrangeTertiaryHovered = "#ffc89d";
export const njSemanticColorBackgroundAccentOrangeTertiaryFocused = "#ffc89d";
export const njSemanticColorBackgroundAccentOrangeTertiaryPressed = "#ff8c47";
export const njSemanticColorBackgroundAccentOrangeSolidDefault = "#bc5921"; // Suitable for orange backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentOrangeSolidHovered = "#8e441a";
export const njSemanticColorBackgroundAccentOrangeSolidFocused = "#8e441a";
export const njSemanticColorBackgroundAccentOrangeSolidPressed = "#693315";
export const njSemanticColorBackgroundAccentOrangeSpotlightDefault = "#442312"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentYellowSecondaryDefault = "#fff7e0"; // Subtle touch of yellow with no meaning tied to the color.
export const njSemanticColorBackgroundAccentYellowSecondaryHovered = "#ffeeb4";
export const njSemanticColorBackgroundAccentYellowSecondaryFocused = "#ffeeb4";
export const njSemanticColorBackgroundAccentYellowSecondaryPressed = "#ffcc59";
export const njSemanticColorBackgroundAccentYellowTertiaryDefault = "#ffeeb4"; // Soft yellow background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentYellowTertiaryHovered = "#ffcc59";
export const njSemanticColorBackgroundAccentYellowTertiaryFocused = "#ffcc59";
export const njSemanticColorBackgroundAccentYellowTertiaryPressed = "#d5a334";
export const njSemanticColorBackgroundAccentYellowSolidDefault = "#ffcc59"; // Suitable for yellow backgrounds when there is no meaning tied to the color. No minimum contrast requirement met!
export const njSemanticColorBackgroundAccentYellowSolidHovered = "#d5a334";
export const njSemanticColorBackgroundAccentYellowSolidFocused = "#d5a334";
export const njSemanticColorBackgroundAccentYellowSolidPressed = "#ab8327";
export const njSemanticColorBackgroundAccentYellowSpotlightDefault = "#362a13"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentGreenSecondaryDefault = "#f3faf4"; // Subtle touch of green with no meaning tied to the color.
export const njSemanticColorBackgroundAccentGreenSecondaryHovered = "#e2f3e3";
export const njSemanticColorBackgroundAccentGreenSecondaryFocused = "#e2f3e3";
export const njSemanticColorBackgroundAccentGreenSecondaryPressed = "#aedfb3";
export const njSemanticColorBackgroundAccentGreenTertiaryDefault = "#e2f3e3"; // Soft green background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentGreenTertiaryHovered = "#aedfb3";
export const njSemanticColorBackgroundAccentGreenTertiaryFocused = "#aedfb3";
export const njSemanticColorBackgroundAccentGreenTertiaryPressed = "#63be74";
export const njSemanticColorBackgroundAccentGreenSolidDefault = "#008836"; // Suitable for green backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentGreenSolidHovered = "#006725";
export const njSemanticColorBackgroundAccentGreenSolidFocused = "#006725";
export const njSemanticColorBackgroundAccentGreenSolidPressed = "#004d1c";
export const njSemanticColorBackgroundAccentGreenSpotlightDefault = "#0a3315"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentGreySecondaryDefault = "#f6f8f9"; // Subtle touch of grey with no meaning tied to the color.
export const njSemanticColorBackgroundAccentGreySecondaryHovered = "#ebeef1";
export const njSemanticColorBackgroundAccentGreySecondaryFocused = "#ebeef1";
export const njSemanticColorBackgroundAccentGreySecondaryPressed = "#ccd4da";
export const njSemanticColorBackgroundAccentGreyTertiaryDefault = "#ebeef1"; // Soft grey background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentGreyTertiaryHovered = "#ccd4da";
export const njSemanticColorBackgroundAccentGreyTertiaryFocused = "#ccd4da";
export const njSemanticColorBackgroundAccentGreyTertiaryPressed = "#9eadb8";
export const njSemanticColorBackgroundAccentGreySolidDefault = "#60798b"; // Suitable for grey backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentGreySolidHovered = "#435c6e";
export const njSemanticColorBackgroundAccentGreySolidFocused = "#435c6e";
export const njSemanticColorBackgroundAccentGreySolidPressed = "#334551";
export const njSemanticColorBackgroundAccentGreySpotlightDefault = "#232d35"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentBlueSecondaryDefault = "#f6f8fd"; // Subtle touch of blue with no meaning tied to the color.
export const njSemanticColorBackgroundAccentBlueSecondaryHovered = "#e7eefc";
export const njSemanticColorBackgroundAccentBlueSecondaryFocused = "#e7eefc";
export const njSemanticColorBackgroundAccentBlueSecondaryPressed = "#c0d4f9";
export const njSemanticColorBackgroundAccentBlueTertiaryDefault = "#e7eefc"; // Soft blue background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentBlueTertiaryHovered = "#c0d4f9";
export const njSemanticColorBackgroundAccentBlueTertiaryFocused = "#c0d4f9";
export const njSemanticColorBackgroundAccentBlueTertiaryPressed = "#7fadef";
export const njSemanticColorBackgroundAccentBlueSolidDefault = "#007acd"; // Suitable for blue backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentBlueSolidHovered = "#005aa0";
export const njSemanticColorBackgroundAccentBlueSolidFocused = "#005aa0";
export const njSemanticColorBackgroundAccentBlueSolidPressed = "#004478";
export const njSemanticColorBackgroundAccentBlueSpotlightDefault = "#0b2d4e"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentUltramarineSecondaryDefault = "#f6f7fb"; // Subtle touch of ultramarine with no meaning tied to the color.
export const njSemanticColorBackgroundAccentUltramarineSecondaryHovered = "#eceef7";
export const njSemanticColorBackgroundAccentUltramarineSecondaryFocused = "#eceef7";
export const njSemanticColorBackgroundAccentUltramarineSecondaryPressed = "#ccd1e9";
export const njSemanticColorBackgroundAccentUltramarineTertiaryDefault = "#eceef7"; // Soft ultramarine background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentUltramarineTertiaryHovered = "#ccd1e9";
export const njSemanticColorBackgroundAccentUltramarineTertiaryFocused = "#ccd1e9";
export const njSemanticColorBackgroundAccentUltramarineTertiaryPressed = "#9fa9d4";
export const njSemanticColorBackgroundAccentUltramarineSolidDefault = "#6372b2"; // Suitable for ultramarine backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentUltramarineSolidHovered = "#44549b";
export const njSemanticColorBackgroundAccentUltramarineSolidFocused = "#44549b";
export const njSemanticColorBackgroundAccentUltramarineSolidPressed = "#2b3d83";
export const njSemanticColorBackgroundAccentUltramarineSpotlightDefault = "#182663"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentPurpleSecondaryDefault = "#faf7fc"; // Subtle touch of purple with no meaning tied to the color.
export const njSemanticColorBackgroundAccentPurpleSecondaryHovered = "#f2ecf7";
export const njSemanticColorBackgroundAccentPurpleSecondaryFocused = "#f2ecf7";
export const njSemanticColorBackgroundAccentPurpleSecondaryPressed = "#deccec";
export const njSemanticColorBackgroundAccentPurpleTertiaryDefault = "#f2ecf7"; // Soft purple background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentPurpleTertiaryHovered = "#deccec";
export const njSemanticColorBackgroundAccentPurpleTertiaryFocused = "#deccec";
export const njSemanticColorBackgroundAccentPurpleTertiaryPressed = "#bf9ed9";
export const njSemanticColorBackgroundAccentPurpleSolidDefault = "#9261b7"; // Suitable for purple backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentPurpleSolidHovered = "#744299";
export const njSemanticColorBackgroundAccentPurpleSolidFocused = "#744299";
export const njSemanticColorBackgroundAccentPurpleSolidPressed = "#533669";
export const njSemanticColorBackgroundAccentPurpleSpotlightDefault = "#312838"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentTealSecondaryDefault = "#f1fafa"; // Subtle touch of teal with no meaning tied to the color.
export const njSemanticColorBackgroundAccentTealSecondaryHovered = "#daf4f3";
export const njSemanticColorBackgroundAccentTealSecondaryFocused = "#daf4f3";
export const njSemanticColorBackgroundAccentTealSecondaryPressed = "#95dfdd";
export const njSemanticColorBackgroundAccentTealTertiaryDefault = "#daf4f3"; // Soft teal background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentTealTertiaryHovered = "#95dfdd";
export const njSemanticColorBackgroundAccentTealTertiaryFocused = "#95dfdd";
export const njSemanticColorBackgroundAccentTealTertiaryPressed = "#3dbcb8";
export const njSemanticColorBackgroundAccentTealSolidDefault = "#008480"; // Suitable for teal backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentTealSolidHovered = "#0a6361";
export const njSemanticColorBackgroundAccentTealSolidFocused = "#0a6361";
export const njSemanticColorBackgroundAccentTealSolidPressed = "#154948";
export const njSemanticColorBackgroundAccentTealSpotlightDefault = "#192f2f"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAccentLimeSecondaryDefault = "#f8fad5"; // Subtle touch of lime with no meaning tied to the color.
export const njSemanticColorBackgroundAccentLimeSecondaryHovered = "#eef5a8";
export const njSemanticColorBackgroundAccentLimeSecondaryFocused = "#eef5a8";
export const njSemanticColorBackgroundAccentLimeSecondaryPressed = "#c7de43";
export const njSemanticColorBackgroundAccentLimeTertiaryDefault = "#eef5a8"; // Soft lime background with no meaning tied to the color, slightly stronger visual contrast than the secondary.
export const njSemanticColorBackgroundAccentLimeTertiaryHovered = "#c7de43";
export const njSemanticColorBackgroundAccentLimeTertiaryFocused = "#c7de43";
export const njSemanticColorBackgroundAccentLimeTertiaryPressed = "#9db603";
export const njSemanticColorBackgroundAccentLimeSolidDefault = "#6c7d02"; // Suitable for lime backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1).
export const njSemanticColorBackgroundAccentLimeSolidHovered = "#525f0a";
export const njSemanticColorBackgroundAccentLimeSolidFocused = "#525f0a";
export const njSemanticColorBackgroundAccentLimeSolidPressed = "#3e460e";
export const njSemanticColorBackgroundAccentLimeSpotlightDefault = "#2a2e0e"; // Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface
export const njSemanticColorBackgroundAdditionalEngieRayDefault = "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)"; // ENGIE's brand gradient. We strongly advise to refer to the brand guidelines for its usage.
export const njSemanticColorBackgroundAdditionalSignatureDefault = "#182663"; // ENGIE's additional brand color. We strongly advise to refer to the brand guidelines for its usage.
export const njSemanticColorBorderNeutralMinimal = "#ebeef1"; // Almost imperceptible, provides a gentle visual separation between elements without drawing much attention to itself. Used for separators.
export const njSemanticColorBorderNeutralSubtle = "#ccd4da"; // Offers a subtle visual distinction between elements, without overpowering the content. Used for separators and cards.
export const njSemanticColorBorderNeutralModerate = "#9eadb8"; // Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming. Use for tags and inputs.
export const njSemanticColorBorderNeutralStrong = "#60798b"; // Bold border, creates a striking visual division between elements. Use for notifications, inline messages and focus ring.
export const njSemanticColorBorderNeutralHeavy = "#232d35"; // Highest level of visual differentiation. Has a strong contrast with the background, making it visually prominent and attention-grabbing. Use for important alerts.
export const njSemanticColorBorderNeutralInverse = "#ffffff"; // Allows the application of a border when used on a contrasted background. Use for inverse buttons or inverse cards.
export const njSemanticColorBorderBrandSubtle = "#c0d4f9"; // Provides a gentle visual separation between elements, with a touch of Engie's brand identity.Suitable for elements where a subtle brand presence is desired.
export const njSemanticColorBorderBrandModerate = "#7fadef"; // Provides a clear separation between elements with the brand identity. Use for tags and inputs.
export const njSemanticColorBorderBrandStrong = "#007acd"; // Bold brand border, creates a striking visual division between elements. Use for borders or visual indicators of elements that reinforce Engie's brand, such as notifications, inline messages and focus ring.
export const njSemanticColorBorderStatusDangerSubtle = "#ffc5b9"; // Subtle way to communicate critical information without overpowering the content. Use for separators and cards.
export const njSemanticColorBorderStatusDangerModerate = "#ff8979"; // Communicates critical information in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming. Use for tags and inputs.
export const njSemanticColorBorderStatusDangerStrong = "#db3735"; // Intended to indicate a state of danger or criticality in your interface. Use for elements that require immediate attention due to errors, warnings, or potentially harmful actions. Serves as a powerful visual cue to alert users to potential issues and encourages careful consideration of their actions.
export const njSemanticColorBorderStatusWarningSubtle = "#ffc89d"; // Subtle way to communicate warning information without overpowering the content. Use for separators and cards.
export const njSemanticColorBorderStatusWarningModerate = "#ff8c47"; // Communicates warning information in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming.
export const njSemanticColorBorderStatusWarningStrong = "#bc5921"; // Conveys a warning or cautionary state in your interface. Use for elements that signify non-critical but important information, such as alerts, notices, or reminders. Helps users recognize situations that require their attention without implying immediate danger.
export const njSemanticColorBorderStatusSuccessSubtle = "#aedfb3"; // Subtle way to communicate success information or positive states without overpowering the content. Use for separators and cards.
export const njSemanticColorBorderStatusSuccessModerate = "#63be74"; // Communicates successful or positive states in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming.
export const njSemanticColorBorderStatusSuccessStrong = "#008836"; // Associated with successful or positive states in your interface. Apply it to elements or components that confirm successful actions or completion of tasks.
export const njSemanticColorBorderStatusDiscoverySubtle = "#deccec"; // Subtle way to highlight discovery or exploration-related content in your interface without overpowering the content. Use for separators and cards.
export const njSemanticColorBorderStatusDiscoveryModerate = "#bf9ed9"; // Highlights discovery or communicates exploration-related content in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming.
export const njSemanticColorBorderStatusDiscoveryStrong = "#9261b7"; // Employed to highlight discovery or exploration-related content in your interface. Use to draw attention to new features, recommendations, or content suggestions. Invites users to explore and engage with new offerings, creating an exciting and engaging experience.
export const njSemanticColorBorderStatusInformationSubtle = "#c0d4f9"; // Subtle way to highlight informational elements in your interface without overpowering the content. Use for separators and cards.
export const njSemanticColorBorderStatusInformationModerate = "#7fadef"; // Highlights informational elements in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming.
export const njSemanticColorBorderStatusInformationStrong = "#007acd"; // Used for informational elements in your interface. Conveys non-critical information or provides additional details. Use for components or supplementary content that enhances the user's understanding of the interface without causing distraction.
export const njSemanticColorBorderStatusPlanetSubtle = "#95dfdd";
export const njSemanticColorBorderStatusPlanetModerate = "#3dbcb8";
export const njSemanticColorBorderStatusPlanetStrong = "#008480";
export const njSemanticColorBorderAccentPinkSubtle = "#ffc1df";
export const njSemanticColorBorderAccentPinkModerate = "#ff80bd"; // Suitable for pink borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentPinkStrong = "#e01b78";
export const njSemanticColorBorderAccentRedSubtle = "#ffc5b9";
export const njSemanticColorBorderAccentRedModerate = "#ff8979"; // Suitable for red borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentRedStrong = "#db3735";
export const njSemanticColorBorderAccentOrangeSubtle = "#ffc89d";
export const njSemanticColorBorderAccentOrangeModerate = "#ff8c47"; // Suitable for orange borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentOrangeStrong = "#bc5921";
export const njSemanticColorBorderAccentYellowSubtle = "#ffcc59";
export const njSemanticColorBorderAccentYellowModerate = "#d5a334"; // Suitable for yellow borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentYellowStrong = "#947023";
export const njSemanticColorBorderAccentGreenSubtle = "#aedfb3";
export const njSemanticColorBorderAccentGreenModerate = "#63be74"; // Suitable for green borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentGreenStrong = "#008836";
export const njSemanticColorBorderAccentGreySubtle = "#ccd4da";
export const njSemanticColorBorderAccentGreyModerate = "#9eadb8"; // Suitable for grey borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentGreyStrong = "#60798b";
export const njSemanticColorBorderAccentBlueSubtle = "#c0d4f9";
export const njSemanticColorBorderAccentBlueModerate = "#7fadef"; // Suitable for blue borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentBlueStrong = "#007acd";
export const njSemanticColorBorderAccentUltramarineSubtle = "#ccd1e9";
export const njSemanticColorBorderAccentUltramarineModerate = "#9fa9d4"; // Suitable for ultramarine borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentUltramarineStrong = "#6372b2";
export const njSemanticColorBorderAccentPurpleSubtle = "#deccec";
export const njSemanticColorBorderAccentPurpleModerate = "#bf9ed9"; // Suitable for purple borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentPurpleStrong = "#9261b7";
export const njSemanticColorBorderAccentTealSubtle = "#95dfdd";
export const njSemanticColorBorderAccentTealModerate = "#3dbcb8"; // Suitable for teal borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentTealStrong = "#008480";
export const njSemanticColorBorderAccentLimeSubtle = "#c7de43";
export const njSemanticColorBorderAccentLimeModerate = "#9db603"; // Suitable for lime borders when there is no meaning tied to the color.
export const njSemanticColorBorderAccentLimeStrong = "#6c7d02";
export const njSemanticColorIconNeutralPrimaryDefault = "#334551"; // Use for visible and prominent icons, ensures they are easily recognizable and stand out. Typically used for essential actions or navigation elements that require immediate user attention.
export const njSemanticColorIconNeutralSecondaryDefault = "#60798b"; // Use for less visible icons, offering a subtle presence that complements the primary content. Used for secondary actions or to support elements within the interface.
export const njSemanticColorIconNeutralSecondaryHovered = "#435c6e";
export const njSemanticColorIconNeutralSecondaryFocused = "#435c6e";
export const njSemanticColorIconNeutralSecondaryPressed = "#334551";
export const njSemanticColorIconNeutralTertiaryDefault = "#778c9b"; // Use for icons with even lower visibility, providing a more understated appearance. These icons are used for tertiary actions or optional elements, contributing to a more streamlined and unobtrusive user experience.
export const njSemanticColorIconNeutralTertiaryHovered = "#60798b";
export const njSemanticColorIconNeutralTertiaryFocused = "#60798b";
export const njSemanticColorIconNeutralTertiaryPressed = "#435c6e";
export const njSemanticColorIconNeutralContrastDefault = "#435c6e"; // These icons utilizes higher contrast or distinct visual treatments to create emphasis and draw attention. It is employed for icons that require enhanced visibility or a stronger visual impact.
export const njSemanticColorIconNeutralContrastHovered = "#334551";
export const njSemanticColorIconNeutralContrastFocused = "#334551";
export const njSemanticColorIconNeutralContrastPressed = "#232d35";
export const njSemanticColorIconNeutralInverseDefault = "#ffffff"; // Use for icons on dark backgrounds. Ensures readability and visual appeal, particularly in scenarios where icons are placed on contrasting surfaces.
export const njSemanticColorIconBrandPrimaryDefault = "#007acd";
export const njSemanticColorIconBrandPrimaryHovered = "#005aa0";
export const njSemanticColorIconBrandPrimaryFocused = "#005aa0";
export const njSemanticColorIconBrandPrimaryPressed = "#004478";
export const njSemanticColorIconBrandContrastDefault = "#004478";
export const njSemanticColorIconStatusDangerPrimaryDefault = "#db3735";
export const njSemanticColorIconStatusDangerPrimaryHovered = "#aa2424";
export const njSemanticColorIconStatusDangerPrimaryFocused = "#aa2424";
export const njSemanticColorIconStatusDangerPrimaryPressed = "#7f1d1b";
export const njSemanticColorIconStatusDangerContrastDefault = "#7f1d1b";
export const njSemanticColorIconStatusSuccessPrimaryDefault = "#008836";
export const njSemanticColorIconStatusSuccessPrimaryHovered = "#006725";
export const njSemanticColorIconStatusSuccessPrimaryFocused = "#006725";
export const njSemanticColorIconStatusSuccessPrimaryPressed = "#004d1c";
export const njSemanticColorIconStatusSuccessContrastDefault = "#004d1c";
export const njSemanticColorIconStatusWarningPrimaryDefault = "#bc5921";
export const njSemanticColorIconStatusWarningPrimaryHovered = "#8e441a";
export const njSemanticColorIconStatusWarningPrimaryFocused = "#8e441a";
export const njSemanticColorIconStatusWarningPrimaryPressed = "#693315";
export const njSemanticColorIconStatusWarningContrastDefault = "#693315";
export const njSemanticColorIconStatusDiscoveryPrimaryDefault = "#9261b7";
export const njSemanticColorIconStatusDiscoveryPrimaryHovered = "#744299";
export const njSemanticColorIconStatusDiscoveryPrimaryFocused = "#744299";
export const njSemanticColorIconStatusDiscoveryPrimaryPressed = "#533669";
export const njSemanticColorIconStatusDiscoveryContrastDefault = "#533669";
export const njSemanticColorIconStatusInformationPrimaryDefault = "#007acd";
export const njSemanticColorIconStatusInformationPrimaryHovered = "#005aa0";
export const njSemanticColorIconStatusInformationPrimaryFocused = "#005aa0";
export const njSemanticColorIconStatusInformationPrimaryPressed = "#004478";
export const njSemanticColorIconStatusInformationContrastDefault = "#004478";
export const njSemanticColorIconStatusPlanetPrimaryDefault = "#008480";
export const njSemanticColorIconStatusPlanetPrimaryHovered = "#0a6361";
export const njSemanticColorIconStatusPlanetPrimaryFocused = "#0a6361";
export const njSemanticColorIconStatusPlanetPrimaryPressed = "#154948";
export const njSemanticColorIconStatusPlanetContrastDefault = "#154948";
export const njSemanticColorIconAccentPinkPrimaryDefault = "#e01b78";
export const njSemanticColorIconAccentPinkPrimaryHovered = "#963563";
export const njSemanticColorIconAccentPinkPrimaryFocused = "#963563";
export const njSemanticColorIconAccentPinkPrimaryPressed = "#5c3749";
export const njSemanticColorIconAccentPinkContrastDefault = "#5c3749";
export const njSemanticColorIconAccentRedPrimaryDefault = "#db3735";
export const njSemanticColorIconAccentRedPrimaryHovered = "#aa2424";
export const njSemanticColorIconAccentRedPrimaryFocused = "#aa2424";
export const njSemanticColorIconAccentRedPrimaryPressed = "#7f1d1b";
export const njSemanticColorIconAccentRedContrastDefault = "#7f1d1b";
export const njSemanticColorIconAccentOrangePrimaryDefault = "#bc5921";
export const njSemanticColorIconAccentOrangePrimaryHovered = "#8e441a";
export const njSemanticColorIconAccentOrangePrimaryFocused = "#8e441a";
export const njSemanticColorIconAccentOrangePrimaryPressed = "#693315";
export const njSemanticColorIconAccentOrangeContrastDefault = "#693315";
export const njSemanticColorIconAccentYellowPrimaryDefault = "#947023";
export const njSemanticColorIconAccentYellowPrimaryHovered = "#6f551d";
export const njSemanticColorIconAccentYellowPrimaryFocused = "#6f551d";
export const njSemanticColorIconAccentYellowPrimaryPressed = "#533f18";
export const njSemanticColorIconAccentYellowContrastDefault = "#533f18";
export const njSemanticColorIconAccentGreenPrimaryDefault = "#008836";
export const njSemanticColorIconAccentGreenPrimaryHovered = "#006725";
export const njSemanticColorIconAccentGreenPrimaryFocused = "#006725";
export const njSemanticColorIconAccentGreenPrimaryPressed = "#004d1c";
export const njSemanticColorIconAccentGreenContrastDefault = "#004d1c";
export const njSemanticColorIconAccentGreyPrimaryDefault = "#60798b";
export const njSemanticColorIconAccentGreyPrimaryHovered = "#435c6e";
export const njSemanticColorIconAccentGreyPrimaryFocused = "#435c6e";
export const njSemanticColorIconAccentGreyPrimaryPressed = "#334551";
export const njSemanticColorIconAccentGreyContrastDefault = "#334551";
export const njSemanticColorIconAccentBluePrimaryDefault = "#007acd";
export const njSemanticColorIconAccentBluePrimaryHovered = "#005aa0";
export const njSemanticColorIconAccentBluePrimaryFocused = "#005aa0";
export const njSemanticColorIconAccentBluePrimaryPressed = "#004478";
export const njSemanticColorIconAccentBlueContrastDefault = "#004478";
export const njSemanticColorIconAccentUltramarinePrimaryDefault = "#6372b2";
export const njSemanticColorIconAccentUltramarinePrimaryHovered = "#44549b";
export const njSemanticColorIconAccentUltramarinePrimaryFocused = "#44549b";
export const njSemanticColorIconAccentUltramarinePrimaryPressed = "#2b3d83";
export const njSemanticColorIconAccentUltramarineContrastDefault = "#2b3d83";
export const njSemanticColorIconAccentPurplePrimaryDefault = "#9261b7";
export const njSemanticColorIconAccentPurplePrimaryHovered = "#744299";
export const njSemanticColorIconAccentPurplePrimaryFocused = "#744299";
export const njSemanticColorIconAccentPurplePrimaryPressed = "#533669";
export const njSemanticColorIconAccentPurpleContrastDefault = "#533669";
export const njSemanticColorIconAccentTealPrimaryDefault = "#008480";
export const njSemanticColorIconAccentTealPrimaryHovered = "#0a6361";
export const njSemanticColorIconAccentTealPrimaryFocused = "#0a6361";
export const njSemanticColorIconAccentTealPrimaryPressed = "#154948";
export const njSemanticColorIconAccentTealContrastDefault = "#154948";
export const njSemanticColorIconAccentLimePrimaryDefault = "#6c7d02";
export const njSemanticColorIconAccentLimePrimaryHovered = "#525f0a";
export const njSemanticColorIconAccentLimePrimaryFocused = "#525f0a";
export const njSemanticColorIconAccentLimePrimaryPressed = "#3e460e";
export const njSemanticColorIconAccentLimeContrastDefault = "#3e460e";
export const njSemanticColorIconAdditionalSignatureDefault = "#182663";
export const njSemanticColorTextNeutralPrimaryDefault = "#171d21"; // Reserved for the most critical and visually prominent content in the interface. Should be employed for elements like page titles and section headings, where it commands attention and establishes hierarchy.
export const njSemanticColorTextNeutralPrimaryHovered = "#007acd";
export const njSemanticColorTextNeutralPrimaryFocused = "#007acd";
export const njSemanticColorTextNeutralPrimaryPressed = "#005aa0";
export const njSemanticColorTextNeutralPrimaryNotInvertibleDefault = "#171d21";
export const njSemanticColorTextNeutralPrimaryNotInvertibleHovered = "#007acd";
export const njSemanticColorTextNeutralPrimaryNotInvertibleFocused = "#007acd";
export const njSemanticColorTextNeutralPrimaryNotInvertiblePressed = "#005aa0";
export const njSemanticColorTextNeutralSecondaryDefault = "#334551"; // Harmonious and legible contrast for various content types. Well-suited for subtitles, subheadings, and regular content, ensures a balanced and readable appearance throughout the interface.
export const njSemanticColorTextNeutralTertiaryDefault = "#60798b"; // Slightly lighter, suitable for secondary subtitles or contextual information. Ideal for additional information.
export const njSemanticColorTextNeutralTertiaryHovered = "#007acd";
export const njSemanticColorTextNeutralTertiaryFocused = "#007acd";
export const njSemanticColorTextNeutralTertiaryPressed = "#005aa0";
export const njSemanticColorTextNeutralContrastDefault = "#435c6e"; // Emphasizes elements with higher contrast to create visual hierarchy and draw attention.
export const njSemanticColorTextNeutralContrastHovered = "#004478";
export const njSemanticColorTextNeutralContrastFocused = "#004478";
export const njSemanticColorTextNeutralContrastPressed = "#0b2d4e";
export const njSemanticColorTextNeutralInverseDefault = "#ffffff"; // Used for light text on a dark background for readability and visual appeal in specific UI components.
export const njSemanticColorTextNeutralInverseHovered = "#ebeef1";
export const njSemanticColorTextNeutralInverseFocused = "#ebeef1";
export const njSemanticColorTextNeutralInversePressed = "#ccd4da";
export const njSemanticColorTextNeutralInverseNotInvertibleDefault = "#ffffff";
export const njSemanticColorTextNeutralInverseNotInvertibleHovered = "#ebeef1";
export const njSemanticColorTextNeutralInverseNotInvertibleFocused = "#ebeef1";
export const njSemanticColorTextNeutralInverseNotInvertiblePressed = "#ccd4da";
export const njSemanticColorTextBrandPrimaryDefault = "#007acd"; // Powerful tool to reinforce the brand's presence and create visual emphasis. Use strategically for text elements that are central to the brand identity or require heightened attention, such as brand names, key headlines, or links.
export const njSemanticColorTextBrandPrimaryHovered = "#005aa0";
export const njSemanticColorTextBrandPrimaryFocused = "#005aa0";
export const njSemanticColorTextBrandPrimaryPressed = "#004478";
export const njSemanticColorTextBrandContrastDefault = "#005aa0"; // Used on secondary backgrounds to keep the the contrast ratio for accessibility reasons.
export const njSemanticColorTextBrandContrastHovered = "#004478";
export const njSemanticColorTextBrandContrastFocused = "#004478";
export const njSemanticColorTextBrandContrastPressed = "#0b2d4e";
export const njSemanticColorTextStatusDangerPrimaryDefault = "#db3735";
export const njSemanticColorTextStatusDangerPrimaryHovered = "#aa2424";
export const njSemanticColorTextStatusDangerPrimaryFocused = "#aa2424";
export const njSemanticColorTextStatusDangerPrimaryPressed = "#7f1d1b";
export const njSemanticColorTextStatusDangerContrastDefault = "#aa2424";
export const njSemanticColorTextStatusDangerContrastHovered = "#7f1d1b";
export const njSemanticColorTextStatusDangerContrastFocused = "#7f1d1b";
export const njSemanticColorTextStatusDangerContrastPressed = "#521814";
export const njSemanticColorTextStatusWarningPrimaryDefault = "#bc5921";
export const njSemanticColorTextStatusWarningPrimaryHovered = "#8e441a";
export const njSemanticColorTextStatusWarningPrimaryFocused = "#8e441a";
export const njSemanticColorTextStatusWarningPrimaryPressed = "#693315";
export const njSemanticColorTextStatusWarningContrastDefault = "#8e441a";
export const njSemanticColorTextStatusWarningContrastHovered = "#693315";
export const njSemanticColorTextStatusWarningContrastFocused = "#693315";
export const njSemanticColorTextStatusWarningContrastPressed = "#442312";
export const njSemanticColorTextStatusSuccessPrimaryDefault = "#008836";
export const njSemanticColorTextStatusSuccessPrimaryHovered = "#006725";
export const njSemanticColorTextStatusSuccessPrimaryFocused = "#006725";
export const njSemanticColorTextStatusSuccessPrimaryPressed = "#004d1c";
export const njSemanticColorTextStatusSuccessContrastDefault = "#006725";
export const njSemanticColorTextStatusSuccessContrastHovered = "#004d1c";
export const njSemanticColorTextStatusSuccessContrastFocused = "#004d1c";
export const njSemanticColorTextStatusSuccessContrastPressed = "#0a3315";
export const njSemanticColorTextStatusInformationPrimaryDefault = "#007acd";
export const njSemanticColorTextStatusInformationPrimaryHovered = "#005aa0";
export const njSemanticColorTextStatusInformationPrimaryFocused = "#005aa0";
export const njSemanticColorTextStatusInformationPrimaryPressed = "#004478";
export const njSemanticColorTextStatusInformationContrastDefault = "#005aa0";
export const njSemanticColorTextStatusInformationContrastHovered = "#004478";
export const njSemanticColorTextStatusInformationContrastFocused = "#004478";
export const njSemanticColorTextStatusInformationContrastPressed = "#0b2d4e";
export const njSemanticColorTextStatusDiscoveryPrimaryDefault = "#9261b7";
export const njSemanticColorTextStatusDiscoveryPrimaryHovered = "#744299";
export const njSemanticColorTextStatusDiscoveryPrimaryFocused = "#744299";
export const njSemanticColorTextStatusDiscoveryPrimaryPressed = "#533669";
export const njSemanticColorTextStatusDiscoveryContrastDefault = "#744299";
export const njSemanticColorTextStatusDiscoveryContrastHovered = "#533669";
export const njSemanticColorTextStatusDiscoveryContrastFocused = "#533669";
export const njSemanticColorTextStatusDiscoveryContrastPressed = "#312838";
export const njSemanticColorTextStatusPlanetPrimaryDefault = "#008480";
export const njSemanticColorTextStatusPlanetPrimaryHovered = "#0a6361";
export const njSemanticColorTextStatusPlanetPrimaryFocused = "#0a6361";
export const njSemanticColorTextStatusPlanetPrimaryPressed = "#154948";
export const njSemanticColorTextStatusPlanetContrastDefault = "#0a6361";
export const njSemanticColorTextStatusPlanetContrastHovered = "#154948";
export const njSemanticColorTextStatusPlanetContrastFocused = "#154948";
export const njSemanticColorTextStatusPlanetContrastPressed = "#192f2f";
export const njSemanticColorTextAccentPinkPrimaryDefault = "#e01b78";
export const njSemanticColorTextAccentPinkPrimaryHovered = "#963563";
export const njSemanticColorTextAccentPinkPrimaryFocused = "#963563";
export const njSemanticColorTextAccentPinkPrimaryPressed = "#5c3749";
export const njSemanticColorTextAccentPinkContrastDefault = "#963563";
export const njSemanticColorTextAccentPinkContrastHovered = "#5c3749";
export const njSemanticColorTextAccentPinkContrastFocused = "#5c3749";
export const njSemanticColorTextAccentPinkContrastPressed = "#34282e";
export const njSemanticColorTextAccentRedPrimaryDefault = "#db3735";
export const njSemanticColorTextAccentRedPrimaryHovered = "#aa2424";
export const njSemanticColorTextAccentRedPrimaryFocused = "#aa2424";
export const njSemanticColorTextAccentRedPrimaryPressed = "#7f1d1b";
export const njSemanticColorTextAccentRedContrastDefault = "#aa2424";
export const njSemanticColorTextAccentRedContrastHovered = "#7f1d1b";
export const njSemanticColorTextAccentRedContrastFocused = "#7f1d1b";
export const njSemanticColorTextAccentRedContrastPressed = "#521814";
export const njSemanticColorTextAccentOrangePrimaryDefault = "#bc5921";
export const njSemanticColorTextAccentOrangePrimaryHovered = "#8e441a";
export const njSemanticColorTextAccentOrangePrimaryFocused = "#8e441a";
export const njSemanticColorTextAccentOrangePrimaryPressed = "#693315";
export const njSemanticColorTextAccentOrangeContrastDefault = "#8e441a";
export const njSemanticColorTextAccentOrangeContrastHovered = "#693315";
export const njSemanticColorTextAccentOrangeContrastFocused = "#693315";
export const njSemanticColorTextAccentOrangeContrastPressed = "#442312";
export const njSemanticColorTextAccentYellowPrimaryDefault = "#947023";
export const njSemanticColorTextAccentYellowPrimaryHovered = "#6f551d";
export const njSemanticColorTextAccentYellowPrimaryFocused = "#6f551d";
export const njSemanticColorTextAccentYellowPrimaryPressed = "#533f18";
export const njSemanticColorTextAccentYellowContrastDefault = "#6f551d";
export const njSemanticColorTextAccentYellowContrastHovered = "#533f18";
export const njSemanticColorTextAccentYellowContrastFocused = "#533f18";
export const njSemanticColorTextAccentYellowContrastPressed = "#362a13";
export const njSemanticColorTextAccentGreenPrimaryDefault = "#008836";
export const njSemanticColorTextAccentGreenPrimaryHovered = "#006725";
export const njSemanticColorTextAccentGreenPrimaryFocused = "#006725";
export const njSemanticColorTextAccentGreenPrimaryPressed = "#004d1c";
export const njSemanticColorTextAccentGreenContrastDefault = "#006725";
export const njSemanticColorTextAccentGreenContrastHovered = "#004d1c";
export const njSemanticColorTextAccentGreenContrastFocused = "#004d1c";
export const njSemanticColorTextAccentGreenContrastPressed = "#0a3315";
export const njSemanticColorTextAccentGreyPrimaryDefault = "#60798b";
export const njSemanticColorTextAccentGreyPrimaryHovered = "#435c6e";
export const njSemanticColorTextAccentGreyPrimaryFocused = "#435c6e";
export const njSemanticColorTextAccentGreyPrimaryPressed = "#334551";
export const njSemanticColorTextAccentGreyContrastDefault = "#435c6e";
export const njSemanticColorTextAccentGreyContrastHovered = "#334551";
export const njSemanticColorTextAccentGreyContrastFocused = "#334551";
export const njSemanticColorTextAccentGreyContrastPressed = "#232d35";
export const njSemanticColorTextAccentBluePrimaryDefault = "#007acd";
export const njSemanticColorTextAccentBluePrimaryHovered = "#005aa0";
export const njSemanticColorTextAccentBluePrimaryFocused = "#005aa0";
export const njSemanticColorTextAccentBluePrimaryPressed = "#004478";
export const njSemanticColorTextAccentBlueContrastDefault = "#005aa0";
export const njSemanticColorTextAccentBlueContrastHovered = "#004478";
export const njSemanticColorTextAccentBlueContrastFocused = "#004478";
export const njSemanticColorTextAccentBlueContrastPressed = "#0b2d4e";
export const njSemanticColorTextAccentUltramarinePrimaryDefault = "#6372b2";
export const njSemanticColorTextAccentUltramarinePrimaryHovered = "#44549b";
export const njSemanticColorTextAccentUltramarinePrimaryFocused = "#44549b";
export const njSemanticColorTextAccentUltramarinePrimaryPressed = "#2b3d83";
export const njSemanticColorTextAccentUltramarineContrastDefault = "#44549b";
export const njSemanticColorTextAccentUltramarineContrastHovered = "#2b3d83";
export const njSemanticColorTextAccentUltramarineContrastFocused = "#2b3d83";
export const njSemanticColorTextAccentUltramarineContrastPressed = "#182663";
export const njSemanticColorTextAccentPurplePrimaryDefault = "#9261b7";
export const njSemanticColorTextAccentPurplePrimaryHovered = "#744299";
export const njSemanticColorTextAccentPurplePrimaryFocused = "#744299";
export const njSemanticColorTextAccentPurplePrimaryPressed = "#533669";
export const njSemanticColorTextAccentPurpleContrastDefault = "#744299";
export const njSemanticColorTextAccentPurpleContrastHovered = "#533669";
export const njSemanticColorTextAccentPurpleContrastFocused = "#533669";
export const njSemanticColorTextAccentPurpleContrastPressed = "#312838";
export const njSemanticColorTextAccentTealPrimaryDefault = "#008480";
export const njSemanticColorTextAccentTealPrimaryHovered = "#0a6361";
export const njSemanticColorTextAccentTealPrimaryFocused = "#0a6361";
export const njSemanticColorTextAccentTealPrimaryPressed = "#154948";
export const njSemanticColorTextAccentTealContrastDefault = "#0a6361";
export const njSemanticColorTextAccentTealContrastHovered = "#154948";
export const njSemanticColorTextAccentTealContrastFocused = "#154948";
export const njSemanticColorTextAccentTealContrastPressed = "#192f2f";
export const njSemanticColorTextAccentLimePrimaryDefault = "#6c7d02";
export const njSemanticColorTextAccentLimePrimaryHovered = "#525f0a";
export const njSemanticColorTextAccentLimePrimaryFocused = "#525f0a";
export const njSemanticColorTextAccentLimePrimaryPressed = "#3e460e";
export const njSemanticColorTextAccentLimeContrastDefault = "#525f0a";
export const njSemanticColorTextAccentLimeContrastHovered = "#3e460e";
export const njSemanticColorTextAccentLimeContrastFocused = "#3e460e";
export const njSemanticColorTextAccentLimeContrastPressed = "#2a2e0e";
export const njSemanticColorTextAdditionalSignatureDefault = "#182663";
export const njSemanticElevationShadow2Dp = "0 1px 5px 0 rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .06), 0 3px 1px rgba(0, 0, 0, .06)";
export const njSemanticElevationShadow4Dp = "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 5px rgba(0, 0, 0, 0.06), 0 1px 10px rgba(0, 0, 0, 0.06)";
export const njSemanticElevationShadow6Dp = "0 3px 5px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.06), 0 1px 18px rgba(0, 0, 0, 0.06)";
export const njSemanticElevationShadow8Dp = "0 5px 5px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.06), 0 3px 14px rgba(0, 0, 0, 0.06)";
export const njSemanticElevationShadow16Dp = "0 8px 10px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.06), 0 6px 30px rgba(0, 0, 0, 0.06)";
export const njSemanticElevationShadow24Dp = "0 11px 15px rgba(0, 0, 0, 0.1), 0 24px 38px rgba(0, 0, 0, 0.06), 0 9px 46px rgba(0, 0, 0, 0.06)";
export const njSemanticElevationZIndexDropdown = "1000";
export const njSemanticElevationZIndexSticky = "1020";
export const njSemanticElevationZIndexFixed = "1030";
export const njSemanticElevationZIndexModalBackdrop = "1040";
export const njSemanticElevationZIndexModal = "1050";
export const njSemanticElevationZIndexPopover = "1060";
export const njSemanticElevationZIndexTooltip = "1070";
export const njSemanticElevationZIndexToastContainer = "1080";
export const njSemanticElevationZIndexToast = "1080";
export const njSemanticFontFamilySansSerif = "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"";
export const njSemanticFontFamilyMonospace = "\"SFMono-Regular\", \"Menlo\", \"Monaco\", \"Consolas\", \"Liberation Mono\", \"Courier New\", monospace";
export const njSemanticFontFamilyDefault = "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"";
export const njSemanticFontWeightLight = "300";
export const njSemanticFontWeightNormal = "400";
export const njSemanticFontWeightBold = "700";
export const njSemanticFontWeightBlack = "900";
export const njSemanticFontLineHeightDefault = "1.5";
export const njSemanticFontLineHeightSansSerif = "1.5";
export const njSemanticMotionEasingFastOutSlowIn = "cubic-bezier(.4, 0, .2, 1)";
export const njSemanticMotionEasingLinearOutSlowIn = "cubic-bezier(0, 0, .2, 1)";
export const njSemanticMotionEasingFastOutLinearIn = "cubic-bezier(.4, 0, 1, 1)";
export const njSemanticOpacityDisabled = "0.35";
export const njSemanticSizeBorderWidth = "0.0625rem";
export const njSemanticSizeBorderRadiusNone = "0";
export const njSemanticSizeBorderRadiusMd = "0";
export const njSemanticSizeBorderRadiusSm = "0";
export const njSemanticSizeBorderFocusRingWidth = "0.125rem";
export const njSemanticSizeBorderFocusRingOffset = "0.125rem";
export const njSemanticSizeFontContent1 = "1rem";
export const njSemanticSizeFontContent2 = "0.875rem";
export const njSemanticSizeFontContent3 = "0.75rem";
export const njSemanticSizeFontHeading1 = "3.375rem";
export const njSemanticSizeFontHeading2 = "2.25rem";
export const njSemanticSizeFontHeading3 = "1.75rem";
export const njSemanticSizeFontHeading4 = "1.5rem";
export const njSemanticSizeFontHeading5 = "1.25rem";
export const njSemanticSizeFontHeading6 = "1.125rem";
export const njSemanticSizeFontDisplay1 = "4.75rem";
export const njSemanticSizeIconSm = "1rem";
export const njSemanticSizeIconMd = "1.5rem";
export const njSemanticSizeIconLg = "2rem";
export const njSemanticSizeIconXl = "3rem";
export const njSemanticSizeIconXxl = "4rem";
export const njSemanticSizeIcon3xl = "5rem";
export const njSemanticSizeIcon4xl = "6rem";
export const njSemanticSizeIcon5xl = "7.25rem";
export const njSemanticSizeIcon6xl = "8rem";
export const njSemanticSizeSpacing0 = "0";
export const njSemanticSizeSpacing2 = "0.125rem";
export const njSemanticSizeSpacing4 = "0.25rem";
export const njSemanticSizeSpacing8 = "0.5rem";
export const njSemanticSizeSpacing12 = "0.75rem";
export const njSemanticSizeSpacing16 = "1rem";
export const njSemanticSizeSpacing20 = "1.25rem";
export const njSemanticSizeSpacing24 = "1.5rem";
export const njSemanticSizeSpacing32 = "2rem";
export const njSemanticSizeSpacing48 = "3rem";
export const njSemanticSizeSpacing64 = "4rem";
export const njSemanticSizeSpacing80 = "5rem";
export const njSemanticSizeSpacing96 = "6rem";
export const njSemanticSizeSpacing112 = "7rem";
export const njCoreColorGrey0 = "#ffffff";
export const njCoreColorGrey100 = "#f6f8f9";
export const njCoreColorGrey200 = "#ebeef1";
export const njCoreColorGrey300 = "#ccd4da";
export const njCoreColorGrey400 = "#9eadb8";
export const njCoreColorGrey500 = "#778c9b";
export const njCoreColorGrey600 = "#60798b";
export const njCoreColorGrey700 = "#435c6e";
export const njCoreColorGrey800 = "#334551";
export const njCoreColorGrey900 = "#232d35";
export const njCoreColorGrey1000 = "#171d21";
export const njCoreColorGreyAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorGreyAlpha100 = "rgba(30, 80, 105, 0.04)";
export const njCoreColorGreyAlpha200 = "rgba(5, 43, 80, 0.08)";
export const njCoreColorGreyAlpha300 = "rgba(0, 40, 70, 0.2)";
export const njCoreColorGreyAlpha400 = "rgba(6, 45, 73, 0.39)";
export const njCoreColorGreyAlpha500 = "rgba(3, 42, 70, 0.54)";
export const njCoreColorGreyAlphaInverse0 = "rgba(0, 0, 0, 0)";
export const njCoreColorGreyAlphaInverse100 = "rgba(188, 225, 229, 0.03)";
export const njCoreColorGreyAlphaInverse200 = "rgba(164, 211, 229, 0.07)";
export const njCoreColorGreyAlphaInverse300 = "rgba(168, 213, 247, 0.17)";
export const njCoreColorGreyAlphaInverse400 = "rgba(166, 222, 255, 0.31)";
export const njCoreColorGreyAlphaInverse500 = "rgba(179, 221, 251, 0.45)";
export const njCoreColorPink100 = "#fff4f9";
export const njCoreColorPink200 = "#ffe9f3";
export const njCoreColorPink300 = "#ffc1df";
export const njCoreColorPink400 = "#ff80bd";
export const njCoreColorPink500 = "#fb3492";
export const njCoreColorPink600 = "#e01b78";
export const njCoreColorPink700 = "#963563";
export const njCoreColorPink800 = "#5c3749";
export const njCoreColorPink900 = "#34282e";
export const njCoreColorRed100 = "#fff7f5";
export const njCoreColorRed200 = "#ffeae5";
export const njCoreColorRed300 = "#ffc5b9";
export const njCoreColorRed400 = "#ff8979";
export const njCoreColorRed500 = "#f34e46";
export const njCoreColorRed600 = "#db3735";
export const njCoreColorRed700 = "#aa2424";
export const njCoreColorRed800 = "#7f1d1b";
export const njCoreColorRed900 = "#521814";
export const njCoreColorRedAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorRedAlpha100 = "rgba(255, 55, 5, 0.04)";
export const njCoreColorRedAlpha200 = "rgba(255, 64, 19, 0.11)";
export const njCoreColorRedAlpha300 = "rgba(255, 48, 5, 0.28)";
export const njCoreColorRedAlpha400 = "rgba(255, 32, 2, 0.53);";
export const njCoreColorOrange100 = "#fff5ed";
export const njCoreColorOrange200 = "#ffeada";
export const njCoreColorOrange300 = "#ffc89d";
export const njCoreColorOrange400 = "#ff8c47";
export const njCoreColorOrange500 = "#d96929";
export const njCoreColorOrange600 = "#bc5921";
export const njCoreColorOrange700 = "#8e441a";
export const njCoreColorOrange800 = "#693315";
export const njCoreColorOrange900 = "#442312";
export const njCoreColorOrangeAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorOrangeAlpha100 = "rgba(255, 130, 30, 0.08)";
export const njCoreColorOrangeAlpha200 = "rgba(255, 115, 8, 0.15)";
export const njCoreColorOrangeAlpha300 = "rgba(255, 114, 4, 0.39)";
export const njCoreColorOrangeAlpha400 = "rgba(255, 97, 3, 0.73);";
export const njCoreColorYellow100 = "#fff7e0";
export const njCoreColorYellow200 = "#ffeeb4";
export const njCoreColorYellow300 = "#ffcc59";
export const njCoreColorYellow400 = "#d5a334";
export const njCoreColorYellow500 = "#ab8327";
export const njCoreColorYellow600 = "#947023";
export const njCoreColorYellow700 = "#6f551d";
export const njCoreColorYellow800 = "#533f18";
export const njCoreColorYellow900 = "#362a13";
export const njCoreColorGreen100 = "#f3faf4";
export const njCoreColorGreen200 = "#e2f3e3";
export const njCoreColorGreen300 = "#aedfb3";
export const njCoreColorGreen400 = "#63be74";
export const njCoreColorGreen500 = "#199d47";
export const njCoreColorGreen600 = "#008836";
export const njCoreColorGreen700 = "#006725";
export const njCoreColorGreen800 = "#004d1c";
export const njCoreColorGreen900 = "#0a3315";
export const njCoreColorGreenAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorGreenAlpha100 = "rgba(35, 155, 35, 0.05)";
export const njCoreColorGreenAlpha200 = "rgba(13, 155, 22, 0.12)";
export const njCoreColorGreenAlpha300 = "rgba(2, 155, 18, 0.32)";
export const njCoreColorGreenAlpha400 = "rgba(3, 150, 31, 0.62);";
export const njCoreColorBlue100 = "#f6f8fd";
export const njCoreColorBlue200 = "#e7eefc";
export const njCoreColorBlue300 = "#c0d4f9";
export const njCoreColorBlue400 = "#7fadef";
export const njCoreColorBlue500 = "#3c8cde";
export const njCoreColorBlue600 = "#007acd";
export const njCoreColorBlue700 = "#005aa0";
export const njCoreColorBlue800 = "#004478";
export const njCoreColorBlue900 = "#0b2d4e";
export const njCoreColorBlueAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorBlueAlpha100 = "rgba(30, 80, 205, 0.04)";
export const njCoreColorBlueAlpha200 = "rgba(15, 85, 225, 0.1)";
export const njCoreColorBlueAlpha300 = "rgba(3, 83, 231, 0.25)";
export const njCoreColorBlueAlpha400 = "rgba(4, 94, 224, 0.51);";
export const njCoreColorUltramarine100 = "#f6f7fb";
export const njCoreColorUltramarine200 = "#eceef7";
export const njCoreColorUltramarine300 = "#ccd1e9";
export const njCoreColorUltramarine400 = "#9fa9d4";
export const njCoreColorUltramarine500 = "#7986bf";
export const njCoreColorUltramarine600 = "#6372b2";
export const njCoreColorUltramarine700 = "#44549b";
export const njCoreColorUltramarine800 = "#2b3d83";
export const njCoreColorUltramarine900 = "#182663";
export const njCoreColorPurple100 = "#faf7fc";
export const njCoreColorPurple200 = "#f2ecf7";
export const njCoreColorPurple300 = "#deccec";
export const njCoreColorPurple400 = "#bf9ed9";
export const njCoreColorPurple500 = "#a377c5";
export const njCoreColorPurple600 = "#9261b7";
export const njCoreColorPurple700 = "#744299";
export const njCoreColorPurple800 = "#533669";
export const njCoreColorPurple900 = "#312838";
export const njCoreColorPurpleAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorPurpleAlpha100 = "rgba(130, 55, 180, 0.04)";
export const njCoreColorPurpleAlpha200 = "rgba(93, 18, 155, 0.08)";
export const njCoreColorPurpleAlpha300 = "rgba(90, 0, 160, 0.20)";
export const njCoreColorPurpleAlpha400 = "rgba(91, 6, 158, 0.39);";
export const njCoreColorTeal100 = "#f1fafa";
export const njCoreColorTeal200 = "#daf4f3";
export const njCoreColorTeal300 = "#95dfdd";
export const njCoreColorTeal400 = "#3dbcb8";
export const njCoreColorTeal500 = "#0a9994";
export const njCoreColorTeal600 = "#008480";
export const njCoreColorTeal700 = "#0a6361";
export const njCoreColorTeal800 = "#154948";
export const njCoreColorTeal900 = "#192f2f";
export const njCoreColorTealAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorTealAlpha100 = "rgba(22, 172, 172, 0.06)";
export const njCoreColorTealAlpha200 = "rgba(8, 182, 175, 0.15)";
export const njCoreColorTealAlpha300 = "rgba(3, 179, 174, 0.42)";
export const njCoreColorTealAlpha400 = "rgba(3, 168, 163, 0.77);";
export const njCoreColorLime100 = "#f8fad5";
export const njCoreColorLime200 = "#eef5a8";
export const njCoreColorLime300 = "#c7de43";
export const njCoreColorLime400 = "#9db603";
export const njCoreColorLime500 = "#7d9200";
export const njCoreColorLime600 = "#6c7d02";
export const njCoreColorLime700 = "#525f0a";
export const njCoreColorLime800 = "#3e460e";
export const njCoreColorLime900 = "#2a2e0e";
export const njCoreColorEngieBlue = "#00AAFF";
export const njCoreColorEngieGreen = "#23d2b5";
export const njCoreColorReferenceBrand100 = "#f6f8fd";
export const njCoreColorReferenceBrand200 = "#e7eefc";
export const njCoreColorReferenceBrand300 = "#c0d4f9";
export const njCoreColorReferenceBrand400 = "#7fadef";
export const njCoreColorReferenceBrand500 = "#3c8cde";
export const njCoreColorReferenceBrand600 = "#007acd";
export const njCoreColorReferenceBrand700 = "#005aa0";
export const njCoreColorReferenceBrand800 = "#004478";
export const njCoreColorReferenceBrand900 = "#0b2d4e";
export const njCoreColorReferenceBrandAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceBrandAlpha100 = "rgba(30, 80, 205, 0.04)";
export const njCoreColorReferenceBrandAlpha200 = "rgba(15, 85, 225, 0.1)";
export const njCoreColorReferenceBrandAlpha300 = "rgba(3, 83, 231, 0.25)";
export const njCoreColorReferenceBrandAlpha400 = "rgba(4, 94, 224, 0.51);";
export const njCoreColorReferenceNeutral0 = "#ffffff";
export const njCoreColorReferenceNeutral100 = "#f6f8f9";
export const njCoreColorReferenceNeutral200 = "#ebeef1";
export const njCoreColorReferenceNeutral300 = "#ccd4da";
export const njCoreColorReferenceNeutral400 = "#9eadb8";
export const njCoreColorReferenceNeutral500 = "#778c9b";
export const njCoreColorReferenceNeutral600 = "#60798b";
export const njCoreColorReferenceNeutral700 = "#435c6e";
export const njCoreColorReferenceNeutral800 = "#334551";
export const njCoreColorReferenceNeutral900 = "#232d35";
export const njCoreColorReferenceNeutral1000 = "#171d21";
export const njCoreColorReferenceNeutralAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceNeutralAlpha100 = "rgba(30, 80, 105, 0.04)";
export const njCoreColorReferenceNeutralAlpha200 = "rgba(5, 43, 80, 0.08)";
export const njCoreColorReferenceNeutralAlpha300 = "rgba(0, 40, 70, 0.2)";
export const njCoreColorReferenceNeutralAlpha400 = "rgba(6, 45, 73, 0.39)";
export const njCoreColorReferenceNeutralAlpha500 = "rgba(3, 42, 70, 0.54)";
export const njCoreColorReferenceNeutralAlphaInverse0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceNeutralAlphaInverse100 = "rgba(188, 225, 229, 0.03)";
export const njCoreColorReferenceNeutralAlphaInverse200 = "rgba(164, 211, 229, 0.07)";
export const njCoreColorReferenceNeutralAlphaInverse300 = "rgba(168, 213, 247, 0.17)";
export const njCoreColorReferenceNeutralAlphaInverse400 = "rgba(166, 222, 255, 0.31)";
export const njCoreColorReferenceNeutralAlphaInverse500 = "rgba(179, 221, 251, 0.45)";
export const njCoreColorReferenceStatusDanger100 = "#fff7f5";
export const njCoreColorReferenceStatusDanger200 = "#ffeae5";
export const njCoreColorReferenceStatusDanger300 = "#ffc5b9";
export const njCoreColorReferenceStatusDanger400 = "#ff8979";
export const njCoreColorReferenceStatusDanger500 = "#f34e46";
export const njCoreColorReferenceStatusDanger600 = "#db3735";
export const njCoreColorReferenceStatusDanger700 = "#aa2424";
export const njCoreColorReferenceStatusDanger800 = "#7f1d1b";
export const njCoreColorReferenceStatusDanger900 = "#521814";
export const njCoreColorReferenceStatusDangerAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusDangerAlpha100 = "rgba(255, 55, 5, 0.04)";
export const njCoreColorReferenceStatusDangerAlpha200 = "rgba(255, 64, 19, 0.11)";
export const njCoreColorReferenceStatusDangerAlpha300 = "rgba(255, 48, 5, 0.28)";
export const njCoreColorReferenceStatusDangerAlpha400 = "rgba(255, 32, 2, 0.53);";
export const njCoreColorReferenceStatusSuccess100 = "#f3faf4";
export const njCoreColorReferenceStatusSuccess200 = "#e2f3e3";
export const njCoreColorReferenceStatusSuccess300 = "#aedfb3";
export const njCoreColorReferenceStatusSuccess400 = "#63be74";
export const njCoreColorReferenceStatusSuccess500 = "#199d47";
export const njCoreColorReferenceStatusSuccess600 = "#008836";
export const njCoreColorReferenceStatusSuccess700 = "#006725";
export const njCoreColorReferenceStatusSuccess800 = "#004d1c";
export const njCoreColorReferenceStatusSuccess900 = "#0a3315";
export const njCoreColorReferenceStatusSuccessAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusSuccessAlpha100 = "rgba(35, 155, 35, 0.05)";
export const njCoreColorReferenceStatusSuccessAlpha200 = "rgba(13, 155, 22, 0.12)";
export const njCoreColorReferenceStatusSuccessAlpha300 = "rgba(2, 155, 18, 0.32)";
export const njCoreColorReferenceStatusSuccessAlpha400 = "rgba(3, 150, 31, 0.62);";
export const njCoreColorReferenceStatusWarning100 = "#fff5ed";
export const njCoreColorReferenceStatusWarning200 = "#ffeada";
export const njCoreColorReferenceStatusWarning300 = "#ffc89d";
export const njCoreColorReferenceStatusWarning400 = "#ff8c47";
export const njCoreColorReferenceStatusWarning500 = "#d96929";
export const njCoreColorReferenceStatusWarning600 = "#bc5921";
export const njCoreColorReferenceStatusWarning700 = "#8e441a";
export const njCoreColorReferenceStatusWarning800 = "#693315";
export const njCoreColorReferenceStatusWarning900 = "#442312";
export const njCoreColorReferenceStatusWarningAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusWarningAlpha100 = "rgba(255, 130, 30, 0.08)";
export const njCoreColorReferenceStatusWarningAlpha200 = "rgba(255, 115, 8, 0.15)";
export const njCoreColorReferenceStatusWarningAlpha300 = "rgba(255, 114, 4, 0.39)";
export const njCoreColorReferenceStatusWarningAlpha400 = "rgba(255, 97, 3, 0.73);";
export const njCoreColorReferenceStatusDiscovery100 = "#faf7fc";
export const njCoreColorReferenceStatusDiscovery200 = "#f2ecf7";
export const njCoreColorReferenceStatusDiscovery300 = "#deccec";
export const njCoreColorReferenceStatusDiscovery400 = "#bf9ed9";
export const njCoreColorReferenceStatusDiscovery500 = "#a377c5";
export const njCoreColorReferenceStatusDiscovery600 = "#9261b7";
export const njCoreColorReferenceStatusDiscovery700 = "#744299";
export const njCoreColorReferenceStatusDiscovery800 = "#533669";
export const njCoreColorReferenceStatusDiscovery900 = "#312838";
export const njCoreColorReferenceStatusDiscoveryAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusDiscoveryAlpha100 = "rgba(130, 55, 180, 0.04)";
export const njCoreColorReferenceStatusDiscoveryAlpha200 = "rgba(93, 18, 155, 0.08)";
export const njCoreColorReferenceStatusDiscoveryAlpha300 = "rgba(90, 0, 160, 0.20)";
export const njCoreColorReferenceStatusDiscoveryAlpha400 = "rgba(91, 6, 158, 0.39);";
export const njCoreColorReferenceStatusInformation100 = "#f6f8fd";
export const njCoreColorReferenceStatusInformation200 = "#e7eefc";
export const njCoreColorReferenceStatusInformation300 = "#c0d4f9";
export const njCoreColorReferenceStatusInformation400 = "#7fadef";
export const njCoreColorReferenceStatusInformation500 = "#3c8cde";
export const njCoreColorReferenceStatusInformation600 = "#007acd";
export const njCoreColorReferenceStatusInformation700 = "#005aa0";
export const njCoreColorReferenceStatusInformation800 = "#004478";
export const njCoreColorReferenceStatusInformation900 = "#0b2d4e";
export const njCoreColorReferenceStatusInformationAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusInformationAlpha100 = "rgba(30, 80, 205, 0.04)";
export const njCoreColorReferenceStatusInformationAlpha200 = "rgba(15, 85, 225, 0.1)";
export const njCoreColorReferenceStatusInformationAlpha300 = "rgba(3, 83, 231, 0.25)";
export const njCoreColorReferenceStatusInformationAlpha400 = "rgba(4, 94, 224, 0.51);";
export const njCoreColorReferenceStatusPlanet100 = "#f1fafa";
export const njCoreColorReferenceStatusPlanet200 = "#daf4f3";
export const njCoreColorReferenceStatusPlanet300 = "#95dfdd";
export const njCoreColorReferenceStatusPlanet400 = "#3dbcb8";
export const njCoreColorReferenceStatusPlanet500 = "#0a9994";
export const njCoreColorReferenceStatusPlanet600 = "#008480";
export const njCoreColorReferenceStatusPlanet700 = "#0a6361";
export const njCoreColorReferenceStatusPlanet800 = "#154948";
export const njCoreColorReferenceStatusPlanet900 = "#192f2f";
export const njCoreColorReferenceStatusPlanetAlpha0 = "rgba(0, 0, 0, 0)";
export const njCoreColorReferenceStatusPlanetAlpha100 = "rgba(22, 172, 172, 0.06)";
export const njCoreColorReferenceStatusPlanetAlpha200 = "rgba(8, 182, 175, 0.15)";
export const njCoreColorReferenceStatusPlanetAlpha300 = "rgba(3, 179, 174, 0.42)";
export const njCoreColorReferenceStatusPlanetAlpha400 = "rgba(3, 168, 163, 0.77);";
export const njCoreElevationZIndex0 = "0";
export const njCoreElevationZIndex1 = "1000";
export const njCoreElevationZIndex10 = "1010";
export const njCoreElevationZIndex20 = "1020";
export const njCoreElevationZIndex30 = "1030";
export const njCoreElevationZIndex40 = "1040";
export const njCoreElevationZIndex50 = "1050";
export const njCoreElevationZIndex60 = "1060";
export const njCoreElevationZIndex70 = "1070";
export const njCoreElevationZIndex80 = "1080";
export const njCoreElevationZIndex90 = "1090";
export const njCoreElevationZIndex100 = "1100";
export const njCoreElevationZIndex110 = "1110";
export const njCoreElevationZIndex120 = "1120";
export const njCoreElevationZIndex130 = "1130";
export const njCoreElevationZIndex140 = "1140";
export const njCoreElevationZIndex150 = "1150";
export const njCoreElevationZIndex160 = "1160";
export const njCoreElevationZIndex170 = "1170";
export const njCoreElevationZIndex180 = "1180";
export const njCoreElevationZIndex190 = "1190";
export const njCoreElevationZIndex200 = "1200";
export const njCoreElevationZIndex210 = "1210";
export const njCoreElevationZIndex220 = "1220";
export const njCoreElevationZIndex230 = "1230";
export const njCoreElevationZIndex240 = "1240";
export const njCoreElevationZIndex250 = "1250";
export const njCoreElevationZIndex260 = "1260";
export const njCoreElevationZIndex270 = "1270";
export const njCoreElevationZIndex280 = "1280";
export const njCoreElevationZIndex290 = "1290";
export const njCoreElevationZIndex300 = "1300";
export const njCoreElevationZIndex310 = "1310";
export const njCoreElevationZIndex320 = "1320";
export const njCoreElevationZIndex330 = "1330";
export const njCoreElevationZIndex340 = "1340";
export const njCoreElevationZIndex350 = "1350";
export const njCoreElevationZIndex360 = "1360";
export const njCoreElevationZIndex370 = "1370";
export const njCoreElevationZIndex380 = "1380";
export const njCoreElevationZIndex390 = "1390";
export const njCoreElevationZIndex400 = "1400";
export const njCoreElevationZIndex410 = "1410";
export const njCoreElevationZIndex420 = "1420";
export const njCoreElevationZIndex430 = "1430";
export const njCoreElevationZIndex440 = "1440";
export const njCoreElevationZIndex450 = "1450";
export const njCoreElevationZIndex460 = "1460";
export const njCoreElevationZIndex470 = "1470";
export const njCoreElevationZIndex480 = "1480";
export const njCoreElevationZIndex490 = "1490";
export const njCoreElevationZIndex500 = "1500";
export const njCoreOpacity50 = "0.05";
export const njCoreOpacity100 = "0.10";
export const njCoreOpacity150 = "0.15";
export const njCoreOpacity200 = "0.20";
export const njCoreOpacity250 = "0.25";
export const njCoreOpacity300 = "0.30";
export const njCoreOpacity350 = "0.35";
export const njCoreOpacity400 = "0.40";
export const njCoreOpacity450 = "0.45";
export const njCoreOpacity500 = "0.50";
export const njCoreOpacity550 = "0.55";
export const njCoreOpacity600 = "0.60";
export const njCoreOpacity650 = "0.65";
export const njCoreOpacity700 = "0.70";
export const njCoreOpacity750 = "0.75";
export const njCoreOpacity800 = "0.80";
export const njCoreOpacity850 = "0.85";
export const njCoreOpacity900 = "0.90";
export const njCoreOpacity950 = "0.95";
export const njCoreOpacity1000 = "1.00";
export const njCoreSize0 = "0";
export const njCoreSize1 = "0.0625rem";
export const njCoreSize2 = "0.125rem";
export const njCoreSize4 = "0.25rem";
export const njCoreSize6 = "0.375rem";
export const njCoreSize8 = "0.5rem";
export const njCoreSize10 = "0.625rem";
export const njCoreSize12 = "0.75rem";
export const njCoreSize14 = "0.875rem";
export const njCoreSize16 = "1rem";
export const njCoreSize18 = "1.125rem";
export const njCoreSize20 = "1.25rem";
export const njCoreSize22 = "1.375rem";
export const njCoreSize24 = "1.5rem";
export const njCoreSize28 = "1.75rem";
export const njCoreSize30 = "1.875rem";
export const njCoreSize32 = "2rem";
export const njCoreSize34 = "2.125rem";
export const njCoreSize36 = "2.25rem";
export const njCoreSize38 = "2.375rem";
export const njCoreSize40 = "2.5rem";
export const njCoreSize42 = "2.625rem";
export const njCoreSize44 = "2.75rem";
export const njCoreSize46 = "2.875rem";
export const njCoreSize48 = "3rem";
export const njCoreSize50 = "3.125rem";
export const njCoreSize52 = "3.25rem";
export const njCoreSize54 = "3.375rem";
export const njCoreSize56 = "3.5rem";
export const njCoreSize58 = "3.625rem";
export const njCoreSize64 = "4rem";
export const njCoreSize76 = "4.75rem";
export const njCoreSize80 = "5rem";
export const njCoreSize96 = "6rem";
export const njCoreSize112 = "7rem";
export const njCoreSize116 = "7.25rem";
export const njCoreSize128 = "8rem";
export const njComponentAvatarSizeSm = "2rem";
export const njComponentAvatarSizeBase = "3rem";
export const njComponentAvatarSizeLg = "4rem";
export const njComponentAvatarSizeXl = "6rem";
export const njComponentBackdropColorBackground = "rgba(3, 42, 70, 0.54)";
export const njComponentSkeletonColorBackground = "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(23, 29, 33, 0.05) 50%, rgba(0, 0, 0, 0) 100%)";
