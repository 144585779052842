import { easter } from "date-easter";
import { addDays, format, isSaturday, isSunday } from "date-fns";

export const isBankHolidays = (date: Date) => {
	const bankHolidays = [];
	const easterDay = easter(date.getFullYear());
	const easterDate = new Date(
		easterDay.year,
		easterDay.month - 1,
		easterDay.day,
		0,
		0,
		0
	);

	const newYear = new Date(easterDay.year, 0, 1, 0, 0, 0);
	bankHolidays.push(format(newYear, "yyyy-MM-dd"));

	bankHolidays.push(format(easterDate, "yyyy-MM-dd"));
	const easterMonday = addDays(easterDate, 1);
	bankHolidays.push(format(easterMonday, "yyyy-MM-dd"));

	const feteTravail = new Date(easterDay.year, 4, 1, 0, 0, 0);
	bankHolidays.push(format(feteTravail, "yyyy-MM-dd"));

	const ascension = addDays(easterDate, 39);
	bankHolidays.push(format(ascension, "yyyy-MM-dd"));

	const pentecote = addDays(easterDate, 49);
	bankHolidays.push(format(pentecote, "yyyy-MM-dd"));

	const pentecoteMonday = addDays(easterDate, 50);
	bankHolidays.push(format(pentecoteMonday, "yyyy-MM-dd"));

	const belgiumNational = new Date(easterDay.year, 6, 21, 0, 0, 0);
	bankHolidays.push(format(belgiumNational, "yyyy-MM-dd"));

	const assomption = new Date(easterDay.year, 7, 15, 0, 0, 0);
	bankHolidays.push(format(assomption, "yyyy-MM-dd"));

	const toussaint = new Date(easterDay.year, 10, 1, 0, 0, 0);
	bankHolidays.push(format(toussaint, "yyyy-MM-dd"));

	const armistice1418 = new Date(easterDay.year, 10, 11, 0, 0, 0);
	bankHolidays.push(format(armistice1418, "yyyy-MM-dd"));

	const noel = new Date(easterDay.year, 11, 25, 0, 0, 0);
	bankHolidays.push(format(noel, "yyyy-MM-dd"));

	return bankHolidays.includes(format(date, "yyyy-MM-dd"));
};

export const getNextWorkingDay = (date: Date) => {
	let newDate = addDays(date, 1);
	while (true) {
		const isNonWorkingDay =
			isSaturday(newDate) || isSunday(newDate) || isBankHolidays(newDate);
		if (isNonWorkingDay) {
			newDate = addDays(newDate, 1);
			continue;
		} else {
			break;
		}
	}
	return newDate;
};

export const addNonWorkingDays = (date: Date, n: number) => {
	let nextDate = date;

	for (let i = 0; i < n; i++) {
		nextDate = getNextWorkingDay(nextDate);
	}

	return nextDate;
};
