import { Typography } from "@mui/material";
import { errorColor } from "../../core/theme";
import { PropsWithChildren, useMemo } from "react";
import { If } from "./If";

const style = {
	message: {
		color: errorColor,
	},
};

export interface ErrorTextProps extends PropsWithChildren<{}> {}

export const ErrorText = (props: ErrorTextProps) => {
	const isArray = useMemo(() => {
		return Array.isArray(props.children);
	}, [props.children]);

	return (
		<>
			<If condition={!isArray}>
				<Typography sx={style.message} component="h4">
					{props.children}
				</Typography>
			</If>

			{isArray &&
				((props.children as any[]) || []).map((error, index) => (
					<Typography key={index} sx={style.message} component="h4">
						<pre>{error}</pre>
					</Typography>
				))}
		</>
	);
};
