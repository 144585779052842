import { Box, CircularProgress, DialogTitle, Typography } from "@mui/material";
import Modal from "../../../../../common/components/Modal";
import { noop } from "../../../../../common/utils/operations";

export interface GeneratingCurtailmentLoadingModalProps {
	isOpen: boolean;
}

export const GeneratingCurtailmentLoadingModal = ({
	isOpen,
}: GeneratingCurtailmentLoadingModalProps) => {
	const style = {
		container: {
			width: "580px",
			padding: "24px",
		},
		bodyContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
		},
		desription: {},
	};
	return (
		<Modal sx={style.container} open={isOpen} disableClose onClose={noop}>
			<Box sx={style.bodyContainer}>
				<CircularProgress data-testid="loader" />
				<DialogTitle>Please wait a moment...</DialogTitle>
				<Typography>
					Curtailment objects generation is in progress.
				</Typography>
			</Box>
		</Modal>
	);
};
