import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../common/hooks/default";
import { Tender } from "../tender.module";
import { selectAuthenticatedUser } from "../../authentication/auth.selector";
import { selectIsTenderActionsLoading } from "../tender.selector";
import { useCallback, useMemo, useState } from "react";
import { updateTenders } from "../tender.thunk";
import { Action } from "../../pricing_list/pricingListSlice";
import { ActionConfirmationModal } from "../components/ActionConfirmationModal";
import { useNavigate } from "react-router-dom";

export function useTenderBatchActions(
	tenders: Tender[],
	postActionCallback?: () => void
) {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(selectAuthenticatedUser);
	const actionsAreLoading = useSelector(selectIsTenderActionsLoading);
	const [currentAction, setCurrentAction] = useState<Action | null>(null);
	const navigate = useNavigate();

	const confirm = useCallback(
		async (action: any) => {
			await dispatch(
				updateTenders(
					tenders.map((tender) => tender.id),
					action,
					currentUser?.name
				)
			);
			if (postActionCallback) {
				postActionCallback();
			}
			setCurrentAction(null);
		},
		[tenders, postActionCallback, setCurrentAction]
	);

	const onTenderBatchAction = useCallback(
		async (action: Action) => {
			if (action.redirection_url) {
				navigate(action.redirection_url);
			} else if (
				action.confirmation_modal ||
				action.fields.confirmationCancel
			) {
				setCurrentAction(action);
			} else {
				confirm(action);
			}
		},
		[setCurrentAction, confirm]
	);

	const modal = useMemo(
		() => (
			<ActionConfirmationModal
				action={currentAction}
				tenders={tenders}
				open={!!currentAction}
				onClose={() => setCurrentAction(null)}
				onConfirm={() => confirm(currentAction)}
			/>
		),
		[currentAction, tenders, setCurrentAction, confirm]
	);

	return { onTenderBatchAction, actionsAreLoading, modal };
}
