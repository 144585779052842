import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "./YesNoTag.style";

const YesNoTag = ({ yes }: { yes: boolean }) => {
	const label = yes ? (
		<>
			<CheckIcon
				fontSize="small"
				sx={{ ...style.icon, ...style.iconYes }}
			/>{" "}
			Yes
		</>
	) : (
		<>
			<CloseIcon
				fontSize="small"
				sx={{ ...style.icon, ...style.iconNo }}
			/>{" "}
			No
		</>
	);

	return <Box sx={{ ...style.root, ...(!yes ? style.no : {}) }}>{label}</Box>;
};

export default YesNoTag;
