import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { memo, useMemo, useState } from "react";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { If } from "../../../../common/components/If";
import { secondaryColor } from "../../../../core/theme";
import { Tender } from "../../../tender_page/tender.module";
import { StyledAccordion, StyledAccordionSummary } from "./StyledAccordion";
import TenderInformationTable from "./TenderInformationTable";
import PencilIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useTenderActions } from "../../../tender_page/hooks/useTenderActions";

interface TenderSectionProps {
	tender?: Tender;
}

function TenderSectionComponent(props: TenderSectionProps) {
	const { tender } = props;
	const [expanded, setExpanded] = useState(true);
	const navigate = useNavigate();

	const pickupAction = useMemo(
		() =>
			tender?.actions_blotter?.filter(
				(action) => action.action === "PICKUP"
			)?.[0],
		[tender]
	);

	const { tenderAction, tenderActionModal, actionsAreLoading } =
		useTenderActions(tender);

	return (
		<If condition={!!tender?.id}>
			<Box
				display="flex"
				justifyContent="flex-start"
				sx={{ width: "100%" }}
			>
				<StyledAccordion expanded={expanded}>
					<StyledAccordionSummary
						aria-controls="tender-content"
						id="tender-header"
					>
						<PrimaryButton
							text="Tender informations"
							type="submit"
							color="info"
							sx={{
								marginRight: "16px",
								width: 200,
								backgroundColor: "#C5CED5",
								color: "#171D21",
							}}
							onClick={() => setExpanded(!expanded)}
						>
							{expanded ? (
								<ChevronDownIcon />
							) : (
								<ChevronRightIcon />
							)}
						</PrimaryButton>
						<PrimaryButton
							text="Edit tender"
							type="button"
							role="link"
							href={`/tenders/${tender?.id}`}
							onClick={(e) => {
								e.preventDefault();
								navigate(`/tenders/${tender?.id}`);
								return false;
							}}
							color="info"
							sx={{
								marginRight: "16px",
								width: 150,
								backgroundColor: "#C5CED5",
								color: "#171D21",
							}}
						>
							<PencilIcon />
						</PrimaryButton>
						<If condition={pickupAction && tender}>
							<PrimaryButton
								text={pickupAction?.display_name || ""}
								type="button"
								disabled={actionsAreLoading}
								onClick={(e) => {
									e.preventDefault();
									if (tender) {
										tenderAction(pickupAction);
									}
									return false;
								}}
								color="info"
								sx={{
									marginRight: "16px",
									width: 150,
									backgroundColor: "#C5CED5",
									color: "#171D21",
								}}
							>
								<TouchAppIcon />
							</PrimaryButton>
						</If>
					</StyledAccordionSummary>
					<AccordionDetails sx={{ backgroundColor: secondaryColor }}>
						{tender && tender?.name && (
							<TenderInformationTable model={[tender]} />
						)}
					</AccordionDetails>
				</StyledAccordion>
			</Box>
			{tenderActionModal}
		</If>
	);
}

export const TenderSection = memo(TenderSectionComponent);
